.change-input {
    position: relative;
    > input {
        padding-right: 70px !important;
        background-position: right 1px center !important;
        &.form-control {
            &:disabled {
                background-color: transparent;
            }
        }
    }
    &.is-invalid {
        > span {
            right: calc(0.75rem + 10px);
        }
    }
    > span {
        position: absolute;
        top: 50%;
        right: calc(0.75rem + 3px);
        transform: translateY(-50%);
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        font-size: 12px;
    }
}

.profile-no-sso {
    .profile-info-form{
        .btn-primary {
            margin-top: 9px;
            padding: 8px 42px;
        }
        .form-group{
            label{
                margin-bottom: 5px;
            }
            input.form-control{
                font-weight: 500;
            }
        }
    }
    .card-connected-account {
        .connect-google {
            padding: 10px 0;
            border-top: 2px #eceef0 solid;
            border-bottom: 2px #eceef0 solid;
            >button{
                background-color: transparent;
                color: $primary;
                text-decoration: underline;
                text-transform: capitalize;
                padding: 0;
                font-weight: 400;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

.profile-sso {
    .card-body {
        padding-bottom: 30px;
    }
    .sso-set-password {
        label {
            margin-right: 15px;
            margin-bottom: 0;
        }
        span {
            font-weight: 500;
            cursor: pointer;
            font-size: 12px;
        }
        p {
            margin-bottom: 22px;
            line-height: 16px;
            color: $primary;
            font-style: italic;
            font-size: 12px;
            font-weight: 400;
        }
    }
    .btn-primary{
        font-size: 13px;
        padding: 6px 42px;
    }
    .card-connected-account {
        .connect-google {
            padding: 10px 0;
            border-top: 2px #eceef0 solid;
            border-bottom: 2px #eceef0 solid;
            >button{
                background-color: transparent;
                color: $primary;
                text-decoration: underline;
                text-transform: capitalize;
                padding: 0;
                font-weight: 400;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .text-disconnect-google {
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}


.account-dropdown.subcrib{
    .dropdown-header{
        --cui-dropdown-header-padding-x: 1.5rem;
    }
}
.email-reference-box{
    .card-header{
        --cui-card-cap-bg: #fff;
        --cui-card-border-color: #e4e7ea;
    }
}