.mw-20 {
    max-width: 20%;
}

.full-screen-popup {
    .modal-dialog {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-width: 100%;
        margin: 0;

        &.modal-fullscreen {
            > .modal-content {
                overflow-y: auto;
                overflow-x: hidden;
            }

            .modal-body {
                overflow-y: unset;
            }
        }
    }

    &.roadmap-guide {
        .modal {
            z-index: 10;
        }
    }

    .talk-to-sales {
        width: 650px;
        margin: 0 auto;
        padding: 53px;
        position: relative;
        border-radius: 12px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1) !important;

        &.thank-you {
            .btn-continue {
                color: #fff;
                letter-spacing: 0.19px;
                background: #4dbd74;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.19px;
                height: 45px;
                width: 156px;
                margin-right: -8px;
                transform: translate(2px, 3px);

                &:hover {
                    background-color: #2bad58;
                }

                &.btn-white {
                    border: 1px solid #c8ced3 !important;
                    color: #73818f;
                    background: #fff;

                    &:hover {
                        background-color: #d9e1e6;
                    }
                }
            }


        }


        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 8px;
            border-radius: 12px 12px 0 0;
            background-image: url(/assets/images/line-rainbow.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
        }

        h3 {
            font-family: Poppins;
            background: linear-gradient(161deg, #29263e, #233b7d 37%, #20a8d8);
            color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 20px !important;
            font-weight: 700 !important;
            line-height: 47px;
            text-align: center;
            letter-spacing: -.09463231px;
        }

        .des {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: .11px;
            line-height: 1.5;
            color: #000;
            margin-bottom: 35px;
        }

    }
}

.popup-package {

    .modal-dialog {
        max-width: none !important;
    }

    .remove-popup .modal-dialog {
        max-width: 494px !important;
    }

    .fade.modal {
        background-color: rgba(0, 0, 0, 0.5)
    }
}

.popup-sandbox {
    // padding: 40px 0;
    // min-width: 900px;
    // max-width: 900px;
    // margin: 0 auto;
    // text-align: center;
    height: fit-content;
    .modal-dialog {
        .modal-content {
            padding: 40px 0;
            min-width: 900px;
            max-width: 900px;
            margin: 0 auto;
            text-align: center;
            border-radius: 12px !important;
        }
    }

    h3 {
        color: #212c36;
        font-family: Poppins, sans-serif;
        font-size: 28px !important;
        font-weight: 700;
        line-height: 35px;
        padding-bottom: 5px;
        background: linear-gradient(161deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #363c43;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        padding-bottom: 5px;
    }

    .btn-schedule {
        padding: 15px 30px;
        border-radius: 30px;
        font-size: 15px;
        font-weight: bold;
        color: white;
        background: #20a8d8;
        text-transform: uppercase;

        &:hover {
            background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        }
    }
}

.subscriber {
    .full-screen-wrapper {
        border-radius: 0;
        text-align: center;
        // padding-bottom: 99px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background: #F0F3F5;

        // padding: 10px;
        &.setup-guide {
            overflow-y: hidden;
        }

        h1.selectpackage {
            display: block;
            margin-top: 16px;
            font-size: 35px;
            font-weight: 600;
            /* width: 315px; */
            height: 45px;
            padding-bottom: 55px;

            &.roadmap {
                font-size: 25px;
                margin-bottom: 10px;
            }
        }

        h6.selectdecription {
            color: #18222b;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            // padding-left: 5px;

            span.link {
                color: #20a8d8;
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &.upgrade-package {
            // background: transparent;
            background: url("/assets/images/background-pricing.png") no-repeat;
            background-size: 100%;
        }

        .upgrade-package {
            background: url("/assets/images/background-pricing.png") no-repeat;
            background-size: 100%;
            background-color: #f7f9fb;
        }

        .pricing-header,
        .guide-body {
            position: relative;
            max-width: 1084px;
            margin: 0 auto;

            .guide-row {
                max-width: 722px;
                margin: 0 auto;

                .selectpackage {
                    font-weight: 700;
                    padding-bottom: 5px;
                }
            }
        }

        button.btn-back.back.btn,
        .btn-back-account {
            position: absolute;
            top: 44px;
            left: 0;
            padding-left: 0;
            margin-bottom: 20px;
            font-size: 14px;
            letter-spacing: 0.2px;
            font-weight: 500;
            color: #73818f;
            text-transform: capitalize;
            position: absolute;
            width: auto;
        }

        .list-package {

            .user-package {
                background-color: #fff;
                border-radius: 20px;
                padding: 30px 30px;
                margin: 0 auto;
                max-width: 900px;
                box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1);

                h4.title {
                    color: #212c36;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 28px;
                    padding-bottom: 22px;
                    background: linear-gradient(161deg, #29263e, #233b7d, #20a8d8);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .user-package-range {
                    width: 100%;
                }

                .user-package-main {
                    display: flex;
                    justify-content: space-between;
                }

                .user-package-content {
                    width: 200px;
                    margin-right: 40px;
                    text-align: left;

                    p {
                        color: #7f8c9a;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 25px;
                        padding-bottom: 5px;
                    }
                }

                .user-package-form {
                    flex: 1 1 0;

                    .user-package-detail {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                    }

                    .rc-slider-rail {
                        position: absolute;
                        height: 14px;
                        background-color: #e9e9e9;
                        border-radius: 6px;
                    }

                    .rc-slider-track {
                        height: 8px;
                        top: 8px;
                        background: linear-gradient(128deg, #ffc107, #20a8d8, #d8d8d8);
                        transition: all .3s ease;
                        left: 3px !important;
                        right: 3px !important;
                    }

                    .rc-slider-step {
                        display: none;
                    }

                    .rc-slider-handle {
                        margin-top: -3px;
                        width: 20px;
                        height: 20px;
                        background-color: #20a8d8;
                        border: 3px solid #fff;
                    }

                    .rc-slider-mark-text {
                        white-space: nowrap;
                        color: #20a8d8;
                        padding-top: 8px;
                        width: 60px;
                        transform: translateX(-70%) !important;
                        font-size: 14px;
                        display: none;
                    }

                }

            }

            .user-package-old {
                margin: 50px auto 0;
                max-width: 800px;

                p {
                    font-weight: 700;
                }
            }

            .package-item {
                h2 {
                    font-weight: 400;
                    color: #18222b;
                    font-size: 35px;
                    text-align: center;
                }
            }

            img.star {
                width: 15px;
                height: 15px !important;
                transform: translate(2px, 5px);
            }

            img.listener {
                width: 14px;
                height: 14px;
                transform: translate(2px, 7px);
            }
        }

        .custom-schedule {
            width: 100%;
            background-color: #fff;
            border-radius: 12px;
            padding: 40px 50px;
            margin: 50px auto 50px;
            text-align: center;
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1);

            &-title {
                color: #212c36;
                padding: 20px 0;
                margin: 0;
            }

            &-btn {
                color: #fff;
                letter-spacing: 0.19px;
                background-color: #18222B;
                color: #fff;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.19px;
                height: 45px;
                width: 156px;
                margin-right: -8px;
                transform: translate(2px, 3px)
            }

            &-info {
                padding-top: 20px;
            }

            &-desc {
                color: #212c36;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                padding: 10px 0;
                width: 100%;
                max-width: 460px;
                margin: 0 auto;
            }

            &-founder {
                color: #7f8c9a;
                line-height: 25px;

                h5 {
                    font-size: 16px !important;
                    color: #7f8c9a !important;
                    font-weight: 500 !important;
                    margin-bottom: 0 !important;
                }

                p {
                    font-size: 14px;
                    font-weight: 300;
                }
            }
        }

        .enhancement {
            background: #FFF;
            border-radius: 10px;
            padding: 30px;
            text-align: left;
            margin: 50px 0;
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1);

            &>h4 {
                font-family: "Poppins";
                font-weight: 600;
                letter-spacing: 0.04em;
                background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block;
                letter-spacing: -0.5px;
                margin: 0px;
                font-size: 24px;
                line-height: 30px;
                padding-bottom: 4px;
                word-break: break-word;
            }

            .enhancement-des {
                font-family: "Poppins";
                margin-bottom: 20px;
            }

            .enhancement-content {
                &-title {
                    font-family: "Poppins";
                    font-size: 16px;
                    font-weight: 700;
                    margin-top: 20px;
                    margin-bottom: 8px;
                }

                &-desc {
                    font-size: 14px;
                    font-family: "Poppins";
                    color: gray;
                }
            }

            .enhancement-schedule {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-title {
                    margin-bottom: 0;
                    font-weight: 400;
                }
            }
        }

        svg.icon.icon-custom-size {
            margin: 45px 0 55px;
            width: 184px;
            height: 31.05px !important;

            &.guide {
                margin: 45px 0 30px;
            }

        }

        svg.icon.icon-custom-size.icon-footer {
            width: 137px;
            height: 23px !important;
            margin: 45px 0px 30px;
        }

        .btn-back {
            position: absolute;
            top: 35px;
            left: 49px;
            padding-left: 0;
            margin-bottom: 20px;
            font-size: 14px;
            letter-spacing: 0.2px;
            font-weight: 400;
            color: #26313e;
            text-transform: capitalize;

            i {
                color: #708393;
                margin-right: 5px;
                font-weight: 400;
            }
        }

        h3 {
            font-size: 35px;
            font-weight: 300;
            color: #1f1e1e;
        }

        h6 {
            color: #000;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0.11px;
            // margin-bottom: 46px;
        }

        .popup-upgrade-package {
            .modal-dialog {
                top: 50%;
                left: 50%;
                height: auto;
                transform: translate(-50%, -50%);
                max-width: 582px;

                .icon-close-popup {
                    margin: 0;
                }
            }
        }

        .text-footer {
            color: #18222b;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            line-height: 17px;
            text-align: right;
            margin-right: 35px;
            transform: translateX(20px);

        }

        ul.d-flex.justify-content-center {
            list-style-type: none;
        }

        svg.icon.check-icon.x-icon {
            COLOR: red;
            width: 16px;
            height: 16px;
        }

        .list-package {
            // display: flex;
            // justify-content: center;
            // padding-left: 270px;
            // margin: 0 -10px;
            // margin-top: 50px;
            // flex-wrap: wrap;
            // img{
            //     padding-right: 5px;
            // }
            max-width: 900px;
            margin: 50px auto 0;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                width: 800px;
                height: 100%;
                top: 0px;
                left: 0px;
                background: transparent;
                z-index: 500;
                margin-left: -16%;
                transform: translateX(-100%);
            }

            &::after {
                position: absolute;
                content: '';
                width: 800px;
                height: 100%;
                top: 0px;
                right: 0px;
                background: transparent;
                z-index: 500;
                margin-right: -16%;
                transform: translateX(100%);
            }

            .slick-list {
                overflow: visible;
                position: relative;
                margin: 0 -10px;

                &::before {
                    content: "";
                    width: calc((100vw - 1084px) / 2);
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 8888;
                    transform: translateX(-100%);
                    background: linear-gradient(270deg, rgba(240, 243, 245, 0.5) 0%, rgba(240, 243, 245, 0.9) 100%);
                }

                &::after {
                    content: "";
                    width: calc((100vw - 1084px) / 2);
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 8888;
                    transform: translateX(100%);
                    // background: linear-gradient(270deg, rgba(240,243,245,0.9) 0% rgba(240,243,245,0.5) 100%);
                    background: linear-gradient(270deg, rgba(240, 243, 245, 0.5) 0%, rgba(240, 243, 245, 0.9) 100%);
                }

                .pricing {
                    padding-left: 9px;
                    padding-right: 3px;
                }

                // .hr {
                //     margin: 22px 15px 20px;
                //     width: 172.75px;
                //     border: 1px solid #c8ced3;
                //     height: 1px;
                // }
                hr {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    border: 0;
                    border-top: 1px solid rgba(0, 0, 0, 0.2);
                    width: 172.5px;
                    height: 1px;
                    margin: 22px auto 20px;
                }
            }

            .package-col {
                padding: 0;
            }

            .package-item {
                // width: 305px;
                // height: 395px;
                // margin: 10px;
                // padding: 36px 19px 26px;
                // border: 1px solid #e8ebed;
                // border-radius: 15px;
                //
                // position: relative;
                // overflow: hidden;
                // background-color: #fff;
                // width: 300px;
                margin: 10px;
                height: 360px;
                padding: 30px 30px 30px 25px;
                border: 1px solid #e8ebed;
                border-radius: 12px;
                box-shadow: 0 10px 10px -4px rgba(0, 0, 0, 0.03);
                position: relative;
                overflow: hidden;
                background-color: #fff;
                box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1);

                .h-auto {
                    height: auto;
                }

                .pricing {
                    h2.text {
                        font-size: 24px;
                        line-height: 42px;
                    }
                }

                ul {
                    li {
                        i {
                            font-weight: 400;
                        }
                    }
                }

                &-features {
                    padding-left: 35px;
                    text-align: left;

                    .plan-features {
                        font-size: 19px;
                        font-weight: 700;
                        line-height: 25px;
                        color: #7f8c9a;
                        margin: 20px 0;
                    }

                    .plan-features-distribute {
                        min-height: 143px;
                    }

                    .plan-features-insights {
                        min-height: 341px;
                    }

                    .plan-features-compliant {
                        min-height: 180px;
                    }

                    .plan-features-support {
                        min-height: 115px;
                    }

                    .plan-features-user {
                        p {
                            margin-bottom: 0;
                            font-weight: 400;
                        }

                        span {
                            font-size: 16px;
                            color: #20a8d8;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .plan-features-header {
                        font-size: 16px;
                        text-align: left;
                        margin-bottom: 0;
                        padding: 0;
                        margin-bottom: 10px;
                    }

                    ul {
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 400;
                        list-style: unset;
                        padding: 0;
                        margin-top: 20px;

                        li {
                            font-size: 14px;
                            line-height: 23px;
                            margin-top: 5px;
                            color: #526172;
                            margin-left: 15px;

                            span.link {
                                cursor: pointer;
                                color: #20a8d8;
                                border-bottom: 1px dashed #20a8d8;

                                &:hover {
                                    border-bottom: 1px solid;
                                }
                            }
                        }

                        .coming-soon {
                            span {
                                font-size: 14px;
                                line-height: 25px;
                                font-weight: 600;
                                color: #1a6daf;
                                margin-top: 3px;
                                display: inline-block;
                                padding-left: 15px;
                            }

                            li {
                                color: #7ca5c6;

                                .link {
                                    padding: 0;
                                    color: #7ca5c6;
                                    font-weight: 400;
                                    border-color: #7ca5c6;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                }

                &.hot-package {

                    // border-color: #ffdd75;
                    // background-color: #fff9e6;
                    // transform: scale(1.015);
                    // box-shadow: 0 16px 20px -9px rgba(0, 0, 0, 0.23);
                    // padding: 41px 20px 26px;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 5px;
                        background-image: url(/assets/images/line-rainbow.png);
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }

                    .btn-select-upgrade {
                        background-color: #4dbd74;
                        // margin-top: -4px;
                        // height: 33.5px;
                        // background-color: #18222B;
                        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
                        color: #fff;
                        font-size: 13px;
                        font-weight: 700;
                        letter-spacing: 0.19px;
                        height: 45px;
                        width: 156px;

                        &:hover {
                            // background-color: #3ea662;
                            background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
                        }
                    }

                    h5 {
                        margin-bottom: 16px;
                    }

                    ul {
                        margin-top: 32px;
                        margin-bottom: 79px;

                        li {
                            margin-bottom: 15.5px;
                        }
                    }
                }
            }

            .current-package {
                background-color: #fbfcfc !important;
                opacity: 0.7;

                &.custom-plan {
                    opacity: 1;
                    position: relative;

                    &::before {
                        top: 0;
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: #fbfcfc !important;
                        left: 0;
                        z-index: 1;
                        opacity: 0.4;
                    }

                    .btn-select-upgrade {
                        position: relative;
                        z-index: 2;
                    }
                }
            }

            .package-label {
                position: absolute;
                left: -2px;
                top: 0;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                border-bottom-right-radius: 8px;
                padding: 3px 7px;
            }

            .hot-package-label {
                color: #000 !important;
                background-color: #ffc107;
                top: -1px;
            }

            .current-label {
                color: #fff !important;
                background-color: #20a8d8;
            }

            h5 {
                text-transform: capitalize;
                margin-bottom: 13px;
                color: #000;
                line-height: 26px;
                font-family: Roboto;
                font-size: 22px;
                font-weight: 500;
                text-align: center;
                letter-spacing: 0.31428573px;
                padding-bottom: 5px;
                padding-left: 10px;

                &.title {
                    color: #20a8d8;
                    font-weight: 700;
                    cursor: pointer;
                    font-size: 20px;
                }
            }

            h3 {
                color: #18222b;
                font-size: 35px;
                font-weight: 400;
                text-align: center;
                font-family: Roboto;
                line-height: 42px;

            }

            p.des {
                font-size: 12px;
                line-height: 17px;
                // padding: 0 17px;
                padding: 5px 17px 0px;
                color: #2c3848;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.08px;
            }

            p.shortdescription {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.08px;
                line-height: 17px;
                color: #73818f;
                // padding: 0 5px;
                height: 25px;
                transform: translate(2px, -13px);
                color: #2c3848;
                font-family: Roboto;
            }

            .friendname {
                p {
                    padding: 0px;

                }
            }

            .slick-arrow {
                &:before {
                    display: none;
                }

                &.slick-next,
                &.slick-prev {
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 10.52px;
                        height: 21.05px;
                        margin: 0;
                    }
                }
            }

            ul.plan-features-body {
                margin-bottom: 77px;
                list-style-type: none;
                padding-left: 12px;

                svg.icon.check-icon {
                    width: 12px !important;
                    // height: 6.28px;
                }

                li {
                    display: flex;
                    justify-content: flex-start;
                    text-align: left;
                    // margin-bottom: 16px;
                    margin-bottom: 14px;
                    margin-bottom: 11px;
                    padding-left: 3px;

                    span {
                        // line-height: 15px;
                        // color: #000;

                        &.plan-features {
                            width: 195px;
                            height: 19px;
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 25px;
                            padding-bottom: 25px;
                            padding-left: 3px;
                        }

                        &.title {
                            padding-left: 10px;
                            transform: translateY(-2px);
                            max-width: 220px;
                            font-family: Roboto;
                            font-size: 13px;
                            font-weight: 400;
                            letter-spacing: 0.086666666px;
                        }
                    }
                }
            }

            ul {
                &.plan-features-header {
                    margin-top: 24px;
                    margin-bottom: 20px;
                    padding-top: 5px;
                    list-style-type: none;
                    padding-left: 12px;
                    min-height: 192px;
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 14px;
                        height: 2px;
                        border: 1px solid #c8ced3;
                        bottom: 1px;
                    }

                    li {
                        display: flex;
                        justify-content: flex-start;
                        text-align: left;
                        // margin-bottom: 16px;
                        margin-bottom: 10px;

                        span {

                            // line-height: 15px;
                            // color: #000;
                            &.plan-features {
                                width: 195px;
                                height: 19px;
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 25px;
                                padding-bottom: 25px;
                                padding-left: 3px;
                            }

                            &.title {
                                // padding-left: 6px;
                                // padding-left: 13px;
                                // font-family: Roboto;
                                // font-size: 13px;
                                // font-weight: 400;
                                // line-height: 30px;
                                // letter-spacing: 0.086666666px;
                                padding-left: 12px;
                                transform: translateY(-2px);
                                max-width: 220px;
                                font-family: Roboto;
                                font-size: 14px;
                            }
                        }

                        // span.title-head {
                        //     margin-left: 8px;
                        //     margin-top: -4px;
                        //     font-size: 14px;
                        //     letter-spacing: 0.093333334px;
                        // }
                    }

                    svg.icon.check-icon {
                        margin: 0px 0px 0px;
                        padding-right: 4px;
                    }
                }
            }

            .package-category {
                margin-top: 16px;
                position: relative;
                text-align: center;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 49%;
                    left: 50%;
                    width: 100%;
                    height: 25px;
                    transform: translate(-50%, -50%);
                    background-color: #b5b5b5;
                }

                span:first-child {
                    position: relative;
                    width: 100%;
                    color: #fff;
                    display: block;
                }
            }

            .btn-select-upgrade {
                color: #fff;
                letter-spacing: 0.19px;
                // height: 34px;
                background-color: #18222B;
                color: #fff;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.19px;
                height: 45px;
                width: 156px;
                margin-right: -8px;
                transform: translate(2px, 3px);
                text-transform: uppercase;

                &.disabled {
                    background-color: #c8ced3 !important;
                    opacity: 1;
                }

                &:hover {
                    background: linear-gradient(161deg, #29263e, #233b7d, #20a8d8) !important;
                }

                &.primary {
                    background: #20a8d8;
                    color: white;
                }
            }

            .slick-slider {
                .slick-dots {
                    display: none !important;
                }

                .slick-arrow {
                    background: #ffffff;
                    z-index: 999;

                    &.slick-prev {
                        left: -120px;
                        top: 152px;
                        width: 75px;
                        height: 75px;
                        background: #ffffff;
                        position: absolute;
                        border-radius: 37.5px;
                        // box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 10%);
                        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
                    }

                    &.slick-next {
                        right: -128px;
                        top: 152px;
                        width: 75px;
                        height: 75px;
                        background: #ffffff;
                        position: absolute;
                        border-radius: 37.5px;
                        // box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 10%);
                        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
                    }

                    &::before {
                        color: #73818f;
                    }
                }
            }
        }

        .get-help,
        .support-ticket-popup {
            svg.icon.icon-custom-size {
                margin: inherit;
                width: auto;
                height: auto !important;
            }
        }

        .get-help svg.icon.icon-custom-size {
            margin-right: 0.5rem;
        }

        .support-ticket-popup {
            text-align: left;
        }

        &.account-see-pricing {
            .list-package {
                max-width: 900px;
            }
        }
    }
}

.popup-notify {
    .modal-dialog {
        max-width: 640px;
    }

    .modal-content {
        position: relative;
        border-radius: 7px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-image: url(/assets/images/line-rainbow.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    .modal-body {
        padding: 40px;
        font-family: Poppins;

        h2 {
            display: inline-block;
            margin-bottom: 0.7em;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: -0.04em;
            background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p {
            font-size: 0.875rem;
            font-weight: 400;
        }

    }

    .icon-close-popup {
        top: 12px;
        right: 12px;
    }

    .group-button {
        display: flex;
        justify-content: center;
        gap: 15px;

        .btn {
            color: #fff;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 0.19px;
            padding: 10px 20px;
        }

        .btn-upgrade {
            background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        }
    }
}

@media screen and (max-width: 1222px) {

    // .full-screen-wrapper button.btn-back.back.btn {
    //     position: absolute;
    //     top: 61px;
    //     left: 80px;
    //     padding-left: 0;
    //     margin-bottom: 20px;
    //     font-size: 14px;
    //     letter-spacing: 0.2px;
    //     font-weight: 500;
    //     color: #73818f;
    //     text-transform: capitalize;
    // }

    .subscriber {
        .full-screen-wrapper {
            .list-package {
                p.des {
                    // height: 56px;
                    padding: 10px 12px 0px;
                }

                .slick-slider {
                    .slick-arrow {
                        background: #ffffff;
                        z-index: 999;

                        &.slick-prev {
                            left: 9px;
                            top: 159px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                            // box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 10%);
                        }

                        &.slick-next {
                            right: 9px;
                            top: 159px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                            // box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 10%);
                        }

                        &::before {
                            color: #73818f;
                        }
                    }
                }

                .slick-arrow {
                    &:before {
                        display: none;
                    }

                    &.slick-next,
                    &.slick-prev {
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            // width: 10px;
                            // height: 15px;
                            width: 10.52px;
                            height: 21.05px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    // button.btn-back.back.btn {
    //     position: absolute;
    //     top: 60px;
    //     left: 70px;
    //     padding-left: 0;
    //     margin-bottom: 20px;
    //     font-size: 14px;
    //     letter-spacing: 0.2px;
    //     font-weight: 500;
    //     color: #73818f;
    //     text-transform: capitalize;
    // }
}

@media screen and (max-width: 1074px) {
    .subscriber {
        .full-screen-wrapper {
            .list-package {
                p.des {
                    // height: 56px;
                    padding: 10px 7px 0px;
                }

                .slick-slider {
                    .slick-arrow {
                        background: #ffffff;
                        z-index: 999;

                        &.slick-prev {
                            left: 10px;
                            top: 159px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                        }

                        &.slick-next {
                            right: 10px;
                            top: 159px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                        }

                        &::before {
                            color: #73818f;
                        }
                    }
                }

                .slick-arrow {
                    &:before {
                        display: none;
                    }

                    &.slick-next,
                    &.slick-prev {
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 10.52px;
                            height: 21.05px;
                            margin: 0;
                        }
                    }
                }
            }

            .list-package .package-item {
                height: auto;
            }

            .list-package p.shortdescription {
                height: auto;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
    .subscriber {
        .full-screen-wrapper {
            .list-package {
                .slick-slider {
                    .slick-arrow {
                        background: #ffffff;
                        z-index: 999;

                        &.slick-prev {
                            left: 7px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                        }

                        &.slick-next {
                            right: 7px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                            ;
                        }

                        &::before {
                            color: #73818f;
                        }
                    }
                }

                // .slick-list hr {
                //     margin-top: 1rem;
                //     margin-bottom: 1rem;
                //     border: 0;
                //     border-top: 1px solid rgba(0, 0, 0, 0.2);
                //     width: 172.5px;
                //     height: 1px;
                //     margin: 23px 40px 20px;
                // }

                .slick-arrow {
                    &:before {
                        display: none;
                    }

                    &.slick-next,
                    &.slick-prev {
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 10.52px;
                            height: 21.05px;
                            margin: 0;
                        }
                    }

                    .slick-dots li button {
                        display: none;
                    }
                }
            }

            .list-package .package-item {
                height: auto;
            }

            .list-package p.shortdescription {
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .subscriber {
        .full-screen-wrapper {
            .list-package {
                .slick-slider {
                    .slick-arrow {
                        background: #ffffff;
                        z-index: 999;

                        &.slick-prev {
                            left: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                        }

                        &.slick-next {
                            right: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                            ;
                        }

                        &::before {
                            color: #73818f;
                        }
                    }
                }

                // .slick-list hr {
                //     margin-top: 1rem;
                //     margin-bottom: 1rem;
                //     border: 0;
                //     border-top: 1px solid rgba(0, 0, 0, 0.2);
                //     width: 172.5px;
                //     height: 1px;
                //     margin: 22px 30px 20px;
                // }

                .slick-arrow {
                    &:before {
                        display: none;
                    }

                    &.slick-next,
                    &.slick-prev {
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 10.52px;
                            height: 21.05px;
                            margin: 0;
                        }
                    }

                    .slick-dots li button {
                        display: none;
                    }
                }
            }

            .list-package .package-item {
                height: auto;
            }

            .list-package p.shortdescription {
                height: 40px;
            }

            .list-package p.des {
                height: 40px;
            }
        }
    }
}

@media screen and (max-width: 807px) {
    .subscriber {
        .full-screen-wrapper {
            .list-package {

                // .slick-list hr {
                //     margin-top: 1rem;
                //     margin-bottom: 1rem;
                //     border: 0;
                //     border-top: 1px solid rgba(0, 0, 0, 0.2);
                //     width: 172.5px;
                //     height: 1px;
                //     margin: 22px 10px 20px;
                // }

                .slick-slider {
                    .slick-arrow {
                        background: #ffffff;
                        z-index: 999;

                        &.slick-prev {
                            left: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                        }

                        &.slick-next {
                            right: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                            ;
                        }

                        &::before {
                            color: #73818f;
                        }
                    }
                }

                .slick-arrow {
                    &:before {
                        display: none;
                    }

                    &.slick-next,
                    &.slick-prev {
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 10.52px;
                            height: 21.05px;
                            margin: 0;
                        }
                    }

                    .slick-dots li button {
                        display: none;
                    }
                }

                .slick-dots li button {
                    display: none;
                }
            }

            .list-package .package-item {
                height: auto;
            }

            .list-package p.shortdescription {
                height: 40px;
            }

            .list-package p.des {
                height: 40px;
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .subscriber {
        .full-screen-wrapper {
            .list-package {
                .slick-slider {
                    .slick-arrow {
                        background: #ffffff;
                        z-index: 999;

                        &.slick-prev {
                            left: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                        }

                        &.slick-next {
                            right: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                            ;
                        }

                        &::before {
                            color: #73818f;
                        }
                    }
                }

                .slick-arrow {
                    &:before {
                        display: none;
                    }

                    &.slick-next,
                    &.slick-prev {
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 10.52px;
                            height: 21.05px;
                            margin: 0;
                        }
                    }

                    .slick-dots li button {
                        display: none;
                    }
                }

                .slick-dots li button {
                    display: none;
                }
            }

            .list-package .package-item {
                height: auto;
            }

            .list-package p.shortdescription {
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 694px) {
    .subscriber {
        .full-screen-wrapper {
            .list-package {
                .slick-slider {
                    .slick-arrow {
                        background: #ffffff;
                        z-index: 999;

                        &.slick-prev {
                            left: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                        }

                        &.slick-next {
                            right: 10px;
                            top: 155px;
                            width: 40px;
                            height: 40px;
                            background: #f3f3f3;
                            position: absolute;
                            border-radius: 37.5px;
                            ;
                        }

                        &::before {
                            color: #73818f;
                        }
                    }
                }

                .slick-arrow {
                    &:before {
                        display: none;
                    }

                    &.slick-next,
                    &.slick-prev {
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 10.52px;
                            height: 21.05px;
                            margin: 0;
                        }
                    }

                    .slick-dots li button {
                        display: none;
                    }
                }

                .slick-dots li button {
                    display: none;
                }
            }

            .list-package .package-item {
                height: auto;
            }

            .list-package p.shortdescription {
                height: auto;
            }
        }
    }
}