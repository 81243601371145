.admin-account-table {
    min-height: 400px;
    td{
        &.td-btn{
            padding-top: 20px !important;
        }
        &.td-detail {
            display: flex;
            align-items: center;
            height: 100%;
            min-height: 64px;
        }
    }
    a.view {
        color: #20a8d8;
        border-color: #20a8d8;
        padding: 0.25rem 0.5rem;
        font-size: 0.76562rem;
        line-height: 1.5;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 0.1875rem;
        background-color: transparent;
        border: 1px solid;
        &:hover{
            text-decoration: none;
            color: #fff;
            background-color: #20a8d8;
            border-color: #20a8d8;
        }
    }
}

.admin-user-course-table {
    min-height: 400px;
    td.td-detail {
        display: flex;
        align-items: center;
    }
    a.view {
        color: #20a8d8;
        border-color: #20a8d8;
        padding: 0.25rem 0.5rem;
        font-size: 0.76562rem;
        line-height: 1.5;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 0.1875rem;
        background-color: transparent;
        border: 1px solid;
        &:hover{
            text-decoration: none;
            color: #fff;
            background-color: #20a8d8;
            border-color: #20a8d8;
        }
    }
}

.account-detail {
    .domains {
        font-weight: 300;
        font-size: 1rem;
        table {
            margin-top: 10px;
            td {
                padding: 0 15px;
            }
        }
    }

    .card:last-child {
        margin-bottom: 80px;
    }

    .card-header p {
        margin-bottom: 0;
    }
}

.tableData{
    &-header{
        display: flex;
        justify-content: space-between;
    }
}
