.support-tickets {
    a {
        text-decoration: none;
        color: #000;

        .ticket-card {
            &:hover {
                background-color: #f7f8f9;
            }
        }
    }
}

.attachment {
    .attachment-field {
        width: 100%;
        max-width: 400px;
        .custom-file-label {
            z-index: 3;
            cursor: pointer;
            padding-right: 85px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.quill {
    .ql-editor {
        height: 175px;

        strong {
            font-weight: 700;
        }

        ul,
        ol {
            padding-left: 15px;
            font-size: 16px;
        }

        li:not(.ql-direction-rtl) {
            padding-left: 10px;

            &::before {
                text-align: left;
                margin-right: 0.1em;
            }
        }
    }
}

.support-content-detail {
    h5 {
        margin-bottom: 30px;
        font-size: 24px;
    }

    .description {
        font-size: 16px;
        font-weight: 300;

        p {
            margin-bottom: 5px;
            font-size: inherit;
            font-weight: inherit;
        }

        ul,
        ol {
            font-size: inherit;
            font-weight: inherit;
        }
    }

    .attachment-file {
        ul,
        ol {
            padding-left: 0;
            margin-left: 24px;
        }
    }

    ul,
    ol {
        font-size: 16px;
        font-weight: 300;
        padding-left: 15px;
        margin-bottom: 5px;

        li {
            span {
                color: #000 !important;
            }
        }
    }
}

.comments-headline {
    font-size: 24px;
    padding-top: 10px;
}

.ticket-details {
    padding-bottom: 60px;
    
    .add-new-comment {
        h5 {
            margin-bottom: 30px;
            font-size: 24px;
        }
    }

    .close-open-ticket-wrapper {
        text-align: center;

        .description {
            font-size: 12px;
            margin-bottom: 10px;
        }

        button {
            width: auto;
            padding: 15px 50px;
            text-transform: none;
            border-radius: 20px;
        }
    }

    .comments {
        .message {
            text-align: justify;
            font-size: 16px;
            font-weight: 300;

            ul,
            ol {
                padding-left: 25px;
                font-size: inherit;
                font-weight: inherit;
            }

            p {
                font-size: inherit;
                font-weight: inherit;
            }
        }

        .content {
            font-size: 16px;
        }

        .meta {
            position: absolute;
            top: 10px;
            right: 20px;
        }
    }

    form {
        ul {
            padding-left: 15px;

            .custom-file-label {
                padding-right: 85px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                z-index: 3;
                cursor: pointer;
            }
        }

        .attachment {
            .attachment-field {
                width: 100%;
                max-width: 400px;
            }

            .attachment-buttons {
                min-width: 120px;
            }
        }
    }

    .ticket-header {
        justify-content: space-between;

        .close-open-ticket-wrapper {
            max-width: 250px;
        }
    }

    // .modal-dialog {
    //     max-width: calc(100vw - 200px);
    //     .modal-content {
    //         width: auto;
    //         min-width: 500px;
    //         margin: 0 auto;
    //     }
    //     img {
    //         max-height: calc(100vh - 70px);
    //         object-fit: contain;
    //     }
    // }
}

@media screen and (max-width: 767px) {
    .ticket-details {
        .ticket-header {
            text-align: center;
            flex-direction: column;

            .close-open-ticket-wrapper {
                max-width: inherit;
            }
        }
    }
}
