@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.subscriber {
    .addicon svg.icon:not(.icon-add) {
        transform: rotate(45deg) !important;
    }

    .addicon,
    .deleteicon {
        .icon:not(.icon-c-s):not(.icon-custom-size) {
            width: 17px;
            height: 17px;

            &.icon-add {
                width: 21px;
                height: 21px;
            }

            &.icon-delete {
                height: 30px;
                width: 30px;
            }
        }
    }


    .textadd {
        text-transform: uppercase;
        /* color: beige; */
        color: #212c36;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
        padding-left: 5px;
        display: inline-block;
        transform: translateY(1px);
        display: inline-block;
        height: 17px;

    }

    .c-wrapper{
        .footer{
            min-height: 0px;
            padding: 0px;
            background: none;
        }
    }

    .website {
        .form-group label {
            color: #73818f !important;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.3px;
            transform: translate(-1px, -2px);
        }

        .invalid-feedback.is-invalid {
            padding-left: 8px;
        }

        .incomplete {
            position: absolute;
            right: 13px;
            color: #ffffff;
            font-weight: 500;
            background-color: $danger;
            text-transform: uppercase;
            // padding: 3px 10px 0;
            border-radius: 9.5px;
            font-size: 10px;
            text-align: center;
            width: 84px;
            height: 19px;
            line-height: 19px;
            top: 10px;
        }

        .variable-input-group {
            .row {
                flex-wrap: wrap;
                align-items: flex-end;
                margin-top: 5px;
            }

            .error-mobile {
                display: none;
            }

            .error-desktop {
                display: block;
            }

            border-radius: 20px !important;
        }

        .status-wrapper {
            display: flex;
            flex-direction: column;
            padding-left: 3px;
            width: auto;

            .status-label {
                margin-bottom: 13px;
            }

            .status {
                margin-bottom: 4px;
            }

            .icon {
                &.spinner {
                    -webkit-animation: spin 2s linear infinite;
                    -moz-animation: spin 2s linear infinite;
                    animation: spin 2s linear infinite;
                }
            }

            //     &:disabled {
            //         cursor: default;
            //     }
            // }

        }

        .deleteicon {
            padding: 0px;
            width: 21px;
            height: 21px;

            .icon:not(.icon-c-s):not(.icon-custom-size) {
                height: 30px;
                width: 30px;
                transform: translate(-4px, -8px);
            }
        }

        button.d-inline.addicon.btn {
            padding: 0px;
            margin-left: 12px;
            transform: translate(1px, 1px);
            // transform: translateY(1px);
            // &:hover{
            //     svg.icon{
            //         background-color: black;
            //     }
            // }
        }

        .icon-verified {
            transform: translate(3px, -2px);
        }

        .iconrefresh {
            border: none;
            background: none;
            margin-left: 5px;
            color: #8e959b;
            margin-right: 5px;
            transform: translate(2px, -3px);
        }

        .btn-support-verification {
            min-width: 153px;
            background-color: #20a8d8;
            margin-right: 10px;
        }

        .domain-options {
            padding-bottom: 7px;
            padding-left: 0;
            width: auto;
        }

        .incomplete-wrapper {
            position: absolute;
            right: 2%;

            .incomplete {
                position: relative;
            }

            span {
                vertical-align: middle;
            }

            svg {
                vertical-align: sub;
            }

            .icon-info {
                &:hover {
                    &~.code-preview {
                        display: block;
                    }
                }
            }

            // .code-preview {
            //     display: none;
            //     position: absolute;
            //     z-index: 11;
            //     background-color: #19222b;
            //     color: #fff;
            //     top: 28px;
            //     right: 0;
            //     min-width: 300px;
            //     p {
            //         font-size: 14px;
            //         margin-bottom: 0;
            //     }
            //     .arrow-preview {
            //         position: absolute;
            //         transform: rotate(90deg);
            //         top: -11px;
            //         right: 125px;
            //     }
            // }
            .block-tooltip {
                &.type-click {
                    background-color: #19222b;
                    color: #fff;
                    top: 28px;
                    left: -289px;
                    max-width: 300px;

                    p {
                        font-size: 14px;
                        margin-bottom: 0;
                    }

                    .arrow-preview {
                        transform: rotate(90deg);
                        top: -11px;
                        left: 285px;
                    }
                }
            }
        }

        .completed {
            // position: absolute;
            // right: 2%;
            // color: #ffffff;
            // font-weight: 500;
            // background-color: $success;
            // text-transform: uppercase;
            // padding: 1px 10px 0;
            // border-radius: 10px;
            position: absolute;
            right: 13px;
            color: #ffffff;
            font-weight: 500;
            background-color: #4dbd74;
            text-transform: uppercase;
            // padding: 3px 10px 0;
            border-radius: 9.5px;
            font-size: 10px;
            text-align: center;
            width: 84px;
            height: 19px;
            line-height: 19px;
            top: 10px;
        }

        .domains {
            position: relative;

            .website-title {
                margin-bottom: 40px;

                h1 {
                    font-family: "Poppins";
                    font-weight: 600;
                    line-height: 1.4em;
                    letter-spacing: 0.04em;
                    background: linear-gradient(150deg, #29263e 0%, #233b7d 48.56%, #20a8d8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    display: inline-block;
                    letter-spacing: -0.5px;
                    min-width: 357px;
                    // height: 45px;
                    margin-top: -6px;
                    word-break: break-word;
                }

                p {
                    padding-left: 5px;
                    // width: 1057px;
                    // height: 22px;
                    color: #18222b;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }

        .card {
            .card-body {
                padding: 1.25rem;
            }
        }

        .button {
            .disabled {
                opacity: 1;
                background-color: #c8ced3;
                cursor: unset;
            }
        }

        .btn-toggle-script {
            width: 100%;
            color: #20a8d8;
            background-color: #f0f3f5;
            font-size: 16px;
            font-weight: 500;
            padding: 10px;
            position: relative;
            border: 1px solid #c8ced3 !important;
            padding-left: 20px;
            text-transform: none;

            .btn-down {
                position: absolute;
                right: 15px;
            }
        }

        .script-code {
            .float-right.d-flex.align-items-center {
                position: absolute;
                top: 15px;
                right: 12px;
                z-index: 2;
            }

            padding: 20px;
            background-color: #f1f3f4;
            border-radius: 15px;
            height: 100%;
            margin-right: -10px;

            &.dark-mode {
                background-color: #000;

                .ace_editor {
                    background-color: #000;

                    .ace_line {
                        span {
                            color: #fff;
                        }
                    }
                }

                .icon-copy {
                    color: #fff;
                }

                .ace_scrollbar {
                    &.ace_scrollbar-h {
                        &::-webkit-scrollbar-thumb {
                            background-color: $primary;
                        }
                    }
                }
            }

            .icon-copy {
                cursor: pointer;
                float: right;
                font-size: 12px;
            }

            .script {
                padding-right: 25px;
                height: 100%;
            }

            #blah2 {
                width: 100% !important;
                height: 100% !important;
                // .ace_scrollbar.ace_scrollbar-v {
                //     overflow: hidden;
                // }
            }


            .script-buttons {
                position: absolute;
                top: 0px;
                right: 30px;
                z-index: 10;
            }

            button {
                padding: 0;

                svg {
                    width: auto;
                    height: auto;
                    margin: 0;
                    vertical-align: middle;
                }
            }

            .ace_scroller {
                &.ace_scroll-left {
                    box-shadow: none;
                }
            }

            .ace_scrollbar {
                &.ace_scrollbar-h {
                    height: 6px !important;
                    width: 100%;

                    &::-webkit-scrollbar {
                        height: 6px;
                        background-color: $gray-200;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $gray-300;
                    }
                }
            }
        }

        .website-collapse-google {
            .script-code {
                .icon-copy {
                    position: relative;
                    top: 2px;
                }
            }
        }

        .domain-settings {
            border-radius: 12px;
            position: relative;

            .card-body {
                .edit-domain {
                    position: absolute;
                    right: 5%;
                    top: 10px;
                    color: $danger;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 700;
                    cursor: pointer;
                    background-color: transparent;
                    font-size: 80%;
                    color: #f96e6d;
                }

                h4.fw-bold {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 27px;
                }

                p.text {
                    color: #121920;
                    font-family: Roboto;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    transform: translateY(-3px);
                }

                input {
                    padding-right: 0;

                    &.is-invalid {
                        padding-right: calc(1.5em + 0.75rem);
                    }
                }
            }

            .domain-name {
                min-width: 382px;
            }
        }

        h1 {
            span {
                font-weight: 300;
                font-size: 32px;
            }
        }

        .domain {
            .domain-detail {
                margin-bottom: 15px;

                label {
                    font-weight: 500;
                    color: #2f353a;
                }

                .form-control {
                    background-color: inherit;

                    &:disabled {
                        color: #9da7b1;
                        background-color: transparent;
                    }
                }
            }
        }

        .upgrade-domain {
            .card {
                margin-top: 10px;
            }

            .card-body {
                padding: 25px 25px 20px;

                .compare-plan {
                    color: #73818f;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .btn-primary {
                button {
                    margin-right: 15px;
                }
            }
        }

        ul {
            li {
                list-style-type: decimal;
            }
        }

        .account-id {
            background: none;
            box-shadow: none !important;
            color: #8192a0;
            position: absolute;
            right: 0;
            margin-top: -10px;

            p {
                font-size: 14px;
            }

            .card-body {
                padding-right: 0;
            }

            .account-console {
                display: flex;
                align-items: center;
                margin-top: -8px;

                p {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }
        }

        .script-validation-modal {
            .modal-dialog {
                max-width: 900px;

                strong,
                b {
                    font-size: rem($font-b);
                }

                .icon-close-popup {
                    top: 5px;
                    right: 10px;
                    width: 20px !important;
                    height: 20px !important;
                }

                .instruction-btn {
                    border: none;
                    background: none;
                    font-weight: 300;
                    text-decoration: underline;
                    padding: 0;

                    &:hover {
                        text-decoration: none;
                    }
                }

                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none !important;
                        color: #167495 !important;
                    }
                }

                .modal-body {
                    padding: 30px 30px 20px 30px;
                }
            }
        }
    }

    .install-website {
        border-radius: 14px;
        margin-bottom: 70px;

        .card {
            margin-bottom: 40px;
        }

        p.mb-4 {
            // height: 74px;
            color: #121920;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            min-height: 50px;
        }

        .install-website-collapse {
            border: 1px solid $secondary;
        }

        .btn-toggle-script {
            box-shadow: none !important;
            border-radius: 0 !important;
            border: 0 !important;
            font-weight: 400;

            i {
                margin-right: 4px;
            }
        }

        .install-website-collapse {
            border-radius: rem(3);
            overflow: hidden;
        }

        .collapse,
        .collapsing {
            .card {
                box-shadow: none !important;
                border: 0 !important;
                border-top: 1px solid $secondary !important;
                border-radius: 0 !important;
                margin-bottom: 0;
            }
        }

        .script-code {
            position: relative;

            .ace_editor {
                background-color: #f1f3f4;

                .ace_print-margin {
                    display: none;
                }
            }

            .ace_scroller,
            .ace_content {
                position: relative;
                height: 100% !important;
            }
        }

        .code-media-block {
            iframe {
                border-radius: 15px;
            }

            // border: 1px solid $gray-200;
            // min-height: 350px;
            .embed-responsive {
                padding: 1px;
            }

            .icon-play {
                width: 60px;
                height: 60px;
                background-color: #f0f3f5;
                border-radius: 50%;
                position: relative;
                cursor: pointer;

                svg {
                    width: 14px;
                    position: absolute;
                    top: 50%;
                    left: calc(50% + 2px);
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    h1 {
        font-family: "Poppins";
        font-weight: 600;
        letter-spacing: 0.04em;
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        letter-spacing: -0.5px;
        margin: 0px;
        font-size: 35px;
        line-height: 52px;
        padding-bottom: 4px;
        word-break: break-word;
    }

    h4 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
    }

    .button-reload-wrap {
        position: relative;
        display: inline-block;

        .block-tooltip {
            top: -20px;
            left: auto;
            right: 0;
            transform: translateY(-100%);
            min-width: inherit;
            white-space: nowrap;

            svg {
                top: auto;
                bottom: 6px;
                left: auto;
                right: 9px;
                transform: translateY(100%) rotate(-90deg);
            }
        }

        .block-tooltip-text {
            border-top-left-radius: 12px;
        }
    }

    .button-reload {
        border: 2px solid #e4e7e9 !important;
        border-radius: 4px;

        &:hover {
            background-color: #e4e7e9;
        }
    }

    input[type='checkbox'] {
        &.select-all#select-all-properties, &.select-all#select-all-metrics{
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 6px;
            cursor: pointer;
            outline: unset;
            vertical-align: middle;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            &:checked {
                background-image: url(/assets/icons/icons-check-update.svg);
                background-color: #20a8d8;
                background-position: center;
            }

            & + label{
                cursor: pointer;
            }    
        }
    }

    .btn-add-row {
        padding-left: 0;
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
            margin: 0;
        }

        .icon:not(.icon-c-s):not(.icon-custom-size) {
            width: 21px;
            height: 21px;
        }

        label {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            line-height: 17px;
            margin: 0;
            cursor: pointer;

            &.add-row {
                margin-left: 0;
            }
        }
    }
}

.ace-kuroir .ace_comment {
    color: #D81B60;
    background: none;
}

.upgrade-domain {
    .card {
        border-radius: 15px;
    }
}

.card {
    border-radius: 12px;
}

.upgrade-package.upgrade-package-variables.bg-light.card-body {
    border-radius: 12px;
    box-shadow: none !important;

    p {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        padding-top: 5px;
    }

    h6 {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    .d-inline-block {
        display: inline-block !important;
        margin-top: -10px;
    }
}

// button.text-uppercase.rounded-pill.btn.btn-success {
//     color: #ffffff;
//     font-family: Roboto;
//     font-size: 13px;
//     font-weight: 700;
//     line-height: 16px;
//     text-align: center;
//     text-transform: uppercase;
//     height: 35px;
//     width: 122px;
// }

.compare-plan {
    color: #73818f;

    &:hover {
        color: #73818f;
        text-decoration: none;
    }
}

.Toastify__toast-container {
    z-index: 10001;
}

@media screen and (max-width: 1660px) {
    .subscriber .website {
        .domain {
            .domain-name {
                min-width: 350px;
                padding-right: 5px;
            }

            .domain-options {
                margin-top: 7px;
            }

            .btn-support-verification {
                margin-left: 16px;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .c-wrapper.v2 {
        .show-nav-child:not(.minimize-nav) {
            .domain-settings .row {

                .domain,
                .upgrade-domain {
                    flex: 0 0 100%;
                    min-width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .subscriber {
        .website {
            .variable-input-group {
                .error-mobile {
                    display: block;
                }

                .error-desktop {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1222px) {
    .subscriber {
        .website {
            .account-id {
                top: -20px;
            }

            .script-code {
                padding: 20px;
                background-color: #f1f3f4;
                border-radius: 15px;
                // min-height: 238px !important;

                #blah2 {
                    width: 100% !important;
                    // min-height: 200px !important;
                }

                .float-right.d-flex.align-items-center {
                    position: absolute;
                    top: 15px;
                    right: 12px;
                    z-index: 2;
                    margin-right: 11px;
                }
            }

            .script {
                margin-top: 15px;
            }
        }

    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .subscriber {
        .website {
            .account-id {
                top: -20px;
            }
            
            .domain.col-xl-6 {
                .col-md-6 {
                    min-width: 294px;
                }
            }

            .col-sm-3.col-md-3 {
                padding-bottom: 8px;
                padding-left: 12px;
                padding-right: 0px;
            }
        }
    }
}

@media screen and (max-width: 1074px) {
    .subscriber {
        .website {
            .install-website {
                p.mb-4 {
                    height: 110px;
                    color: #121920;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    min-height: 50px;
                }

            }

            .script-code {
                padding: 20px;
                background-color: #f1f3f4;
                border-radius: 15px;
                height: 100%;

                &.dark-mode {
                    background-color: #000;

                    .ace_editor {
                        background-color: #000;

                        .ace_line {
                            span {
                                color: #fff;
                            }
                        }
                    }

                    .icon-copy {
                        color: #fff;
                    }

                    .ace_scrollbar {
                        &.ace_scrollbar-h {
                            &::-webkit-scrollbar-thumb {
                                background-color: $primary;
                            }
                        }
                    }
                }

                .icon-copy {
                    cursor: pointer;
                    float: right;
                    font-size: 12px;
                }

                .script {
                    padding-right: 25px;
                    height: 100%;
                }

                #blah2 {
                    width: 100%;
                    height: 100% !important;

                }

                .script-buttons {
                    position: absolute;
                    top: 0px;
                    right: 30px;
                    z-index: 10;
                }

                button {
                    padding: 0;

                    // border-radius: 30px;
                    svg {
                        width: auto;
                        height: auto;
                        margin: 0;
                        vertical-align: middle;
                    }
                }

                .ace_scroller {
                    &.ace_scroll-left {
                        box-shadow: none;
                    }
                }

                .ace_scrollbar {
                    &.ace_scrollbar-h {
                        height: 6px !important;
                        width: 100%;

                        &::-webkit-scrollbar {
                            height: 6px;
                            background-color: $gray-200;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $gray-300;
                        }
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 760px) {
    .subscriber {
        .website {

            // .domain{
            //     .account-id{
            //         display: block !important;
            //         width: 100%;
            //     }
            // }
            .website-title {
                height: 100px;
            }
        }
    }
}

// @media screen and (max-width: 1700px) {
//     .subscriber {
//         .website {
//             p.mb-4 {
//                 min-height: 100px;

//             }
//         }
//     }
// }
