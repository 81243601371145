.consent-privacy {
     &-revert-box {
          background-color: #d2eef7;
          border: 1px solid #a1d9ff !important;
     }

     &.cvr-create-new {
          .cvr-step-card {
               min-height: auto;
               .table-region {
                    label {
                         color: #000 !important;
                         font-weight: 500;
                    }
               }
          }
     }

     .description {
          font-weight: 400;
     }

     .row-consent-rule p {
          padding-right: 75px;
     }

     .btn-method {
          position: relative;
          border: 1px solid transparent;
          border-color: #636f83;
          transition: 0.3s ease all;
          min-width: 200px;
          text-align: center;
          padding-right: 30px;

          svg {
               position: absolute;
               right: 12px;
          }

          &:hover {
               background-color: #dfdfdf;
          }

          &.active {
               background-color: #434343;
               color: #fff;
               opacity: 1;
          }

          &.disabled {
               opacity: 1;
               // cursor: not-allowed !important;
          }
     }

     .create-rule-tab {
          display: flex;
          align-items: center;
          padding-right: 15px;
     }

     .custom-control {
          .radio-method + .custom-control-label {
               font-size: 15px !important;
               font-weight: 500 !important;
               margin-bottom: 0;
               padding-right: 7px;
               color: #000 !important;
               &::before {
                    top: 0;
                    border-radius: 50% !important;
                    content: '';
                    background-color: #fff;
                    border: 1px solid #636f83;
               }
          }
     }

     .radio-method-active + .custom-control-label {
          &::before {
               border-radius: 50% !important;
               content: '';
               background-color: #fff;
               border: 1px solid #3cb1d9;
          }

          &::after {
               top: 4px !important;
               left: -20px;
               width: 8px;
               height: 8px;
               border-radius: 50%;
               background: #3cb1d9;
               background-image: unset !important;
          }
     }

     .radio-method-active + .label-active {
          color: #3cb1d9 !important;
     }

     .table-box {
          td {
               padding: 10px 10px 0;
               display: flex;
               align-items: center;
               justify-content: space-between;
               p {
                    margin-bottom: 0;
               }
          }
          .css-table-body {
               border: 1px solid #e2e6e9;
               border-radius: 15px;
               display: grid !important;
               min-width: 500px;
               border-collapse: initial;
               .css-table-row {
                    &:first-child {
                         border-top-left-radius: 15px;
                         border-top-right-radius: 15px;
                    }

                    &:last-child {
                         border-bottom-left-radius: 15px;
                         border-bottom-right-radius: 15px;
                    }
               }
          }
     }

     .row-region {
          display: flex;
          position: relative;
          margin-left: 0;
          row-gap: 10px;
          flex-wrap: nowrap;

          + .row-region {
               margin-top: 20px;
          }
         
          button {
               padding: 0;

               .icon-add {
                    margin-top: 9px;
               }
          }

          .select-type-col {
               max-width: 250px;
          }

          .invalid-feedback {
               position: absolute;
               bottom: -18px;
          }

          .region-type,
          .select-type-col {
               width: 100%;
               max-width: 295px;
               margin-right: 15px;
               padding-inline: 0;

               div[class*='-container'] {
                    margin-bottom: 0;
               }
          }

          .actions {
               display: flex;
               button .icon-delete {
                    margin-left: -3.5px;
               }
          }
     }

     .btn-add-row {
          margin-top: 10px;
     }

     .tooltip-country {
          display: flex;
          align-items: center;
          min-height: 35px;
          position: relative;
          padding-left: 0;

          .block-tooltip {
               display: block;
               left: 48px;
               min-width: 260px;

               .block-tooltip-text {
                    overflow-x: hidden;
                    height: 270px;

                    &::-webkit-scrollbar {
                         width: 8px;
                         background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                         background-color: #474747;
                         border-top-left-radius: 2px;
                         border-top-right-radius: 2px;
                         border-bottom-left-radius: 2px;
                         border-bottom-right-radius: 2px;
                    }

                    &::-webkit-scrollbar-track {
                         background: #8a8a8a;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                         background: #555;
                    }
               }
          }

          ul {
               list-style: none;
               padding: 0%;
               margin: 0;
          }
     }

     .block-tooltip {
          text-transform: none;
          left: calc(100% + 0px);

          .block-tooltip-text {
               text-align: left;
          }

          svg {
               top: 0;
               margin: 0;
          }
     }

     .consent-rules-default {
          padding: 20px 20px 10px;
          background-color: #c7c7c7;
     }

     .consent-rules-custom {
          display: block;
          position: relative;
          min-height: 341px;
     }

     .box-content-description {
          padding: 20px;
          background-color: #e9e9e9;
          border-radius: 12px;
     }

     .btn-edit {
          padding: 5px 20px;
     }

     .css-yk16xz-control {
          padding-right: 20px;
     }

     .confirm-save-popup {
          .confirm-save {
               padding: 0 34px 57px !important;

               h1 {
                    line-height: 32px;
                    height: auto;
                    margin-bottom: 15px;
                    padding-bottom: 0;
               }

               .confirm-save-content {
                    margin-bottom: 20px;
               }
          }
     }

     &.v2 {
          .upgrade-package {
               font-size: 13px;
               border-radius: 12px;
               background: #f0f3f5;
               margin-bottom: 22px;
               box-shadow: none !important;

               .card-body {
                    padding: 15px 20px;
               }

               .upgrade-package-text {
                    padding-right: 90px;
                    font-size: inherit;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.086666666px;
               }

               .upgrade-package-button {
                    white-space: nowrap;
                    padding: 10px 15px;
                    border-radius: 17.5px;

                    font-size: inherit;
                    font-weight: 700;
                    line-height: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 0.18571427px;
               }
          }

          .consent-listener {
               .description {
                    margin-bottom: 5px;
               }

               form {
                    margin-top: 16px;
               }

               .select-consent {
                    max-width: 316px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0.5px;
                    margin-bottom: 20px;

                    * {
                         cursor: pointer;
                    }

                    div[class*='-container'] {
                         border-radius: 12px;

                         div[class$='-menu'] {
                              border-radius: 12px;
                              overflow: hidden;

                              &:has(.no-options) {
                                   overflow: unset;
                              }
                         }

                         div[class$='-option'] {
                              background-color: transparent !important;
                              color: #7f8c9a !important;

                              &:hover {
                                   color: #20a8d8 !important;
                                   background-color: #f3f5f6 !important;
                              }
                         }
                    }

                    div[class*='-control'] {
                         padding: 7px 5px;
                         background-color: transparent;
                         background-image: url('/assets/icons/icon-chevron-bottom.svg');
                         background-size: 7px;
                         background-position: center right 14px;
                    }
               }

               .btn-save {
                    padding: 10px 25px;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 0.18571427px;
               }
          }

          .consent-rules {
               .description {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    margin-bottom: 16px;
               }
          }

          .list-rules {
               margin-bottom: 19px;

               .no-rule {
                    color: #7f8c9a;
               }
          }
     }

     .cvr-step-2 {
          .listener-for-gpc {
               .pgc-option {
                    .form-control {
                         padding-right: 0;
                    }

                    label {
                         padding-top: 2px;
                    }
               }
          }

          .box-content-description {
               p {
                    font-size: 15px;
               }
          }

          .listener-for-gpc-open {
               p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.08px;
                    word-break: break-word;
               }
          }

          .card-header {
               p {
                    font-size: 13px;
                    margin-bottom: 0;
               }
               .title {
                    min-width: 330px;
               }
          }
          .card-body {
               .label-row {
                    color: #000 !important;
                    font-weight: 700;
                    margin: 15px 0 10px;
               }
          }

          .table-inden {
               border: 1px solid #33333326 !important;
               box-shadow: unset !important;
               &.consent-type {
                    z-index: 4;
               }
               &-header {
                    background: unset !important;
                    p {
                         font-size: 15px;
                         font-weight: 700;
                    }
               }
               .card-body {
                    padding-top: 0;
                    padding-bottom: 20px;
               }
               &-consent {
                    background-color: #0a0e13;
                    margin: 0 -20px;
                    padding: 15px 8px;
               }
               &-col p {
                    color: #fff;
               }
               &-allow p {
                    color: #109e31;
                    font-weight: 500;
               }
               &-notallow p {
                    color: #ffc107;
               }
               &-title p {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 37px;
                    margin-left: 15px;
               }
               &-row {
                    margin-top: 20px;

                    .block-tooltip {
                         left: 40px;
                         top: 40px;
                         &-text {
                              position: absolute;
                              z-index: 5;
                              white-space: wrap;
                         }
                    }
               }

               &-select > div {
                    overflow: unset !important;
                    & > div {
                         overflow: unset;
                         & > div {
                              overflow: unset;
                         }
                    }
               }
               p {
                    margin-bottom: 0;
               }
               .table-region {
                    .block-tooltip {
                         left: 135px;
                         top: 10px;
                    }
               }
          }
          .bg-gray {
               background-color: $gray-100;
          }
     }

     .listener-for-gpc {
          .pgc-option {
               display: flex;
               align-items: center;

               input[type='checkbox'] {
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    min-height: inherit;
                    margin-right: 8px;
                    border-color: #c8ced3;
                    border: 1px solid #212c36 !important;
                    cursor: pointer;
                    vertical-align: middle;
                    -moz-appearance: none;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    &.step-2 {
                         border-radius: 4px;
                         border: 1px solid #20a8d8 !important;
                         &:checked {
                              background-image: url(/assets/icons/icons-check-update.svg);
                              background-color: #20a8d8;
                         }
                    }
                    &:checked {
                         background-image: url(/assets/icons/icon-check.svg);
                         background-repeat: no-repeat;
                         background-position: 50% 45%;
                         border: none;
                         opacity: 1;
                         background-color: #212c36;
                         border: none !important;
                    }
               }

               label {
                    margin-bottom: 0;
                    line-height: 1;
                    vertical-align: middle;
                    transform: none;
                    margin-right: 10px;
                    cursor: pointer;
                    &.step-2 {
                         color: #000 !important;
                         font-weight: 500;
                    }
               }

               .tooltip-wrapper {
                    position: relative;
               }

               .block-tooltip {
                    max-width: 400px;
                    left: calc(100% + 10px);
               }
          }
          &-open {
               .box.step-2 {
                    border: 1px solid #625b5b30;
                    width: fit-content;
                    border-radius: 12px;
               }

               ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    p {
                         margin-bottom: 0;
                    }

                    li {
                         display: flex;
                         align-items: center;
                         width: 500px;
                         justify-content: space-between;
                         &.step-2 {
                              padding: 10px 15px;
                              &:nth-of-type(even) {
                                   background-color: #d7d6d60f;
                              }
                              &:nth-of-type(odd) {
                                   background-color: #e3dede47;
                              }
                         }
                    }
               }

               .select-option {
                    width: 180px;
                    &.step-2 {
                         width: 240px;
                    }
               }
          }
     }
}

.personal-data .data-layer-events-layout {
     p {
          font-size: 14px;
          font-weight: 400;
     }

     .table-data {
          .select-type {
               width: 270px;
          }

          .input-key {
               width: 450px;
          }

          .tooltip-wrapper {
               position: relative;

               svg {
                    width: 12px;
                    height: 12px;
                    margin-top: -2px;
                    margin-left: 7px;
               }
          }

          .css-table-header {
               .block-tooltip {
                    top: auto;
                    left: calc(100% - 90px);
                    margin-bottom: 25px;

                    svg {
                         margin-left: 0;
                         top: calc(100% - 6px);
                         left: calc(50% - 67px);
                         transform: rotate(-90deg);
                    }
               }
          }

          .css-table-body {
               .css-table-row {
                    .event-name > div {
                         padding: 8px 0 8px 0;
                    }

                    .event-friendly-name {
                         max-width: 240px;
                    }

                    .select-type,
                    .input-key {
                         position: relative;

                         .invalid-feedback {
                              position: absolute;
                              margin-top: -1px;
                         }
                    }
               }

               .block-tooltip {
                    left: calc(100% + 10px);
                    top: 4px;

                    svg {
                         margin-top: 0;
                         margin-left: 0;
                         top: 6px;
                         left: -8px;
                    }
               }
          }
     }
}

@media screen and (max-width: 1400px) {
     .personal-data .data-layer-events-layout .table-data .css-table-header {
          .block-tooltip {
               left: calc(100% - 210px);

               svg {
                    left: calc(50% + 50px);
               }
          }
     }

     .consent-privacy {
          margin-bottom: 250px !important;

          .button-group {
               .button-wrapper:last-of-type {
                    .btn-method .block-tooltip {
                         top: calc(100% + 3px);
                         left: auto;
                         right: -34px;
                         min-width: 250px;

                         .block-tooltip-text {
                              border-top-left-radius: 12px;
                         }

                         svg {
                              top: -14px;
                              left: 73%;
                              transform: rotate(90deg);
                         }
                    }
               }
          }
     }
}

@media (max-width: 1268px) {
     .personal-data {
          .data-layer-events-layout .table-data {
               .select-type {
                    width: 200px;
               }

               .input-key {
                    width: 270px;
               }
          }
     }

     .consent-privacy .tooltip-country .block-tooltip {
          top: 41px !important;
          left: -124px !important;

          .arrow-preview {
               transform: rotate(90deg);
               top: -14px;
               left: 118px;
          }

          .block-tooltip-text {
               border-top-left-radius: 12px !important;
          }
     }
}
