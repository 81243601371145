.animate__faster-300ms {
     animation-duration: .3s !important;
}

.animate__faster-400ms {
     animation-duration: .4s !important;
}

.animate__faster-2s {
     animation-duration: 2s !important;
}

.animate__faster-3s {
     animation-duration: 3s !important;
}

.animate__faster-4s {
     animation-duration: 4s !important;
}

.animate__delay-500ms {
     animation-delay: .5s !important;
}

.animate__delay-800ms {
     animation-delay: .5s !important;
}

.animate__delay-1s {
     animation-delay: 1s !important;
}

.animate__delay-1500ms {
     animation-delay: 1.5s !important;
}

.animate__delay-2s {
     animation-delay: 2s !important;
}

.animate__delay-3s {
     animation-delay: 3s !important;
}

.animate__delay-4s {
     animation-delay: 4s !important;
}

.animate__delay-4500ms {
     animation-delay: 4.5s !important;
}

.animate__delay-5s {
     animation-delay: 5s !important;
}

.animate__delay-5500ms {
     animation-delay: 5.5s !important;
}

.animate__delay-6s {
     animation-delay: 6s !important;
}

.animate__delay-6500ms {
     animation-delay: 6.5s !important;
}

.animate__delay-7s {
     animation-delay: 7s !important;
}

.animate__delay-7500ms {
     animation-delay: 7.5s !important;
}

.animate__delay-8s {
     animation-delay: 8s !important;
}

.animate__delay-8500ms {
     animation-delay: 8.5s !important;
}

.animate__delay-9s {
     animation-delay: 9s !important;
}

.animate__delay-10s {
     animation-delay: 10s !important;
}

.animate__delay-11s {
     animation-delay: 11s !important;
}

.animate__delay-12s {
     animation-delay: 12s !important;
}

.animate__delay-13s {
     animation-delay: 13s !important;
}

.animate__delay-14s {
     animation-delay: 14s !important;
}

.animate__delay-15s {
     animation-delay: 15s !important;
}

.animate__delay-16s {
     animation-delay: 16s !important;
}

.animate__delay-17s {
     animation-delay: 17s !important;
}

.animate__delay-18s {
     animation-delay: 18s !important;
}

.animate__delay-19s {
     animation-delay: 19s !important;
}

.animate__delay-20s {
     animation-delay: 20s !important;
}

.animate__delay-21s {
     animation-delay: 21s !important;
}

.animate__delay-22s {
     animation-delay: 22s !important;
}

.dot-elastic-1 {
     display: inline-block;
     width: 10px;
     height: 10px;
     border-radius: 5px;
     background-color: #000;
     animation: dot-elastic 0.75s infinite linear;
     transform-origin: bottom;
     margin-right: 2px;
}

.dot-elastic-2 {
     display: inline-block;
     width: 10px;
     height: 10px;
     border-radius: 5px;
     background-color: #000;
     animation: dot-elastic 0.75s infinite linear 0.25s;
     transform-origin: bottom;
     margin-right: 2px;
}

.dot-elastic-3 {
     display: inline-block;
     width: 10px;
     height: 10px;
     border-radius: 5px;
     background-color: #000;
     animation: dot-elastic 0.75s infinite linear 0.5s;
     transform-origin: bottom;
}

@keyframes dot-elastic {
     0% {
          transform: scaleY(1);
          background-color: #000;
     }
     50% {
          transform: scaleY(0.5);
          background-color: #9880ff;
     }
     100% {
          transform: scaleY(1);
          background-color: #000;
     }
}


// Animation Loading Dots Listenlayer

.dots-listenlayer {
     width: 100%;
     display: flex;
     gap: 2px;
}

.dots-listenlayer-1,
.dots-listenlayer-2,
.dots-listenlayer-3 {
     width: 10px;
     height: 10px;
     background-color: #b1b9c0;
     border-radius: 50%;
}

.dots-listenlayer-1 {
     animation: dots-listenlayer-1 1s infinite linear;
}
.dots-listenlayer-2 {
     animation: dots-listenlayer-2 1s infinite linear;
}
.dots-listenlayer-3 {
     animation: dots-listenlayer-3 1s infinite linear;
}

@keyframes dots-listenlayer-1 {
     0% {
          transform: translateY(0);
          background-color: #b1b9c0;
     }
     10% {
          transform: translateY(-100%);
          background-color: #20a8d8;
     }
     20% {
          transform: translateY(0);
          background-color: #b1b9c0;
     }
     20.1% {
          transform: translateY(0);
     }
     100% {
          transform: translateY(0);
     }
}

@keyframes dots-listenlayer-2 {
     0% {
          transform: translateY(0);
     }
     10% {
          transform: translateY(0);
          background-color: #b1b9c0;
     }
     20% {
          transform: translateY(-100%);
          background-color: #ffc107;
     }
     30% {
          transform: translateY(0);
          background-color: #b1b9c0;
     }
     100% {
          transform: translateY(0);
     }
}

@keyframes dots-listenlayer-3 {
     0% {
          transform: translateY(0);
     }
     10% {
          transform: translateY(0);
     }
     20% {
          transform: translateY(0);
          background-color: #b1b9c0;
     }
     30% {
          transform: translateY(-100%);
          background-color: #4dbd74;
     }
     40% {
          transform: translateY(0);
          background-color: #b1b9c0;
     }
     100% {
          transform: translateY(0);
     }
}
