.affiliate {
    .btn-primary {
        padding: 7px 10px;
        text-transform: uppercase;
        font-weight: 500;
    }
    p {
        span {
            &.font-weight-normal {
                font-weight: 500 !important;
            }
        }
    }
    input {
        &.add-padding-right {
            padding-right: 30px;
        }
    }
    .copy-link {
        display: flex;
        justify-content: space-between;
        input {
            padding-top: 8px;
            color: #212b34;
            cursor: text;
            &:disabled {
                background-color: $light;
            }
        }
        .affiliate-copy-input {
            width: 100%;
            margin-right: 7px;
        }
        .affiliate-copy-button {
            position: relative;
        }
    }
    .affiliate-description {
        max-width: 600px;
        margin-bottom: 31px;
        p {
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .affiliate-link {
        .card-body {
            padding-top: 19px;
            padding-left: 25px;
            padding-right: 25px;
            .card-title {
                margin-bottom: 7px;
            }
        }
        .btn-affiliate-copy {
            margin-left: auto;
            padding: 7px 18px;
        }
    }
    .affiliate-rewards {
        .card-body {
            padding-top: 19px;
            padding-left: 25px;
            padding-right: 25px;
            .card-title {
                margin-bottom: 7px;
            }
        }
    }
}

.list-social {
    .row {
        margin-right: -7px;
        margin-left: -7px;
        .col-1 {
            padding-right: 7px;
            padding-left: 7px;
            max-width: 7.53333%;
        }
    }
    button {
        padding: 0;
        .icon {
            width: auto !important;
            height: auto !important;
            margin: 0;
        }
    }
    svg {
        color: #212c36;
    }
}

.copy-link {
    svg {
        color: $success;
        position: absolute;
        left: -33px;
        transform: translateY(-50%);
        top: 52%;
    }
}

.reach-our-goals {
    border-radius: 0.25rem;
    color: #fff;
    padding: 34px 40px 34px 40px;
    background-image: url("/assets/images/background-affiliate.png");
    background-size: cover;
    background-position: center;
    p {
        font-size: 1.1rem;
        font-weight: 200;
    }
    .title {
        border-bottom: 1px solid #25292d;
        margin-bottom: 2.7rem;
    }
    .our-goals,
    .our-mission {
        h5 {
            color: #65717d;
        }
    }
    .our-mission {
        h5 {
            margin-bottom: 0.3rem;
        }
        margin-bottom: 2.3rem;
    }
    .our-goals {
        h5 {
            margin-bottom: 1.3rem;
        }
        .row {
            h5 {
                margin-bottom: 0.4rem;
                color: inherit;
                text-transform: uppercase;
            }
        }
    }
}
