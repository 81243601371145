body #storybook-root {
     display: flex;
     justify-content: center;
     align-items: center;
     height: calc(100% - 32px);
     .icons-search {
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          width: 100%;
          &-wrap {
               position: relative;
               input {
                    min-width: 500px;
                    height: 42px;
                    border-radius: 22px;
                    outline: none !important;
                    box-shadow: none !important;
                    border: 4px solid #183153;
                    padding: 0 40px;
               }
               button {
                    border: none;
                    position: absolute;
                    top: calc(50% - 14px);
                    right: 12px;
                    background: none;
               }
               .icon-search {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    left: 10px;
                    top: calc(50% - 12.5px);
               }
               .icon-clear{
                    border: 1px solid transparent;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
               }
          }
     }
     .wrapper {
          width: 100%;
          flex-wrap: wrap;
          gap: 10px 18px;
          margin-top: 70px;
          .box-icon {
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: space-evenly;
               width: 180px;
               height: 180px;
               background-color: #fff;
               padding: 20px;
               border-radius: 8px;
               cursor: pointer;
               &:hover {
                    background-color: #dfdfdf;
               }
               svg {
                    margin-bottom: 10px;
                    width: 35px;
                    height: 35px;
               }
          }
     }
}


