html:not([dir='rtl']) .sidebar.sidebar-lg:not(.hide).sidebar-fixed ~ .c-wrapper {
     margin-left: 200px;

     &.v2 {
          transition: margin .3s;
          margin-left: 197px;

          &.show-nav-child {
               margin-left: 438px;
          }

          &.minimize-nav {
               margin-left: 44px;
          }

          &.show-nav-child.minimize-nav {
               margin-left: 285px;
          }

          .header-toggler {
               position: absolute;
               left: -62px;
          }

          &.minimize-nav:not(.show-nav-child) {
               .header-toggler {
                    position: relative;
                    left: 0;
               }
          }
     }
}

.sidebar:not(.sidebar-v2) {
     .sidebar-nav {
          padding: 0;
          .sidebar-nav-title {
               font-weight: 700;
               text-transform: uppercase;
               transition: .3s;
          }
          .sidebar-nav-link {
               gap: 15px;

               &:hover {
                    background-color: $primary;
               }
          }
     }
}

.sidebar {
     width: 200px;
     background: #121920;

     .sidebar-nav-dropdown {
          position: relative;
          transition: background 0.3s ease-in-out;
     }

     .sidebar-nav-link {
          outline: none;

          &.c-active,
          &.active {
               background-color: #0a0f13;
               overflow: hidden;

               &:before {
                    background-color: #20a8d8;
                    background: linear-gradient(128deg, #ffc107, #20a8d8, #d8d8d8);
               }

               background-image: url('/assets/images/arrow-item-menu.svg');
               background-repeat: no-repeat;
               background-position: right;
               background-size: 7px;
               z-index: 999;
          }
     }

     .hide-icon-arrow {
          .sidebar-nav-link {
               &.c-active,
               &.active {
                    background-image: none !important;
               }
          }
     }

     .sidebar-brand {
          justify-content: flex-start;
          padding-left: 12px;
     }

     .sidebar-brand-full {
          max-width: 135px;

          &.hide {
               display: none;
          }
     }

     .sidebar-nav {
          z-index: 1;

          .sidebar-nav-dropdown {
               z-index: -1;

               .feature-note {
                    padding: 0 2.1em 0 2.4em;
                    margin-top: -4px;

                    p {
                         a {
                              text-decoration: underline;

                              &:hover,
                              &:focus {
                                   text-decoration: none;
                                   color: #167495 !important;
                              }
                         }

                         padding: 0.7em 1em 0.7em;
                         font-size: 10px;
                         // background: #1c232a;
                         margin-bottom: 0;
                    }
               }
          }

          .sidebar-nav-dropdown-items {
               .sidebar-nav-link {
                    padding-left: rem(45);
               }
          }

          .upgrade-reporting {
               margin-top: -4px;
               padding: 0 1rem;

               p {
                    button {
                         color: #20a8d8;
                         text-decoration: underline;
                         background: transparent;
                         border: none;
                         padding: 0;

                         &:hover,
                         &:focus {
                              text-decoration: none;
                              color: #167495 !important;
                         }
                    }

                    font-size: 10px;
                    // background: #212c36;
                    margin-bottom: 0;
               }
          }
     }

     .sidebar-nav-title {
          letter-spacing: 0.05em;
          margin-top: 0.1em;
          color: $gray-600;
     }

     .sidebar-nav-link,
     .sidebar-nav-dropdown-toggle {
          white-space: normal;
          cursor: pointer;
          font-size: 13px;
          padding: 1em 1.25em;
          position: relative;
          color: $white;
          background-position: right !important;
          font-weight: 400;
          line-height: 16px;

          .sidebar-nav-icon {
               max-width: 15px;
               max-height: 15px;
          }

          svg {
               color: $gray-600 !important;
          }

          &:before {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               width: 5px;
               height: 100%;
               background-color: transparent;
          }

          &.disabled {
               cursor: default;
          }

          &.disabled:hover {
               background: none;
          }

          &:hover {
               text-decoration: none;
          }
     }

     &.sidebar-unfoldable {
          .system-utilization {
               opacity: 0;
               overflow: hidden;
          }

          &:hover {
               .system-utilization {
                    opacity: 1;
               }
          }
     }

     .nav-listener-setting {
          font-size: 10px;
          color: #ecedee;
     }

     .my-account-nav {
          .sidebar-nav-title {
               font-size: 12px;
               padding: 0.75rem 1rem 0 1rem;
               margin-bottom: 10px;
               margin-top: 2px;
          }
     }

     &.sidebar-fixed {
          z-index: 1040;
     }

     &.tour-destination {
          z-index: 4022;
     }
     &.sidebar-v2 {
          .sidebar-nav-item:not(.custom-btn-add) {
               .sidebar-nav-link {
                    svg+span {
                         margin-left: 15px;
                    }
               }
          }
          
          &.minimize-nav {
               .sidebar-nav-item:not(.custom-btn-add) {
                    .sidebar-nav-link {
                         svg+span {
                              transition: all .3s;
                              margin-left: 9px;
                         }
                    }
               }
          }
     }
}

.sidebar.sidebar-dark.sidebar-lg.sidebar-fixed {
     box-shadow: none !important;
}

.side-bar {
     // padding: 45px 42px 0 45px;
     padding: 45px 43.5px 0 43.5px;

     &.sidebar {
          z-index: 10000;

          &.show {
               width: 495px;

               &.compare-plans {
                    width: 100%;
                    padding: 0;
               }

               .sidebar-close {
                    position: fixed;
                    top: 0;
               }
          }
     }
     &.sidebar-light{
          background-color: #fff;
          max-height: 100vh;
     }
     &.master-class {
          z-index: 10001;
          overflow-y: auto;

          &::-webkit-scrollbar-track {
               -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
               border-radius: 10px;
               background-color: #f5f5f5;
          }

          &::-webkit-scrollbar {
               width: 2px;
               background-color: #f5f5f5;
          }

          &::-webkit-scrollbar-thumb {
               border-radius: 10px;
               -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
               background-color: #212c36;
          }
     }

     h3 {
          font-family: 'Poppins';
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0;
          background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // margin-top: 40px;
          margin-top: 36px;
          // margin-bottom: 15px;
          margin-bottom: 20px;
     }

     .subtitle {
          font-weight: 300;
          line-height: 24px;
          letter-spacing: 0.11px;
          color: #000;
          margin-bottom: 72px;
     }

     .btn-back {
          position: absolute;
          top: 28px;
          left: 40px;
          padding-left: 0;
          margin-bottom: 20px;
          // font-size: 14px;
          // letter-spacing: 0.2px;
          // font-weight: 400;
          // color: #26313e;
          text-transform: capitalize;
          color: #73818f;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;

          .i {
               // color: #708393;
               color: #73818f;
               margin-right: 10px;
          }
     }

     .icon-close-popup {
          top: 34px;
          right: 28px;
     }

     svg.icon.icon-close-popup {
          width: 18px !important;
          height: 18px !important;
          color: #7f8c9a;
     }

     .sidebar-popup {
          display: flex;
          flex-direction: column;
          height: 100%;
          // margin-top: 57px;
          margin-top: 25px;

          .form-group {
               .input-card-number {
                    position: relative;

                    .logo-card {
                         display: flex;
                         align-items: center;
                         position: absolute;
                         top: 50%;
                         right: 15px;
                         transform: translateY(-50%);
                         width: calc(100% - 155px);
                         justify-content: flex-end;

                         img {
                              vertical-align: middle;
                              border-style: none;
                              filter: grayscale(100%);
                              max-height: 18px;
                              margin-right: 3px;
                         }

                         .form-control {
                              height: calc(1.5em + 0.75rem + 10px);
                         }
                    }
               }
          }

          .sidebar-header {
               position: relative;

               .description {
                    margin-bottom: 25px;

                    p {
                         color: #2f353a;
                         font-family: Roboto;
                         font-size: 16px;
                         font-weight: 400;
                         line-height: 23px;
                         text-align: center;
                         margin-left: 24px;
                    }
               }

               .sidebar-body {
                    margin-bottom: 30px;

                    .limitation {
                         margin-bottom: 3px;
                         font-size: 16px;
                         font-weight: 500;
                         letter-spacing: 0.035em;
                         font-family: Poppins;
                    }

                    .limitation-text {
                         margin-bottom: 17px;
                         padding-top: 5px;
                    }

                    .recommendation {
                         margin-bottom: 3px;
                         font-size: 16px;
                         font-weight: 500;
                         letter-spacing: 0.035em;
                         line-height: 28px;
                         font-family: Poppins;
                    }

                    .recommendation-text {
                         padding: 0 20px;
                    }

                    p {
                         color: #2f353a;
                         font-family: Roboto;
                         font-size: 14px;
                         font-weight: 400;
                         text-align: center;
                    }

                    p.text-center.recommendation-text.fw-4 {
                         padding-left: 24px;
                         padding-right: 24px;
                         padding-bottom: 18px;
                    }
               }

               .sidebar-additional-options {
                    margin-top: 25px;

                    .recommendation-text {
                         padding: 0 20px;
                    }

                    p {
                         font-size: 13px;
                    }
               }
          }

          .sidebar-footer {
               margin-top: auto;
               display: flex;
               flex-direction: column;
               border-top: 2px solid #eaeaea;
               padding: 40px 0 45px;

               .sidebar-upgrade-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 100%;
               }

               .c-avatar-img {
                    height: 79px;
                    width: 79px;
               }

               .description {
                    p {
                         font-family: Roboto;
                         font-weight: 300;
                         line-height: 18px;
                         letter-spacing: 0.086666666px;
                         color: #000000 !important;
                    }
               }

               i.fal.fa-comment {
                    width: 16px;
                    height: 16px;
               }
          }

          .underline {
               text-decoration: underline;
          }

          .annual-billing {
               align-items: center;
          }

          .payment-description {
               padding: 10px 0px;
          }

          .group-btn {
               display: grid;

               .btn-success-now {
                    text-transform: uppercase;
                    padding: 14.5px 10px 14.5px 10px;
                    border-radius: 22.5px;
                    color: #ffffff;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    text-align: center;
                    text-transform: uppercase;
               }

               .compare-plan {
                    margin-top: 15px;
                    // opacity: 0.7;
                    text-transform: capitalize;
                    color: #7f8c9a;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    text-align: center;

                    &:hover {
                         color: #3c4b64;
                         text-decoration: none;
                    }
               }
          }

          .help-btns {
               .btn-help-desk {
                    border-radius: 17.5px;
                    width: 121px;
                    height: 35px;
               }
          }
     }
}

.sidebar-popup{
     .sidebar-header {
          display: block;
     }
}

.masterclass-aside {
     .button-wrapper {
          display: grid;

          .purchase-btn {
               border-radius: 3px;
               padding: 12px 10px;
          }
     }
}

.system-utilization {
     max-height: 100px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     overflow: hidden;

     .sidebar-nav-title {
          margin-top: 25px;
          padding: 20px 18px;
     }

     .statistics-item {
          padding: 0 17px 0;
          cursor: pointer;

          .progress + small {
               display: inline-block;
               line-height: 1.2em;
               margin-top: 1em;
          }
     }

     .title-statistics {
          margin-bottom: 2px;
          font-size: 13px;
     }

     .text-muted {
          font-size: 10px;
     }

     .btn-packages {
          text-align: center;
          margin-bottom: 12px;
     }

     a {
          &.btn-success {
               display: block;
               text-transform: uppercase;
               font-weight: 500;
               padding: 0.5em;
               margin-bottom: 0.4em;
               text-decoration: none;
               color: #fff;
          }
     }

     .btn-compare {
          text-decoration: none;
          color: #fff;
          font-size: 13px;
     }

     a {
          &.btn-compare {
               text-decoration: none;
               color: #fff;
               font-size: 13px;
          }
     }

     .progress-bar {
          background-color: $success !important;
     }
}

.subscriber-my-account-sidebar {
     box-shadow: none !important;

     &.sidebar {
          color: #242e37;
          background-color: $light;

          .sidebar-brand {
               background: none;
          }

          .sidebar-nav-link {
               color: #212c36;

               svg {
                    color: #637687;
               }

               &.c-active,
               &.active {
                    background-color: #e5ebef;
                    color: #212c36 !important;
                    font-weight: 500;

                    svg {
                         color: #212c36 !important;
                    }
               }

               &.nav-link-my-account {
                    text-transform: uppercase;
                    color: $gray-600;
                    font-weight: 500;
                    pointer-events: none;
               }

               &:hover {
                    color: $light;
                    background-color: $blue;

                    svg {
                         color: $light;
                    }
               }
          }
     }
}

.switch-layout {
     flex: 0 0 40px;
     display: flex;
     flex-direction: row;
     align-items: center;
     padding-left: 18px;
     margin-top: -20px;
     position: relative;
     z-index: 9;
}

.toggle-sidebar {
     &.hide {
          display: none;
     }
}

.sidebar-v2 {
     width: auto;
     background-color: #121920;

     .sidebar-nav-link {
          display: flex;
          flex: 1 1;
          align-items: center;
          padding: .8445rem 1rem;
          text-decoration: none;
          white-space: nowrap;
          transition: background .3s,color .3s;
     }

     .sidebar-nav-icon {
          flex: 0 0 56px;
          height: 1.09375rem;
          font-size: 1.09375rem;
          text-align: center;
          transition: .3s;
          fill: currentColor;
     }

     &.minimize-nav {
          .sidebar-lv1 {
               padding-right: 0;

               .sidebar-nav-link {
                    text-indent: -9999px;
               }
          }
     }

     div {
          transition: all 0.3s ease;
     }

     .sidebar-brand {
          background-color: transparent;
     }

     .sidebar-top {
          min-height: 56px;
          position: relative;
          z-index: 1;
          background-color: #121920;
     }

     .header-toggler {
          padding-left: 8px;
          transition: all 0.8s ease;

          &.toggler-right {
               transform: rotateY(180deg);
          }
     }

     .sidebar-content-wrap {
          height: calc(100% - 57px);
          display: flex;
     }

     .sidebar-nav-list {
          display: flex;
          flex-direction: column;
          width: 100%;
          list-style-type: none;
          position: relative;

          .sidebar-lv2-scroll {
               display: flex;
               justify-content: flex-end;
               align-items: center;
          }

          .iconScroll {
               width: 22px;
               height: 12px;
               margin-right: 16px;
               cursor: pointer;
               pointer-events: auto;
          }

          .scrollTop {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               width: 100%;
               height: 61px;
               background: linear-gradient(360deg, rgba(18, 25, 32, 0) 0%, #121920 100%);
               pointer-events: none;
               z-index: 1000;
          }

          .scrollBottom {
               content: '';
               position: sticky;
               padding: 20px 0px 20px 20px;
               bottom: 0;
               left: 0;
               right: 0;
               width: 100%;
               height: 61px;
               background: linear-gradient(180deg, rgba(18, 25, 32, 0) 0%, #121920 100%);
               pointer-events: none;
               z-index: 1000;
          }
     }

     .sidebar-nav {
          z-index: 2;
          overflow: hidden;
          touch-action: auto;
          -ms-overflow-style: none;
          overflow-anchor: none;
     }

     .sidebar-lv1 {
          width: 197px;
          padding-right: 4px;
          position: relative;
          z-index: 1;
          background-color: #121920;

          .minimize-sidebar {
               position: absolute;
               z-index: 2;
               display: block;
               top: 50%;
               right: 0;
               transform: translateX(100%);

               svg {
                    cursor: pointer;
               }

               .wrap {
                    position: relative;
               }

               .arrow-area {
                    position: absolute;
                    top: -16px;
                    left: -13px;
                    width: auto;
                    height: 48px;
                    transform: rotate(180deg);
                    color: #121920;
               }

               .minimize-icon {
                    position: relative;
                    color: #73818f;
                    top: 4px;
                    left: -4px;
                    width: auto;
                    height: 6px;
                    transform: translateY(-130%);
               }
          }

          .sidebar-nav-item {
               margin-bottom: 10px;
               position: relative;

               a {
                    color: #73818f;
                    background-color: #1a222c;
                    transition: none;
                    display: flex;
                    align-items: flex-start;
                    padding: 14px 15px;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0.1714286px;

                    &:hover {
                         background-color: #0a0f13;
                    }

                    svg {
                         color: inherit !important;
                    }
               }

               &.active a {
                    color: #20a8d8;
                    background-color: #0a0f13;
                    font-weight: 700;
               }

               &.has-child:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 1000;
                    top: calc(50% - 1px);
                    right: 16px;
                    width: 7px;
                    height: 7px;
                    transform: rotate(45deg) translateY(-50%);
                    border-top: 1px solid #6b7076;
                    border-right: 1px solid #6b7076;
               }
          }

          .sidebar-nav-link {
               text-transform: uppercase;
               padding: 14px 18px;
               letter-spacing: -0.3px;
               background-image: none;

               svg {
                    margin-right: -5px;
               }

               &:before {
                    display: none;
               }
          }
     }

     .sidebar-lv2 {
          width: 245px;
          margin-left: -241px;
          background-color: #0a0e13;

          .sidebar-nav-dropdown-items {
               list-style: none;
               padding-inline-start: 0;
               .sidebar-nav-item:not(:first-child) {
                    .sidebar-nav-link {
                         border: none;

                         &::after {
                              content: '';
                              display: block;
                              position: absolute;
                              top: 0;
                              right: 0;
                              width: 92%;
                              height: 1px;
                              transition: all 0.3s ease;
                              background-color: #121920;
                         }
                    }
               }

               .sidebar-nav-item {
                    &.btn-add-wrapper,
                    &.custom-btn-add {
                         height: 45px;
                         display: flex;
                         align-items: center;

                         .c-active,
                         &.active {
                              color: #7f8c9a !important;
                              background-image: none;

                              &:hover {
                                   color: #ffffff !important;
                              }

                              &::before {
                                   content: unset;
                              }
                         }
                    }

                    &.highlight .sidebar-nav-link {
                         color: #20a8d8;
                    }

                    &.destination-nav {
                         .sidebar-nav-link .badge {
                              color: #7f8c9a;
                              background-color: transparent;
                              border: solid 1px #7f8c9a;
                              border-radius: 3px;
                              text-transform: uppercase;
                              line-height: 8px;
                              font-size: 7px;
                              font-weight: 700;
                              letter-spacing: 0.099999994px;
                              padding: 2px 3.5px 1px 3.5px;
                         }
                    }
               }
          }

          .sidebar-nav-groups-items {
               .btn-add-wrapper {
                    margin-left: 10px;
               }

               .groups-nav-items {
                    .container {
                         max-width: 600px;
                         margin: 0 auto;
                         margin-bottom: 5px;
                         display: flex;

                         .text-divider {
                              display: flex;
                              align-items: center;
                              --text-divider-gap: 1rem;
                              width: 100%;
                              font-size: 12px;
                              color: #7f8c9a;
                         }

                         .text-divider::before,
                         .text-divider::after {
                              content: '';
                              height: 1px;
                              background-color: #7f8c9a;
                              flex-grow: 1;
                         }

                         .text-divider::before {
                              margin-right: var(--text-divider-gap);
                         }

                         .text-divider::after {
                              margin-left: var(--text-divider-gap);
                         }
                    }

                    .sidebar-nav-item {
                         .sidebar-nav-link {
                              color: #fff !important;
                              display: flex;
                              gap: 9px;

                              &.active {
                                   color: #20a8d8 !important;
                              }
                         }

                         .sidebar-nav-link:hover {
                              color: #20a8d8 !important;
                         }

                         .sidebar-nav-link::after {
                              background-color: transparent !important;
                         }
                    }

                    .highlight {
                         .sidebar-nav-link {
                              color: #20a8d8 !important;
                         }
                    }

                    .sidebar-nav-item:last-child {
                         margin: 0;
                    }

                    .no-data {
                         display: inline-block;
                         margin-bottom: 13px;
                         white-space: nowrap;
                         font-size: 12px;
                         padding: 0px 54px;
                         font-style: italic;
                         color: #405a76;
                    }
               }
          }

          .sidebar-nav-link,
          .sidebar-nav-dropdown-toggle {
               position: relative;
               padding: 13.5px 18px;
               border-top: 1px solid #121920;
               background-size: 24px;
               background-position: center right -36px !important;
               color: $white;

               svg {
                    color: inherit !important;
               }

               &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 2px;
                    height: 0;
                    opacity: 0;
                    background: linear-gradient(128deg, #ffc107, #20a8d8, #d8d8d8);
                    transition: all 0.3s ease;
                    transform: translateY(-50%);
               }

               &.c-active:before,
               &.active::before {
                    height: 100%;
                    opacity: 1;
               }

               &.c-active,
               &.active {
                    color: #20a8d8;
                    background-color: transparent;
                    background-image: url('/assets/icons/icon-arrow-area.svg');
                    background-position: center right -12px !important;
                    background-repeat: no-repeat;
               }

               &:hover {
                    color: #20a8d8;
               }
          }

          .sidebar-nav-item:first-child {
               .sidebar-nav-link {
                    border: none;
               }
          }

          // .sidebar-nav-link .sidebar-nav-icon {
          //      margin-right: -6px;
          // }

          .sidebar-nav-dropdown {
               .sidebar-nav-dropdown-toggle {
                    display: flex;
                    flex: 1 1;
                    align-items: center;
                    text-decoration: none;
                    transition: background 0.3s, color 0.3s;
                    border-bottom: 1px solid #121920;

                    i {
                         margin-left: 7px;
                         margin-top: -3px;
                         svg {
                              rect {
                                   fill: #000 !important;
                              }
                              path {
                                   fill: #73818f !important;
                              }
                         }
                    }

                    &:after {
                         background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
                         margin-right: 5px;
                         transform: rotate(-90deg);
                         margin-left: auto;
                         display: block;
                         flex: 0 1 8px;
                         height: 8px;
                         content: '';
                         background-repeat: no-repeat;
                         background-position: 50%;
                         transition: transform 0.3s;
                    }
               }

               &.c-show .sidebar-nav-dropdown-toggle:after {
                    transform: rotate(90deg);
               }
          }

          .sidebar-nav-dropdown .item-dropdown-toggle {
               .sidebar-nav-dropdown-toggle {
                    border-bottom: 1px solid #121920;

                    &:after {
                         margin-right: 5px;
                         transform: rotate(-90deg);
                    }
               }

               &.c-show .sidebar-nav-dropdown-toggle:after {
                    transform: rotate(90deg);
               }
          }

          .sidebar-nav-dropdown-last {
               position: absolute;
               bottom: 0;
               width: 100%;
               background: #090b0f;
          }

          .sidebar-nav-dropdown {
               &.has-button-add .sidebar-nav-dropdown-items::before {
                    height: calc(100% - 77px);
                    top: calc(50% + 22px);
               }
          }

          .sidebar-nav-dropdown-items {
               position: relative;
               margin-left: 20px;
               margin-bottom: 0;

               &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 2px;
                    height: calc(100% - 28px);
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: #121920;
               }

               a {
                    font-size: 11px;
                    letter-spacing: 0.15714285px;
                    color: #7f8c9a;
               }

               .sidebar-nav-link {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    padding-left: 17px;
                    white-space: normal !important;

                    .sidebar-nav-icon {
                         margin-top: -1px;
                    }
               }
          }
     }

     .sidebar-items-wrap {
          position: relative;
          // overflow-x: hidden;
          height: 100%;
          opacity: 0;
          transform: translateX(-100%);
          transition: all 0.2s ease;
          // overflow: hidden;

          &::-webkit-scrollbar {
               width: 0;
          }

          &::-webkit-scrollbar-track {
               background: #f1f1f1;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
               background: #888;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
               background: #555;
          }

          .btn-add-wrapper {
               a {
                    font-size: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-radius: 17.5px;
                    border: solid 1px #73818f !important;
                    max-width: max-content;
                    padding: 5px 19px 4.5px 19px !important;
                    letter-spacing: 0.14285713px;
                    transition: 0.15s;
                    z-index: 1;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                         color: #ffffff !important;
                         background-color: #20a8d8;
                         border: solid 1px #20a8d8 !important;
                    }

                    svg {
                         width: 9.17px;
                         height: 9.17px;
                         flex: unset;
                         margin: 0 6px 0 0 !important;
                         transition: 0s;
                    }
               }
          }

          .custom-btn-add {
               height: 100%;
               display: flex;
               align-items: center;
               padding-left: 18px;

               a {
                    font-size: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-radius: 17.5px;
                    border: solid 1px #7f8c9a !important;
                    max-width: max-content;
                    padding: 5px 19px 4.5px 19px !important;
                    margin: 10px 0px;
                    letter-spacing: 0.14285713px;
                    transition: 0.15s;
                    z-index: 1;
                    align-items: center;
                    justify-content: center;
                    color: #7f8c9a;

                    &:hover {
                         color: #ffffff !important;
                         background-color: #20a8d8;
                         border: solid 1px #20a8d8 !important;
                    }

                    svg {
                         width: 9.17px;
                         height: 9.17px;
                         flex: unset;
                         margin: 0 6px 0 0 !important;
                         transition: 0s;
                    }
               }
          }

          .custom-des-nav {
               a {
                    font-size: 12px;
                    letter-spacing: 0.15714285px;
               }
          }
     }

     .navigation-list {
          opacity: 0;
          // visibility: hidden;
          transform: translateX(-100%);
          overflow-y: unset;
          position: absolute;
          z-index: 0;
          width: 100%;
          // top: var(--scrollY);

          &.show {
               opacity: 1;
               position: relative;
               // height: 100%;
               // visibility: visible;
               transform: translateX(0);
               z-index: 1;
          }
     }

     .nav-sticky {
          position: sticky;
          bottom: 0;
          z-index: 100;
          background-color: #090b0f !important;
          width: 100%;
          margin-bottom: 6px;
     }

     .sidebar-nav-dropdown {
          &.custom .sidebar-nav-dropdown-toggle .tooltip-wrapper {
               position: relative;
               margin-left: 7px;
               margin-top: -3px;

               > svg {
                    width: 11px;
                    height: 11px;

                    &:hover path {
                         fill: #c8ced3 !important;
                    }

                    path {
                         fill: #73818f !important;
                    }

                    rect {
                         fill: transparent !important;
                    }
               }

               .block-tooltip {
                    left: 30px;
                    top: 4px;
                    z-index: 1000;

                    .arrow-preview {
                         width: 8px;
                         top: -1px;
                         left: -7px;

                         path {
                              fill: #fff !important;
                         }
                    }

                    .block-tooltip-text {
                         width: fit-content;
                         padding: 10px 16px;
                         border: solid 0.5px #c8ced3;
                         background: #fff;
                         color: #000;
                         font-size: 12px;
                         letter-spacing: 0.08px;
                         font-weight: 400;
                         line-height: 17px;
                         border-top-left-radius: 0 !important;
                         border-top-right-radius: 10px !important;
                         border-radius: 12px;

                         p {
                              line-height: 21px;
                         }
                    }
               }
          }
     }

     .sidebar-nav-link,
     .sidebar-nav-dropdown-toggle {
          position: relative;
          letter-spacing: 0.2px;

          &.c-active,
          &.active {
               color: #20a8d8;
          }

          .badge {
               color: #0b1016;
               background-color: #ffc207 !important;
               padding: 3px 8px;
               border-radius: 20px;
          }

          &:hover {
               background-color: inherit;
          }
     }

     .system-utilization {
          padding: 20px 17px 0;
     }

     .system-utilization,
     .switch-layout {
          min-width: 192px;
     }

     &.minimize-nav {
          .sidebar-brand {
               padding-left: 12px;
          }

          .sidebar-lv1 {
               width: 44px;

               .sidebar-nav-link {
                    padding: 15px;
               }

               .sidebar-nav-item.has-child:before {
                    display: none;
               }
          }

          .system-utilization,
          .switch-layout {
               display: none;
          }

          .minimize-sidebar {
               .minimize-icon {
                    transform: rotate(180deg) translateY(130%);
               }
          }
     }

     &.show-nav-child {
          .header-toggler {
               padding-right: 23px;
          }

          .sidebar-lv2 {
               margin-left: 0;
          }

          .sidebar-items-wrap {
               opacity: 1;
               transform: translateX(0);
          }
     }

     .sidebar-nav-dropdown-items {
          max-height: 0;
          padding: 0;
          overflow-y: hidden;
          list-style: none;
          transition: max-height 0.3s ease-in-out;
     }

     .sidebar-nav-dropdown.c-show {
          > .sidebar-nav-dropdown-items {
               max-height: calc(100% - 135px);

               .sidebar-nav-link {
                    color: #7f8c9a;

                    &.c-active,
                    &.active {
                         color: #20a8d8;
                    }
               }
          }
     }
}

.support-sidebar {
     .sidebar-nav-link {
          &.c-active {
               color: rgba(0,0,0,.8);
               background: rgba(0,0,0,.05);
          }
          color: $black;
          &:hover {
               color: $white;
          }
     }
}

@keyframes mymove {
     0% {
          bottom: 100%;
     }

     5% {
          bottom: 90%;
     }

     6% {
          bottom: 100%;
     }

     100% {
          bottom: 100%;
     }
}

.scroll-footer {
     position: relative;
     overflow: visible !important;

     &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 61px;
          min-height: 61px;
          background: linear-gradient(180deg, rgba(18, 25, 32, 0) 0%, #121920 100%);
          bottom: 100%;
          left: 0;
     }

     &::before {
          background-image: url(/assets/images/dropdown.svg);
          position: absolute;
          content: '';
          bottom: 100%;
          right: 0;
          width: 100%;
          height: 61px;
          min-height: 61px;
          background-repeat: no-repeat;
          background-size: 7px;
          z-index: 2;
          background-position: 93% 70%;
          animation: mymove 10s;
          animation-iteration-count: infinite;
     }
}

@media screen and (min-width: 1450px) and (max-width: 1800px) {
     .c-default-layout {
          .sidebar {
               &.sidebar-left {
                    width: calc(((100vw - 1450px) / 7) + 200px);
               }
          }
     }

     html:not([dir='rtl']) .sidebar.sidebar-lg:not(.hide).sidebar-fixed ~ .c-wrapper {
          margin-left: calc(((100vw - 1450px) / 7) + 200px);
     }
}

@media screen and (min-width: 1801px) {
     .c-default-layout {
          .sidebar {
               &.sidebar-left {
                    width: 250px;
               }
          }
     }

     html:not([dir='rtl']) .sidebar.sidebar-lg:not(.hide).sidebar-fixed ~ .c-wrapper {
          margin-left: 250px;
     }
}

@media screen and (max-width: 1449px) {
     .scroll-footer {
          position: relative;
          overflow: visible !important;

          &:after {
               position: absolute;
               content: '';
               width: 100%;
               height: 61px;
               min-height: 61px;
               background: linear-gradient(180deg, rgba(18, 25, 32, 0) 0%, #121920 100%);
               bottom: 100%;
               left: 0;
          }

          &::before {
               background-position: 91% 70%;
          }
     }
}

@media screen and (max-width: 991px) {
     .sidebar {
          .sidebar-nav-link {
               &.c-active,
               &.active {
                    overflow: hidden;

                    &:before {
                         background-color: #20a8d8;
                         background: linear-gradient(128deg, #ffc107, #20a8d8, #d8d8d8);
                    }

                    background-image: none;
                    background-repeat: no-repeat;
                    background-position: right;
                    background-size: 7px;
                    z-index: 999;
               }
          }
     }
}
