.support-ticket-popup {
    .modal {
        z-index: 9999;
    }

    .modal-dialog {
        margin: 0;
        position: absolute;
        bottom: 0;
        right: 1.9rem;
        max-width: initial;
    }

    .modal-content {
        border-radius: initial;
        right: -8px;
    }

    .ticket-popup-content {
        padding: 20px 30px 30px;
        max-width: 425px;
        max-height: calc(100vh - 30px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-300;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-left: 2px solid #fff;
            border-right: 2px solid #fff;
        }

        &.strategy-request-content,
        &.ticket-popup-successfully {
            max-width: 620px;
        }

        &.ticket-video-content {
            min-width: 620px;
            max-width: auto;
        }
    }

    .btn-back-ticket {
        position: relative;

        svg {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        span {
            padding-left: 16px;
            cursor: pointer;
            display: inline-block;
            position: relative;
        }
    }

    .btn-close-ticket {
        position: relative;
        text-align: right;

        svg {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        span {
            padding-left: 16px;
            cursor: pointer;
            display: inline-block;
            position: relative;
        }
    }

    .ticket-popup-header {
        text-align: center;
    }

    .ticket-popup-body {
        button.btn {
            width: 100%;
            margin-bottom: 15px;
            text-transform: initial;
            padding: 12px;

            &.btn-text-example {
                span {
                    display: block;

                    &:last-of-type {
                        font-size: 11px;
                    }
                }
            }
        }
    }

    .ticket-popup-footer {
        .ticket-popup-footer-link {
            text-align: right;
        }
    }

    .ticket-group-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;

        label {
            display: inline-block;
            margin: 0;
            margin-top: 6px;
            flex: 0 0 30%;
            max-width: 30%;
        }

        .ticket-group-input {
            flex: 0 0 70%;
            max-width: 70%
        }
    }

    .ticket-group-column {
        margin-bottom: 15px;

        >p {
            margin-bottom: 5px;
        }

        .ticket-invalid-feedback {
            display: block;
            padding-top: 3px;
            color: #f86c6b;
            font-size: 11px;
        }
    }

    .ticket-group-input {
        .ticket-invalid-feedback {
            display: block;
            margin-top: 3px;
            margin-bottom: 14px;
            color: #f86c6b;
            font-size: 11px;
        }

        .ql-tooltip {
            &.ql-editing {
                left: 0 !important;
            }
        }
    }

    .ticket-group-drop-zone {
        .dropzone {
            min-height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #e4e7e9;
            border-radius: 0.25rem;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }

            p {
                margin-bottom: 0;
            }
        }

        .no-click-drop {
            opacity: .5;
            cursor: not-allowed;
        }

        .drop-zone-list-file {
            margin-top: 15px;

            ul {
                padding: 0;

                li {
                    list-style-position: inside !important;
                    list-style-type: disc !important;

                    .remove-file-image {
                        display: inline-block;
                        margin-left: 10px;
                        font-weight: 600;
                        color: #f86c6b;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .ticket-video-wrapper {
        margin-bottom: 10px;
        background-color: #fff;
        border-radius: 4px;
        border: 0.5px solid #e4e7e9;
        overflow: hidden;

        .ticket-loading-video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .embed-responsive {
            position: relative;

            iframe {
                z-index: 2;
            }
        }
    }

    .ticket-text-below-video {
        margin-bottom: 20px;
    }

    .ticket-group-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        input {
            margin: 0;
            position: relative;
            margin-right: 5px;
            cursor: pointer;
        }

        label {
            margin: 0;
            cursor: pointer;
        }
    }
}
