.goals-wrapper {
    height: 72vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -30px;
    padding-right: 20px;
    .goals-items {
        .goals-item {
            .card {
                
                border: 1px solid #f0f3f5!important;
                box-shadow: 0 4px 6px 2px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important;
                .card-body {
                    display: flex;
                    flex-direction: column;
                    .card-text {
                        p {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #e2e6e9;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #73818f;
        border-radius: 2px;
        border: 1px solid #e2e6e9;
    }

    .goals-items {
        margin-right: 5px;
    }

    .row-items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
        gap: 20px;
        margin-bottom: 10px;
    }

    .goals-item>.card {
        margin: 0;
        height: 100%;
    }
}

.goals-items {
    .card-header-actions {
        display: flex;
        align-items: center;
        gap: 15px;
        .action-open-menu {
            svg {
                width: 17px;
                height: 17px;
                color: #73818f;
            }
        }
        .coming-soon-text {
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            color: #c8ced3;
       }
    }
}