.list-image-upload {
    .content {
        padding: 3px;
        height: 100%;
        border: 1px solid #000;
    }
    .content:active{
        border: 4px solid #000;
    }
    
    .attachment {
        position: relative; 
        float: left; 
        padding: 8px; 
        margin: 0; 
        color: #3c434a; 
        list-style: none; 
        text-align: center; 
        -webkit-user-select: none;
        -ms-user-select: none; 
        user-select: none; 
        box-sizing: border-box; 
        width: 20%;

        .thumbnail {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 1;
            transition: opacity .1s;

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
                overflow: hidden;
            }
            .centered {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translate(50%, 50%);

                img,
                video {
                    transform: translate(-50%, -50%);
                }
            }
            img,
            video {
                position: absolute;
                top: 0;
                left: 0;
                max-height: 100%;
            }
        }
    }

    .attachment-preview {
        position: relative;
        box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
        background: #f0f0f1;
        cursor: pointer;
        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }
}

.redis-center {
    margin-left: auto;
    margin-right: auto;
}

.select-upload-library {
    button{
        margin-right: 10px;
    }
}

.media-detail-modal {
    .modal-dialog {
        .thumbnail {
            display: flex;
            justify-content: center;
            align-items: center;

            .details-image {
                max-width: 100%;
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .media-detail-modal {
        .modal-dialog {
            max-width: 70%;
        }
    }
}
