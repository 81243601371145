.page-question {
     h5 {
          span {
               font-size: 14px;
               font-style: italic;
          }
     }
}
.admin-user-course-table {
     .badge {
          padding: 0.5em 0.8em;
     }
     .react-datepicker-wrapper {
          width: 100%;
     }
}
.answers-box {
     background-color: #f5f5f5;
     .right-border {
          border-right: 2px solid #313131;
     }
     .errors {
          color: red;
          font-size: 14px;
          line-height: 1;
     }
}
.upload-file {
     position: relative;
}
.certificate-page {
     .image-certificate {
          min-height: 300px;
          height: 100%;
          img {
               max-height: 300px;
          }
     }
     .course-item {
          padding: 5px 0;
          .custom-radio *{
               cursor: pointer;
          }
     }
}

.page-add-test {
     .add-question {
          background-color: #f3f3f3;
          padding: 15px 0;
          text-align: center;
          width: 100%;
          display: block;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          span {
               padding: 6px 12px;
               background-color: #c1c1c1;
               border-radius: 5px;
          }
     }
}

.page-courses {
     .add-lesson {
          background-color: #f3f3f3;
          padding: 15px 0;
          text-align: center;
          width: 100%;
          display: block;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          a {
               padding: 6px 12px;
               background-color: #d3d3d3;
               border-radius: 5px;
               color: #000;
               transition: 0.5s all;
               &:hover {
                    background-color: #b9b9b9;
                    list-style: none;
                    text-decoration: none;
               }
          }
     }
     .list-lessons {
          .lesson-item {
               padding: 5px 0;
          }
     }
     
}


.page-tests {
     .name {
          strong {
               font-weight: 600;
               color: #50575e;
               line-height: 1.5em;
               font-size: 14px;
          }
     }
}
.page-lesson, .page-courses, .certificate-page {
     .table-right {
          display: block;
          max-height: 334px;
          overflow: auto;
          &::-webkit-scrollbar-track {
               -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
               border-radius: 10px;
               background-color: #F5F5F5;
          }
     
          &::-webkit-scrollbar {
               width: 8px;
               background-color: #F5F5F5;
          }
     
          &::-webkit-scrollbar-thumb {
               border-radius: 10px;
               -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
               background-color: rgb(158, 158, 158);
          }
     }
}


.page-lesson {
     .list-tests {
          .test-item {
               padding: 7px 0;
               .custom-radio *{
                    cursor: pointer;
               }
          }
     }
     .has-video {
          border: 1px solid rgb(156, 156, 156);
          padding: 3px;
          cursor: pointer;
          margin-right: 20px;
          margin-bottom: 15px;
          width: 160px;
          height: 130px;
          position: relative;
          .video-box {
               width: auto;
               height: 100%;
               background-color: #4a4a4a;
               transition: 0.5s all;
               position: relative;

               video {
                    position: relative;
                    width: 100%;
                    height: 100%;
               }

               .video-bg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    background-color: #5d5d5d80;

                    svg {
                         width: 2rem;
                         height: 2rem;
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                    }
               }

               &:hover {
                    background-color: #5e5e5e;
               }
          }
     }
     .modal-content {
          video {
               width: 100%;
               max-height: 650px;
          }
     }
}

.admin-partner {
     img {
          max-width: 180px;
     }
}

.user-course-admin {
     .section-wrapper {
          position: relative;
          border: 1px solid rgba(200,206,211,.35);
          border-radius: 15px;
          overflow: hidden;
          &:not(:last-child) {
               margin-bottom: 10px;
          }
     }
     .course-content .section-toggle {
          background-color: #f0f3f5;
          width: 100%;
          border: none;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          display: flex;
          justify-content: space-between;
          padding: 12px 14px 8px 19px;
     }
     .lesson .wrapper {
          padding: 20px;
     }
     .badge {
          padding: 4.5px 4px 4px;
          font-size: 10px;
          font-weight: 700;
          line-height: 1;
          text-align: center;
          letter-spacing: .1428571px;
          color: #fff;
          border-radius: 9.5px;
          background-color: #c8ced3 !important;
          min-width: 82px;
          text-transform: uppercase;
          &.bg-success {
               background-color: #4dbd74 !important;
          }
     }
}