.account-dormant-popup {
	.modal-content {
		height: 100%;
		border-radius: 0;
	}

	.account-dormant {
		padding: 44px 37px;
		margin-right: auto;

		.des {
			font-weight: 400;
			line-height: 1.4em;
			margin-bottom: 10px;
		}
	}

	.cancel-account-dormant {
		width: 480px;
		margin: 0 auto;
		padding: 60px 35px 55px 35px;

		.account-dormant {
			padding-bottom: 55px;
		}

		.foot-note {
			margin-top: 24px;
		}

		.modal-content {
			height: auto;
		}

		.confirm-save-icon {
			padding-bottom: 25px;
		}

		h2 {
			margin-bottom: 12px;
		}

		.confirm-save-content {
			margin-bottom: 12px;
		}

		.input-edit-account-name input {
			border-width: 1px;
			border-radius: 0;
			text-align: left;
			height: 35px;
			letter-spacing: -0.4px;
			background-color: transparent;
		}

		.button-group {
			margin-top: 15px;
			display: flex !important;
			gap: 20px;
			align-items: center;
			justify-content: center;

			button {
				padding: 12px 20px;
				min-width: 165px;
			}
		}
	}

	.confirm-save-content {
		p {
			line-height: 1.42em;
			margin-bottom: 0.7em;
		}
	}

	.btn-save {
		font-weight: 500;
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
	}

	.btn-cancel {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		letter-spacing: 0.2px;
		font-weight: 400;
		margin-top: 25px;
		margin-bottom: 4px;
		text-transform: unset;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.btn-create {
		margin-top: 20px;
		font-size: 13px;
		max-width: 420px;
		margin: 35px auto 5px;
	}
}