//metric-dropdown

.metric-dropdown {
     padding: 0 20px;
     .rule-segment-item {
          border-radius: 999px;
          margin: 5px 12px 5px 0px;
          padding: 5px 14px 5px 13px;
          border: 1px solid rgba(0, 0, 0, 0.08);
          &-info {
               font-size: 14px;
               font-weight: 400;
               line-height: 20px;
               text-align: left;
               path {
                    fill: #424242;
                    opacity: 0.5;
               }
          }
     }

     .add-metric {
          position: relative;
     }

     .btn-insert-variable:hover {
          rect:first-child {
               fill: rgba(32, 168, 216, 0.1);
          }
          path {
               fill: #20a8d8;
          }
     }

     .icon-arrow {
          position: absolute;
          min-width: 450px;
          z-index: 999;
          display: block;
          svg {
               position: absolute;
               top: 6.2px;
               left: 18px;
               width: 15px;
               height: 15px;
          }
     }

     .insert-variable-dropdown {
          position: relative;

          &.first-metric {
               .icon-arrow {
                    transform: rotateY(180deg);
                    right: 0px;
               }
               ul {
                    right: inherit;
                    left: 22px;
                    border-top-right-radius: 12px;
                    border-top-left-radius: 0;
               }
          }

          ul {
               top: 15px;
               list-style: none;
               position: absolute;
               right: 22px;
               padding: 0 10px 10px 10px;
               z-index: 99;
               border: 1px solid #e4e7ea !important;
               box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
               max-height: 280px;
               overflow-y: auto;
               background-color: #fff;
               width: 380px;
               border-radius: 12px;
               border-top-right-radius: 0;
               &.value-no-options-available {
                    padding-top: 14px;
                    left: 50%;
                    transform: translateX(-50%);

                    li {
                         text-align: center;
                         cursor: default;

                         &:hover {
                              text-decoration: none;
                         }
                    }

                    &.no-options {
                         text-align: center;
                    }
               }

               &::-webkit-scrollbar {
                    width: 7px;
                    background-color: transparent;
               }

               &::-webkit-scrollbar-thumb {
                    background-color: $gray-300;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border-left: 2px solid #fff;
                    border-right: 2px solid #fff;
               }

               .variable-filter {
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    &:before {
                         content: '';
                         display: block;
                         position: absolute;
                         z-index: 0;
                         background-color: #fff;
                         width: calc(100% + 20px);
                         height: 50px;
                         top: -10px;
                         left: -10px;
                    }

                    input {
                         position: relative;
                         border: 0;
                         padding: 5px 0 5px 5px;
                    }
               }

               li {
                    padding: 5px 0 5px 5px;
                    word-break: break-all;

                    &:not(.variable-filter):not(.no-options) {
                         span {
                              &:hover {
                                   cursor: pointer;
                                   text-decoration: underline;
                              }
                         }
                    }
                    &:nth-child(3) {
                         border-top: 0 !important;
                    }
               }
               .default {
                    background: rgba(244, 244, 245, 1);
                    border-top: 0 !important;
               }
               .child:hover {
                    background: rgba(244, 244, 245, 1);
                    label {
                         color: rgba(32, 168, 216, 1) !important;
                    }
               }
               .custom-control-label {
                    &::before {
                         border-radius: 6px;
                    }
               }
          }
     }

     form {
          .text-left {
               margin-bottom: 15px;
          }

          .create-custom-rule {
               &.create-custom-rule-invalid {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-height: 21px;
                    flex-wrap: wrap;
                    text-align: left;
               }

               text-align: end;

               .invalid-feedback {
                    width: auto;
                    margin-top: 0;
               }

               .create-custom-rule-link {
                    text-decoration: underline;
                    font-size: 12px;
               }
          }
     }

     label {
          &.disabled {
               color: #979da1 !important;
          }
     }

     input[type='text']:disabled {
          &::placeholder {
               color: #979da1;
          }

          background: transparent;
          border-color: rgba($color: #e4e7e9, $alpha: 0.5);
     }
     .icon-close-search {
          cursor: pointer;
          width: 10px;
          height: 10px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          fill: #424242;
          opacity: 0.5;
     }

     .invalid-custom-variables {
          margin-top: 0.25rem;
          font-size: 80%;
          font-weight: 400;
     }

     .browser-step4-label {
          margin-right: 10px;
     }

     .insert-variable-dropdown-icon {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
               padding: 0;
               margin-left: 5px;
               background-color: transparent;
               border: 0;
               border-bottom: 1px dotted #000;
          }
     }
}

// custom other dropdown
.other-dropdown {
     .dropdown {
          position: relative;
          width: 300px;
          &-content {
               width: 100%;
               padding: 10px;
               border: 1px solid #ccc;
               border-radius: 4px;
               cursor: pointer;
          }

          &-menu {
               position: absolute;
               top: 100%;
               left: 0;
               width: 100%;
               border: 1px solid #ccc;
               border-radius: 4px;
               background-color: white;
               box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
               z-index: 1000;

               &.show {
                    box-shadow: unset !important;
               }
          }
     }

     .toggle {
          svg {
               position: absolute;
               right: 5px;
          }
     }

     ul {
          top: 15px;
          list-style: none;
          position: absolute;
          padding: 0;
          z-index: 99;
          border: 1px solid #e4e7ea !important;
          box-shadow: unset;
          max-height: 280px;
          width: 100%;
          overflow-y: auto;
          background-color: #fff;
          border-radius: 4px;

          &::-webkit-scrollbar {
               width: 7px;
               background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
               background-color: $gray-300;
               border-top-left-radius: 3px;
               border-top-right-radius: 3px;
               border-bottom-left-radius: 3px;
               border-bottom-right-radius: 3px;
               border-left: 2px solid #fff;
               border-right: 2px solid #fff;
          }
     }

     li {
          list-style: none;
          padding: 10px 5px 10px 10px;
          cursor: pointer;
          overflow: hidden;
          .form-control {
               position: relative;
               border: 0;
               padding: 5px 0 5px 5px;
          }

          &.box-search {
               padding: 7px 5px 7px 10px;
               position: sticky;
               top: 0;
               z-index: 1;
               &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 0;
                    background-color: #fff;
                    width: calc(100% + 20px);
                    height: 60px;
                    top: -10px;
                    left: -10px;
               }
               &:hover {
                    background-color: unset;
               }
          }

          &.item {
               &:hover {
                    background-color: #f0f0f0;
                    color: rgba(32, 168, 216, 1);
               }
          }
     }

     .category {
          border-top: 1px solid #eee;
          &-header {
               padding: 10px;
               cursor: pointer;
               background-color: #f7f7f7;
          }
          &-items {
               padding: 10px;
          }
     }
}
