.customize-radio{
    margin: 0;
    position: relative;
    width: 17px !important;
    height: 17px !important;
    border: 1px solid #707070 !important;
    box-shadow: none !important;
    background: #ffff;
    background-image: none !important;
    cursor: pointer;
    &:checked{
        border-color: #20A8D8 !important;
        background-repeat: no-repeat;
        background-size: 17px;
        background-position: center;
        background-image: url("/assets/icons/checked-send-data.svg") !important;
    }
}

.table-inden .card-body .table-region .customize-radio:checked ~ label{
    color: $primary !important;
}