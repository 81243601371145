.tooltip-fields {
     &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          background-color: #979797;
     }

     &::-webkit-scrollbar {
          width: 6px;
     }

     &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #73818f;
     }
}

.analytics {
     margin-top: -26px;

     .tippy-content {
          max-width: 500px;
          padding: 12px !important;
          text-align: left !important;
          font-size: 13px;
     }

     .tooltip-copy {
          .icon-copy {
               color: $primary;
               background-color: #fff;
               border-radius: 12px;
               padding: 2px 10px;
               font-size: 12px;
               font-weight: 600;
               width: max-content;
               cursor: pointer;
          }
     }

     .analytics-overlay {
          filter: blur(2px);
     }

     .report-table-bottom {
          width: 100%;
     }

     .chartButton {
          width: 60px;
          display: flex;
          justify-content: center;
          border-left: 1px solid #a8aaab;

          .buttonChartIcon {
               padding: 5px 8px;
               outline: none;
               border: none;
               border-radius: 5px;
               background: #f0f3f5;
               border: 1px solid #20a8d8;

               &:hover {
                    background: #20a8d8;

                    &>svg {
                         color: #fff !important;
                    }
               }

               &.active {
                    background: #20a8d8;
               }
          }
     }

     .report-table {
          .k-loading-mask {
               z-index: 3 !important;
          }

          .compare-report-table table thead tr:last-child {
               display: none;
          }

          &.no-action {

               td,
               th {
                    pointer-events: none;
               }
          }

          .ghost-loading {
               z-index: 1;
               position: absolute;
               left: 56%;
               bottom: 13%;
               transform: translate(-50%, -50%);
          }

          .filter-loading {
               width: 200px;
               position: absolute;
               top: 46%;
               left: 50%;
               transform: translate(-50%, -50%);
               z-index: 9;

               .progress {
                    height: 10px;
               }
          }

          .contentTooltipLongLoading {
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
               z-index: 9;

               &.analytics-package {
                    top: 90%;
               }

               .upgrade-content {
                    border-radius: 50px;
                    padding: 6px;
                    width: 192px;
                    height: 52px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);

                    &-icon {
                         width: 44px;
                         height: 44px;
                         border-radius: 50%;
                         background: #ffc107;
                         display: flex;
                         align-items: center;
                         justify-content: center;
                    }

                    &-desc {
                         margin-left: 12px;

                         p {
                              font-size: 12px;
                              font-weight: bold;
                              margin: 0;
                         }

                         .upgrade-link {
                              cursor: pointer;
                              color: #20a7d8bd;
                              text-decoration: underline;
                              font-size: 13px;

                              &:hover {
                                   color: #20a8d8;
                              }
                         }
                    }
               }

               svg {
                    width: 14px;
                    translate: 8px -2px;

                    path {
                         fill: #22a7da !important;
                    }
               }
          }

          .k-grid-content {
               table {
                    td {
                         &.compare-FFB300, &.compare-D1D5DB{
                              &:not(.k-grid-content-sticky) {
                                   left: 0 !important;
                              }
                         }
                    }
               }
          }
     }

     table {
          thead {
               tr:nth-child(2) {
                    position: relative;
                    z-index: 3 !important;
               }

               .k-table-row {
                    th {
                         font-size: 12px;
                    }
               }
          }
     }

     .wrapperChartReport {
          padding: 0 20px;
          position: relative;

          .headChart {
               height: 60px;
               display: flex;
               align-items: center;
               justify-content: space-between;

               .chart-segment-dropdown {
                    position: relative;
                    .chart-segment-action {
                         width: 300px;
                         padding: 0 12px;
                         display: flex;
                         border: 1px solid #b4b4b4;
                         border-radius: 8px;
                         align-items: center;
                         justify-content: space-between;
                         cursor: pointer;

                         .chart-segment-text {
                              display: flex;
                              align-items: center;

                              span.chart-segment-desc {
                                   font-size: 12px;
                                   padding: 6px 0;
                                   color: #18222B;
                              }
                         }
                    }

                    .segment-icon {
                         padding: 1px 5px;
                         border-radius: 15px;
                         border: 1px solid #20a8d8;
                         background: #e9f6fb;

                         span.segment-item-level {
                              padding: 0;
                              font-size: 12px;
                              color: #20a8d8;
                         }
                    }

                    .chart-segment-content{
                         position: absolute;
                         z-index: 999;
                         left: 0;
                         bottom: -7px;
                         transform: translateY(100%);
                         background-color: #fff;
                         border: 1px solid #E2E6E9;
                         border-radius: 6px;
                         width: 400px;
                         box-shadow: 0px 8px 16px 0px #00001526;
                         
                         .chart-segment-item {
                              padding: 8px 10px;
                              border-bottom: 1px solid #E2E6E9;

                              &:hover{
                                   background-color: #f0f3f5;
                              }

                              .custom-control{
                                   width: 100%;
                              }

                              .custom-control-label{
                                   display: flex;
                                   font-size: 14px;
                                   align-items: center;
                                   width: 100%;
                              }
                         }
                          
                    }
               }

               .customMetricChart {
                    display: flex;
                    gap: 15px;
                    align-items: center;

                    span.customMetricText {
                         padding: 6px 10px 6px 20px;
                         border-radius: 20px;
                         border: 1px solid #b4b4b4;
                         position: relative;
                         font-size: 12px;

                         svg {
                              cursor: pointer;
                              margin-left: 5px;

                              path {
                                   fill: #b4b4b4;
                              }
                         }
                    }

                    .iconColorMetric {
                         position: absolute;
                         content: '';
                         width: 8px;
                         height: 8px;
                         top: 50%;
                         left: 5px;
                         transform: translate(0, -50%);
                         border-radius: 50%;
                    }

                    // span::before {
                    //     content: '';
                    //     position: absolute;
                    //     width: 8px;
                    //     height: 8px;
                    //     top: 50%;
                    //     left: 5px;
                    //     transform: translate(0, -50%);
                    //     border-radius: 50%;
                    // }

                    // span:nth-child(1)::before {
                    //     background-color: #67CEE1;
                    // }
                    // span:nth-child(2)::before {
                    //     background-color: #F7D246;
                    // }

                    .wrapperIconPlus {
                         width: 15px;
                         height: 15px;
                         padding: 13px;
                         border-radius: 50%;
                         border: 1px solid #b4b4b4;

                         .iconPlus {
                              content: '';
                              position: absolute;
                              left: 52%;
                              top: 50%;
                              transform: translate(-50%, -50%);
                              color: #b4b4b4;
                         }
                    }
               }

               .actionChart {
                    display: flex;
                    gap: 15px;
                    align-items: center;

                    .dropDownIcon {
                         padding: 6px;
                         border: 1px solid #b4b4b4;
                         border-radius: 10px;
                         color: #5f86e2;
                         cursor: pointer;
                    }

                    .chartDate {
                         display: flex;
                         border: 1px solid #b4b4b4;
                         border-radius: 10px;
                         cursor: pointer;

                         span {
                              padding: 6px 10px;

                              &.active {
                                   background-color: #2d3943;
                                   color: white;
                              }
                         }

                         span:nth-child(1) {
                              border-right: 1px solid #b4b4b4;
                              border-top-left-radius: 10px;
                              border-bottom-left-radius: 10px;

                              &:hover {
                                   background-color: #67727a;
                                   color: white;
                              }
                         }

                         span:nth-child(2) {
                              border-right: 1px solid #b4b4b4;

                              &:hover {
                                   background-color: #67727a;
                                   color: white;
                              }
                         }

                         span:nth-child(3) {
                              border-top-right-radius: 10px;
                              border-bottom-right-radius: 10px;

                              &:hover {
                                   background-color: #67727a;
                                   color: white;
                              }
                         }
                    }
               }
          }

          .containerChart {

               .chart-no-data {
                    min-height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & p {
                         text-align: center;
                         font-weight: bold;
                    }
               }

               .chart-segment {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;

                    &-item {
                         width: 100%;

                         &-4 {
                              width: calc((100% / 2) - 10px);
                         }
                    }
               }
          }

          .compareChart {
               p {
                    font-size: 14px;

                    span {
                         padding: 2px 10px 2px 20px;
                         position: relative;
                         font-weight: 400;

                         .iconColorMetric {
                              position: absolute;
                              content: '';
                              width: 8px;
                              height: 8px;
                              top: 50%;
                              left: 5px;
                              transform: translate(0, -50%);
                              border-radius: 50%;
                         }
                    }
               }
          }
     }

     .report-header {
          padding: 0 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);

          .report-title {
               font-weight: 700;
               font-size: 16px;
               line-height: 19px;
               margin-bottom: 0;
               min-width: max-content;
               padding-right: 10px;
          }

          .header-item {
               padding: 0 15px;
               border-left: 1px solid rgba(0, 0, 0, 0.08);
          }
     }

     .disabled {
          opacity: 0.6;
          pointer-events: none;
          &.unset-pointer-events{
               pointer-events: unset;
          }
     }

     .scope-dropdown {
          display: flex;
          align-items: center;
          min-height: 47px;
     }

     table {
          &.scrollbar {
               display: block;
               overflow-x: auto;
               white-space: nowrap;
          }

          &.conversionDetails {
               tbody {
                    tr {
                         cursor: pointer;
                    }
               }
          }

          thead {
               tr {
                    text-align: center;
                    border: 1px solid #e4e7ea;

                    &:first-child {
                         background-color: #8d9ba8;
                         color: #fff;
                    }

                    &:nth-child(2) {
                         th {
                              background-color: #d2dbe0;
                         }
                    }
               }
          }

          th {
               border-right: 1px solid #e4e7ea;
          }

          td {
               border: 1px solid #e4e7ea;
               width: 100%;
               max-width: 530px;
               overflow-x: auto;

               &.compare-FFB300, &.compare-D1D5DB{
                    position: relative;
                    text-align: center;
                    padding-left: 17px;
                    z-index: 1;

                    &.k-grid-content-sticky, &.k-grid-footer-sticky{
                         position: sticky;
                         z-index: 2;
                    }
               }

               &.compare-FFB300::after{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    left: 12px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #FFB300;
                    z-index: -1;
               }

               &.compare-D1D5DB::after{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    left: 12px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #D1D5DB;
               }

               &::-webkit-scrollbar {
                    height: 4px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #73818f;
                    border-radius: 3px;
                    border: 1px solid #e2e6e9;
               }

               &::-webkit-scrollbar-track {
                    height: 4px;
                    background-color: #e2e6e9;
               }

               .tooltip-wrapper {
                    overflow: hidden;

                    .tooltip-copy {
                         max-width: 600px;
                         word-wrap: break-word;
                         white-space: pre-wrap;

                         .icon-copy {
                              color: $primary;
                              background-color: #fff;
                              border-radius: 12px;
                              padding: 2px 10px;
                              font-size: 12px;
                              font-weight: 600;
                              width: max-content;
                              cursor: pointer;
                         }
                    }
               }
          }

          .clickable,
          .view-detail {
               text-decoration: underline;
               cursor: pointer;

               &:hover {
                    text-decoration: none;
               }

               &.list-tags {
                    text-decoration: none !important;
                    overflow-x: auto;

                    &::-webkit-scrollbar {
                         display: none;
                    }

                    .no-click {
                         cursor: default !important;
                    }
               }

               .tags-wrapper {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;

                    .tag {
                         cursor: pointer;
                         border-radius: 4px;
                         border: 1px solid rgb(195, 218, 254);
                         background-color: rgb(235, 244, 255);
                         padding: 2px 4px;
                         line-height: 15px;
                         --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
                         box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

                         +.tag {
                              margin-left: 4px;
                         }

                         &:not(.no-click):hover {
                              color: #fff;
                              border: 1px solid rgb(67, 65, 144);
                              background-color: rgb(67, 65, 144);
                         }
                    }
               }
          }
     }

     .per-page-select {
          margin-top: 10px;

          select {
               &.form-control {
                    background-position-x: calc(100% - 10px);
                    padding-right: 22px;
               }
          }
     }

     .date-range-wrapper {
          position: relative;

          .custom-date-wrapper {
               position: absolute;
               left: -130px;
               z-index: 101;
               padding: 20px 15px;
          }

          .calendar-wrapper {
               margin-bottom: 10px;
          }

          .presets {
               margin-bottom: 10px;

               button {
                    font-size: 12px;
                    text-transform: none;
                    background: none;
                    color: #000;

                    &:hover,
                    &.active {
                         color: #20a8d8;
                    }
               }
          }

          .bottom-buttons {
               display: flex;
               justify-content: flex-end;
          }

          .date-range {
               &.btn {
                    width: auto;
                    background-color: #f0f3f5;
                    text-align: center;
                    padding: 4px 20px;
                    margin-bottom: 10px;
                    border: 2px solid #e4e7e9 !important;
               }
          }
     }

     .menu-report {
          background-color: #f0f3f5;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;

          .search-field {
               position: relative;

               input {
                    padding-right: 30px;
                    border-radius: 12px;
               }

               svg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
               }
          }

          &-list {
               label {
                    margin-bottom: 0;
                    margin-left: 5px;
                    font-weight: 500;
                    cursor: pointer;
               }

               svg {
                    margin: 0;
               }
          }

          .nav-tabs {
               border: none;

               .nav-link {
                    padding: 14.5px 16px;
                    border: none;
                    text-decoration: none;
                    border-radius: 0%;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0s ease-in-out;

                    &:hover {
                         >svg {
                              color: #ff7e07 !important;

                              path {
                                   fill: #ff7e07;
                              }
                         }

                         >label {
                              color: #ff7e07 !important;
                         }
                    }

                    &.active {
                         border: none;
                         border-top: 3px solid #20a8d8;
                         border-right: 1px solid lightgray;
                         border-left: 1px solid lightgrey;
                         background: #fff;

                         >svg {
                              color: #20a8d8 !important;

                              path {
                                   fill: #20a8d8;
                              }
                         }

                         >label {
                              color: #20a8d8 !important;
                         }
                    }
               }
          }
     }

     .tab-content {
          background: #fff;
          padding: 0 20px;

          .report-select {
               padding: 10px 12px;
               max-width: 768px;

               &-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    button {
                         cursor: pointer;
                    }

                    p {
                         margin-bottom: 0;
                         font-size: 14px;
                    }

                    label {
                         font-size: 13px;
                         margin-bottom: 0;
                         text-decoration: underline;
                         text-transform: lowercase;
                         color: #000 !important;
                         font-weight: 400;
                         cursor: pointer;
                    }

                    svg {
                         margin: 0 0 0 5px;
                         cursor: pointer;
                    }
               }

               .css-g1d714-ValueContainer,
               .css-1hwfws3,
               .react-select__value-container--is-multi {
                    padding: 2px 12px;
               }

               .react-select__menu {
                    z-index: 10;
               }

               .css-f0arjc-control {
                    box-shadow: none;
                    border: none;
                    min-height: 33px;

                    .css-109onse-indicatorSeparator {
                         display: none;
                    }
               }

               .css-14jk2my-container {
                    border: 0px solid #e4e7e9 !important;
                    box-shadow: none !important;
                    border-radius: 12px;
                    min-height: 37px;
               }
          }
     }

     .filter-report {
          justify-content: flex-end;
          padding: 10px 10px;

          &.export-report {
               .filter-button {
                    text-transform: none;
               }
          }

          .react-select__menu {
               z-index: 10 !important;
          }

          button {
               cursor: pointer;
          }

          .filter-button {
               border-radius: 3.25rem;
               border: 2px solid #e4e7e9 !important;
               background-color: #f0f3f5;

               &:hover {
                    background-color: #20a8d8;

                    label {
                         color: #ffffff !important;
                    }
               }

               label {
                    text-decoration: none;
               }

               &.is-direct {
                    background-color: #20a8d8;
                    color: #ffffff !important;
               }

          }

          label {
               font-size: 13px;
               margin-bottom: 0;
               text-decoration: underline;
               text-transform: lowercase;
               color: #000 !important;
               font-weight: 400;
               cursor: pointer;
          }

          svg {
               margin: 0 0 0 5px;
               cursor: pointer;
          }

          select {
               background-color: #fff;
               margin-left: 5px;

               &.form-control {
                    max-width: 150px;

                    &:disabled {
                         background-color: #c8ced3;
                    }
               }
          }

          .select-filter {
               margin-left: 5px;
               min-width: 200px;

               .form-control {
                    padding-right: 25px;
                    color: #333333;
               }

               &.is-invalid {
                    .form-control {
                         border: 1px solid #f86c6b !important;
                    }
               }

               &:disabled {
                    background-color: #c8ced3;
               }

               .css-2b097c-container,
               .css-b62m3t-container {
                    min-height: 35px;
                    overflow: hidden;
               }

               .css-g1d714-ValueContainer {
                    padding-right: 25px;
               }

               .css-1uccc91-singleValue {
                    padding-right: 25px;
               }

               .css-b8ldur-Input {
                    margin-left: 3px;
               }

               .select-wrapper {
                    input+div {
                         z-index: 10 !important;
                    }
               }
          }

          .select-filter-operator {
               margin-left: 5px;
               min-width: 200px;

               &:disabled {
                    background-color: #c8ced3;
               }

               .css-2b097c-container {
                    min-height: 35px;
               }

               .css-g1d714-ValueContainer {
                    padding-right: 25px;
               }

               .css-1uccc91-singleValue {
                    padding-right: 25px;
               }

               .css-b8ldur-Input {
                    margin-left: 3px;
               }
          }

          .field-value-search {
               margin-left: 5px;

               input {
                    &.form-control {
                         max-width: 350px;
                         min-width: 220px;
                    }
               }

               .datepicker-wrapper {
                    .date-range-overlay {
                         position: fixed;
                         top: 0;
                         left: 0;
                         width: 100%;
                         height: 100%;
                    }

                    .calendar-wrapper {
                         position: absolute;
                         background-color: #fff;
                         width: 350px;
                         z-index: 10;
                         top: 40px;
                         right: 0;

                         .react-calender {
                              width: 350px;
                              max-width: 100%;
                              background: #fff;
                              border: 1px solid #a0a096;
                              font-family: Arial, Helvetica, sans-serif;
                              line-height: 1.125em;
                         }
                    }
               }

               .select-field-custom {
                    max-width: 500px;
                    min-width: 250px;

                    div[class*='-ValueContainer'] {
                         margin-right: 20px !important;
                    }

                    svg {
                         margin: 0;
                    }
               }
          }

          .field-parameter-button {
               padding-top: 0;
               padding-bottom: 0;
               max-height: 35px;
          }
     }
}

.custom-report {
     .menu-final {
          &-group {
               display: flex;
               align-items: center;
               flex-wrap: wrap;
          }
     }

     .date-range-overlay {
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
     }

     .custom-date-wrapper {
          position: absolute;
          top: 100%;
          z-index: 999;
          right: 0;
     }

     .select-metric {
          max-width: 400px;
     }

     .custom-table td {
          width: auto !important;
          min-width: 300px;
     }
}

.analytics-requiring {
     display: flex;
     align-items: center;
     justify-content: center;

     &.analytics-requiring {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%);
          z-index: 2;
     }

     .upgrade-content {
          border-radius: 50px;
          padding: 6px;
          width: 192px;
          height: 52px;
          background: #fff;
          display: flex;
          align-items: center;
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);

          &-icon {
               width: 44px;
               height: 44px;
               border-radius: 50%;
               background: #ffc107;
               display: flex;
               align-items: center;
               justify-content: center;
          }

          &-desc {
               margin-left: 12px;

               p {
                    font-size: 12px;
                    font-weight: bold;
                    margin: 0;
               }

               .upgrade-link {
                    cursor: pointer;
                    color: #20a7d8bd;
                    text-decoration: underline;
                    font-size: 13px;

                    &:hover {
                         color: #20a8d8;
                    }
               }
          }
     }
}

.report {
     &-tabs {
          background: #fafafa;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #e6e6e6;

          &-list {
               border: 0;
               align-items: center;
          }

          &-item {
               .nav-link {
                    padding: 10px 16px;
                    color: black;
                    font-weight: 500;
                    cursor: pointer;
                    border: 0;
                    border-radius: 0;

                    &.active {
                         border-top: 2px solid #20a8d8;
                         border-right: 1px solid #e6e6e6;
                         border-left: 1px solid #e6e6e6;
                         color: #20a8d8;
                         background-color: #ffffff;

                         label {
                              color: #20a8d8 !important;
                         }

                         &:hover {
                              color: #20a8d8;

                              label {
                                   color: #20a8d8 !important;
                              }
                         }
                    }
               }

               label {
                    margin-bottom: 0;
                    cursor: pointer;
               }
          }

          &-content {
               background-color: #ffffff !important;
          }

          &-btn {
               border-radius: 5px;
               margin-left: 20px;
               position: relative;

               &::before {
                    content: '';
                    width: 1px;
                    height: 60%;
                    position: absolute;
                    top: 50%;
                    right: calc(100% + 20px);
                    background-color: #e6e6e6;
                    transform: translateY(-50%);
               }

               &-bg {
                    background-color: #20a8d8;
                    color: #ffffff;
               }
          }
     }

     &-table {
          position: relative;

          .k-sort-icon {
               color: #000;
          }

          .table-head {
               .lock-column {
                    &.show {
                         opacity: 0.5 !important;
                         visibility: visible !important;
                    }

                    &.active {
                         opacity: 1 !important;
                         visibility: visible !important;
                    }
               }

               .icon-sort {
                    margin-left: 2px;
                    transform: rotate(180deg);
                    height: 12px;

                    &.asc,
                    &.desc {
                         path {
                              fill: #000000;
                         }
                    }

                    &.desc {
                         transform: rotate(0);
                    }
               }

               &:hover {
                    &>.k-link>.lock-column {
                         opacity: 0.5;
                         visibility: visible;
                    }
               }
          }

          .k-table-th.k-header {
               font-weight: 500;

               .k-cell-inner {
                    justify-content: center;
               }
          }

          .k-grid-norecords {
               text-align: left;
               &-template {
                    border: unset;
                    height: 2.8em;
                    line-height: 2.8em;
                    margin-left: 15px;
               }
          }

          .k-table {
               width: 100% !important;

               .k-table-tfoot {
                    td {
                         font-size: 14px;
                    }
               }

               td {
                    white-space: pre !important;
                    overflow: hidden;
                    border-bottom: 0;
                    border-left: 0;
                    font-size: 13px;
               }
          }

          .k-table-tfoot {
               tr {
                    background: #2d3a43;

                    td {
                         color: #ffffff;
                         border-color: #e4e7ea;
                         border-left: 1px solid;

                         &:first-child {
                              text-align: right;
                         }
                    }
               }
          }

          .k-grid-footer .k-grid-footer-sticky {
               background-color: #2d3a43;
          }

          .k-grid-header .k-table-th.k-grid-header-sticky {
               background-color: #d2dbe0;
          }

          &-bottom {
               padding: 20px;
               align-items: center;
               justify-content: space-between;

               .toggle-bottom {
                    display: flex;
                    align-items: center;
               }

               ul.report-table-page {
                    margin-bottom: 0;
               }
          }

          &.table-resizing {
               .k-grid-content {
                    overflow: hidden;
               }
          }
     }
}

.lock-column {
     width: 16px;
     height: 16px;
     margin-left: 5px;
     padding: 10px;
     align-self: flex-start;
     position: relative;
     opacity: 0;
     visibility: hidden;

     .icon-lock {
          position: absolute;
          left: 5px;
          top: 3px;
     }
}

.search-group {
     input {
          border-right: 0 !important;
     }
}

.btn-search {
     border: 1px solid #e4e7ea !important;
     border-left: 0 !important;
     border-radius: 12px;
     border-top-left-radius: 0;
     border-bottom-left-radius: 0;
     box-sizing: border-box;
     max-height: 35px;
     background-color: #ffffff;
}

.list-custom-reports {
     .accordion-item {
          overflow: inherit;
     }
}

.is-invalid {
     .form-control {
          border: 1px solid #f86c6b !important;
     }
}

.index-99 {
     z-index: 99;
}

.block-tooltip {
     &.m-width-240 {
          min-width: 240px;
     }

     &.top-18 {
          top: 18px;
     }

     &.index-1001 {
          z-index: 1001 !important;
     }
}

.saved-viewed {
     &-dropdown-item {
          &:active {
               color: #000000;
               background-color: #ffffff;
          }
     }
}