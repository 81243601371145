.btn-tour {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	border-radius: 17.5px;
	background: #f8f9fa;
	min-width: 87px;
	padding-left: 15px;
	position: relative;

	color: #20a8d8;
	font-size: 13px;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;
	letter-spacing: 0.18571427px;

	&:hover {
		color: #63c2de;
		background-color: #18222b;
	}

	svg {
		color: #ffc107;
	}

	&.box-minimize {
		background: #18222b;
		color: #fff;
		padding: 10px 2rem 9px 1.2rem;

		.pulse-status.online {
			animation: none;
		}

		.pulse-status {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 12px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: rgb(99, 99, 99);

			&.online {
				background-color: #4dbd74;
				box-shadow: 0 0 0 rgba(153, 241, 145, 0.4);
				animation: pulse-status 1s infinite;
			}
		}

		@-webkit-keyframes pulse-status {
			0% {
				-webkit-box-shadow: 0 0 0 0 rgba(153, 241, 145, 0.4);
			}

			70% {
				-webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			}

			100% {
				-webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			}
		}

		@keyframes pulse-status {
			0% {
				-moz-box-shadow: 0 0 0 0 rgba(153, 241, 145, 0.4);
				box-shadow: 0 0 0 0 rgba(153, 241, 145, 0.4);
			}

			70% {
				-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
				box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			}

			100% {
				-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
				box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			}
		}
	}
}

.screen-guide {
	min-height: 100vh;
	padding: 0;
	overflow: hidden !important;
	z-index: 1050;
	position: absolute;

	>.modal {
		border-radius: 0;
		background-color: #ffffff;
		top: -50%;
		left: -15%;
		z-index: 9;
		transform: scale(1) translateX(15%) translateY(50%) translateY(-0.9px);
		transition: all 0.75s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.15s linear;
	}

	&.minimize {
		>.modal {
			visibility: hidden;
			transform: scale(0) translate(15%, 50%);
			transition: all 0.5s ease;
		}
	}

	.modal-dialog {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		max-width: 100%;
		margin: 0;
	}

	&.roadmap-guide {
		.modal {
			z-index: 10;
		}
	}
}

// .video-setupGuide {
// 	.fade.modal {
// 		background-color: rgba(0, 0, 0, 0.5)
// 	}
// }

.tour-overlay {
	position: fixed;
	z-index: 1047;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.9;
	background-color: #121920;

	&.step-1 {
		z-index: 1045;
	}
}

.tour {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	z-index: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;

	&.show {
		visibility: visible;
		opacity: 1;
		z-index: 1055;
	}
}

.setup-guide {
	display: flex;
	flex-direction: column;
	height: 100%;

	.pricing-header.guide {
		min-height: 170px;
	}

	.guide-body {
		width: 100%;

		.tableLoading {
			width: 100%;
		}

		.tableLoading__animated {
			border-radius: 15px;
		}

		.tableLoading__item {
			background: #F0F3F5;
		}

		.tableLoading .row1 {
			height: 70px;
			border-radius: 15px;
		}

		.tableLoading .row2 {
			height: 10px;
			background-color: #F0F3F5;
		}
	}

	&.scroll {

		// overflow-y: scroll;
		.guide-body {
			overflow-x: hidden;
			padding-right: 10px;
			height: 500px;
			max-width: 780px;

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				border-radius: 10px;
				background-color: #F5F5F5;
			}

			&::-webkit-scrollbar {
				width: 8px;
				background-color: #F5F5F5;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
			}
		}
	}

	&-header {
		padding: 0 60px;

		button {
			text-transform: uppercase !important;
			font-size: 13px;
			font-weight: 400;
		}

		.btn-back,
		i {
			color: #000 !important;
			top: unset !important;
			left: unset !important;
		}

		.minimize {
			border: 1px solid #7F8C9A !important;
			border-radius: 22px;
			padding: 7px 15px;
			color: #7F8C9A;
			width: auto;
		}

		.guide {
			margin-left: 60px;
		}
	}

	&-footer {
		flex: 1;
		font-size: 14px;
		color: #7F8C9A;
		background: $gray-200;

		.c-switch-input:checked~.c-switch-slider::before {
			transform: translateX(12px);
		}

		.c-switch-slider,
		.c-switch {
			&::before {
				top: 1px;
				left: 1px;
				width: 18px;
				height: 18px;
			}
		}
	}
}

.invite-roadmap {
	.modal-dialog {
		max-width: 582px !important;
		margin: 0 auto;
		right: 0;
	}
}

.tour.step-29,
.tour.step-30,
.tour.step-31,
.tour.step-32 {
	&.show {
		z-index: 4100;
	}

	&::after {
		content: '';
		right: 0;
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		width: 70%;
		height: 100%;
	}
}

.tour.step-26,
.tour.step-27,
.tour.step-28 {
	&.show {
		z-index: 4022;
	}
}

.tour.step-29,
.tour.step-31,
.tour.step-32 {
	&::after {
		background: unset;
	}
}

.tour.step-34 {
	.choose-destination-wizard {
		transform: unset;

		.buttons {
			display: none;
		}
	}

}

.tour-guide {
	position: absolute;
	z-index: 1;
	display: flex;
	align-items: center;
	gap: 30px;
	opacity: 0;

	color: #ffffff;
	font-family: "Poppins";
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5em;

	width: 100%;
	max-width: 770px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&.setup-guide {
		color: #2F353A;
		font-family: 'Roboto';
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		position: relative;
		margin-left: 20px;
		top: unset;
		left: unset;
		transform: translateY(120px);
		opacity: 1;
		transition: .5s all linear;
		transition-delay: .5s;

		&:first-child {
			transform: unset !important;
		}

		&.show {
			transform: translateY(0);
			opacity: 1;
			height: auto;
		}

		.content {
			.group-loading-dots {
				position: relative;
				.dots-animation {
					position: absolute;
					left: 0;
					top: 20px;
					z-index: 1;
					+ .animate__animated {
						background-color: #f0f3f5;
						position: relative;
						z-index: 2;
					}
				}
			}

			.message-box {
				position: relative;
				min-height: 57px;
				&:first-child {
					.message {
						position: relative;
						border-top-left-radius: 0;

						&::before {
							content: '';
							position: absolute;
							top: 0;
							left: -6px;
							border-radius: 24px 0 0px 200px;
							-moz-border-radius: 24px 0px 0px 200px;
							-webkit-border-radius: 24px 0px 0px 200px;
							background-color: #e3e3e3;
							width: 20px;
							height: 12px;
						}
					}
				}
				.dots-animation  {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					&.animate__faster-2s {
						animation-duration: 2.5s !important;
				   }
				   
				   &.animate__faster-3s {
						animation-duration: 3.5s !important;
				   }
				   
				   &.animate__faster-4s {
						animation-duration: 4.5s !important;
				   }
				}
			}

			.message {
				position: relative;
				border: 1px solid $light;
				background-color: #e3e3e3;
				border-radius: 10px;
				padding: 12px 15px;
				margin-bottom: 10px;
				text-align: left;
				width: fit-content;
			}

			.feature {
				&-block {
					border: 1px solid #d2d2d2;
					border-radius: 15px;
					padding: 12px 16px;
					margin-bottom: 12px;

					&.success {
						background-color: #C9EBD5;
						border-color: #C9EBD5;
						cursor: pointer;
					}

					&.wait {
						color: #d2d2d2;

						.tooltip-wrapper {
							position: relative;
						}
					}

					.hover-show-tooltip:hover~.block-tooltip {
						top: 0px;
						left: calc(100% - 400px);
					}
				}

				&-title {
					font-family: 'Roboto';
					font-size: 15px;
					font-weight: 500;

					p {
						color: #18222B;
						margin-bottom: 0;
					}

					&-icon {
						margin-right: 5px;
					}

					.tooltip-wrapper {
						position: relative;

						.icon-custom-size {
							margin: 0 0 0 10px;
							width: 14px;
							height: 14px !important;
						}

						.hover-show-tooltip:hover~.block-tooltip {
							top: 8px;
							left: 40px;
						}

						.block-tooltip {
							.block-tooltip-text {
								padding: 10px 5px;
								;

								p {
									color: #fff !important;
								}
							}
						}
					}
				}

				&-btn {
					padding: 0;
					font-size: 12px;
					background-color: $primary;
					width: 100px;
					height: 25px;
					line-height: 0;
					margin-left: 12px;

					&:active {
						color: #ffffff;
					}

					&-icon {
						margin-right: 5px;
					}

					&.wait {
						background-color: #d8d8d8 !important;
						border-color: #d8d8d8 !important;
					}

					&-success {
						padding: 1px 0;
						color: #73818F;
						text-transform: inherit;
						font-weight: 400;
						cursor: pointer;

						svg.icon.icon-custom-size.roadmap {
							margin: 0;
							width: 23px;
						}
					}

				}

				&-time {
					font-size: 14px;
					margin-left: 10px;
				}

				&-icon {
					&-success {
						margin-left: 10px;
					}
				}
			}

			.animate__animated {
			     -webkit-animation-fill-mode: backwards;
			     animation-fill-mode: backwards;
			}

		}

		.invite-member {
			text-transform: inherit;
			color: $primary;
			text-decoration: underline;
			font-size: 16px;
			padding-right: 0;
			font-weight: 400;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			svg {
				margin: 0 6px 0 0 !important;
				width: 18px !important;
			}
		}
	}

	&.show {
		opacity: 1;
	}

	p {
		font-size: 16px;
		margin: 0;
		color: #fff;

		&:not(:last-child) {
			margin-bottom: 0.45em;
		}
	}

	.buttons {
		display: flex;
		flex-wrap: wrap;
		gap: 33px;
	}

	.btn {
		color: #ffffff;
		font-family: "Roboto";
		font-size: 13px;
		font-weight: 700;
		line-height: 16px;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.18571427px;
		text-decoration: none;

		&.minimize {
			color: #2F353A;
			border: 1px solid #7F8C9A !important;
		}
	}

	.back-button {
		opacity: 0.75;
		display: inline-flex;
		align-items: center;
		gap: 10px;
		padding: 0;

		svg {
			width: auto;
			height: 10px;
		}
	}

	.btn.next-button {
		padding: 14.5px 20px;
		min-width: 140px;
		border-radius: 22.5px;
		background: #20a8d8;
		color: #ffffff;

		&.roadmap {
			max-height: 35px;
			padding: 10px 20px;
		}
	}

	.skip-button {
		color: #ffffff;
		opacity: 0.5;
		font-weight: 500;
		padding: 0;
	}

	.tour-guide-image img {
		max-width: 100%;
	}

	.tour-guide-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		// transform: translate(167px, -15px);

		p {
			// font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
			color: inherit;
			font-family: inherit;
		}

		.notice-text {
			width: 90%;
			font-weight: 400;
			color: #ffc107;
		}

		.sub-text {
			font-size: 16px;
			line-height: 1.45em;
			margin-top: 15px;
		}

		.back-button {
			margin-bottom: 22px;
		}

		.buttons {
			margin-top: 24px;
		}
	}

	&.layout-top {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}


	&.event-reporting {
		max-width: 750px;
		top: auto;
		bottom: 450px;

		.tour-guide-image {
			transform: translateY(60px);
		}
	}

	&.script-not-running {
		max-width: 717px;

		.tour-guide-image {
			transform: translateY(17px);
		}
	}

	&.add-script-manual,
	&.access-settings {
		align-items: flex-start;
		transform: none;
		max-width: 718px;
		top: calc(var(--fixTop) + 120px);
		left: auto;
		right: 720px;
		gap: 35px;

		.tour-guide-image {
			transform: translateY(-23px);
		}
	}

	&.listener-accounts {
		align-items: flex-start;
		transform: none;
		max-width: 746px;
		gap: 45px;
		// top: calc(var(--fixTop) + var(--accountFixTop) + 70px);
		top: calc(var(--fixTop) + var(--accountFixTop));
		left: auto;
		right: 270px;

		.tour-guide-image {
			transform: translateY(7px);
		}

		.tour-guide-content {
			transform: translateY(calc(-1 * var(--fixTop)));
		}
	}

	&.personal-login {
		transform: none;
		max-width: 800px;
		top: calc(var(--fixTop) + 80px);
		left: auto;
		right: 290px;
	}

	&.publish {
		transform: none;
		max-width: 780px;
		gap: 70px;
		top: calc(var(--fixTop) + 45px);
		left: 325px;

		// .tour-guide-image {
		// 	transform: translate(233px,-26px);
		// }
	}

	&[class*=menu-] {
		transform: none;
		gap: 48px;
		top: 90px;
		left: 510px;
	}

	&.main-menu-dashboard {
		max-width: 775px;
		top: 132px;
		left: 270px;
	}

	&.main-menu-data {
		max-width: 780px;
		top: 175px;
		left: 503px;

		.tour-guide-image {
			transform: translateY(-4px);
		}
	}

	&.menu-consent {
		align-items: flex-start;
		top: 10px;
		// left: 405px;
	}

	// &.menu-listeners {
	// 	left: 420px;
	// }

	// &.menu-custom-data {
	// 	top: 120px;
	// 	left: 420px;
	// }

	&.menu-trigger-and-conversions {
		top: calc(var(--fixTop) + 3%);
		// left: 420px;
	}

	&.menu-destinations {
		top: calc(var(--fixTop) + 14%);
	}

	// &.menu-google-tag-manager {
	// 	top: calc(var(--fixTop) + 18%);
	// 	// left: 420px;
	// }

	&.menu-notifications {
		top: calc(var(--fixTop) + 2%);
	}

	&.popup-destination-wizard {
		align-items: flex-end;
		left: 5%;
		top: 450px;

		.content {
			max-width: 430px;
		}
	}

	&.menu-add-destination-wizard {
		left: -5%;
		top: 200px;

		.tour-guide-image {
			transform: translate(68px , -7px);
		}

		.content {
			max-width: 500px;
		}
	}

	&.center-popup-destination-wizard {
		transform: none;
		grid-gap: 48px;
		gap: 48px;
		left: -5%;
		top: calc(var(--fixTop) + 15%);

		.content {
			max-width: 500px;
		}
	}

	&.create-destination-wizard {
		left: 72%;
	}

	&.preview-configure-conversion {
		top: 115px;
		left: 1100px;
	}

	&.preview-trigger  {
		top: 120px;
		left: 1100px;
	}

	&.preview-trigger-second {
		top: 130px;
		left: 1100px;
		.tour-guide-content {
			.buttons {
				transform: translate(400px, -35px);
			}
		}
	}

	// &.active-gtm-destination-wizard {
	// 	// top: calc(var(--fixTop) + 23%);
	// 	left: 41%;
	// }

	// &.accordion-gtm-destination-wizard {
	// 	top: calc(var(--fixTop) + 27%);
	// }

	&.choose-destination-wizard {
		top: calc(var(--fixTop) + 11%);
		left: 37%;

		.back-button {
			z-index: 1051;
		}
	}

	&.end-destination-wizard {
		top: calc(var(--fixTop) + 18%);
		left: 43%;
	}

	// &.main-menu-analytics {
	// 	left: 430px;
	// }

	&.menu-listener-custom-forms {
		// display: flex;
		flex-direction: column-reverse;
		top: 130px;
		left: 500px;

		.tour-guide-content {
			transform: translate(40px, -70px);

			.content {
				max-width: 450px;

				p {
					color: #fff;
				}
			}
		}

		.tour-guide-image {
			transform: translate(-80%, 10%);
		}

		&.category {
			.tour-guide-content {
				transform: translate(100px, -20px);
	
				.content {
					max-width: 450px;
	
					p {
						color: #fff;
					}
				}
			}
		}
	}

	&.configure-conversion {
		// display: flex;
		flex-direction: column-reverse;
		top: 370px;
		left: 800px;

		.tour-guide-content {
			transform: translate(40px, -70px);

			.content {
				max-width: 600px;

				p {
					color: #fff;
				}
			}
		}

		.tour-guide-image {
			transform: translate(-80%, 10%);
		}
	}

	&.menu-listener-custom-forms.get-started {
		.tour-guide-content {
			.content {
				p {
					span {
						display: none;
					}
				}

				.no-listener {
					display: none;
				}
			}
		}
	}

	&.menu-listener-custom-forms.no-listener {
		.tour-guide-content {
			.content {
				.active-listener {
					display: none;
				}
			}
		}
	}

	&.road-map-form-category {
		top: 450px;
		left: 66vw;
		max-width: 710px;

		.content {
			max-width: 430px;

			p {
				color: #fff;
			}
		}

		.skip-button {
			text-decoration: underline;
		}
	}

	&.requiring-action-form-category {
		top: 120px;

		.content {
			max-width: 430px;

			p {
				color: #fff;
			}
		}

		.tour-guide-content {
			transform: translate(0, -15px);
		}
	}

	&.edit-forms-categories {
		top: calc(var(--fixTop) + 190px);
		left: 54%;

		.content {
			max-width: 430px;

			p {
				color: #fff;
			}
		}

		.buttons {
			margin-bottom: 20px;
		}
	}

	&.forms-inventory {
		top: calc(var(--fixTop) + 252px);
		left: 54%;

		.tour-guide-content {
			padding-bottom: 50px;
		}

		.content {
			max-width: 430px;

			p {
				color: #fff;
			}
		}
		.buttons {
			margin-bottom: 30px;
		}
	}

	&.forms-rule {
		top: calc(var(--fixTop) + 33%);
		left: 54%;

		.tour-guide-content {
			padding-bottom: 75px;
		}

		.content {
			max-width: 430px;

			p {
				color: #fff;
			}
		}
	}

	&.notifications {
		top: calc(var(--fixTop) + 30%);
		left: 72%;
		display: flex;
		flex-direction: column-reverse;

		.content {
			max-width: 430px;

			p {
				color: #fff;
			}
		}

		.skip-button {
			text-decoration: underline;
		}
	}

	&.road-map-custom-forms {
		top: 400px;
		left: 66vw;
		max-width: 710px;

		.content {
			max-width: 430px;

			p {
				color: #fff;
			}
		}

		.skip-button {
			text-decoration: underline;
		}
	}

	&.content-gtm-destination-wizard {
		.skip-button {
			text-decoration: underline;
		}
	}

	&.target-custom-forms {
		top: 120px;
		left: 54%;

		.content {
			max-width: 500px;

			p {
				color: #fff;
			}
		}
		.buttons {
			margin-bottom: 50px;
		}
	}

	&.iframe-custom-forms {
		top: calc(var(--fixTop) + 21%);
		left: 54%;

		.content {
			max-width: 550px;

			p {
				color: #fff;
			}
		}
		.buttons {
			margin-bottom: 30px;
		}
	}

	&.tour-over {
		transform: none;
		align-items: flex-start;
		gap: 15px;
		top: calc(var(--fixTop) + 62px);
		left: 600px;
		max-width: 710px;
	}
}

.tour-element {
	&.highlight {
		z-index: 1050 !important;
	}

	&.header-fixed {
		margin-left: 4px;
	}

	.tour-active+.dropdown-item {
		background-color: transparent;
		color: $primary;

		.settingtille {
			color: #20a8d8 !important;
			font-weight: 400;
		}

		.settingdescription {
			color: #63c2de !important;
			font-weight: 500;
		}

		.logo-right {
			opacity: 1;
			visibility: visible;
		}
	}

	&.highlight-child {
		z-index: 1050 !important;

		&:before {
			content: '';
			display: block;
			position: absolute;
			z-index: 3;
			top: 0;
			left: 0;
			right: 0;
			bottom: -1px;
			opacity: 0.75;
			background-color: #121920;
		}

		.highlight-item {
			z-index: 5;

			&.btn-brand {
				opacity: 1;
				color: #fff;
				background-color: #20a8d8;
			}

			&.switch-account-nav {
				.switch-account-btn {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

				.account-select {
					top: 100% !important;
					transform: none !important;
				}
			}
		}
	}

	&.nav-highlight-child {
		z-index: 1050 !important;

		.sidebar-lv2 .navigation-list>li:not(.highlight-item),
		.sidebar-lv2 .sidebar-items-wrap {
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 3;
				top: 0;
				left: 0;
				right: 0;
				bottom: -1px;
				opacity: 0.75;
				background-color: #121920;
			}
		}

		.sidebar-lv2 .navigation-list {
			z-index: 3;
		}

		.highlight-item {
			z-index: 5;
		}

		.switch-layout {
			z-index: 1;
		}
	}

	&.dashboard-event.highlight {
		position: relative;
		z-index: 1060 !important;

		&:before {
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.tour-guide.event-reporting {
			position: absolute;
			top: -28px;
			bottom: auto;
			left: 50%;
			transform: translate(-50%, -100%);
		}
	}
}

.sidebar-v2 {
	&.tour-element {
		.sidebar-top .sidebar-brand .sidebar-brand-full {
			color: #fff;
		}

		.sidebar-lv2 {
			.sidebar-nav-link {
				letter-spacing: 0.18571427px;
			}

			.sidebar-nav-dropdown-items .sidebar-nav-link {
				color: $white;
			}
		}

		&.highlight {
			.sidebar-lv1 .minimize-sidebar {
				z-index: 0;
			}

			.sidebar-lv2 .sidebar-nav-link {
				&.active{
					background-image: none;
				}
				&.c-active {
					background-image: none;
				}
				
			}
		}
	}
}

.c-wrapper.v2 {
	&.show-tour {
		.get-help {
			z-index: 1030;
		}
	}
}

.image-preload {
	display: none;
}

@media (max-width: 1910px) {
	.tour-guide {
		&.requiring-action-form-category {
			top: 120px;
			left: 65%;
		}

		&.target-custom-forms {
			top: 140px;
		}
	}
}

@media (max-width: 1825px) {
	.tour-guide {

		&.preview-configure-conversion {
			top: 115px;
			left: 900px;
		}

		&.preview-trigger {
			top: 120px;
			left: 900px;
		}

		&.preview-trigger-second {
			top: 130px;
			left: 900px;
			.tour-guide-content {
				.buttons {
					transform: translate(400px, -35px);
				}
			}
		}

		&.create-destination-wizard {
			top: 500px;
			left: 74% !important;
		}

		&.center-popup-destination-wizard {
			left: -8%;
			.content {
				max-width: 410px;
			}
		}

		&.road-map-form-category {
			top: 450px;
		}

		&.menu-add-destination-wizard {
			left: -8%;
			top: calc(var(--fixTop) + 30%);
	
			.content {
				max-width: 430px;
			}
		}

		&.requiring-action-form-category {
			top: 120px;
			left: 65%;
		}

		&.menu-listener-custom-forms {
			left: 430px;
			.tour-guide-image {
				img {
					max-width: 14vw;
				}
			}
		}

		&.configure-conversion {
			left: 750px;
			.tour-guide-image {
				img {
					max-width: 14vw;
				}
			}
		}
	}
}

@media (max-width: 1695px) {
	.tour-guide {
		&.create-destination-wizard {
			left: 86% !important;
			.tour-guide-content {
				.content {
					max-width: 300px;
				}
			}
		}

		&.menu-listener-custom-forms {
			left: 380px;
		}

		&.configure-conversion {
			left: 750px;
		}

	}
}

@media (max-width: 1580px) {
	.tour-guide {

		&.add-script-manual,
		&.access-settings {
			right: 620px;
		}

		&.listener-accounts {
			right: 225px;
		}

		&.menu-listener-custom-forms {
			left: 310px;
			&.category {
				// .tour-guide-image {
				// 	transform: translate(0px, -10px);
				// }
				left: 310px;
				.tour-guide-content {
					transform: translate(30px, -20px);
				}
			}
		}

		&.configure-conversion {
			left: 700px;
			.tour-guide-content {
				transform: translate(80px, -80px);
			}
		}

		// &.requiring-action-form-category {
		// 	top: calc(var(--fixTop) + 17%);
		// 	left: 61%;
		// }

		&.iframe-custom-forms {
			top: calc(var(--fixTop) + 24%);
			left: 61%;
		}

		&.target-custom-forms {
			top: 140px;
			left: 900px;
		}

		&.edit-forms-categories {
			top: 180px;
			left: 61%;
		}

		&.forms-inventory {
			top: 240px;
			left: 63%;
		}

		&.forms-rule {
			top: 300px;
			left: 62%;
		}

		&.popup-destination-wizard {
			left: 16%;
			max-width: 400px;
		}

		&.menu-add-destination-wizard {
			top: calc(var(--fixTop) + 30%);
			left: -11%;

			.content {
				max-width: 380px;
			}
		}

		&.center-popup-destination-wizard {
			top: calc(var(--fixTop) + 10%);
			left: -12%;
			
			.content {
				max-width: 380px;
			}
		}

		&.create-destination-wizard {
    		top: 480px;
			left: 86%;

			.content {
				max-width: 290px;
			}
		}
	}
}

@media (max-width: 1439px) {
	.tour-guide {
		&.target-custom-forms {
			top: 160px;
			left: 900px;
		}
	}
}

@media (max-width: 1430px) {
	.tour-guide {
		&.target-custom-forms {
			top: 160px;
			left: 900px;
		}

		&.requiring-action-form-category {
			top: 140px;
			left: 65%;
		}

		&.road-map-form-category {
			top: 530px;
			left: 63vw;
		}

		&.road-map-custom-forms {
			top: 450px;
			left: 55vw;
		}

		&.menu-listener-custom-forms {
			flex-direction: row-reverse;
			left: 500px;
			.tour-guide-image {
				transform: translate(-150px, -30px);
				img {
					max-width: 80%;
				}
			}
			&.category {
				left: 500px;
				.tour-guide-image {
					transform: translate(-100px, 20px);
				}
				.tour-guide-content {
					transform: translate(-50px, -20px);
				}
			}
		}

		&.configure-conversion {
			flex-direction: row-reverse;
			left: 900px;
			top: 150px;
			.tour-guide-content {
				transform: translate(-200px, 50px);
				.content {
					max-width: 700px;
				}
			}
		}

		&.add-script-manual,
		&.access-settings {
			flex-direction: column-reverse;
			align-items: end;
			max-width: 580px;
			gap: 0;
			right: 530px;
		}

		&.listener-accounts {
			top: calc(var(--fixTop) + var(--accountFixTop) + 98px);
		}

		&.iframe-custom-forms {
			top: calc(var(--fixTop) + 26%);
			left: 64%;
		}

		&.edit-forms-categories {
			top: 200px;
			left: 64%;
		}

		&.forms-inventory {
			top: 290px;
			left: 65%;
		}

		&.forms-rule {
			top: 320px;
			left: 64%;
		}

		&.popup-destination-wizard {
			top: calc(var(--fixTop) + 51%);
			left: 14%;
	
			.content {
				max-width: 345px;
			}
		}

		&.menu-add-destination-wizard {
			left: -13%;

			.content {
				max-width: 355px;
			}
		}
		
		&.center-popup-destination-wizard {
			top: calc(var(--fixTop) + 10%);
			left: -13%;

			.content {
				max-width: 355px;
			}
		}
		
		&.create-destination-wizard {
			top: 280px;
			left: 62% !important;
			.tour-guide-content{
				margin-bottom: 80px;
				.content {
					max-width: 433px !important;
				}
			}
		}
	}
}

@media (max-width: 1399px) {
	.tour-guide {
		&.tour-over {
			left: 440px;
		}
	}
}

@media (max-width: 1338px) {
	.tour-guide {
		&.configure-conversion {
			.tour-guide-content {
				transform: translate(-200px, 0);
				.content {
					p {
						font-size: 15px;
					}
				}
			}
		}
	}
	.tour-guide:not(.menu-listener-custom-forms):not(.configure-conversion) {

		font-size: 14px;
		max-width: 510px;

		&.setup-guide {
			max-width: unset;
		}

		&.target-custom-forms {
			top: 180px;
			left: 700px;
		}

		&.requiring-action-form-category {
			top: 170px;
			left: 69%;
		}

		&.forms-rule {
			top: 360px;
			left: 69%;
		}

		&.edit-forms-categories {
			top: 240px;
			left: 64%;
		}

		.buttons {
			gap: 15px;
		}

		.next-button {
			padding: 10px 15px;
		}

		.tour-guide-image {
			max-width: 150px;
		}

		&.event-reporting {
			max-width: 550px;
			bottom: 550px;
		}

		&.script-not-running {
			max-width: 540px;
		}

		&.add-script-manual,
		&.access-settings {
			max-width: 500px;

			.tour-guide-content {
				margin-top: -20px;
			}
		}

		&.listener-accounts {
			max-width: 580px;
			top: calc(var(--fixTop) + var(--accountFixTop) + 108px);
			right: 230px;
		}

		&.personal-login {
			max-width: 600px;
		}

		&.publish {
			max-width: 550px;
			gap: 20px;
		}

		&.menu-add-destination-wizard {
			&.show {
				&.layout-top {
					left: 6%;
					max-width: 256px;
				}
			}
		}

		&[class*=menu-]:not(.menu-listener-custom-forms):not(.configure-conversion):not(.menu-add-destination-wizard) {
			left: 460px;
			gap: 20px;
		}

		&.preview-configure-conversion {
			top: 130px;
			left: 700px;
		}	

		&.preview-trigger {
			top: 155px;
			left: 700px;
			.tour-guide-content {
				transform: translate(0, -20px)
			}
		}	

		&.preview-trigger-second {
			top: 160px;
			left: 600px;
			.tour-guide-content {
				.buttons {
					transform: translate(300px, -55px);
				}
			}
		}

		&.main-menu-dashboard {
			left: 220px;
			max-width: 530px;
		}

		&.main-menu-data {
			max-width: 530px;
		}

		&.tour-over {
			max-width: 550px;
		}

		.tour-guide-content .sub-text {
			font-size: 13px;
		}

		&.popup-destination-wizard {
			left: 16%;
			max-width: 256px;
		}

		&.center-popup-destination-wizard {
			left: -4%;
		}
		
		&.create-destination-wizard {
			top: 310px;
		}
	}

	button.btn-datalayer.btn {
		margin-left: 10px;
	}

	.header-nav .notification-bell {
		margin-right: 10px;
	}
}

@media (max-width: 1263px) {
	.tour-guide {

		&.center-popup-destination-wizard {
			left: -8% !important;
			max-width: 260px;
			.tour-guide-content {
				.content {
					max-width: 240px;
				}
			}
		}

		&.menu-listener-custom-forms {
			&.category {
				.tour-guide-image {
					transform: translate(-160px, 54px);
				}
				.tour-guide-content {
					transform: translate(-138px, 20px);
				}
			}
		}
		
		&.add-script-manual,
		&.access-settings {
			max-width: 390px;
			right: 530px;
			flex-direction: column-reverse;
			align-items: end;
		}
		&.create-destination-wizard {
			top: 350px !important;
		}
	}
}

@media (max-width: 1199px) {
	.tour-guide {
		&.target-custom-forms {
			top: 200px;
			left: 700px;
		}

		&.event-reporting {
			bottom: 570px;
		}
	}
}

@media (max-width: 1198px) {
	.tour-guide {
		&.tour-guide.edit-forms-categories {
			left: 69%;
		}
		&.menu-listener-custom-forms {
			left: 360px;
			&.category {
				left: 450px;
			}
		}
	}
}

@media (max-width: 1152px) {
	.tour-guide {
		&.configure-conversion {
			.tour-guide-image {
				transform: translate(-160%, 10%);
			}
		}
	}
}

@media (max-width: 1150px) {
	.tour-guide {
		&.configure-conversion {
			.tour-guide-image {
				transform: translate(-162%, 10%);
			}
		}
	}
}

@media (max-width: 991px) {

	.tour,
	.tour-overlay,
	.btn-tour,
	.tour-guide {
		display: none;
	}

	.tour-element {
		z-index: inherit !important;
	}
}
