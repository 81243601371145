.notification-bell {
     // padding-right: 13px;
     // padding-right: 32px;
     margin-right: 32px;
     padding-left: 2px;
     transform: translateY(-1px);
     position: relative;

     svg {
          width: 17px !important;
          height: 18px !important;
     }

     .badge {
          width: 20px;
          height: 15px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 9px;
          font-weight: 400;
          line-height: 11px;
          border-radius: 7.5px;
          transform: translateY(-9px);
          margin-left: -10px;

          &.big-number {
               max-width: 27px;
               width: auto;
               height: 17px;
               border-radius: 7.5px;
          }
     }

     &:hover {
          color: #212c36 !important;
     }
}

.filter-report-bell {
     .badge {
          transform: translateY(-16px);
     }    
}

.save-report-bell {
     .option-item-delete {
          min-width: 47px;
     }
}

.monitoring-notifications {
     margin-bottom: 40px;

     .search-input-group {
          position: relative;

          .clear-search {
               position: absolute;
               top: 50%;
               right: 2px;
               transform: translateY(-50%);
               // background: #e8f0fe;
               background: white;
               border-radius: unset;
               height: 90%;
               z-index: 5;

               svg {
                    transform: translateY(-2px);
               }
          }
     }

     .table {
          margin-bottom: 1px;
     }

     .pagination {
          padding-top: 20px;
          border-top: 1px solid #e4e7ea;
     }

     p {
          font-weight: 400;
     }
}

.table-notification {
     input[type='checkbox'] {
          cursor: pointer;
     }

     #all-monitorings {
          width: 16px;
          height: 16px;
     }

     .dash-symbol {
          position: relative;
          border: 1px solid #767676;
          background: #ffffff;
          width: 16px;
          height: 16px;
          border-radius: 3px;
          top: -3px;
          border-width: 1.5px;
          // font-weight: 500;
          vertical-align: middle;
          appearance: none !important;
          -webkit-appearance: none !important;
          outline: none !important;

          &::before {
               position: absolute;
               content: '-';
               top: -9.5px;
               left: 3.5px;
               font-size: 20px;
          }
     }

     th:not(:first-child),
     td:not(:first-child) {
          text-align: center;
     }

     th {
          font-size: 16px;
          padding-left: 15px;
          padding-right: 15px !important;
          position: relative;
          
          svg.icon {
               position: absolute;
               right: 0;
               top: 50%;
               transform: translateY(-50%);
          }
     }

     td {
          vertical-align: middle;

          &.unread {
               font-weight: 700;
          }

          a {
               font-weight: inherit;
               color: inherit;
               text-decoration: none;
          }
     }

     .being-archived {
          position: relative;
          overflow: hidden;
          padding-top: 0;
          padding-bottom: 0;
          line-height: 0;
          border: none;
          transition: all 0.5s ease;

          * {
               line-height: 0;
               display: none;
          }

          .block-tooltip {
               display: none;
          }
     }

     .all-checkbox {
          margin-left: -15px;
     }

     .label-all {
          margin-left: 10px;
     }

     .col-md-12 {
          padding: 0;
     }

     .mess-select-all {
          background-color: rgba(241, 243, 244, 0.871);
          padding: 12px;
          margin-bottom: 5px;
          text-align: center;
          font-size: 16px;
     }

     .select-all {
          // color: #20A8D8;

          align-items: center;
          border: none;
          display: inline-flex;
          justify-content: center;
          outline: none;
          font-size: 16px;
          background: none;
          border-radius: 4px;
          box-sizing: border-box;
          cursor: pointer;
          // font-weight: 500;
          height: 36px;
          min-width: 80px;
          color: #20a8d8;
          margin-left: 4px;
          padding: 0 8px;

          &:hover {
               background: #d0ecf5;
          }
     }

     // .enveloppe-open {
     //     margin-right: 10px;
     // }
     //  .enveloppe-close {
     //     margin-left: 10px !important;
     // }
     .enveloppe-open,
     .enveloppe-close {
          width: 22px !important;
     }
}

.all-icon {
     .button-mright {
          margin-right: 10px !important;
     }
}

.list-button-notifications {
     input {
          width: 16px;
          height: 16px;
     }

     .block-tooltip {
          top: 4px;
          min-width: 90px;
          white-space: nowrap;

          svg {
               position: absolute;
               height: 20px !important;
               top: 0;
               margin: 0;
          }

          .block-tooltip-text {
               padding: 5px;
               text-transform: capitalize;
          }
     }

     .checkbox-1 {
          transform: translateY(4px);
     }

     .hover-show-tooltip:not(.icon-c-s):not(.icon-custom-size) {
          width: 24px;
          height: 24px;
     }
}

.list-button-notifications1 {
     transform: translate(-10px, 12px);

     .enveloppe-open {
          transform: translateY(-2px);
     }

     .block-tooltip {
          top: 4px;
          min-width: 90px;
          white-space: nowrap;

          svg {
               position: absolute;
               height: 20px !important;
               top: 0;
               margin: 0;
          }

          .block-tooltip-text {
               padding: 5px;
               text-transform: capitalize;
          }
     }

     .hover-show-tooltip:not(.icon-c-s):not(.icon-custom-size) {
          width: 24px;
          height: 24px;
     }
}

.monitoring-notification-detail {
     ul li {
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #18222b;
     }

     p {
          color: #18222b;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
     }

     .list-button-notifications {
          padding: 10px 0;
          margin-bottom: 20px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;

          .hover-show-tooltip:not(.icon-c-s):not(.icon-custom-size) {
               width: 21px;
               height: 21px;
          }

          a {
               color: #2f353a;
               font-size: 18px;
               text-decoration: none;
          }
     }
     .btn-primary {
          padding: 6px 24px;
     }
}

.cvr-step-card {
     .simple-switch {
          display: inline-block;
          border: 2px solid transparent;
          border-radius: 18px;

          button {
               min-width: 60px;
               border-radius: 14px;
               background-color: transparent;
          }

          &.active {
               border-color: #20a8d8;

               button.btn-active {
                    background-color: #20a8d8;
                    color: #fff;
               }
          }

          &.deactive {
               border-color: #ced2d8;

               button.btn-deactive {
                    background-color: #ced2d8;
               }
          }
     }

     .send-mail-form {
          margin-bottom: 36px;
     }

     form {
          > .form-group {
               position: relative;

               &:nth-child(1) {
                    z-index: 4;
               }

               &:nth-child(2) {
                    z-index: 3;
               }

               &:nth-child(3) {
                    z-index: 2;
               }

               &:nth-child(4) {
                    z-index: 1;
               }
          }
     }

     .extra-note {
          position: relative;
          z-index: 0;
          transform: translateY(-1px);
     }

     .step3-form-label {
          display: flex;
          align-items: center;

          label {
               margin: 0;
          }
     }

     .config-label {
          position: relative;
          z-index: 1;
     }
}
