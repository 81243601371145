.custom-form-categories {
     padding-top: 154px;
     &-container {
          max-width: 665px;
          margin: 0 auto;
          position: relative;
          z-index: 1000;

          &--wizard {
               z-index: 1056;
          }
     }
}
.custom-form-categories,
.form-categories {
     .modal-skip {
          .modal-content {
               box-shadow: 0 24px 38px 3px rgba(60,75,100,.14),0 9px 46px 8px rgba(60,75,100,.12),0 11px 15px -7px rgba(60,75,100,.2);
          }
     }
     .remove-popup {
          .modal-content {
               width: 100%;
               max-width: 500px;
               padding: 55px 42px 30px;
               transform: translateX(-50%);
               position: absolute;
               left: 50%;
          }
          .modal-body {
               .icon-close-popup {
                    top: 30px;
                    right: 30px;
               }
          }
     }
}

.configure-custom-form,
.custom-form-categories {
     .custom-form-container--wizard {
          z-index: 1056;
     }
}
