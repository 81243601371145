.term-form {
    .error {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #f86c6b;
    }
    .react-datepicker-popper {
        z-index: 20;
    }
}