.create-preview-rule {
	.name-your-cookies {
		.css-1t87h5r-option,
		.css-t7okaz-option {
			background-color: #e9e9e9;
		}
	}

	.main-container {
		max-width: 1220px;
		display: none;

		&.show {
			display: flex;
		}
	}

	// .select-custom-variable{
	// 	.css-2b097c-container, .css-1pahdxg-control{
	// 		border-radius: 22.5px;
	// 	}

	// }
	.css-yk16xz-control {
		border-radius: 22.5px;
	}

	&.account-setup #create-account-content {
		padding-top: 0;
	}

	.main-content:not(.full-width) {
		max-width: inherit;
	}

	.steps {
		width: 223px;
	}

	&.account-setup .modal-body h3 {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.account-name {
		width: 550px;

		.form-group .form-control {
			text-align: left;
		}

		input,
		textarea,
		button.btn {
			width: 100%;
		}
	}

	.rule-conditions {
		width: 930px;
		text-align: left;

		.condition-row {
			margin: 0;
			gap: 10px;

			.col,
			div[class*=col] {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.select-react-customize:after {
			display: none;
		}

		.select-wrapper div.form-control,
		div[class*=ValueContainer],
		select.form-control {
			padding-right: 27px;
		}

		.button-new-rule {
			gap: 10px;
		}

		.new-rule-action {
			button {
				margin: 0;
			}
		}

		.native-browser-storage-field {
			min-width: 210px;
		}
	}

	.custom-input {
		flex: 1;
	}

	.select-react-customize .select-wrapper .form-control {
		color: #000000;
		padding: 6px 15px;
		border: 1px solid #e4e7e9 !important;

		+div {
			width: auto !important;
			min-width: 210px;
			max-width: 530px;
		}
	}

	.custom-variables {
		.form-group {
			display: flex;
			gap: 15px;
			margin-bottom: 0;

			.select-field {
				flex: 1;

				.select-react-customize {
					&::after {
						right: 27px;
					}
				}

				.custom-select__menu-list {
					padding: 0;
				}
			}

			.form-control {
				background: #f0f3f5;
				padding: 10px 22px;
				background-blend-mode: normal;
				border: none !important;
				height: 44px;
				padding-right: 43px;
			}
		}


		.button-group {
			&.min-width {
				min-width: 85px;
				text-align: left;
			}
		}
	}

	.rule-value {
		width: 730px;

		.custom-variable-action {
			margin-bottom: 10px;
		}

		.form-group {
			flex-direction: column;
			text-align: left;

			label {
				margin-bottom: 0;
			}
		}

		.browser-step4-value {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.insert-wrapper {
				display: block;
				text-align: left;
			}

			.browser-step4-form {
				gap: 10px;
			}
		}

		.browser-step4-form {
			display: flex;
			margin-bottom: 5px;

			.insert-variable-dropdown-icon {
				display: flex;
			}
		}

		.cookie-expires {
			display: flex;
			justify-content: space-between;
			gap: 20px;

			.form-group {
				flex: 1;
			}
		}

		.insert-variable-dropdown-icon button {
			margin-left: 0;
		}
	}

	.popup-create-variable {
		.modal {
			z-index: 1052;
		}

		.modal-backdrop {
			z-index: 1051;
		}

		.modal-dialog {
			margin: 1.75rem auto;
		}

		.modal-body {
			padding: 60px 60px 75px;
		}

		.modal-body>svg.icon-close-popup {
			position: absolute;
			top: 30px;
			right: 30px;
			cursor: pointer;
		}
	}

	.confirm-save-popup {
		.modal-dialog {
			max-width: 460px;
			margin: 1.75rem auto;
		}

		.confirm-save {
			padding: 0 34px 57px !important;

			&-icon {
				padding: 60px 0 25px;

				svg {
					transform: none !important;
				}
			}

			p {
				color: #2f353a;
				font-size: 16px;
				letter-spacing: 0;
			}

			button:not(.event-toggle) {
				font-size: 13px;
				min-width: inherit;
				margin-bottom: 0;
				text-decoration: none;

				&.btn-primary {
					color: #ffffff;
					background: #4dbd74;

					&:hover {
						background-color: #2bad58;
					}
				}

				&.btn-cancel {
					color: #73818f;
					background-color: transparent;

					&:hover {
						background-color: #d9e1e6;
					}
				}
			}
		}
	}

	#create-account-content {
		.confirm-save-popup {
			h1 {
				font-weight: 700;
				font-size: 20px;
				letter-spacing: -0.9px;
			}
		}
	}

	.form-group {
		p.des {
			margin-bottom: 20px;
		}

		.form-control {
			padding-left: 15px;
			border-radius: 22.5px;
		}
	}

	.textarea-wrap {
		background-color: #f0f3f5;
		padding: 10px;
		padding-left: 15px;
		border-radius: 22.5px;

		.form-control {
			padding-left: 0;
			border-radius: 0;
		}

		textarea {
			padding: 0;
			resize: none;

			&::-webkit-scrollbar {
				width: 5px;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f1f1f1;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				background: #888;
				border-radius: 10px;
			}
		}

		&.border-input {
			background-color: #fff;
			border: 2px solid #e4e7e9;

			textarea {
				border: none;
			}
		}

		&.disabled {
			background-color: #e4e7ea;
		}

		&.is-invalid {
			border-color: #f86c6b;
		}
	}

	.invalid-feedback {
		text-align: left;
	}
}

.popup-publish-updates {
	.group-button {
		display: flex;
		justify-content: center;
		gap: 20px;

		.btn {
			padding: 7px 25px;
		}
	}
}