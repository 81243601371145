.destination-layout {
     .accordion {
          .accordion-item {
               p {
                    font-family: Roboto;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.1px;
               }

               .general-setting-form {
                    .form-control {
                         border-radius: 12px;
                         border: 1px solid #e4e7e9;

                         &.is-invalid {
                              border: 1px solid #f86c6b;
                         }
                    }

                    div[class*=container] {
                         border: none !important;
                    }

                    div[class*=control] {
                         border-radius: 12px;
                         border: 1px solid #e4e7e9 !important;
                    }

                    div[class*=MenuList] {
                         &::-webkit-scrollbar-track {
                              -webkit-box-shadow: inset 0 0 6px rgb(255, 254, 254);
                              border-radius: 5px;
                              background-color: #F5F5F5;
                         }

                         &::-webkit-scrollbar {
                              width: 3px;
                         }

                         &::-webkit-scrollbar-thumb {
                              border-radius: 5px;
                              -webkit-box-shadow: inset 0 0 6px rgba(115, 129, 143, 1);
                              background-color: #73818F;
                         }
                    }

                    .css-yk16xz-control,
                    .css-pahdxg-control {
                         border-radius: 12px;
                         border: 1px solid #e4e7e9 !important;
                    }
               }
          }
     }

     .gtm-destination {
          .card {
               &.gtm-info-container {
                    border: 1px solid #f0f3f5 !important;
                    max-width: 500px;
                    margin: 0 auto;
               }
          }

          .item-row {
               background: #f0f3f5;
               display: flex;
               justify-content: space-between;
               align-items: center;
               padding: 0 20px;

               .btn {
                    padding: 0;
               }
          }

          .react-select {
               border-radius: 12px;
               margin-bottom: 15px;
               max-width: 400px;

               .select {
                    &__control {
                         padding: 6px 9px;
                         border-radius: 12px;
                    }
               }

               &.no-filter {
                    .react-select {
                         &__control {
                              padding: 10px 9px;
                         }
                    }
               }
               &.listener-select {
                    .react-select {
                         &__control {
                              padding: 6px 9px;
                         }
                    }
               }
          }

          .listener-select {
               .option-row {
                    display: flex;
                    align-items: center;

                    .icon {
                         height: 18px;
                         margin-right: 10px;
                    }
               }
          }

          i {
               margin-right: 10px;
               transform: translateY(1px);
          }

          .settings {
               .btn-connect-gtm {
                    margin-bottom: 20px;

                    svg {
                         margin-right: 20px;
                         height: 18px;
                         width: 18px;
                    }
               }

               .foot-note {
                    max-width: 530px;
                    margin: 0 auto;
                    display: block;
                    color: #7f8c9a;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;

                    a {
                         text-decoration: underline;
                    }
               }
          }

          .variables,
          .triggers,
          .tags {
               .react-select {
                    &__control {
                         padding: 6px 9px;
                         border-radius: 12px;
                    }
               }
               
               a {
                    &.accordion-item {
                         text-decoration: none;
                         color: #000;
                    }
               }

               .name-container {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
               }

               .name {
                    flex: 1;
                    font-weight: 500;
                    margin-right: 30px;
                    white-space: nowrap;
               }

               .ll-name {
                    margin-right: 20px;
               }

               .input-filter {
                    max-width: 500px;
                    margin-bottom: 20px;
               }

               .item-row button .icon-delete {
                    margin: 1px 0 0;
                    width: 24px;
                    height: 24px;
               }
          }

          .pagination {
               margin-top: 20px;

               .page-link {
                    z-index: 0;
               }
          }
     }

     .accordion {
          .options-data-wrapper .switch-item {

               .block-tooltip {
                    left: 35%;
               }

               &.conversion-destionation {
                    .block-tooltip {
                         left: 83%;
                    }
               }
          }

          .edit-container {
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding-left: 15px;
               padding-right: 15px;
               background: #f2fcff;

               .btn {
                    padding: 0;
                    width: 41px;
                    height: 17px;
                    font-family: Roboto;
                    font-size: 9.36px;
                    font-weight: 700;
                    line-height: 11px;
                    text-align: center;
               }

               .btn-custom {
                    width: 54px;
                    height: 19px;
                    border-radius: 4px;
                    border: solid 1px #121920 !important;
                    color: #121920;
                    font-family: Roboto;
                    font-size: 10px;
                    font-weight: 700;
                    margin-right: 11px;
                    cursor: auto;
               }

               span {
                    font-size: 13px;
               }
          }
     }

     .collapse {
          .custom-script-container {
               padding-right: 20px;
          }

          .btn-dark {
               background-color: #828282;

               &.active {
                    background-color: #2f353a;
                    opacity: 1;
               }
          }

          .triggers-multiselect {
               #triggersName {
                    height: 60px;
               }

               .css-1pahdxg-control,
               .css-yk16xz-control,
               .css-1fhf3k1-control {
                    height: 56px;
                    overflow-y: scroll;
               }

               .css-yk16xz-control {
                    background-image: none;
               }
          }

          .browser-step4-value {
               strong {

                    margin-right: 15px;
                    font-weight: 800;
               }
          }

          .insert-variable-dropdown-icon {
               display: flex;
               justify-content: flex-end;
               align-items: center;

               svg {
                    width: unset !important;
                    height: unset !important;
               }
          }

          .browser-step4-form {
               button {
                    padding: 0;
                    margin-left: 5px;
                    background-color: transparent;
                    border: 0;
                    border-bottom: 1px dotted #000;
               }
          }

          .content-c {
               height: 35px;
               display: flex;
               align-items: center;
          }

          .insert-variable-dropdown {
               ul {
                    margin-top: 3px;
                    list-style: none;
                    position: absolute;
                    right: 0;
                    padding: 10px;
                    z-index: 99;
                    border: 1px solid #e4e7ea !important;
                    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.13) !important;
                    max-height: 280px;
                    overflow-y: auto;
                    background-color: #fff;
                    width: 380px;
                    border-radius: 5px;

                    li {
                         padding-bottom: 5px;
                         word-break: break-word;
                         cursor: pointer;
                    }

                    .variable-filter {
                         position: -webkit-sticky;
                         position: sticky;
                         top: 0;

                         &::before {
                              content: "";
                              display: block;
                              position: absolute;
                              z-index: 0;
                              background-color: #fff;
                              width: calc(100% + 20px);
                              height: 50px;
                              top: -10px;
                              left: -10px;
                         }

                         input {
                              position: relative;
                         }
                    }

                    &::-webkit-scrollbar {
                         width: 7px;
                         background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                         background-color: $gray-300;
                         border-top-left-radius: 3px;
                         border-top-right-radius: 3px;
                         border-bottom-left-radius: 3px;
                         border-bottom-right-radius: 3px;
                         border-left: 2px solid #fff;
                         border-right: 2px solid #fff;
                    }

                    .no-options-message {
                         .icon-info {
                              width: unset;
                              height: unset;
                         }
                    }

                    .no-options-info {
                         left: calc(100% - 160px);
                         top: calc(100% + 18px);
                         border-top-left-radius: 12px;

                         .arrow-preview {
                              left: 130px;
                              top: -17px;
                              transform: rotate(90deg);
                         }
                    }
               }
          }
     }

     .triggers-rules .default-rules .row-rule .info-wrap {
          min-width: 35%;
     }
}

.view-code-editor {
     &.resizable {
          padding: 10px;
          background: #18222b;
          width: 100%;
          border-radius: 12px;

          &.invalid-script {
               border: 2px solid #f86c6b;
          }

          .ace_scroller,
          .ace_gutter {
               // margin-top: 10px;
               background: #18222b;

          }

          .ace_editor,
          .ace_text-layer {
               width: 100% !important;
          }

          .ace_dark {
               background: #18222b;
          }

          .ace_gutter-cell {
               background-color: #18222b;
               text-align: left;
               padding-left: 0px;

               &.ace_error {
                    background-position: 8px center;
               }
          }

          .ace_scrollbar {
               height: calc(100% - 20px);
               top: 50%;
               right: 9px;
               transform: translateY(-50%);
               background-color: transparent;

               &::-webkit-scrollbar {
                    background-color: transparent;
               }

               &::-webkit-scrollbar-track {
                    background-color: #353e47;
                    border-radius: 10px;
               }

               &::-webkit-scrollbar-thumb {
                    background-color: #67727d;
                    border-radius: 10px;
               }

               &.ace_scrollbar-h {
                    display: none;
               }
          }
     }
}

.custom-destination-modal {
     .modal-content {
          position: fixed;
          height: 100%;
          width: 100%;
          border-radius: 0;
          background-color: #f0f3f5;
          overflow-y: auto;
          border: 0 !important;
     }

     .card-body {
          padding: 1.25rem 90px;
     }

     .name-destination {
          label {
               &.name {
                    margin-right: 10px;
                    margin-bottom: 0;
                    transform: none;
               }
          }

          .tooltip-wrapper {
               position: relative;
          }
     }

     .btn.btn-save {
          width: 30%;
          margin: 0 auto;
     }
}

.destinations {
     .card.custom-destination {
          background: #d0ecf5;
          box-shadow: none !important;
          border: 0 !important;
     }

     .card-icon {
          .label-top-card {
               border-radius: 10px;
               padding: 5px 15px;
          }
     }

     .library-wrapper {
          height: 65vh;
     }
}