.subscriber {
    .features {
        margin-bottom: 40px;

        .justify-content-between {
            .btn {
                padding-top: 0.475rem;
            }
        }

        .filters-feature {
            margin-bottom: 15px;
            margin-top: 10px;

            button {
                background-color: $white;
                border: 0;
                border-radius: 25px;

                color: #18222b;
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.18571427px;

                padding: 10px 25px;
                margin-bottom: 15px;

                &:not(:last-child) {
                    margin-right: 6px;
                }

                &.active {
                    color: #ffffff;
                    background: $primary;
                    border-radius: 17.5px;
                    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1) !important;

                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    text-align: center;
                }

                &.no-active {
                    background: #F8F9FA;
                    border-radius: 17.5px;

                    color: #18222b;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.18571427px;
                }
            }

            .switch-enable-feature {
                margin-right: 20px;
                padding-top: 3px;
            }
        }

        .card-feature-item {
            min-height: 291px;

            .card-body {
                padding: 0;
            }

            .card-title {
                padding: 20px;
                margin-bottom: 0;

                .label-top-card {
                    &.color-added {
                        cursor: pointer;
                    }
                }
            }

            .card-icon {
                padding: 20px;

                .card-header-actions {
                    margin-left: auto;
                }

                .icon-info {
                    margin-right: 20px;
                    height: 17px;
                }
            }
        }

        .tabs-box-feature {
            .nav-tabs {
                border: none;
                margin-bottom: 16px;

                .nav-item {
                    min-width: calc(100% / 3);

                    &:not(:last-child) {
                        border-right: 2px solid #fff;
                    }

                    .nav-link {
                        border: none;
                        border-radius: 0;

                        color: #18222b;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15px;
                        text-align: center;
                        // letter-spacing: 0.18571429px;
                        padding: 12px 5px;
                        background-color: $light;
                        font-family: Roboto;
                        letter-spacing: 0.17834143px;

                        &.active {
                            background-color: $white;
                            border-top: $light solid 1px;
                        }
                    }
                }
            }

            .tab-content {
                padding: 0 20px 20px;
                border-bottom: solid 1px rgba(200, 206, 211, 0.3);

                .tabs-message {
                    padding: 7px 13px;
                    margin-bottom: 14px;
                    border-radius: 4px;
                    background: #fee1e1;

                    display: flex;
                    justify-content: space-between;
                    text-align: center;

                    .tabs-message-text {
                        color: #863f3f;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    .tabs-message-button {
                        color: #863f3f;
                        font-size: 10px;
                        font-weight: 700;
                        line-height: 16px;
                        text-align: right;
                        text-transform: uppercase;

                        cursor: pointer;
                    }

                    button {
                        &.tabs-message-button {
                            padding: 0;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                p {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 19px;
                    letter-spacing: 0.086666666px;
                    font-family: Roboto;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .info-icon {
            cursor: pointer;
        }

        .card-text {
            h4 {
                margin-bottom: 5px;
            }

            p,
            h4,
            li {
                font-size: 16px;
            }

            ul,
            ol {
                margin-bottom: 1rem;
                padding-left: 30px;

                li {
                    margin-bottom: 5px;
                }
            }
        }

        .card-support {
            p {
                font-size: 12px;
            }
        }

        .code-preview {
            position: absolute !important;
            min-height: 325px;
            top: calc(100% - 35px);
            z-index: 99;
            margin-bottom: 80px;

            &.code-preview-col-1,
            &.code-preview-col-2 {
                left: 60px;
            }

            // &.code-preview-col-2{
            //     top: 100%;
            //     left: 50%;
            //     transform: translateX(-50%);
            // }
            &.code-preview-col-3 {
                right: 100%;

                .arrow-preview {
                    transform: rotateY(180deg);
                    right: -10px;
                    left: auto;
                }
            }

            .arrow-preview {
                position: absolute;
                height: 20px !important;
                left: -10px;
            }

            .card-header {
                background-color: #19222b;
                padding: rem(14) rem(12);

                a {
                    color: #fff;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .card-body {
                background-color: #212c36;
                padding: 0;

                .ace_gutter,
                .ace_scroller {
                    background-color: #212c36;
                }

                .ace_gutter-active-line {
                    background-color: #19222b;
                }
            }

            .ace_scroller {
                right: 0 !important;
            }

            &:after {
                content: "";
                width: 100%;
                height: 100px;
                bottom: -35px;
                position: absolute;
            }
        }

        .preview-copy {
            background-color: transparent;
            border: none;
            cursor: pointer;
            text-decoration: underline;
            display: inline;
            margin: 0;
            padding: 0;
        }

        .preview-copy,
        .preview-copy {

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .un-preview-copy {
            cursor: default;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .feature-type {
            display: none;
            position: absolute;
            top: 50px;
            left: 25px;
            height: auto;
            box-shadow: none !important;
            background-color: #19222b !important;

            .arrow-preview {
                position: absolute;
                transform: rotate(270deg) scaleX(-1);
                top: -10px;
            }

            .card-body {
                padding: 1em;
                min-height: 0;
            }
        }

        .feature-type-icon {
            &:hover {
                &~.feature-type {
                    display: block;
                }
            }
        }

        .btn-learn-video {
            text-transform: none;
            padding: 0;

            position: relative;
            padding-left: 17px;

            margin-right: 20px;

            svg {
                margin: 0;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 8.64px;
                height: 12.48px;
            }

            span {
                color: $primary;
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                text-align: right;
                letter-spacing: 0.2px;
            }
        }

        .btn-use-cases {
            text-transform: none;
            padding: 0;

            span {
                vertical-align: middle;
                color: #708393;
                font-family: Roboto;
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                text-align: right;
                letter-spacing: 0.2px;
            }
        }

        .instruction-message {
            background-color: #fee1e1;
            padding: 15px;
            margin-bottom: 20px;
            color: #863f3f;
            border-radius: 5px;
            position: relative;
        }
    }
}

.tour-guide-content{
    .learn-video-popup{
        .modal-dialog {
            right: 0;
            margin: 0 auto;
        }
    }
}

.learn-video-popup {
    text-align: center;

    .modal-dialog {
        max-width: 900px;
    }

    .learn-video-popup-body {
        position: relative;
        padding: 30px;

        .title {
            margin-bottom: 20px;
            &.roadmap{
                margin-bottom: 0;
                margin-top: 20px;
                margin-bottom: 0;
                font-size: 15px;
                p{
                    margin-bottom: 0;
                }
            }
        }
    }

    .icon-close-popup {
        top: 10px;
        right: 10px;
        width: 20px !important;
        height: 20px !important;
    }

    .desc {
        padding: 0 30px;
    }
}

.holder-icon {
    width: 18px;
}

@media screen and (max-width: 767px) {
    .subscriber {
        .features {
            .tabs-box-feature {
                .nav-tabs {
                    .nav-item {
                        min-width: 100%;
                    }
                }
            }
        }
    }
}