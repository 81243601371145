$primary-color: #63c2de;
$secondary-color: #ffffff;
$tertiary-color: #73818f;

.steps {
    width: 115px;
    margin-top: 58px;

    .headline {
        p {
            color: $tertiary-color;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
        }
    }

    ol {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 25px;
        display: block;
        font-size: 1.1rem;

        li {
            position: relative;
            display: block;
            overflow: hidden;
            color: #7f8c9a;
            height: auto;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            text-align: left;
            padding-top: 0;
            padding-bottom: 15px;
            padding-left: 28px;
            z-index: 1;

            &:before {
                content: '';
                position: absolute;
                width: 11px;
                height: 11px;
                line-height: 11px;
                margin-top: -15px;
                margin-left: 4px;
                top: 55%;
                left: -0.5px;
                border-width: 2px;
                font-size: 1.5rem;
                box-sizing: border-box;
                border-radius: 50%;
                border: 2px solid $secondary-color;
                background: $secondary-color;
                box-shadow: 0 0 0 3px $secondary-color;
                z-index: 10;
            }

            &:after {
                content: '';
                position: absolute;
                background: #ffffff;
                // background: #63c2de;
                box-shadow: 0 0 0 3px $secondary-color;
                width: 2px;
                height: auto;
                top: 0;
                left: -0.5px;
                bottom: 0;
                margin-top: 0;
                margin-left: 9px;
                z-index: 10;
            }

            &:first-child:after {
                right: 0;
                height: 65%;
                width: 2px;
                left: -0.5px;
                margin-left: 9px;
                top: auto;
            }

            &:last-child:after {
                height: 22%;
                width: 2px;
                left: -0.5px;
                margin-left: 9px;
            }

            .steps-circle {
                display: none;
                text-align: center;
                border-radius: 50%;
                position: absolute;
                width: 11px;
                height: 11px;
                line-height: 11px;
                margin-top: -15px;
                border-width: 2px;
                font-size: 1.25rem;
                margin-left: 4px;
                top: 55%;
                left: -0.5px;
                z-index: 11;
                color: $primary-color;
                background: $primary-color;
            }
        }

        .steps-complete:after {
            background: #63c2de;
        }

        .steps-selected {
            display: block;
            color: $primary-color;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            text-align: left;

            &:first-child,
            &:last-child {
                .steps-circle {
                    &:after {
                        background: $primary-color;
                    }
                }
            }

            &.steps-complete+.steps-selected {
                &:not(:first-child) {
                    .steps-circle {
                        &::before {
                            content: '';
                            position: absolute;
                            background: #63c2de;
                            top: -9px;
                            left: 5px;
                            width: 2px;
                            height: 9px;
                        }
                    }
                }
            }

            .steps-circle {
                display: block;

                // &:before {
                //     border-color: $primary-color;
                //     background: $primary-color;
                // }
                // &:after {
                //     background: $primary-color;
                // }
            }

            .steps-sub-title {
                line-height: 1rem;
                margin-top: 0.25rem;
                font-size: 0.875rem;
                color: #7f8c9a;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .steps-vertical {
        display: none;
    }
}