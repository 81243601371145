.subscriber {
     .col-12.col-sm-6.col-xl-4 {
          padding-right: 5px;
     }

     .col-12.col-sm-6.col-xl-4:nth-child(3n) {
          padding-right: 15px;
     }

     .card-body {
          .card-text {
               p.f-14 {
                    font-family: Roboto;
                    font-size: 13px !important;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.086666666px;
                    color: #121920;
               }
          }
     }

     .d-flex.align-items-center.float-xl-right.flex-wrap {
          transform: translateY(5px);
     }

     .listeners,
     .tag-managers,
     .browser-addons {
          p {
               // padding-left: 5px;
               // color: #18222b;
               font-family: Roboto;
               font-size: 16px;
               font-weight: 400;
               line-height: 22px;
          }
     }

     .listeners {
          .tip-video {
               margin-bottom: 21px;
          }

          .card {
               .card-title {
                    .custom-badge {
                         border: solid 1px #121920;
                    }
               }
          }

          .filter-slider {
               margin: 0 -30px 28px -24px;
          }

          .listener-premium-card.card {
               // border-top: 3px solid $yellow  !important;
               position: relative;

               &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 4px;
                    width: 98%;
                    height: 2px;
                    background-image: url(/assets/images/line1.png);
                    background-size: cover;
                    background-repeat: no-repeat;

                    // left: 6px;
                    // width: 97%;
                    // height: 2px;
                    // background-color: #ffc107;
                    // border-top-left-radius: 17px;
                    // border-top-right-radius: 17px;
               }
          }

          .listener-title {
               position: relative;

               .popup-btn {
                    border: none;
                    background: transparent;

                    &:active,
                    &:focus {
                         text-decoration: none;
                    }
               }
          }

          p {
               a {
                    text-decoration: underline;

                    &:hover {
                         text-decoration: none;
                    }
               }
          }

          .btn-learn-video {
               text-transform: none;
               padding: 0;

               position: relative;
               padding-left: 17px;

               margin-right: 20px;

               svg {
                    margin: 0;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
               }

               span {
                    color: $primary;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    text-align: right;
                    letter-spacing: 0.2px;
               }
          }

          .card-icon .card-header-actions {
               .action-open-menu {
                    svg {
                         width: 17px;
                         height: 17px;
                    }
               }

               .coming-soon-text {
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #c8ced3;
                    margin-right: 20px;
               }
          }
     }

     .listener-message {
          color: $message-863;
          font-weight: 500;

          .listener-button {
               font-weight: 500;
               padding: 3px 10px;
               color: $message-863;
               border-color: $message-863;
               min-width: 110px;

               &:hover {
                    color: #fff;
                    background-color: $message-863;
               }
          }

          .x-close {
               cursor: pointer;
          }
     }

     .bg-listener-message {
          background-color: #fee1e1;
     }

     // .c-dark-theme {
     //      .listener-message {
     //           color: #d16767;
     //           background-color: #0a0b18 !important;
     //           .listener-button {
     //                color: #d16767;
     //                border-color: #d16767;
     //                &:hover {
     //                     color: #e1e1e1;
     //                     background-color: #d16767;
     //                }
     //           }
     //      }
     // }

     .form-control {
          &:focus {
               border-color: #e4e7e9;
               box-shadow: none;
          }
     }

     .library-wrapper {
          height: 73vh;
          overflow-y: auto;
          overflow-x: hidden;
          margin-right: -30px;
          padding-right: 20px;

          &::-webkit-scrollbar {
               width: 5px;
          }

          &::-webkit-scrollbar-track {
               width: 5px;
               background-color: #e2e6e9;
               border-radius: 2px;
          }

          &::-webkit-scrollbar-thumb {
               background: #73818f;
               border-radius: 2px;
               border: 1px solid #e2e6e9;
          }

          .listener-items {
               margin-right: 5px;
          }
     }

     .listener-items {
          margin-bottom: 50px;

          .card-header-actions {
               .action-open-menu {
                    svg {
                         color: #73818f;
                    }
               }
          }
     }

     .listeners {
          .row-items {
               display: grid;
               grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
               gap: 20px;
          }

          .listener-item>.card {
               margin: 0;
               height: 100%;
          }
     }
}

@media screen and (min-width: 768px) {
     .subscriber {
          .listener-message {
               padding: 15px 32px;
          }
     }
}

@media screen and (min-width: 1601px) {
     .header-with-subheader {
          .listener-message {
               .icon {
                    width: 30px;
                    height: 20px;
               }
          }
     }
}

@media screen and (max-width: 1600px) {
     .header-with-subheader {
          .listener-message {
               .icon {
                    width: 30px;
                    height: 20px;
               }
          }
     }
}

@media screen and (max-width: 1199px) {
     .header-with-subheader {
          .listener-message {
               .icon {
                    width: 40px;
               }
          }
     }

     .col-12.col-sm-6.col-xl-4 {
          padding-right: 15px !important;
     }

     .col-12.col-sm-6.col-xl-4:nth-child(3n) {
          padding-right: 15px !important;
     }
}

@media screen and (max-width: 768px) {
     .header-with-subheader {
          .listener-message {
               .icon {
                    width: 60px;
               }
          }
     }
}

@media screen and (max-width: 767px) {
     .subscriber {
          .listener-message {
               padding: 15px 16px;

               .icon {
                    width: 16px;
               }
          }

          .listeners {
               .filter-card {
                    .filter-card-select {
                         padding-left: 0 !important;
                         margin-top: 15px;
                    }
               }
          }
     }
}

@media screen and (max-width: 414px) {
     .subscriber {
          .listener-message {
               >.listener-message-content {
                    flex-direction: column;
                    align-items: flex-start !important;

                    >.listener-message-text {
                         margin-right: 0 !important;
                    }

                    .listener-message-button {
                         margin-top: 10px;
                    }
               }
          }
     }
}