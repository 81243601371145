.datalayer-variables {
     .collapse,
     .collapsing {
          margin-left: 15px;
          margin-right: 15px;
     }

     .row {
          & > .collapse,
          & > .collapsing {
               .variable-name {
                    padding-left: 35px;
               }

               .row {
                    & > .collapse,
                    & > .collapsing {
                         .variable-name {
                              padding-left: 60px;
                         }
                    }
               }
          }
     }

     .variable-path {
          position: relative;
          @include flex-container(flex, center, center);
          .variable-path-text {
               position: absolute;
               z-index: 10;
               top: calc(50% + 25px);
               left: calc(50% - 15px);
               transform: translateX(-50%);
               margin-bottom: 0;
               min-width: max-content;
               border: 0 !important;

               .code-preview {
                    margin-bottom: 0;
                    color: $white;
                    background-color: #19222b;
                    border: 0 !important;
               }

               svg {
                    position: absolute;
                    height: 20px !important;
                    left: 50%;
                    top: -14px;
                    transform: rotateZ(90deg);
               }

               .card-body {
                    display: flex;
                    align-items: center;
                    padding: rem(15) rem(17) rem(15) rem(23);

                    p {
                         margin-bottom: 0;
                         margin-right: 20px;
                         color: #fff;
                    }

                    button {
                         line-height: 13px;
                         padding: rem(2) rem(7);
                         background-color: $white;
                         color: $primary;
                         min-width: 50px;
                    }
               }
          }
     }

     .variables-children {
          padding-right: 20px !important;

          .btn-sm {
               padding: 0;
          }
     }

     .variables-children-lv1 {
          .variable-name {
               padding-left: 35px;
          }
     }

     .variables-children-lv2 {
          .variable-name {
               padding-left: 60px;
          }
     }

     .variable-status {
          span {
               min-width: 49px;
          }
     }

     .variable-status-header {
          .block-tooltip {
               &.reporting-values-tooltip {
                    bottom: 17px;
               }
          }
     }
}

.woo.block-tooltip {
     min-width: 500px;
     left: auto;
     right: -100px;

     svg {
          right: calc(50% - 155px);
          left: auto;
     }
}

.table-event {
     padding: 1.05rem 0;

     .headline {
          padding: 0 1.5rem;
          margin-bottom: 1.5rem;
     }

     .message-warning {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
     }

     .css-table {
          display: table;
          margin-bottom: 1.2rem;
          width: 100%;
     }

     .css-table-header,
     .css-table-row {
          & > div {
               display: table-cell;
               padding: 0.75rem;
          }
     }

     .block-tooltip {
          bottom: 17px;
     }

     &.reporting-table-event {
          .block-tooltip {
               bottom: 17px;

               &.reporting-values-tooltip {
                    bottom: 42px;
               }
          }
     }

     .css-table-header {
          display: table-header-group;
          background-color: #18222b;
          color: #fff;

          & > div {
               border-bottom: none;
               font-weight: 400;
               padding-left: 1.5rem;
               vertical-align: bottom;
          }
     }

     .css-table-body {
          display: table-row-group;

          .event-input-group {
               input {
                    border: 1px solid #e2e6e9;
                    border-radius: 12px;

                    &[disabled] {
                         color: #9da7b1;
                         background-color: transparent;
                    }

                    &.has-edit-btn {
                         padding-right: 92px;

                         &[disabled] {
                              padding-right: 92px;

                              &.is-invalid {
                                   // background-position: right 40px top 7px;
                                   padding-right: 60px;
                              }
                         }

                         &.is-invalid {
                              // background-position: right 85px top 7px;
                              padding-right: 92px;
                              background-position: right calc(0.375em + 0.0875rem) center;

                              & + .btn-edit {
                                   right: 26px;
                              }
                         }
                    }

                    &.is-invalid {
                         & + .btn-cancel-edit {
                              right: 30px;
                         }
                    }
               }

               .btn-edit {
                    background-color: transparent;
                    font-size: 80%;
                    color: #f96e6d;
                    border: 0;
                    padding: 0;
                    @include position-right-middle-param(12px);
                    z-index: 3;

                    &.disabled {
                         opacity: 1;
                         cursor: default;
                    }
               }
          }
     }

     .css-table-row {
          display: table-row;

          & > div {
               border-top: none;
               vertical-align: text-top;
               padding-left: 25px;

               span {
                    color: #73818f;
                    vertical-align: middle;
               }

               &.event-status {
                    span {
                         min-width: 49px;
                    }
               }

               &.bubble-message {
                    position: relative;

                    .session-length {
                         position: absolute;
                         right: 89%;
                         z-index: 9;
                         top: 60%;
                         background-color: #fff;
                         color: #fff;
                         width: 60vw;
                         max-width: 300px;

                         .session-length-container {
                              .input-container {
                                   display: flex;
                                   align-items: center;

                                   input {
                                        max-width: 100px;
                                        margin-right: 10px;
                                   }
                              }
                         }
                    }

                    .code-preview {
                         position: absolute;
                         right: 100%;
                         width: 370px;
                         z-index: 9;
                         top: 50%;
                         background-color: #19222b;
                         color: $white;
                         border-top-right-radius: unset;

                         &.across-domains {
                              width: 80vw;
                              max-width: 540px;
                         }

                         &.video-progresses {
                              background-color: #fff;
                              width: 380px;

                              p {
                                   color: #000;
                                   min-width: calc(100% - 40px);
                                   margin: 0;
                                   font-weight: 400;
                                   vertical-align: middle;
                              }

                              .feature-support-vidyard:not(:first-child) {
                                   padding-top: 15px;
                              }

                              &:after {
                                   content: '';
                                   display: block;
                                   height: 30px;
                                   width: 100%;
                                   position: absolute;
                                   z-index: 5;
                                   bottom: -30px;
                              }

                              .variable-input-group {
                                   .btn-edit {
                                        padding-left: 0;
                                   }
                              }

                              .remove-popup {
                                   .icon-close-popup,
                                   h2,
                                   .remove-popup-description {
                                        color: #000;
                                   }
                              }
                         }

                         & > .card-body {
                              & > svg {
                                   position: absolute;
                                   transform: scaleX(-1);
                                   right: -9px;
                                   cursor: pointer;

                                   &.icon-close-popup {
                                        color: #73818f;
                                        top: 5px;
                                        right: 5px;
                                   }
                              }
                         }

                         .video-progress-setting {
                              .headline {
                                   color: #000;
                                   padding: 0;
                                   font-size: 16px;
                                   font-weight: 500;
                                   line-height: 1.5em;
                                   margin-bottom: 5px;
                                   padding-right: 15px;

                                   &.fw-normal {
                                        font-weight: 400;
                                   }
                              }

                              .video-progress-content {
                                   .list-fields {
                                        overflow-y: auto;
                                        overflow-x: hidden;
                                        max-height: 350px;
                                   }
                              }
                         }

                         .divider {
                              display: block;
                              background-color: #c8ced3;
                              width: 100%;
                              height: 1px;
                              margin: 1em 0;
                         }

                         .session-length-container {
                              .input-container {
                                   display: flex;
                                   align-items: center;

                                   input {
                                        max-width: 100px;
                                        margin-right: 10px;
                                   }
                              }
                         }
                    }

                    & > svg {
                         cursor: pointer;

                         &:hover {
                              color: #73818f;
                         }
                    }

                    .arrow-preview {
                         position: absolute;
                         transform: rotateY(180deg);
                         right: -10px;
                         left: auto;
                    }
               }

               .invalid-feedback {
                    display: block;
               }
          }

          .variable-name {
               width: 30%;
          }

          .variable-friendlyname {
               width: 33%;
          }

          .variable-path {
               width: 15%;
          }

          &:nth-child(odd) {
               background-color: #f4f5f6;
          }

          &.inactive {
               input {
                    color: #abaeb2 !important;
               }
          }

          &.second-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 2.3rem;

                    &:before {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #73818f;
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
               }
          }

          &.third-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 2.9rem;

                    &:before {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #73818f;
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #18222b;
                         position: absolute;
                         left: 10px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 2.9rem;
               }
          }

          &.fourth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 3.5rem;

                    &:before {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #73818f;
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b 50%, #73818f 50%);
                         position: absolute;
                         left: 10px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 3.5rem;
               }
          }

          &.fifth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 4.1rem;

                    &:before {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f 50%, #18222b 50%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f 50%, #18222b 50%);
                         position: absolute;
                         left: 20px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 4.1rem;
               }
          }

          &.sixth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 4.7rem;

                    &:before {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 33.33%, #18222b 33.33%, #18222b 66.66%, #73818f 66.66%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b 50%, #73818f 50%);
                         position: absolute;
                         left: 30px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 4.7rem;
               }
          }

          &.seventh-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 5.3rem;

                    &:before {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 33.33%, #18222b 33.33%, #18222b 66.66%, #73818f 66.66%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b, #18222b 33.33%, #73818f 33.33%, #73818f 66.66%, #18222b 66.66%);
                         position: absolute;
                         left: 30px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 5.3rem;
               }
          }

          &.eighth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 5.9rem;

                    &:before {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 25%, #18222b 25%, #18222b 50%, #73818f 50%, #73818f 75%, #18222b 75%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 33.33%, #18222b 33.33%, #18222b 66.66%, #73818f 66.66%);
                         position: absolute;
                         left: 40px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 5.9rem;
               }
          }

          &.ninth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 6.5rem;

                    &:before {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 25%, #18222b 25%, #18222b 50%, #73818f 50%, #73818f 75%, #18222b 75%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 25%, #18222b 25%, #18222b 50%, #73818f 50%, #73818f 75%, #18222b 75%);
                         position: absolute;
                         left: 40px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 6.5rem;
               }
          }

          &.tenth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 7.1rem;

                    &:before {
                         content: '';
                         width: 50px;
                         height: 100%;
                         background: linear-gradient(
                              to right,
                              #73818f,
                              #73818f 20%,
                              #18222b 20%,
                              #18222b 40%,
                              #73818f 40%,
                              #73818f 60%,
                              #18222b 60%,
                              #18222b 80%,
                              #73818f 80%
                         );
                         position: absolute;
                         left: 0;
                         top: 0;
                    }

                    &:after {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b, #18222b 25%, #73818f 25%, #73818f 50%, #18222b 50%, #18222b 75%, #73818f 75%);
                         position: absolute;
                         left: 50px;
                         top: 0;
                    }
               }

               .left-indent {
                    padding-left: 7.1rem;
               }
          }
     }

     .datalayer-variables {
          .btn-plus {
               box-shadow: none !important;
               background-color: transparent !important;

               i {
                    font-weight: 600;
               }
          }
     }
}

.listener-reporting {
     .reporting-table-event {
          padding: 0;

          .css-table {
               margin-bottom: 0;
          }

          .variable-name {
               width: 20%;
          }

          .variable-friendlyname {
               width: 27%;
          }

          .reporting-values {
               width: 15%;
          }

          .variable-path {
               width: 10%;
          }

          .reporting-values {
               .c-switch {
                    padding-top: 7px;
               }
          }

          .variable-value-check {
               cursor: pointer;
               margin-left: 10px;
               font-size: 16px;
          }

          .css-table-header {
               .info-icon {
                    cursor: pointer;
                    outline: none;
               }
          }

          .variable-friendlyname {
               span {
                    word-break: break-all;
               }
          }
     }
}

.save-changes-button {
     margin-bottom: 0.3rem;
     padding: 0 1.5rem;

     button {
          min-width: 129px;
          height: 35px;
          color: #ffffff;
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0.18571427px;
          margin-top: 10px;

          &.disabled {
               cursor: default;
               opacity: 1;
               border-radius: 22px;
               background-color: $gray-400;

               &:hover {
                    background-color: $gray-400;
               }
          }
     }
}

.event-native-data-layer .save-changes-button {
     padding: 0;
}

.event-native-content {
     .list-field-buttons {
          margin-left: 10px;
          &.has-error {
               position: relative;
               bottom: 11px;
          }
     }
     label {
          line-height: 21px !important;
     }

     .custom-object-input {
          border: 1px solid #e2e6e9 !important;
          border-radius: 12px !important;
     }
     .item-middle {
          position: relative;
          height: 37px;
     }

     .native-config{
          flex: 0 0 30%;
          max-width: 30%
     }

}

.list-field-buttons {
     height: 37px;
     display: flex;
     align-items: center;
     .btn {
          background-color: transparent !important;
          padding: 0;
          width: 30px;
          height: 30px;

          svg {
               width: 100%;
               height: auto;
               margin: 0;

               &.icon-add {
                    width: 70%;
               }
          }
     }
     .form-check-label {
          margin-top: 5px;
     }
     .label-description {
          margin-top: 5px;
          margin-bottom: 0;
     }
}

.listener-icon-headline {
     width: 30px;
     height: 30px;
     position: relative;
     // top: 9px;
     top: 7px;
     margin-right: 7px;
}

.listener-icon-headline1 {
     width: 30px;
     height: 30px;
     position: relative;
     top: -2px;
     margin-right: 7px;
}

.rounded {
     border-radius: 50px !important;
}

.c-wrapper.v2 {
     .datalayer-variables {
          .variable-name-header {
               padding-left: 21px;
          }

          .variable-name {
               padding: 10px 20px;
               color: #73818f;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;
               letter-spacing: 0.3px;

               .variable-path {
                    margin-left: 5px;
                    width: auto;
               }
          }

          .variable-path {
               svg {
                    height: 11px;
               }
          }

          .variable-status span {
               min-width: inherit;
          }

          .btn-plus {
               color: #73818f;
               padding: 0;

               i {
                    font-weight: 400;
                    margin: 0;
               }
          }
     }

     .css-table-row > div.bubble-message .across-domains {
          border-radius: 12px;
     }

     .send-to-gtm {
          width: 16%;
          position: relative;

          .gtm-wrapper {
               width: fit-content;
               margin: 0 auto;

               &:hover {
                    .block-tooltip {
                         display: block;
                         z-index: 100;
                    }
               }
          }

          .icon-action {
               width: 20px;
               height: 20px;
          }

          .block-tooltip {
               max-height: 24px;
               top: 8px;
               left: 52%;
               transform: translate(-50%, -100%);
               min-width: inherit;
               background-color: #18222b;
               border-radius: 12px;

               .block-tooltip-text {
                    padding: 6px 8px;
                    text-align: center;
                    white-space: nowrap;
                    border-radius: 5px;

                    p {
                         font-size: 10px;
                         font-weight: 500;
                         line-height: 12px;
                         letter-spacing: 0.16666667px;
                    }
               }

               .arrow-tooltip-preview {
                    width: 42px;
                    top: auto;
                    bottom: 7px;
                    left: 50%;
                    transform: translate(-50%, 100%) rotate(180deg);
                    color: #18222b;
               }
          }

          a {
               &.option {
                    .error {
                         color: #f86b6c;
                    }
               }
          }
     }
}

@media screen and (max-width: 1900px) {
     .event-native-content {
          .event-native-row{
               .native-config{
                    flex: 0 0 35%;
                    max-width: 35%
               }
          }
     }
}

@media screen and (min-width: 1500px) {
     .event-native-content {
          .event-native-row{
               .event-native-label{
                    .custom-control-label{
                         white-space: nowrap;
                    }
               }
          }
     }
}