.preview {
     min-height: 100vh;
     padding: 0;
     overflow: hidden !important;
     position: relative;
     z-index: 1057;

     > .modal {
          border-radius: 0;
          background-color: #ffffff;
          top: -50%;
          left: -15%;
          z-index: 1040;
          transform: scale(1) translateX(15%) translateY(50%) translateY(-0.9px);
          transition: all 0.75s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.15s linear;
          display: flex !important;
          flex-flow: column;
     }

     &.minimize {
          min-height: unset;
          > .modal {
               visibility: hidden;
               transform: scale(0) translate(15%, 50%);
               transition: all 0.5s ease;
          }
     }

     .confirm-save-popup {
          .modal-dialog {
               max-width: 637px;
          }

          .show-again {
               display: flex;
               align-items: center;
               justify-content: center;

               label {
                    cursor: pointer;
                    margin: 0;
                    letter-spacing: 0.2px;
               }
          }

          input[type="checkbox"] {
               display: inline-block;
               width: 17px;
               height: 17px;
               border-radius: 50%;
               margin-right: 8px;
               border-color: #c8ced3;
               border: 1px solid #212c36 !important;
               cursor: pointer;

               vertical-align: middle;
               appearance: none;
               -webkit-appearance: none;
               outline: none;

               &:checked {
                    background-image: url("/assets/icons/icon-check.svg");
                    background-repeat: no-repeat;
                    background-position: 51% 45%;
                    border: none;
                    opacity: 1;
                    background-color: #212c36;
                    border: none !important;
               }
          }

          .confirm-save {
               padding: 68px 45px 57px !important;

               h3 {
                    font-family: "Poppins";
                    font-weight: 700;
                    letter-spacing: -0.5px;
                    background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 20px;
               }

               p {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0.65px;
                    word-break: break-word;

                    strong {
                         word-break: break-word;
                    }
               }

               .content {
                    margin-top: 55px;
                    margin-bottom: 48px;
               }

               .actions {
                    margin-top: 24px;
               }

               .c-blue {
                    color: #20a8d8;
               }

               .c-red {
                    font-weight: 500;
                    color: #f86c6b;
               }

               .c-black {
                    font-weight: 500;
                    color: #212c36;
               }

               button:not(.event-toggle) {
                    font-size: 18px;
                    min-width: 395px;
                    margin-bottom: 22px;
                    background-color: #20a8d8;

                    &:hover {
                         background-color: #43b6d7;
                         border-color: #39b2d5;
                    }
               }

               .btn-cancel {
                    text-decoration: underline;
                    font-size: 16px;
                    color: #7f8c9a;
                    cursor: pointer;
                    display: inline-block;

                    &:hover {
                         text-decoration: none;
                    }
               }

               button.btn-save {
                    height: 55px;
                    font-size: 13px;
                    letter-spacing: 0.19px;
                    font-weight: 700;
                    color: #fff;
                    border-radius: 22.5px;
                    padding: 12px 10px 30px;
                    border: none;
                    border-radius: 30px;
                    background-color: #18222b;
               }

               .input-domain input {
                    border-radius: 15px;
               }
          }

          .lottie-animation {
               max-width: 50%;
               margin: 0 auto;
               margin-bottom: 15px;
          }

          svg {
               transform: scale(2) !important;
               max-height: 86px;
          }

          span.foot-note {
               padding: 0 5px;
               color: #7f8c9a;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 400;
               line-height: 23px;
               text-align: center;
          }

          .cannot-access {
               background: rgba(252, 210, 210, 0.35);
               width: 420px;
               margin: 0 auto 25px;
               padding: 32px 20px 15px;
               border-radius: 12px;

               .title {
                    p {
                         color: #73818f;
                         font-size: 15px;
                         font-weight: 400;
                         text-align: center;
                    }
               }

               p {
                    color: #2f353a;
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 23px;
                    text-align: center;
                    margin-bottom: 10px;
                    text-align: left;
               }
          }

          &.begin-test {
               .modal-dialog {
                    max-width: 640px;
               }

               .collapse-row {
                    position: relative;
                    border: 1px solid #d8d8d8;
                    border-radius: 10px;
                    overflow: hidden;
                    background-color: transparent;
                    margin-bottom: 10px;
               }

               .event-toggle {
                    color: #000;
                    background-color: #fff;
                    width: 100%;
                    border: none;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 9px 17px;
                    font-size: 18px;
                    position: relative;
                    border-radius: 15px;

                    &:after {
                         content: "";
                         position: absolute;
                         top: 20px;
                         right: 15px;
                         width: 9px;
                         height: 9px;
                         transform: translateY(-50%) rotate(45deg);
                         border: 2px solid #000;
                         border-top: none;
                         border-left: none;
                         transition: transform 0.3s;
                    }

                    &.show {
                         z-index: 9;
                         padding-bottom: 4px;

                         &::after {
                              transform: translateY(-25%) rotate(225deg);
                         }
                    }
               }

               .collapse-body {
                    position: relative;
                    z-index: 9;
                    padding: 7px 18px 15px;
                    margin-top: -9px;
                    text-align: left;
                    font-size: 16px;
                    line-height: 1.55em;
                    letter-spacing: 0.35px;
                    color: #7f8c9a;
               }

               &.collapsing .collapse-body {
                    z-index: 9;
                    padding-top: 6px;
               }

               .confirm-save .btn {
                    height: 55px;
                    border-radius: 30px;
               }

               .show-again {
                    margin-top: 35px;
               }
          }

          // .modal.fade {
          //      background-color: #00000090;
          // }
     }

     .urlBar-holster {
          &:focus-within {
               box-shadow: 0 0 0 2px #0095ff;
               background-color: #fff;

               .urlBar-lockIcon {
                    display: none;
               }

               .urlBar-inputRender {
                    visibility: hidden;
               }

               &:hover {
                    background-color: #fff;
               }

               .urlBar-textInput {
                    color: #000;
               }

               .urlBar-inputRender,
               .urlBar-textInput {
                    left: 10px;
               }

               .urlBar-inputRender {
                    color: #0000;
               }
          }

          &:hover {
               background-color: #e9ebec;
          }

          background-color: #ffffff;
          border-radius: 40px;
          flex-grow: 1;
          height: 32px;
          margin-left: 6px;
          margin-right: 2px;
          position: relative;
          text-overflow: ellipsis;
          transition: background-color 0.15s;
          white-space: nowrap;
     }

     .urlBar-inputWrapper {
          align-items: center;
          cursor: text;
          display: flex;
          height: 100%;
          overflow-x: hidden;
          position: relative;
          z-index: 2;
          margin: 0;

          .urlBar-lockIcon {
               color: #9e9e9e;
               left: 10px;
               pointer-events: none;
               position: absolute;
               top: calc(50% - 6px);
               z-index: 1;

               svg {
                    display: block;
                    height: 12px;
                    width: 12px;
               }
          }

          .urlBar-inputRender,
          .urlBar-textInput {
               height: 100%;
               left: 28px;
               overflow: hidden;
               position: absolute;
               right: 10px;
               top: 50%;
               width: calc(100% - 38px);
               padding: 5px 0;
               transform: translateY(-50%);
          }

          .urlBar-highlight,
          .urlBar-secondary {
               color: #000;
          }

          .urlBar-textInput {
               background-color: initial;
               caret-color: #000;
               color: #0000;
               z-index: 10;
               background: none;
               border: none;
               font-family: inherit;
               font-size: inherit;
               line-height: inherit;
          }

          .urlBar-inputRender {
               color: #000;
               overflow: hidden;
               user-select: none;
          }
     }

     .preview_main_tooltip {
          .block-tooltip {
               position: absolute;
               z-index: 999;
               top: calc(100% + 10px);
               left: auto;
               right: 0;

               svg {
                    left: auto;
                    right: 6px;
                    top: -12px;
                    transform: rotate(90deg);

                    path {
                         fill: #19222b !important;
                    }
               }
          }

          svg {
               path {
                    fill: #91969b !important;
               }

               rect {
                    fill: transparent !important;
               }
          }
     }
}

.preview_header {
     padding: 10px 40px;

     h5 {
          margin: 0;
          font-weight: 700;
          letter-spacing: 0.9px;
     }

     .preview_header_logo {
          svg.icon:not(.icon-c-s):not(.icon-custom-size) {
               width: 23px;
               height: 28px;
               margin-right: 15px;
          }
     }

     &_action {
          > div {
               margin-left: 20px;
          }

          &_close {
               button {
                    padding: 0.375rem 0.6rem;
               }
          }
     }
}

.preview_header_action {
     > div:not(.preview_header_action_close) {
          svg {
               width: 15px;
               height: 15px;
               margin-right: 12px;
          }

          button {
               font-size: 16px;
               padding: 5px 14px;
          }
     }

     .preview_header_action_close {
          margin-left: 14px;
     }

     .preview_header_action_minimize {
          margin-left: 12px;
     }
}

.preview_main {
     .open-new-tab {
          position: absolute;
          right: 40px;
          cursor: pointer;

          .block-tooltip {
               svg.arrow-preview {
                    right: 2px;
               }

               .block-tooltip-text {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 0;
               }
          }
     }

     .split-view {
          .split-view-container {
               .split-view-view {
                    overflow-y: auto !important;
               }
          }
     }

     .SplitPane {
          height: calc(100% - 56px) !important;
     }

     &_tooltip {
          position: relative;
     }

     &_content {
          display: flex;
          flex-direction: column;
          height: 100%;
          background-color: #c8ced3;
          overflow-x: hidden;

          &:last-child {
               flex: 1;
          }

          &_header {
               padding: 5px 20px;
               color: #fff;
               text-align: center;
               position: relative;
               box-shadow: 0 5px 5px -3px #00000052;

               h4 {
                    margin: 0;
                    letter-spacing: 0.6px;
               }

               .hover-show-tooltip {
                    margin-left: 10px;
                    cursor: pointer;
               }

               &::before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 2px;
                    top: 0;
               }
          }

          &_body {
               width: 100%;
          }
     }

     &_listenlayer {
          &_header {
               background-color: #212c36;

               &::before {
                    right: -1px;
                    background-color: #212c36;
               }
          }

          &_body {
               background-color: #ffffff;
               flex: 1;
               display: flex;
               flex-direction: column;
               overflow: hidden;
               min-height: calc(100vh - 165px);

               .preview-listener-select-wrapper {
                    position: relative;
                    padding: 15px 40px;
                    z-index: 900;

                    p {
                         font-weight: 500;
                         letter-spacing: 0.4px;
                         color: #7f8c9a;
                         margin-top: 5px;
                    }

                    a {
                         text-decoration: underline;

                         &:hover {
                              text-decoration: none;
                         }
                    }
               }

               .preview-events {
                    padding: 0 40px 30px;
                    overflow-y: auto;
                    flex: 1;

                    &::-webkit-scrollbar {
                         width: 8px;
                         background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                         background-color: #adadad;
                         border-top-left-radius: 2px;
                         border-top-right-radius: 2px;
                         border-bottom-left-radius: 2px;
                         border-bottom-right-radius: 2px;
                         transition: 0.5s all;
                    }

                    &::-webkit-scrollbar-track {
                         background: #e3e3e3;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                         background: #555;
                    }

                    .no-event-text {
                         text-align: center;
                         margin-top: 20px;
                         font-weight: 400;
                         letter-spacing: 0.4px;
                         color: #7f8c9a;
                    }

                    p {
                         font-size: 14px;
                         color: #7f8c9a;
                         font-weight: 500;
                    }
               }

               .group-event {
                    margin-bottom: 15px;

                    .group-headline {
                         position: relative;
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         gap: 20px;
                         cursor: pointer;
                         margin-bottom: 15px;

                         .line {
                              display: block;
                              flex: 1;
                              height: 1px;
                              min-width: 150px;
                              background-color: #000;
                         }

                         .icon-toggle {
                              position: relative;
                              display: block;
                              flex: 0 0 10px;

                              &:before {
                                   content: "";
                                   display: block;
                                   position: absolute;
                                   top: 50%;
                                   width: 8px;
                                   height: 8px;
                                   transform: translateY(-75%) rotate(45deg);
                                   border: 2px solid #1e242a;
                                   border-top: none;
                                   border-left: none;
                                   transition: transform 0.3s;
                              }
                         }

                         &.show {
                              .icon-toggle {
                                   &:before {
                                        transform: translateY(-25%) rotate(225deg);
                                   }
                              }
                         }
                    }
               }

               .description-destination-preview {
                    margin-bottom: 10px;
                    span {
                         cursor: pointer;
                    }
               }
          }

          .preview-listener-select {
               min-width: 230px;
               border: none !important;
               z-index: 10;

               .react-select__control {
                    background-color: #d0ecf5;
                    border: 1px solid #d0ecf5;
                    border-radius: 12px;
                    &--menu-is-open {
                         background-color: #fff;
                         border-color: #e2e6e9;
                         border-bottom-left-radius: 0;
                         border-bottom-right-radius: 0;
                         box-shadow: -1px 1px 7px 0 #e2e6e9 !important;
                         div[class*="-placeholder"] {
                              color: #929497 !important;
                         }
     
                         &:before {
                              content: "";
                              display: block;
                              position: absolute;
                              bottom: 0;
                              left: 0;
                              width: 100%;
                              height: 5px;
                              transform: translateY(-100%);
                              background-color: transparent;
                         }
                    }
               }

               // span + div[class*="-control"] {
               //      background-color: #fff;
               //      border-color: #e2e6e9;
               //      border-bottom-left-radius: 0;
               //      border-bottom-right-radius: 0;
               //      box-shadow: -1px 1px 7px 0 #e2e6e9 !important;

               //      div[class*="-placeholder"] {
               //           color: #929497 !important;
               //      }

               //      &:before {
               //           content: "";
               //           display: block;
               //           position: absolute;
               //           bottom: 0;
               //           left: 0;
               //           width: 100%;
               //           height: 5px;
               //           transform: translateY(-100%);
               //           background-color: transparent;
               //      }
               // }

               .react-select__menu {
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 0;
                    border: 1px solid #f0f3f5;
                    border-radius: 0 0 12px 12px;
                    box-shadow: -1px 1px 7px 0 #f0f3f5 !important;
                    padding: 0;
                    overflow: hidden;

                    > div:first-child {
                         padding: 0;

                         &::-webkit-scrollbar-track {
                              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                              border-radius: 5px;
                              background-color: #f5f5f5;
                         }

                         &::-webkit-scrollbar {
                              width: 7px;
                              background-color: #f5f5f5;
                         }

                         &::-webkit-scrollbar-thumb {
                              border-radius: 5px;
                              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                              background-color: #555;
                         }
                    }

                    div[class*="-option"] {
                         padding-left: 18px;
                         cursor: pointer;

                         &:not(:last-child) {
                              border-bottom: 1px solid #dee2e5;
                         }
                    }
               }

               div[class*="-singleValue"] {
                    font-weight: 400;
               }

               div[class*="-multiValue"] {
                    font-weight: 400;
                    padding-left: 7px;
                    padding-bottom: 1px;
                    border-radius: 15px;
                    overflow: hidden;

                    + div:not([class*="-multiValue"]) {
                         margin: 0;
                         width: 0;
                    }
               }

               .option-row {
                    display: flex;
                    align-items: center;
                    padding-left: 0;

                    .icon {
                         height: 18px;
                         margin-right: 10px;
                    }
               }

               &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    width: 7px;
                    height: 7px;
                    transform: translateY(-65%) rotate(45deg);
                    border: 2px solid #212c36;
                    border-top: none;
                    border-left: none;
                    transition: transform 0.3s;
                    pointer-events: none;
               }
          }

          .preview-select-option {
               background-color: #fff;
               padding: 20px 40px 15px;
               button {
                    text-transform: capitalize;
                    font-size: 16px;
               }
               button.outline-dark {
                    background-color: #2f353a;
                    color: #fff;
                    border: none;
               }
          }
     }

     &_website {
          overflow-y: hidden;

          &_header {
               background-color: #18222b;

               &::before {
                    left: -1px;
                    background-color: #18222b;
               }
          }

          &_body {
               background-color: #f0f3f5;
               flex: 1;
               overflow: hidden;

               > div {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
               }
          }
     }

     .preview-event {
          position: relative;
          border: 1px solid rgba(200, 206, 211, 0.35);
          border-radius: 15px;
          background-color: #f0f3f5;

          &.preview-event-child {
               background-color: #ffffff;

               .event-toggle {
                    background-color: #ffffff;
               }
          }

          &.extend {
               z-index: 12;
               &:hover {
                    z-index: 99;
               }
          }

          .action-field {
               z-index: 9;
               min-width: 55px;
               margin-left: 12px;
               display: inline;
               position: relative;

               span {
                    padding: 2px 5px;
                    border-radius: 5px;
                    transition: 0.3s all;
                    cursor: pointer;

                    &:hover {
                         .tooltip-container > svg {
                              color: #3f9cbd;
                         }
                    }

                    &.active .tooltip-container > svg {
                         color: #3f9cbd;
                    }
               }

               .tooltip-container:hover > svg {
                    color: #3f9cbd;
               }

               svg {
                    color: #c6cbd1;
                    max-width: 16px;
               }
          }

          .view-code {
               margin-right: 40px;
               color: #747e8a;
               font-size: 12px;
               text-transform: uppercase;
               font-weight: 500;
               z-index: 9;
               cursor: pointer;
               display: flex;
               align-items: center;
               justify-content: space-between;
               min-width: 100px;
               padding: 4px 8px;
               transition: 0.3s all;
               border-radius: 5px;
               white-space: nowrap;
          }

          .preview-feature {
               padding: 0 20px 20px;

               .preview-feature-content {
                    padding: 15px 0;
                    background-color: #18222b;
                    border-radius: 15px;
               }

               .preview-feature-message {
                    font-weight: 400;
                    color: #fff;
                    padding: 0 20px;
                    margin: 0;
               }

               ul {
                    padding: 0;
                    margin: 0;
                    display: table;
                    width: 100%;

                    li {
                         // padding: 5px 20px;
                         list-style: none;
                         color: #fff;
                         display: table-row-group;
                         align-items: center;
                         position: relative;

                         > div {
                              display: table-cell;
                         }

                         .key {
                              // min-width: 150px;
                              padding-left: 20px;
                              padding-right: 30px;
                              width: 35%;
                         }

                         .value {
                              color: #ffc107;
                              flex: 1;
                              // max-width: 405px;
                              padding-right: 10px;
                              word-break: break-word;

                              > span {
                                   color: #898989;
                              }
                         }

                         &.active {
                              .action-on-field {
                                   visibility: visible;
                                   width: 12%;
                                   max-width: 193px;
                              }
                         }

                         &:hover {
                              background-color: #2c3741;
                              z-index: 1;

                              .action-on-field {
                                   visibility: visible;
                              }
                         }

                         .action-on-field {
                              height: 100%;
                              top: 0;
                              display: flex;
                              align-items: center;
                              visibility: hidden;

                              span {
                                   margin-right: 12px;

                                   &:hover {
                                        color: #1fa7d7;
                                   }

                                   > svg {
                                        width: 16px;
                                        height: 16px;
                                   }
                              }
                         }
                    }
               }

               .ace_editor {
                    width: 100% !important;
                    height: 330px !important;
                    border-radius: 9px;

                    // .ace_scroller,
                    // .ace_gutter {
                    //      top: 15px;
                    // }
               }

               .event-toggle {
                    padding: 10px 20px;
                    border-radius: 15px;

                    &:hover {
                         z-index: 15;
                    }
               }
          }

          &:not(:last-child) {
               margin-bottom: 10px;
          }

          .block-tooltip-text p {
               color: #fff;
          }

          .event-toggle {
               background-color: #f0f3f5;
               width: 100%;
               border: none;
               border-radius: 15px;
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 16px 20px;
               font-size: 18px;
               letter-spacing: 0.3px;
               position: relative;
               z-index: 1;

               &:after {
                    content: "";
                    position: absolute;
                    right: 30px;
                    width: 8px;
                    height: 8px;
                    transform: translateY(-75%) rotate(45deg);
                    top: 50%;
                    border: 2px solid #1e242a;
                    border-top: none;
                    border-left: none;
                    transition: transform 0.3s;
               }

               &.show::after {
                    border-color: #73818f;
                    transform: translateY(-25%) rotate(225deg);
               }

               .overlay-click {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    cursor: pointer;
               }

               &:not(.show) .overlay-click {
                    z-index: 10;
               }

               .event-name {
                    word-break: break-word;
                    margin-right: 40px;

                    &.parent {
                         min-width: 240px;
                         margin-right: 15px;
                    }
               }

               .event-index {
                    color: #a5afb7;
                    font-size: 14px;
               }

               .event-and-tool {
                    display: flex;

                    .action-field {
                         min-width: 62px;
                    }
               }

               &:hover {
                    z-index: 100;
               }

               .badge {
                    color: $black;
                    background-color: #c8ced3;
                    font-weight: 400;
                    border: 1px solid #353e47;
                    border-radius: 20px;
                    padding: 5px 10px;
               }

               &-headline {
                    min-width: 70%;

                    .create-rule-button {
                         z-index: 11;
                         color: #000;
                         background-color: #c6cbd1;
                         text-transform: inherit;

                         &:hover {
                              color: #fff;
                              background-color: #3f9cbd;
                         }
                    }
               }
          }

          .event-meta {
               margin-right: 40px;
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               justify-content: flex-end;
               gap: 5px 10px;
          }

          .listener-name {
               display: inline-flex;
               color: #a5afb7;
               font-size: 14px;
               margin-top: 2px;
          }

          .preview-event .preview-event .preview-event .noChild {
               background-color: #eff3f6;
               border-radius: 15px;

               .event-toggle {
                    background-color: inherit;
               }
          }
     }

     .website-parameter {
          display: flex;
          align-items: center;
          background-color: #f0f3f5;
          width: auto;
          padding: 18px 20px;
          border-bottom: 1px solid #ccc;

          .icon-back,
          .icon-next {
               color: #000;
               font-size: 20px;
               margin-right: 5px;
               display: flex;
               align-items: center;
               border-radius: 25px;
               transition: 0.3s all;
               padding: 5px 7px;
               justify-content: center;
          }

          .icon-back.disable,
          .icon-next.disable {
               color: #ccc;
          }

          .icon-back:hover,
          .icon-next:hover,
          .icon-reload:hover {
               background-color: #ebebeb;
          }

          .icon-reload {
               padding: 7px 8px;
               border-radius: 25px;
               transition: 0.3s all;
               margin-right: 20px;
               min-width: 35px;
               display: flex;
               align-items: center;
               justify-content: center;

               svg {
                    font-size: 27px;
                    width: 20px;
                    height: 18px;
                    color: #606369;
                    font-weight: 500;
                    cursor: pointer;
               }
          }

          // .slug {
          //      width: 80%;
          //      position: relative;
          //      display: flex;
          // }

          // .slug-input {
          //      border-top-right-radius: 15px;
          //      border-bottom-right-radius: 15px;
          //      border-top-left-radius: 15px;
          //      border-bottom-left-radius: 15px;
          //      background-color: #ffffff;
          //      padding: 5px 20px;
          //      border: 1px solid #ffffff;
          //      width: 100%;
          //      outline: none;
          //      min-height: 20px;
          //      font-size: 14px;
          //      font-weight: 400;
          //      font-family: 'Montserrat', sans-serif;
          // }

          #iconClose {
               display: none;
               text-align: center;
               font-size: 18px;
          }
     }

     .account-script-manually {
          h3 {
               margin-bottom: 17px;

               + p {
                    font-size: 20px;
                    line-height: 1.4em;
                    letter-spacing: -0.35px;
                    max-width: 530px;
                    margin: 0 auto 27px;
               }
          }

          .script-code {
               position: relative;
               background-color: #eff3f6;
               border-radius: 10px;
               padding: 22px 18px;
               margin-left: -10px;
               margin-right: -10px;
               margin-bottom: 25px;

               .copy-script {
                    position: absolute;
                    z-index: 9;
                    top: 15px;
                    right: 25px;

                    .icon-copy {
                         color: #828c94;
                    }

                    .btn {
                         background: none;
                         min-width: inherit;
                         padding: 0;
                         height: auto;
                         margin: 0;
                         margin-left: 10px;
                         color: #828c94;

                         &:hover {
                              background: none;
                         }

                         img {
                              width: 23px;
                              height: auto;
                         }
                    }
               }

               .ace_editor {
                    width: 100%;
                    background: none;
               }

               .ace_scrollbar {
                    display: none;
               }
          }
     }

     .confirm-save-popup .account-script-manually .btn-save:not(.event-toggle) {
          font-size: 18px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          background-color: #20a8d8;
     }

     .tooltip-container {
          position: relative;

          .block-tooltip {
               padding: 0;
               min-width: inherit;
               top: calc(100% + 5px);
               left: 50%;
               transform: translateX(-50%);
               background: none !important;

               &.show {
                    display: block;
               }

               .block-tooltip-text {
                    padding: 15px;
                    border-radius: 5px;
                    background-color: #73818e;

                    p:hover {
                         color: #ffc107;
                    }
               }

               p {
                    font-weight: 400;
               }

               svg {
                    &.arrow-preview {
                         top: 9px;
                         left: 50%;
                         transform: translate(-50%, -100%) rotate(90deg) scaleY(-1);

                         path {
                              fill: #73818e !important;
                         }
                    }

                    &.arrow-tooltip-preview {
                         top: 9px;
                         left: 50%;
                         transform: translate(-50%, -100%);
                         max-width: inherit;
                         width: 50px;
                         color: #73818e;
                    }
               }

               .copy-on-field {
                    min-width: 120px;
                    padding: 15px;

                    p {
                         font-size: 12px;
                         letter-spacing: 0.4px;
                         text-transform: uppercase;
                         margin-bottom: 8px;

                         &:last-child {
                              margin-bottom: 0;
                         }

                         &:hover {
                              color: #ffc107;
                         }
                    }
               }
          }

          &.show-on-hover {
               &:hover {
                    .block-tooltip {
                         display: block;
                    }
               }

               .block-tooltip {
                    top: calc(100% + 12px);

                    &:before {
                         content: "";
                         display: block;
                         width: 25px;
                         height: 35px;
                         position: absolute;
                         z-index: 1;
                         top: -30px;
                         left: 50%;
                         transform: translateX(-50%);
                    }
               }

               .block-tooltip-text p {
                    white-space: nowrap;
               }
          }
     }

     .action-on-field {
          .arrow-preview {
               top: 18px;
          }

          > span,
          .copy-on-field > span {
               cursor: pointer;
          }

          .block-tooltip-text {
               white-space: nowrap;
          }

          .tooltip-container .block-tooltip .arrow-tooltip-preview {
               top: 7px;
          }
     }

     .view-code-editor {
          .ace_print-margin-layer .ace_print-margin {
               display: none;
          }
          .ace_scrollbar {
               height: calc(100% - 20px);
               top: 50%;
               right: 9px;
               transform: translateY(-50%);
               background-color: transparent;

               &::-webkit-scrollbar {
                    background-color: transparent;
               }

               &::-webkit-scrollbar-track {
                    background-color: #353e47;
                    border-radius: 10px;
               }

               &::-webkit-scrollbar-thumb {
                    background-color: #67727d;
                    border-radius: 10px;
               }

               &.ace_scrollbar-h {
                    display: none;
               }
          }
     }

     .select-wrap {
          position: relative;
          margin-left: 12px;

          .clear-filter {
               display: none;
               position: absolute;
               z-index: 999;
               top: 50%;
               right: 14px;
               transform: translateY(-50%);
               cursor: pointer;

               svg {
                    width: 12px;
                    height: 12px;
               }
          }

          &.selected {
               .clear-filter {
                    display: block;
               }

               .preview-listener-select:after {
                    display: none;
               }

               &.select-1 {
                    div[class*="-multiValue"] {
                         padding: 0;
                         margin-left: -5px;
                         font-weight: 400;
                         background-color: transparent !important;

                         > div {
                              &:first-child {
                                   font-size: 16px !important;
                              }

                              &:last-child {
                                   display: none;
                              }
                         }
                    }
               }
          }
     }

     .action-field .block-tooltip-text p:not(:last-child) {
          margin-bottom: 5px;
     }
}

svg.inverse {
     transform: rotate(180deg);
}

.preview_main {
     .notice {
          padding: 10px 18px 12px;
          color: #fff;
          border: 1px solid #c8ced3;
          border-top: none;

          h4 {
               margin-bottom: 2px;
               font-size: 16px;
          }

          &.listener-warning {
               .content {
                    max-width: unset;
               }
          }

          .content {
               max-width: 70%;

               > p {
                    font-size: 12px;
                    line-height: 1.4em;
                    letter-spacing: 0.28px;
                    margin-bottom: 6px;
                    font-weight: 400;
                    color: #fff;
               }

               code {
                    color: blanchedalmond;
               }

               .collapse-row {
                    .btn-expand {
                         position: relative;
                         font-size: 12px;
                         color: #fff;
                         display: flex;
                         justify-content: flex-end;
                         margin-right: 16px;

                         &:after {
                              content: "";
                              position: absolute;
                              top: 9px;
                              right: -14px;
                              width: 7px;
                              height: 7px;
                              transform: translateY(-50%) rotate(45deg);
                              border: 2px solid #fff;
                              border-top: none;
                              border-left: none;
                              transition: transform 0.3s;
                         }

                         &.show {
                              z-index: 9;

                              &::after {
                                   transform: translateY(-25%) rotate(225deg);
                              }
                         }
                    }
               }

               .list-events {
                    .event-status {
                         margin-bottom: 5px;

                         svg {
                              width: 20px;
                              height: 20px;
                         }

                         p {
                              font-size: 12px;
                              letter-spacing: 0.28px;
                              font-weight: 400;
                              margin-bottom: 0;
                              margin-left: 6px;
                              color: #fff;
                         }
                    }
               }
          }

          button {
               background-color: #fff;
               color: #212c36;
               font-weight: 700;
               letter-spacing: 0.4px;
               padding: 10px 25px;

               &:hover {
                    background-color: #f5f5f5;
               }
          }
     }

     .notice-warning {
          background-color: #ee4b4a;
     }

     .notice-complete {
          background-color: #4dbd74;
     }

     .the-website-preview {
          position: relative;
          flex: 1;

          iframe {
               height: 100%;
          }

          .content-reload {
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
               text-align: center;
               color: #8a8a8a;
          }
     }

     .reopen-window-message {
          background-color: $orange;

          .content {
               p {
                    color: #000;
                    margin: 0;
               }
          }
     }
}

@media screen and (max-width: 980px) {
     .preview_main {
          .preview-event .event-toggle {
               .event-name {
                    &.parent {
                         min-width: unset;
                    }
               }
          }
     }
}
