.payment-methods {
    max-width: 850px;
    .css-table {
        margin-top: 40px;
        margin-bottom: 40px;
        .css-table-header {
            font-weight: 700;
        }
        .css-table-header,
        .css-table-body .css-table-row {
            display: flex;
            align-items: center;
            > div {
                padding: 10px;
                &.account-name {
                    flex: 0 0 20%;
                    max-width: 20%;
                }
                &.expiration-date {
                    flex: 0 0 20%;
                    max-width: 20%;
                }
                &.card-info {
                    flex: 0 0 35%;
                    max-width: 35%;
                }
                &.action {
                    flex: 0 0 25%;
                    max-width: 25%;
                    text-align: center;
                }
            }
        }
        .payment-hidden {
            height: 40px;
            border-radius: 12px;
            padding: 0 12px;
        }
        .btn {
            color: #f86c6b;
            border: 1px solid #f86c6b !important;
            border-radius: 40px;
            &:hover {
                color: #fff;
                background-color: #f86c6b;
            }
        }
    }
}

.personal-payment-modal {
    .modal-dialog {
        max-width: 628px;
    }
    .modal-body {
        padding: 80px 57px 62px;
    }
    .icon-payment {
        text-align: center;
        margin-bottom: 25px;
    }
    .form-group {
        &.input-card-number {
            width: 62%;
        }
        &.expiration-card {
            width: 20%;
        }
        &.cvc-card {
            width: 14%;
        }
    }
}
