.define-form-categories {
     margin-bottom: 20.5px;

     h1 {
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 0;
          margin-bottom: 3px;
     }

     p {
          color: #121920;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          &.text-estimated {
               font-size: 11px;
               line-height: 13px;
               color: #73818f;
               letter-spacing: 0.2px;
               margin-bottom: 46px;
          }
     }

     .define-form-categories--subtitle {
          color: #2f353a;
          font-size: 16px;
          line-height: 23px;
     }

     ul {
          text-align: left;
          padding-left: 0;
          color: #121920;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 20px;
          li {
               margin-bottom: 5px;
               a {
                    color: inherit;
                    text-decoration: underline;
                    &:hover {
                         text-decoration: none;
                    }
               }
          }
          &.custom-mark {
               list-style: none;
               li {
                    position: relative;
                    padding-left: 15px;
                    &:after {
                         content: "";
                         position: absolute;
                         left: 1px;
                         top: 6px;
                         width: 6px;
                         height: 6px;
                         background-color: #73818f;
                         border-radius: 50%;
                    }
               }
          }
     }

     .btn-success {
          margin-top: 14px !important;
          margin-bottom: 10px !important;
          font-size: 15px;
          line-height: 18px;
          max-width: 200px;
          width: 100%;
          min-height: 44px;
     }

     &.card {
          border-radius: 12px;
          border: 1px solid #f0f3f5 !important;
          box-shadow:
               0px 10px 10px -5px rgba(0, 0, 0, 0.04),
               0px 20px 25px -5px rgba(0, 0, 0, 0.1) !important;
     }

     .card-body {
          padding: 34px 42px 0;
     }

     &--get-started {
          padding: 0 1px;
     }

     &--forms {
          &-cards {
               margin: 27px 0;
               max-height: 370px;
               overflow-y: auto;

               &::-webkit-scrollbar {
                    width: 5px;
               }

               &::-webkit-scrollbar-track {
                    background: #eeeeee;
                    border-left: 2px solid #ffffff;
               }

               &::-webkit-scrollbar-thumb {
                    background: #cccccc;
                    border-left: 2px solid #ffffff;
               }

               &::-webkit-scrollbar-thumb:hover {
                    background: #aaaaaa;
               }

               & + p {
                    margin: 68px 0 36px;
               }
          }

          &-card {
               border-radius: 12px;
               border: 1px solid #f0f3f5;
               background: #ffffff;
               box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
               padding: 12px 24px 12px 14px;

               display: flex;
               align-items: center;
               margin-bottom: 10px;

               img {
                    min-width: 26px;
                    height: 26px;
                    margin-right: 7px;
               }

               h6 {
                    font-family: "Poppins";
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                    margin-bottom: 0;
               }

               &-button {
                    color: #73818f;
                    font-family: "Roboto";
                    font-size: 13px;
                    font-weight: 500;
                    text-decoration: underline;
                    margin-left: auto;

                    &:hover {
                         text-decoration: none;
                         color: #73818f;
                    }
               }
          }
          .custom-mark {
               button {
                    color: #121920;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    display: inline-block;
                    text-transform: none;
                    text-decoration: underline;
                    &:hover {
                         text-decoration: none;
                         color: #121920;
                    }
               }
          }
     }

     &--not-listener {
          .btn-primary {
               padding: 7px 12px;
               margin: 10px 0 44px;
               min-width: 197px;
               svg {
                    margin: 0;
                    margin-right: 4px;
                    width: 13px;
                    position: relative;
                    top: -1px;
               }
          }
     }
}
