//segment dropdown
.no-scope-dropdown {
     .custom-segment-dropdown {
          .box-segment {
               max-width: 100%;
          }
     }
}
.custom-segment-dropdown {
     position: relative;
     min-height: 47px;
     .segment-panel {
          position: absolute;
          z-index: 999;
          right: -15px;
          bottom: -7px;
          transform: translateY(100%);
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 8px;
          width: 540px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     }
     .box-segment {
          max-width: 590px;
     }
     .rule-segment {
          //   &:first-child {
          //        padding-left: 30px;
          //   }
          &-item {
               padding: 0px 15px 0px 0px;
               background: rgba(226, 232, 240, 1);
               border-radius: 999px;
               margin: 5px 12px 5px 0px;
               &-icon {
                    padding: 4px 12px 4px 12px;
                    border-radius: 999px;
                    border: 1px solid rgba(32, 168, 216, 1);
                    background: rgba(233, 246, 251, 1);
               }

               &-level {
                    color: rgba(32, 168, 216, 1);
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
               }

               &-info {
                    p {
                         font-size: 14px;
                    }

                    .icon-close path {
                         fill: #a1a1aa;
                         &:hover {
                              fill: rgba(32, 168, 216, 1);
                         }
                    }
               }
          }
     }

     .btn-add-segments {
          min-width: max-content;
     }

     .no-segment {
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          color: rgba(100, 116, 139, 1);
     }

     .header {
          padding: 16px;
          p {
               font-weight: bold;
               margin-bottom: 0px;
          }
     }
     .text-maximum {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.06px;
          text-align: right;
          color: rgba(115, 129, 143, 1);
          margin-top: 2px;
     }

     .clear-all label {
          color: rgba(32, 168, 216, 1) !important;
          text-decoration: none;
          font-size: 12px;
          cursor: pointer;
     }

     .content {
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          padding: 0px 8px 0px 0px;
     }

     .sidebar-segment {
          width: 140px;
          border-right: 1px solid #ddd;
          padding: 0px 8px 0px 8px;
          background: rgba(248, 249, 250, 1);
          &-item {
               padding: 4px 8px 4px 8px;
               cursor: pointer;
               margin-bottom: 5px;
               &:first-child {
                    margin-top: 12px;
               }

               &.selected {
                    background-color: rgba(209, 213, 219, 1);
                    border-radius: 4px;
               }
          }
     }

     .segments {
          flex: 1;
          padding-left: 8px;
          padding-top: 4px;
     }

     .segment-options-container {
          max-height: 288px;
          min-height: 250px;
          overflow-y: auto;
          padding-right: 8px;
          margin-bottom: 10px;
          &::-webkit-scrollbar {
               width: 5px;
               height: 5px;
               position: relative;
               top: 5px;
          }
          &::-webkit-scrollbar-track {
               border-bottom-left-radius: 9px;
               border-bottom-right-radius: 9px;
          }
          &::-webkit-scrollbar-thumb {
               height: 4px;
               border-radius: 4px;
               background-color: rgba(228, 228, 231, 1);
          }
          .list-content:last-child {
               .popup {
                    top: -15px;
               }
          }
          .list-content:first-child {
               .popup {
                    top: 24px;
               }
          }
     }

     .segment-option {
          padding: 8px;
          border-radius: 4px;
          width: 100%;
          p {
               font-size: 14px;
               font-weight: 400;
               line-height: 20px;
               text-align: left;
               color: rgba(66, 66, 66, 1);
               margin-bottom: 0px;
          }
     }

     .custom-checkbox {
          display: inline-block;
          position: relative;
          padding-left: 24px;
          cursor: pointer;
          font-size: 16px;
          user-select: none;
          margin-right: 10px;
          .custom-control-label {
               &::before {
                    border-radius: 6px;
                    top: 5px;
               }
               &::after {
                    top: 5px;

               }
          }
     }

     .custom-checkbox input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
     }

     .custom-checkbox .checkmark {
          position: absolute;
          top: -5px;
          left: 0;
          height: 16px;
          width: 16px;
          background-color: #eee;
          border: 1px solid #ddd;
          border-radius: 6px;
     }

     .custom-checkbox:hover input ~ .checkmark {
          background-color: #ccc;
     }

     .custom-checkbox input:checked ~ .checkmark {
          background-color: #007bff;
          border-color: #007bff;
     }

     .custom-checkbox .checkmark:after {
          content: '';
          position: absolute;
          display: none;
     }

     .custom-checkbox input:checked ~ .checkmark:after {
          display: block;
     }

     .custom-checkbox .checkmark:after {
          left: 6px;
          top: 2px;
          width: 3px;
          height: 8px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
     }

     .info-icon {
          position: relative;
          padding-bottom: 3px;
          margin-left: 10px;
          margin-right: 8px;
          cursor: pointer;
          &.tippy-box {
               margin-left: 10px;
               max-width: 640px;
          }
     }

     .menu-icon-container {
          position: relative;
     }

     .menu-icon {
          cursor: pointer;
          border-radius: 2px;
          position: relative;
          z-index: 1;
          font-size: 20px;
          svg:hover {
               background-color: #f9f9f9;
               path {
                    fill: rgba(32, 168, 216, 1);
               }
          }
     }

     .popup {
          position: absolute;
          top: 24px;
          right: 12px;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 4px;
          z-index: 10;
          padding: 4px 8px;
          width: 100px;
          &:hover {
               box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
     }

     .popup-item {
          display: flex;
          align-items: center;
          cursor: pointer;
          a {
               font-size: 12px;
               font-weight: 400;
               line-height: 14.06px;
               text-align: left;
               color: rgba(66, 66, 66, 1);
          }
     }

     .view-icon {
          margin-right: 8px;
          color: #007bff;
     }

     .content-popup {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.06px;
     }

     .footer-segment {
          padding: 16px;
     }

     .create-segment {
          color: #007bff;
          cursor: pointer;
     }

     .btn-apply {
          width: 100px;
          //   &.disabled {
          //        background: rgba(228, 228, 231, 1);
          //        color: rgba(161, 161, 170, 1);
          //   }
     }

     .btn-create {
          color: black;
     }

     .spinner-border {
          width: 20px;
          height: 20px;
     }
}
.custom-header-segment {
     .rule-segment {
          &-item {
               padding: 0px 15px 0px 0px;
               background: rgba(226, 232, 240, 1);
               border-radius: 999px;
               margin: 5px 12px 5px 0px;
               &-icon {
                    padding: 3px 5px 2px 5px;
                    border-radius: 999px;
                    border: 1px solid rgba(32, 168, 216, 1);
                    background: rgba(233, 246, 251, 1);
               }

               &-level {
                    color: rgba(32, 168, 216, 1);
                    font-size: 11px;
                    font-weight: 500;
                    text-align: center;
               }

               &-info {
                    p {
                         font-size: 14px;
                    }

                    .icon-close path {
                         fill: #a1a1aa;
                         &:hover {
                              fill: rgba(32, 168, 216, 1);
                         }
                    }
               }
          }
     }
}