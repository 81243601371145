.rule-detail {
     .conditions-group {
          margin-bottom: 10px;

          select.form-control option,
          select.form-select option {
               color: #7f8c9a;
               background: transparent;

               &:hover {
                    color: #20a8d8;
                    background: #f3f5f6;
               }
          }
     }

     .block-tooltip {
          cursor: context-menu;
     }

     .difference-step {
          box-shadow: none !important;
     }

     .icon-trigger {
          width: 16px;
          height: 16px;

          &.icon-action {
               width: 20px;
               height: 20px;
          }
     }

     .css-1rhbuit-multiValue {
          border-radius: 9px;
     }

     .value-no-options-available {
          .no-options-info {
               left: calc(100% + 30px);
               border-radius: 12px;

               svg {
                    &.icon:not(.icon-c-s):not(.icon-custom-size) {
                         top: 9%;
                         left: -9px;
                    }
               }

               span {
                    color: #fff !important;
               }
          }
     }

     .css-4ljt47-MenuList,
     .css-11unzgr,
     .css-cz7poj {
          padding: 0;

          &::-webkit-scrollbar {
               width: 3px;
               border-radius: 12px;
          }

          &::-webkit-scrollbar-track {
               width: 3px;
               background-color: #e2e6e9;
               border-radius: 2px;
               border-radius: 12px;
          }

          &::-webkit-scrollbar-thumb {
               background: #73818f;
               border-radius: 12px;
               border: none;
               min-height: 50px;
               width: 3px;
          }
     }

     .react-select__menu {
          border-radius: 12px;

          div[class$='MenuList'],
          .react-select__menu-list {
               border-radius: 12px;
               padding-top: 0;
               padding-bottom: 0;
          }
     }

     .css-g1d714-ValueContainer,
     .css-2b097c-container .custom-select__placeholder {
          color: hsl(0, 0%, 50%) !important;
     }

     .select-listener-wrapper {
          .select-listener {
               &.css-2b097c-container {
                    border: none !important;
               }

               &.disable-select {
                    &::after {
                         content: none;
                    }
               }
          }
     }

     .cvr-create-new {
          .cvr-step-card {
               &.cvr-step-3 {
                    min-height: 237px;
                    
                    // .is-invalid~.invalid-feedback{
                    //      position: absolute;
                    // }
               }

               &.cvr-step-4 {
                    min-height: 237px;
               }
          }
     }

     .populate-to-fields {
          .css-2b097c-container {
               border: 1px solid #e2e6e9 !important;
          }

          .css-14jk2my-container,
          .react-select--is-disabled {
               background-color: hsl(0, 0%, 95%);
               border-radius: 12px;
               border: 1px solid #e2e6e9 !important;
          }

          .css-1fhf3k1-control {
               border-radius: 12px;
          }

          .react-select--is-disabled .react-select__placeholder {
               color: #2f353a!important;
          }
     }

     .cvr-create-new {
          padding-bottom: 0;
          margin-bottom: 0;
     }

     input,
     select,
     textarea {
          &.form-control {
               border: 1px solid #e2e6e9;
          }
     }

     .cvr-step-1,
     .cvr-step-2,
     .cvr-step-3,
     .cvr-step-4 {
          margin-bottom: 10px;
     }

     .list-rules-and-or {
          .form-control {
               min-height: 37px;
          }

          label {
               font-weight: 300;
          }

          .conditions-match {
               label {
                    font-weight: 400;
                    line-height: 17px;
               }
          }
     }

     p {
          color: #18222b;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
     }

     .accordion {
          .accordion-item {
               p {
                    color: #18222b;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
               }
          }
     }

     .cvr-step-card,
     .cvr-create-new,
     .cvr-step-1,
     .cvr-step-2,
     .cvr-step-3,
     .list-rules-and-or,
     .cvr-step-4 {
          border-radius: 12px;

          // .conditions-match {
          //      flex-wrap: wrap;
          //      gap: 15px;

          //      .select-type,
          //      .select-event-variable,
          //      .input-value-rules {
          //           margin-left: 0;
          //      }
          // }

          .conditions-match {
               .input-value-rules {
                    min-width: 100px;
               }
          }

          .form-group {
               .form-control.is-invalid {
                    overflow: visible;

                    .css-1hwfws3 {
                         padding: 0;
                    }

                    .custom-select__placeholder {
                         margin-left: 0;
                         margin-right: 0;
                    }
               }
          }

          .show-popup-create {
               .form-group {
                    z-index: unset;
               }
          }

          button.px-4.btn-next-step.btn.btn-primary {
               min-width: 196px;
          }

          &.difference-step {
               .card-header {
                    border-radius: 12px;
               }
          }

          .rule-step {
               .btn-edit {
                    border-radius: 12.6px;
                    background: #ffffff;
                    color: #63c2de;
                    font-family: Roboto;
                    font-size: 9.36px;
                    font-weight: 700;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 0.13371427px;
                    padding: 2.5px 10px 1.5px;
               }
          }

          h5 {
               font-family: Roboto;
               font-size: 16px;
               font-weight: 700;
               line-height: 24px;
               margin-bottom: 0;
          }

          .card-header {
               background: #f0f3f5;
               min-height: 47px;
               border-bottom: none;
               border-top-right-radius: 12px;
               border-top-left-radius: 12px;
          }

          .card-body-rules,
          .accordion-body {
               background: #f8f9fa;
               border-bottom-left-radius: 12px;
               border-bottom-right-radius: 12px;
          }

          .accordion-item {
               border-radius: 12px;
               border: 0;
          }

          .card-body-rules {
               .card-header {
                    min-height: 45px;
               }

               &.use-trigger {
                    background-color: #fff;
               }
          }

          .template-data-action {
               .template-content-header {
                    color: #212c36 !important;
                    line-height: 17px;
               }

               .form-group:not(.custom-script) {
                    span {
                         color: #73818f;
                    }
               }

               .offset-md-1 {
                    margin-left: 24px;
               }

               .custom-checkbox {
                    .custom-control-label {
                         &::before,
                         &::after {
                              top: 3px;
                         }
                    }
               }
          }

          textarea {
               &.form-control {
                    &:disabled {
                         background-image: url(/assets/icons/lock-dropdown.svg);
                         background-size: 12px 13px;
                         background-repeat: no-repeat;
                         background-position-y: 50%;
                         background-position-x: calc(100% - 15px);
                         padding-right: 35px;
                    }
               }
          }

          input {
               &.form-control {
                    height: 37px;

                    &:disabled {
                         background-image: url(/assets/icons/lock-dropdown.svg);
                         background-size: 12px 13px;
                         background-repeat: no-repeat;
                         background-position-y: 50%;
                         background-position-x: calc(100% - 15px);
                         padding-right: 35px;
                    }
               }

               // &.form-control[type='text']:disabled {
               //      background-image: url('/assets/icons/lock-dropdown.svg');
               //      background-size: 12px 13px;
               //      background-repeat: no-repeat;
               //      background-position-y: 50%;
               //      background-position-x: 97%;
               // }
          }

          input {
               &.form-control {
                    &.disable-input {
                         background-image: url('/assets/icons/lock-dropdown.svg');
                         background-size: 12px 13px;
                         background-repeat: no-repeat;
                         background-position-y: 50%;
                         background-position-x: 97%;
                    }
               }
          }

          // .input-field-variable{
          //      .input-icons{
          //           width: 305px;
          //      }
          // }
          .input-icons {
               .form-control {
                    padding-right: 30px;
               }
          }

          .form-control {
               border-radius: 12px;
               overflow: hidden;
               color: #212c36;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;

               &.disable-select {
                    background-image: url('/assets/icons/lock-dropdown.svg');
                    background-size: 12px 13px;
                    background-color: #e4e7ea;
               }

               &.is-invalid,
               &.stretch-container.is-invalid {
                    border-color: #f86c6b !important;
               }

               &.not-selected {
                    color: #7f8c9a !important;
               }

               &.description {
                    line-height: 18px;
               }

               &#description {
                    overflow-y: auto;
                    min-height: 37px;
                    resize: vertical;
                    line-height: 18px;

                    &::-webkit-scrollbar {
                         width: 3px;
                    }

                    &::-webkit-scrollbar-track {
                         width: 3px;
                         background-color: #e2e6e9;
                         border-radius: 2px;
                         margin-top: 5px;
                         margin-bottom: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                         background: #73818f;
                         width: 3px;
                         border-radius: 12px;
                         border: none;
                    }
               }

               // &#notificationType{
               //      font-size: 1rem;
               //      font-weight: 300;
               //      color: #000000 !important;
               //      line-height: 1.5;
               // }
          }

          .form-control[type='radio'] {
               width: 17px;
               height: 17px;
               margin-right: 8px;
          }

          .form-control[checked] {
               color: #20a8d8;
          }

          .select-type {
               width: 170px;
               min-width: 170px;

               // &.select-condition .form-control {
               //      width: auto;
               // }

               &.custom-report-select {
                    .form-control {
                         text-overflow: clip;
                         padding-right: 20px;

                         &+div {
                              z-index: 5 !important;
                         }
                    }

                    &.is-invalid{
                         .form-control{
                              border-color: #f86c6b !important;
                         }                       

                         .invalid-feedback {
                              display: block;
                         }

                         + .button-delete-rule {
                              margin-top: 5px;
                         }
                    }
               }

               .form-control {
                    min-height: 37px;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    option {
                         &:disabled {
                              color: #73818f;
                         }
                    }
               }

               &:first-of-type() {
                    margin-left: 0;
               }

               &.consent-block {
                    width: 206px;

                    .react-select__control {
                         cursor: pointer;
                    }
               }
          }

          .select-type,
          .select-event-variable,
          .input-value-rules {
               margin-left: 15px;

               .css-2b097c-container,
               .select-listener.segment {
                    border: 1px solid #e4e7e9 !important;

                    &.is-invalid {
                         border-color: #f86c6b !important;
                    }
               }

               label {
                    color: #73818f !important;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0.5px;
               }
          }

          .select-event-variable {
               width: 100%;
               max-width: 355px;
               min-width: 0;

               .not-selected {
                    div[class*='control'] div[class*='singleValue'] {
                         color: #7f8c9a;
                         margin: 0;
                    }
               }
          }

          .input-value-rules {
               margin-left: 15px;
               max-width: 300px;
               flex: 1;

               input {
                    margin-top: 2px;
                    .none-mt{
                         margin-top: 0;
                    }
               }
          }

          label {
               color: #73818f !important;
               font-size: 14px;
               letter-spacing: 0.5px;
               margin-bottom: 4px;
               font-weight: 400;

               &.advanced-collapse {
                    margin-bottom: 0.5rem;
                    color: #212c36 !important;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
               }
          }

          &.monitoring-step-3 {
               label {
                    margin-bottom: 0;
               }
          }

          .switch-box {
               label {
                    margin-bottom: 0;
               }
          }

          .all-or-any {
               label {
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.086666666px;
               }
          }

          .custom-control-inline {
               margin-top: 5px;

               label {
                    line-height: 24px;
               }
          }

          .checkbox-send-data {
               label {
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.086666666px;
               }
          }
     }

     .conditions-match {
          // margin-top: 15px;
          position: relative;

          &:first-child {
               // margin-top: 20px;
          }
     }

     .conditions-validate {
          .invalid-feedback {
               position: absolute;
               margin-top: 2px;
          }

          .conditions-match {
               &.is-invalid {
                    & ~ .invalid-feedback {
                         margin-top: 0;
                         position: unset;
                    }
               }
          }

          .list-rule-conversion {
               .conditions-match {
                    &.is-invalid {
                         & ~ .invalid-feedback {
                              margin-top: 0;
                              position: unset;
                         }
                    }
               }
               .is-invalid~.invalid-feedback{
                    position: absolute;
               }
          }

          .list-rule-wrapper {
               .conditions-match ~ .invalid-feedback {
                    bottom: 58px;
               }
          }

          .card-body,
          .list-rule-wrapper > div {
               > .invalid-feedback {
                    position: relative;
                    margin: 0 0 9px;
               }
          }

          div[class$='container'],
          .conditions-match {
               &.is-invalid {
                    margin-bottom: 0;

                    & + .invalid-feedback {
                         margin-top: 2px;
                    }
               }
          }
     }

     div[class$='container'] {
          border-radius: 12px;
          min-height: 37px;

          div[class$='-menu'].react-select__menu {
               overflow: hidden !important;

               &:has(.no-options) {
                    overflow: unset !important;
               }
          }

          div[class*='MenuList'],
          div[class$='control'] > div[class*='css']:nth-of-type(2) > div[class*='css'],
          div[class$='-menu'] > div[class*='css'],
          .css-11unzgr,
          .css-1qmqbrx,
          .css-ingr37,
          .css-cz7poj {
               &::-webkit-scrollbar {
                    width: 3px;
                    border-radius: 12px;
               }

               &::-webkit-scrollbar-track {
                    width: 3px;
                    background-color: #e2e6e9;
                    border-radius: 2px;
                    border-radius: 12px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #73818f;
                    border-radius: 12px;
                    border: none;
                    min-height: 50px;
                    width: 3px;
               }
          }

          div[class$='-option'] {
               font-size: 14px;
               color: #7f8c9a;
               letter-spacing: 0.2px;
               cursor: pointer;
               word-wrap: break-word;

               &.react-select__option,
               &:hover {
                    color: #20a8d8;
                    background-color: #f3f5f6;
               }
          }

          &.disable-select {
               overflow: hidden;
               background-color: #e4e7ea;
               border: 1px solid #e4e7e9 !important;

               .css-1fhf3k1-control,
               .react-select__control {
                    background-image: url('/assets/icons/lock-dropdown.svg');
                    background-size: 12px 13px;
                    background-color: #e4e7ea;
               }
          }

          .react-select__placeholder,
          .custom-select__placeholder {
               color: grey !important;
          }
     }

     .css-2b097c-container {
          border: 1px solid #e2e6e9 !important;

          &.is-invalid {
               border: 1px solid #f86c6b !important;
          }
     }

     .css-yk16xz-control,
     .css-1pahdxg-control,
     .css-1fhf3k1-control,
     .react-select__control {
          border-radius: 12px;
          border: 1px dashed #e2e6e9;
          overflow: hidden;
          min-height: 34px !important;

          .css-g1d714-ValueContainer,
          .react-select__value-container {
               // padding-top: 5px !important;
               padding-left: 8px;
               padding-right: 12px;
          }
     }

     .monitoring-step-3 {
          .css-yk16xz-control,
          .css-1pahdxg-control {
               .css-g1d714-ValueContainer {
                    padding-top: 0px !important;
               }
          }

          form > .form-group {
               z-index: auto;
          }
     }

     .icon:not(.icon-c-s):not(.icon-custom-size) {
          &.icon-delete {
               height: 30px;
               width: 30px;
          }

          &.icon-add {
               width: 21px;
               height: 21px;
          }

          &.icon-add-gray {
               width: 11px;
               height: 11px;
          }
     }

     .field-button-add-delete {
          height: 37px;

          button {
               padding: 0;
          }

          svg {
               margin: 0;
          }
     }

     .button-new-rule {
          margin-left: 20px;
          min-height: 37px;

          .btn-add-or-rule,
          .btn-all-or-any {
               min-width: 37px;
               border-radius: 12px;
               background: #ffffff;
               color: #000000;
               font-family: Roboto;
               font-size: 10px;
               font-weight: 700;
               line-height: 17px;
               text-align: center;
               text-transform: uppercase;
               letter-spacing: 0.14285713px;
               padding: 2px 0;
               border: 1px solid #e4e7e9 !important;
               margin: 0;
               margin-right: 5px;
               cursor: pointer;

               &.active {
                    background: #212c36;
                    color: #ffffff;
               }
          }

          .btn-all-or-any {
               background: #f0f3f5;

               &.active {
                    background: #20a8d8;
                    color: #ffffff;
               }
          }

          .icon-delete {
               position: absolute;
               bottom: 4px;
               cursor: pointer;
          }
     }

     .button-delete-rule {
          // height: 37px;
          display: flex;
          align-items: center;
          margin-top: 20px;
          margin-left: 10px;
          margin-bottom: 3px;
     }

     .remove-group {
          min-width: 102px;
          border-radius: 12.6px;
          background: #e0e0e0;
          padding: 5px 8px 4px 20px;
          color: #73818f;
          font-family: Roboto;
          font-size: 9.36px;
          font-weight: 700;
          line-height: 11px;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0.13371427px;
          margin: 0;
          position: relative;

          &::before {
               content: '';
               width: 11px;
               height: 11px;
               position: absolute;
               left: 7.4px;
               top: 50%;
               transform: translateY(-44%);
               background-image: url(/assets/icons/remove-icon-group.svg);
               background-repeat: no-repeat;
          }
     }

     .field-input-variable {
          .input-icons {
               max-width: 309px;

               input {
                    // text-overflow: ellipsis;
               }
          }
     }

     .error-field-general {
          span {
               margin-bottom: calc(16.8px + 0.25rem);
          }
     }

     .invalid-right-0 {
          .input-icons {
               .input-content {
                    &.form-control {
                         &.is-invalid {
                              background-position: right calc(10px) center;
                         }
                    }
               }
          }
     }

     .input-icons {
          position: relative;
          margin-bottom: 0;

          button {
               margin: 0;
               position: absolute;
               right: 0;
               top: 50%;
               transform: translateY(-50%);

               svg {
                    margin: 0;
               }
          }

          .insert-variable-dropdown {
               width: 100%;

               ul {
                    padding-left: 20px;
                    width: 100%;
                    border-radius: 12px;
                    top: 45px;
                    border: 1px solid #e4e7e9 !important;
                    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
               }

               .value-no-options-available {
                    svg {
                         right: -23px;
                         top: 36%;
                    }
               }
          }

          .icon:not(.icon-c-s):not(.icon-custom-size) {
               height: 13px;
               width: 13px;
          }

          svg {
               position: absolute;
               right: 12px;
               top: 50%;
               transform: translateY(-50%);
          }

          &.input-icons-custom {
               svg {
                    left: -20px;
                    top: 44%;
               }

               label {
                    text-decoration: underline;
                    color: #73818f;
                    margin-bottom: 0;
               }
          }

          &.input-icons-errors {
               .form-control.is-invalid.input-content {
                    background-position: right calc(2em + 0.1875rem) center;
                    padding-right: calc(2.65em + 0.75rem) !important;
               }

               &.input-icons-field {
                    svg {
                         top: 50%;
                    }
               }
          }

          .input-content {
               &.form-control {
                    border: 1px solid #e4e7e9;
                    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important;

                    &.input-icon-child {
                         border-radius: 12px 12px 0 0;
                    }

                    &.is-invalid {
                         background-position: right calc(2em + 0.1875rem) center;
                    }
               }
          }
     }

     .parameter-field {
          .input-icons {
               margin-bottom: 0px;

               &.input-icons-errors {
                    svg {
                         top: 50%;
                    }
               }

               .insert-variable-dropdown {
                    width: auto;
               }
          }

          .template-custom-param-col {
               padding: 0 0 0 15px;

               .icon-delete {
                    margin-left: -4px;
               }

               .add-row {
                    margin-top: 5px;
               }
          }

          .field-parameter {
               width: auto;
          }
          .select-react-customize{
               .select-wrapper {
                    .stretch-container{
                         background-color: transparent;
                    }
               }
          }
     }

     .custom-script {
          .action-custom-html {
               color: #73818f;
          }

          .input-icons {
               position: relative;

               .icon:not(.icon-c-s):not(.icon-custom-size) {
                    height: 13px;
                    width: 13px;
               }

               button {
                    left: -20px;
               }

               .insert-variable-dropdown {
                    ul {
                         width: 380px;
                         top: 18px;
                         border-radius: 15px;
                    }
               }

               &.input-icons-custom {
                    svg {
                         top: -2px;
                         left: 0;
                         transform: none;
                    }

                    a {
                         text-decoration: underline;
                         color: #73818f;
                    }

                    .insert-variable-dropdown {
                         min-width: 309px;
                         right: 0;
                         left: auto;
                         top: 23px;

                         ul {
                              padding: 0;
                              overflow-x: hidden;
                              position: initial;
                              width: 100%;
                              border: none !important;
                              margin-bottom: 0;
                              border-radius: 12px;
                              box-shadow: none !important;
                              max-height: 280px;
                              margin-top: 0;

                              &.have-scroll {
                                   width: calc(100% - 5px);

                                   li {
                                        padding-right: 9px;
                                   }
                              }

                              &.script-custom-variable {
                                   min-width: auto;
                              }
                         }
                    }

                    .button-insert-variable {
                         text-align: right;
                         position: relative;
                         top: auto;
                         right: auto;
                         transform: none;
                         padding: 0 0 0 20px;
                         height: auto;
                         cursor: pointer;

                         .custom-script-label {
                              margin: 0;
                              text-decoration: none;
                              cursor: pointer;

                              &:hover {
                                   text-decoration: underline;
                              }
                         }

                         svg {
                              top: 46%;
                              transform: translateY(-50%);
                         }

                         label {
                              margin-top: 0px !important;
                         }
                    }

                    .type-variable {
                         svg {
                              top: 50%;
                              transform: translateY(-50%);
                              left: auto;
                         }

                         strong {
                              margin-right: 0px;
                         }
                    }
               }
          }

          textarea {
               &.form-control {
                    background: #18222b;
                    color: #fff !important;
                    padding-left: 40px;
                    border: 0px;
               }
          }

          .text-area-wrapper {
               position: relative;
               overflow: hidden;
               border-radius: 12px;

               &.is-invalid {
                    border: 2px solid red;
                    background: #18222b;
               }
          }

          textarea,
          .numbers {
               margin: 0;
               padding: 10px 0;
               height: 200px;
               border-radius: 0;
               // resize: none;
               outline: none;

               &:focus-visible {
                    outline: none;
               }
          }

          .numbers {
               display: flex;
               flex-direction: column;

               text-align: right;
               box-shadow: none;
               position: absolute;
               border: none;
               background: #18222b;
               color: #73818f !important;
               padding: 10px;
               width: 1.5rem;
               left: 3px;
               border-radius: 12px;

               div {
                    width: 1.5rem;
                    text-align: left;
               }
          }
     }

     .select-react-customize {
          min-height: 37px;

          &.is-invalid {
               .select-wrapper {
                    .stretch-container {
                         border: 1px solid #f86c6b !important;
                    }

                    input.form-control{
                         border: 1px solid #f86c6b !important;
                    }
               }
          }

          .select-wrapper {
               .stretch-container {
                    min-height: 37px;
                    border: 1px solid #e2e6e9 !important;
                    padding: 9px 30px 6px 10px;
                    // text-overflow: ellipsis;
                    white-space: nowrap;
                    min-width: 205px;
                    // background-color: transparent;
               }

               .overflow-drag {
                    overflow: auto;
                    display: block;

                    &::-webkit-scrollbar {
                         display: none;
                    }
               }

               input {
                    border: 1px solid #e2e6e9 !important;
               }

               .css-b8ldur-Input,
               .css-1g6gooi,
               .custom-select__input-container {
                    input {
                         border: none !important;
                    }
               }

               div[class$='-container'] {
                    overflow: hidden;
               }
          }
     }

     h6 {
          margin-bottom: 0;
          color: #212c36;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
     }

     .btn-add-row {
          padding-left: 0;
          display: flex;
          align-items: center;

          svg {
               margin: 0;
          }

          label {
               cursor: pointer;
               font-size: 13px;
               margin-top: 3px;
          }
     }

     label {
          font-family: Roboto;
          font-size: 13px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0.086666666px;

          &.add-row {
               color: #73818f !important;
               font-size: 13px !important;
               font-weight: 700 !important;
               line-height: 16px;
               text-transform: uppercase !important;
               letter-spacing: 0.18571427px;
               margin-left: 7px !important;
               margin-bottom: 0 !important;
               white-space: nowrap;
               text-decoration: none !important;
          }
     }

     .form-edit-lookup-title {
          h1 {
               white-space: initial;
               line-height: 40px;
               margin-top: -5px;
          }
     }

     .form-edit-lookup-button {
          .form-edit-lookup-button-child button {
               margin-right: 1rem;
          }
     }

     &.engagement-rule {
          .conditions-group .conditions-match {
               .select-type {
                    &.fix-width {
                         max-width: 200px;

                         select {
                              width: 100%;
                         }
                    }
               }
          }

          .cvr-create-new {
               .accordion-body {
                    background-color: #ffffff;
               }
          }
     }

     .actions-wrapper {
          button {
               padding: 0;

               + button {
                    margin-left: 5px;
               }
          }

          .button-group {
               display: flex;
               gap: 5px;
          }
     }

     .consent-privacy {
          .button-group {
               .block-tooltip {
                    top: 12px;
               }
          }

          .block-tooltip {
               .block-tooltip-text {
                    border-top-left-radius: 0;

                    &::-webkit-scrollbar {
                         width: 5px;
                    }
               }
          }

          .tooltip-country {
               height: 37px;
               margin-right: 5px;

               .block-tooltip {
                    top: 15px;
                    left: 26px;
               }
          }

          .custom-checkbox .custom-control-label {
               line-height: 17px;
          }

          .table-region label {
               line-height: 19px;
          }
     }

     .variable-field {
          &-group {
               display: flex;
               align-items: flex-start;
          }

          &-value {
               flex: 1;
          }

          &-button {
               position: relative;
               top: 31px;
          }
     }

     .use-trigger {
          .select-trigger {
               margin-bottom: 15px;
          }

          .select-react-customize {
               width: 309px;
          }
     }

     .cvr-create-new-dataAction {
          .insert-variable-dropdown ul {
               max-height: 380px;
          }

          .select-insert-variable {
               &.select-event-items {
                    .insert-variable-dropdown {
                         max-width: 309px;
                    }
               }

               .insert-variable-dropdown {
                    min-width: 309px;
                    top: 0px;

                    ul {
                         padding: 0;
                         overflow-x: hidden;
                         position: initial;
                         width: 100%;
                         border: none !important;
                         margin-bottom: 0;
                         border-radius: 12px;
                         box-shadow: none !important;
                         max-height: 280px;
                         margin-top: 0;

                         &.have-scroll {
                              width: calc(100% - 5px);
                         }
                    }

                    li {
                         padding: 5px 12px 5px 17px;
                    }

                    .variable-filter {
                         padding: 10px 12px 10px 17px;
                    }
               }

               &.select-event-items {
                    .insert-variable-dropdown {
                         ul {
                              max-width: 309px;
                              right: auto;
                         }
                    }
               }
          }
     }
}

.rule-detail {
     .cvr-step-2 {
          .card-header {
               h5 {
                    max-width: 900px;
               }

               span {
                    max-width: 500px;
               }
          }

          .card-body {
               .select-wrapper {
                    .form-control {
                         padding-right: 35px !important;
                    }
               }

               .form-control {
                    padding-right: 35px;

                    &.is-invalid {
                         padding-right: 35px !important;
                    }

                    &#numberOfDays {
                         padding-right: 12px;
                    }
               }

               input:not(:is(.input-content, .custom-select__input)) {
                    padding-right: 12px !important;
               }

               .size-input-icon {
                    .form-control {
                         max-width: 309px;
                    }
               }
          }
     }

     .monitoring-rule {
          .form-control {
               min-height: 37px;
          }
     }

     .cvr-step-4 {
          div[class*='col'] {
               padding-left: 15px;
               padding-right: 0px;
          }
     }

     .lookup-table .table-body {
          .event-variable-value {
               .option {
                    max-width: 300px;
               }
          }
     }

     .input-icons {
          li {
               min-height: 38px;

               &.variable-filter-bottom {
                    height: 0px;
                    min-height: auto;
               }
          }

          .type-variable {
               position: relative;
               padding: 0;

               svg {
                    right: 15px;
               }

               svg {
                    &.icon:not(.icon-c-s):not(.icon-custom-size) {
                         height: 8px;
                         width: 8px;
                    }
               }
          }

          &.insert-variable-trigger {
               &.input-icon-child {
                    .input-content {
                         border-radius: 12px 12px 0 0;
                    }
               }

               .insert-variable-dropdown {
                    top: 45px;
               }
          }
     }

     .triggers-multiselect {
          .input-field-wrapper {
               div[class$='container'] {
                    padding: 1px 3px 0 0;
               }

               .react-select-container {
                    border: 1px solid #e2e6e9;
               }
          }

          .react-select {
               &__control {
                    overflow-y: auto;
               }
               &__multi-value {
                    border: 1px solid #b4b4b4;
               }
               &__multi-value__remove:hover {
                    background-color: unset;
                    color: unset;
               }
          }

          .css-1pahdxg-control,
          .css-yk16xz-control,
          .react-select__control {
               &::-webkit-scrollbar {
                    width: 3px;
               }

               &::-webkit-scrollbar-track {
                    width: 3px;
                    background-color: #e2e6e9;
                    border-radius: 2px;
                    margin-top: 2px;
                    margin-bottom: 2px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #73818f;
                    width: 3px;
                    border-radius: 12px;
                    border: none;
               }
          }
     }

     div[class$='container'] {
          &.insert-variable-container {
               min-height: 25px;
          }
     }

     .button-insert-variable + .insert-variable-dropdown {
          li:not(.variable-filter) span {
               color: #7f8c9a;

               &:hover {
                    color: #20a8d8;
                    background-color: #f3f5f6;
               }
          }
     }

     div[class*='col'].variable-field-group {
          padding-right: 15px;

          &:has(.variable-field-button) {
               padding-right: 0;
          }

          .insert-variable-field {
               .insert-variable-dropdown {
                    position: absolute;
                    top: 100%;
                    right: 0;

                    ul.value-no-options-available {
                         left: auto;
                         right: 0;
                         top: 100%;
                         transform: none;
                         // max-height: 380px;
                         padding-top: 0;
                    }
               }
          }

          textarea {
               resize: vertical;
          }
     }
}

.subscriber .cvr-create-new-dataAction {
     .input-icons {
          .insert-variable-dropdown {
               margin-top: -1px;
               list-style: none;
               position: absolute;
               padding: 0px;
               z-index: 99;
               border: 1px solid #e4e7ea !important;
               box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
               max-height: 280px;
               background-color: #fff;
               width: 100%;
               border-radius: 12px;
               overflow: hidden;
               margin-bottom: 1rem;
               min-width: 280px;
               right: 0;
          }

          ul {
               padding: 0;
               overflow-x: hidden;
               position: initial;
               width: 100%;
               border: none !important;
               margin-bottom: 0;
               border-radius: 12px;
               box-shadow: none !important;
               max-height: 280px;
               margin-top: 0;

               &.have-scroll {
                    width: calc(100% - 5px);

                    li {
                         padding-right: 9px;
                    }
               }

               &::-webkit-scrollbar {
                    width: 3px;
               }

               &::-webkit-scrollbar-track {
                    width: 3px;
                    background-color: #e2e6e9;
                    border-radius: 2px;
                    margin-top: 8px;
                    margin-bottom: 8px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #73818f;
                    width: 3px;
                    border-radius: 12px;
                    border: none;
                    min-height: 50px;
               }

               // &::-webkit-scrollbar-track-piece:end {
               //      margin-bottom: 10px;
               // }

               // &::-webkit-scrollbar-track-piece:start {
               //      margin-top: 10px;
               // }

               li {
                    padding: 5px 17px 5px 17px;

                    &:hover {
                         background: #f3f5f6;

                         span {
                              color: #20a8d8 !important;
                         }
                    }

                    strong {
                         color: #212c36;
                    }

                    &.variable-filter {
                         background: #fff;
                         padding-bottom: 10px;
                         padding-top: 10px;
                         z-index: 10;
                    }
               }
          }

          .insert-variable-dropdown ul li {
               &.border-bottom {
                    padding-top: 5px;
               }
          }
     }
}

@media screen and (max-width: 1674px) {
     .rule-detail {
          .form-edit-lookup-button {
               flex-direction: row;
               flex-wrap: wrap;

               .form-edit-lookup-button-child {
                    margin-right: 0;
                    margin-bottom: 1rem;
               }
          }

          .add-row-button {
               flex-direction: column;
               justify-content: flex-start;
               align-items: flex-start !important;

               .add-row-button-child {
                    margin-bottom: 1rem;
               }
          }

          .form-edit-lookup-title {
               h1 {
                    font-size: 30px;
                    line-height: 35px;
               }
          }
     }
}
