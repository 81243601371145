.table-wrapper {
    overflow-x: auto;

    &.user-source {
        overflow: visible !important;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        height: 7px;
        width: 80%;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a5a5a5;
    }

    &[data-rbd-scroll-container-context-id] {
        thead {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 9;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

    select {
        &::-webkit-scrollbar-track {
            box-shadow: none;
            border-radius: 5px;
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #c8ced3;
        }
    }
}

.box-edit-consent-rule {
    background-color: #f0f3f5;
    box-shadow: unset !important;

    span {
        text-decoration: underline;
        cursor: pointer;
    }

    .card-body {
        padding: 0.75rem;
    }
}

.user-source.lookup-table .table-body td.btn-remove-row {
    right: 15px;
}

.form-edit-lookup {
    padding-bottom: 40px;

    &.ecommerce {
        .table-wrapper {
            thead {
                tr td:not(:first-child) {
                    border-left: none;
                }
            }

            .table td {
                border-left: 1px solid #e4e7ea;
            }

            .event-variable-value,
            .custom-variable-value {
                padding: 9.5px 12px;

                span {
                    display: unset;
                    position: unset;
                    padding: 0;
                    background-color: #fff;
                }
            }

        }

        .table-body {
            &:not(.conditions-row) {
                &:hover {
                    td:first-child {
                        span {
                            display: unset;
                        }
                    }
                }
            }
        }
    }

    .message-errors {
        ul {
            padding-left: 15px;
        }
    }

    .form-edit-lookup-row {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        margin-bottom: 10px;

        .form-edit-lookup-title {
            flex: auto;
        }

        .form-edit-lookup-button {
            flex: 1;
            white-space: nowrap;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            button {
                height: fit-content;
                margin-right: 15px;
            }
        }
    }

    .table-wrapper .table-body td .form-control {
        height: 37px;
    }
}

.lookup-table {

    // border: 1px solid #e4e7ea;
    thead {
        &.sticky {
            position: relative;
            z-index: 100;
        }
    }

    &.lookup-table-sticky {
        display: none;
        position: fixed;
        top: 57px;
        z-index: 5;
        overflow-x: hidden;

        .table-header {
            border-bottom: 1px solid #e4e7ea;
        }
    }

    .tr {
        display: table-row-group;
    }

    .rule-flow {
        td {
            border-top: 0;
            background-color: #73818f;
            color: #fff;
            padding: 0.5rem 0.75rem;

            &:first-child {
                background-color: #18222b;
                position: relative;
                border-top-left-radius: 12px;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    border-top: 19px solid #73818f;
                    border-bottom: 18px solid #73818f;
                    border-left: 10px solid #18222b;
                }
            }

            &:last-child {
                // border-right: 1px solid #e4e7ea;
                border-top-right-radius: 12px;
            }
        }
    }

    .table-header {
        background-color: #fff;

        .user-source-header-item {
            border-right: 1px solid #e4e7ea;
            font-weight: 500;
            font-size: 16px;

            span {
                line-height: 1;
                display: block;
            }
        }

        td {
            &.event-header {
                min-width: 175px;
                max-width: 175px;
            }

            &.variable-header {
                min-width: 250px;
                // max-width: 250px;
            }

            min-width: 250px;

            // max-width: 250px;
            &.has-condition {
                position: relative;
                padding-left: 40px;
                padding-right: 40px;

                &:first-child {
                    padding-left: 0.75rem;
                }
            }

            label {
                color: #2f353a !important;
            }

            .conditions {
                position: absolute;
                width: 60px;
                height: 28px !important;
                padding: 0 18px 0 9px;
                top: 50%;
                transform: translateY(-50%);
                right: -28px;
                z-index: 2;
                background-color: #f0f3f5;
                color: #2b363f;
                background-position-x: calc(100% - 9px);
            }

            label {
                display: block;
                font-weight: 600;
                margin-bottom: 0;
                font-size: 80%;
                color: #7d8a97;
            }

            span {
                display: inline-block;
                max-width: 100%;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                -ms-text-overflow: ellipsis;
                text-overflow: ellipsis;
                color: #2b363f;
                white-space: nowrap;
                line-height: 1.8rem;

                &.text-danger {
                    line-height: 1.1;
                }
            }

            &:first-child {
                border-left: 1px solid #e4e7ea;
            }

            &:last-child {
                border-right: 1px solid #e4e7ea;
            }
        }
    }

    .table-body {
        position: relative;

        td.none-conditions {
            height: 35px;
            background-color: #f0f3f5;
        }

        &.isDragging {
            display: inline-table;

            td {
                &:first-child {
                    border-left: 1px solid #e4e7ea;

                    span {
                        display: inline-flex;
                    }
                }
            }
        }

        &.block {
            td {

                select,
                input {
                    background-color: #d0d0d0;
                }
            }
        }

        td {
            border-bottom: 1px solid #e4e7ea;
            padding: 0;

            &:first-child {
                border-left: 1px solid #e4e7ea;
            }

            &:not(first-child) {
                border-right: 1px solid #e4e7ea;
            }

            &:first-child {
                &.event-variable-value-ellipsis {
                    span {
                        visibility: hidden;
                        position: absolute;
                        background-color: #d0d0d0;
                        height: 100%;
                        vertical-align: middle;
                        padding: 0 2px;
                        display: flex;
                        align-items: center;
                    }

                    select,
                    input {
                        transition: all 0.3s ease;
                    }
                }
            }

            &.event-variable-value.currency select {
                padding-right: 50px;

                .option {
                    max-width: 500px;
                }
            }

            select {
                border: 1px solid #eff1f3;
                border: none;

                &.event-conditions,
                &.variable-conditions {
                    background-color: #f0f3f5;
                    color: #2b363f;
                }

                background-color: #fff;
            }

            input {
                background-color: #fff;
                outline: none;
                border: none;
                padding-right: 15px;
                overflow: hidden;

                &:focus {
                    background-color: #fff;
                }
            }

            &.btn-remove-row {
                position: absolute;
                border: none;
                height: auto;
                right: 33px;
                visibility: hidden;
                vertical-align: middle;
                top: 50%;
                transform: translateY(-50%);

                i {
                    font-size: 20px;
                    cursor: pointer;
                    margin-top: 5px;
                }
            }

            .form-control:disabled {
                background-image: url(/assets/icons/lock-dropdown.svg);
                background-size: 12px 13px;
                background-repeat: no-repeat;
                background-position-y: 50%;
                background-position-x: calc(100% - 15px);
                padding-right: 35px;
                cursor: auto;
            }
        }

        &:hover {
            &>.btn-remove-row {
                visibility: visible;

                &.icon-close-hidden {
                    display: none !important;
                }
            }
        }

        &:not(.conditions-row) {
            &:hover {
                td {
                    &:nth-last-child(2) {
                        .insert-variable {
                            right: 10px;
                        }
                    }

                    &:first-child {
                        span {
                            visibility: visible;
                        }
                    }
                }
            }
        }

        &.conditions-row {
            td {
                border-top: 0;
                border-bottom: 0;

                &.custom-variable-conditions {
                    background-color: #c8ced3;
                }

                select {
                    border-bottom: none;
                }

                &.custom-variable-conditions {
                    border-bottom: 1px solid #e4e7ea;
                }
            }
        }

        .event-variable-value {
            * {
                color: #2b363f;
            }

            position: relative;

            &.variable-column {
                &>input {
                    &:focus {
                        padding-right: 78px;

                        &+.regex-group-wrapper {
                            display: block;
                        }
                    }
                }

                // &:hover {
                //     &>input {
                //         padding-right: 78px;

                //         &+.regex-group-wrapper {
                //             display: block;
                //         }
                //     }
                // }
            }

            .regex-group-wrapper {
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                display: none;
                background-color: #fff;
                padding-left: 6px;

                &.checked,
                &:hover {
                    display: block;
                }

                input {
                    width: 10px;
                    margin-left: 0px;
                    cursor: pointer;
                }

                label {
                    font-size: 10px;
                    vertical-align: middle;
                    margin-left: 15px;
                    margin-bottom: 0;
                    cursor: pointer;
                }
            }
        }

        .custom-variable-value {
            position: relative;

            .select-wrapper {
                .form-control {
                    border-radius: 0;
                    border: 0 !important;

                    .disable-select {
                        background-color: #d0d0d0;
                    }
                }
            }

            select {
                &.form-control {
                    padding-right: 20px;
                }
            }

            * {
                color: #2faedb !important;
            }

            .insert-variable {
                position: absolute;
                overflow: hidden;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                white-space: nowrap;
                cursor: pointer;

                &>.icon {
                    display: none;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                }

            }

            &:hover {
                &>.insert-variable {
                    &>.icon {
                        display: block;
                    }
                }
            }

            .form-control {
                padding-right: 50px !important;
            }
        }

        &:last-child {
            td {
                &:first-child {
                    span {
                        border-bottom: none;
                    }
                }
            }
        }

        // &.hasError {
        //     input, select {
        //         background: #fff5f5;
        //     }
        // }
    }

    &.table-only-view {
        .table-body td .form-control:disabled {
            background-image: none;
            padding-right: 0;
        }

        .block-tooltip.condition-tooltip {
            display: none;
            bottom: 15px;
            left: 0;
            max-width: 100%;
            z-index: 10;
            font-size: 13px;

            .block-tooltip-text {
                padding: 8px 12px;
                border-radius: 8px;
                border-top-left-radius: 8px;
                color: #ffffff;

                &::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    border-width: 6px;
                    border-style: solid;
                    border-color: #000000 transparent transparent transparent;
                }
            }
        }

        input.form-control:hover {
            ~.block-tooltip.condition-tooltip {
                display: block;
            }
        }
    }
}

.regex-wrapper,
.import-export-wrapper {
    position: relative;
    // width: 500px;
    margin-right: 16px;

    .regex-recommemd,
    .import-export {
        display: flex;
        align-items: center;

        button {
            padding: 0;
            margin-left: 5px;
            background-color: transparent;
            border: 0;
            border-bottom: 1px dotted #000;
        }
    }

    ul {
        width: 500px;
        margin-top: 7px;
        list-style: none;
        position: absolute;
        left: 0;
        padding: 10px;
        z-index: 99;
        border: 1px solid #e4e7ea !important;
        box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.13) !important;
        max-height: 280px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;

        li {
            padding-bottom: 5px;
            display: table-row-group;

            span {
                display: table-cell;
                padding-bottom: 10px;

                &:first-child {
                    padding-right: 20px;
                    white-space: nowrap;
                }

                &:last-child {
                    max-width: 500px;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-300;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-left: 2px solid #fff;
            border-right: 2px solid #fff;
        }
    }
}

.import-export-wrapper {
    ul {
        width: 250px;

        li {
            button {
                background: transparent;
                border: none;

                &:hover {
                    text-decoration: underline;
                }

                display: flex;
                align-items: center;

                span {
                    padding-bottom: 0;
                    margin-left: 5px;
                }
            }

            &:not(:last-child) {
                button {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.import-modal {
    .modal-dialog {
        max-width: 690px;
        min-width: 690px;
    }

    .modal-body {
        padding: 30px;

        .import-modal-body {
            position: relative;
        }

        svg {
            position: absolute;
            top: -10px;
            right: -10px;
        }

        .modal-title {
            font-size: 1.5rem;
            margin-bottom: 20px;
            text-align: center;
        }

        .import-modal-step {
            margin-top: 25px;
            margin-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                margin-bottom: 0;
            }

            button {
                min-width: 220px;
                padding: 11px 35px 10px;
                border-radius: 10px;
            }
        }

        .custom-file-input {
            height: 45px;

            &:focus {
                +.custom-file-label {
                    box-shadow: none;
                    border-color: #e4e7ea;
                }
            }
        }

        .custom-file-label {
            padding-right: 140px;
            z-index: 3;
            cursor: pointer;
            border-radius: 10px;
            overflow: hidden;
            height: 45px;
            display: flex;
            align-items: center;

            &:after {
                background: $light;
                color: $primary;
                padding-left: 40px;
                padding-right: 40px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                height: 100%;
            }

            &:hover {
                &:after {
                    background: #d9e1e6;
                }
            }
        }

        select.form-control {
            border-radius: 10px;
            height: 45px;
            border-width: 1px;
        }

        .import-modal-button {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            button {
                flex-shrink: 0;
                min-width: 220px;
                padding: 11px 35px 10px;
                border-radius: 10px;

                &:not(:last-child) {
                    margin-right: 35px;
                }
            }
        }

        .import-modal-error {
            padding: 15px;
            background-color: #fee1e1;
            margin-top: 40px;

            p {
                color: #863f3f;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 0;
            }
        }

        .import-modal-progress {
            margin-top: 40px;

            .progress {
                margin-bottom: 5px;
            }

            p {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .import-modal-step-disable {
            button {
                color: #23282c;

                &:hover {
                    color: #23282c;
                    cursor: default;
                }
            }
        }

        .import-modal-file-disable {
            .custom-file-label {
                cursor: default;

                &:after {
                    color: #23282c;
                    background-color: #f0f3f5;
                    opacity: 0.5;
                }
            }
        }

        .import-modal-select-disable {
            select {
                cursor: default;
            }
        }
    }
}

.insert-automatic-variables {
    position: relative;

    &.no-options {
        text-align: left;

        .card-body {
            text-align: center;
        }
    }

    .variable-filter {
        position: sticky;
        top: 0;

        h6 {
            margin-bottom: 5px;
        }
    }

    ul {
        padding-left: 0;
        margin-top: 5px;
        margin-bottom: 0;
        overflow-y: auto;
        max-height: 280px;
        overflow-wrap: break-word;

        li {
            word-break: break-all;
            list-style: none;
            padding: 5px 0;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-300;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-left: 2px solid #fff;
            border-right: 2px solid #fff;
        }
    }

    &.insert-variables-lookup {
        border: 1px solid #e4e7ea;
        border-radius: 12px;
        padding-top: 10px;

        ul {
            &::-webkit-scrollbar {
                width: 3px;
                border-radius: 12px;
            }

            &::-webkit-scrollbar-track {
                width: 3px;
                background-color: #e2e6e9;
                border-radius: 2px;
                border-radius: 12px;
                margin-bottom: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: #73818f;
                border-radius: 12px;
                border: none;
                min-height: 50px;
                width: 3px;

            }
        }

        li {
            min-height: 38px;
            padding: 0 12px;
            color: #7F8C9A;

            &:hover {
                background: #f3f5f6;

                span {
                    color: #20a8d8 !important;
                }

                text-decoration: none;
            }

            strong {
                color: #212c36;
            }

            &:last-child {
                border-bottom: none !important;
            }

            &.no-options {
                cursor: auto;

                &:hover {
                    background: unset;
                }
            }

            &.active-variable {
                background-color: #f3f5f6;

                span {
                    color: #20A8D8 !important;
                }
            }
        }

        .variable-filter {
            margin-bottom: 10px;
            padding: 0 12px;
        }

        .type-variable {
            svg {
                &.icon:not(.icon-c-s):not(.icon-custom-size) {
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }

    .btn-back {
        background: none;
        border: none;
        padding: 0;
        margin-bottom: 5px;

        i {
            margin-right: 5px;
        }
    }
}

.insert-variable-modal {
    .modal-dialog {
        top: calc(50vh - 180px);
    }

    .icon-close-popup {
        top: 17px;
        right: 15px;
        z-index: 10;

        &.icon-close-popup-insert {
            top: 7px;
            right: 10px;
        }
    }

    .modal-body {
        padding-top: 25px;
    }
}

.default-lookup-rule {
    .table-wrapper {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
}

.insert-variables {
    position: absolute;
}

@media screen and (min-width: 576px) {
    .insert-variable-modal {
        .modal-dialog {
            max-width: 530px;
        }
    }
}