.modal-skip {
     .modal {
          padding-left: var(--padding-left);
          &-dialog {
               max-width: 530px;
          }

          &-content {
               border-radius: 12px;
               overflow: hidden;
               padding: 27px 21px 40px;

               &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 7px;
                    background-color: #f86c6b;
               }
          }

          &-body {
               padding: 0;
          }
     }

     &-bg-gradient {
          .modal {
               &-content {
                    &:before {
                         background-color: transparent;
                         background-image: url('/assets/images/line-rainbow.png');
                         background-size: cover;
                         background-repeat: no-repeat;
                         background-position: center;
                    }
               }
          }
     }

     &-bg-success {
          .modal {
               &-content {
                    &:before {
                         background-color: #4dbd74;
                    }
               }
               &-body {
                    > svg {
                         margin-bottom: 20px;
                    }

                    .modal-skip-button {
                         &-group {
                              flex-direction: row-reverse;
                         }

                         &--back {
                              background-color: transparent;
                              color: #73818F;
                              border-color: #73818F;

                              > svg {
                                   display: none;
                              }
                         }

                         &--skip {
                              background-color: #20A8D8;
                              color: #ffffff;
                              border: none;
                         }
                    }
               }
          }
     }

     h3 {
          background: linear-gradient(161deg, #29263e 0%, #233b7d 37.08%, #20a8d8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          font-family: 'Poppins';
          font-size: 20px;
          font-weight: 700;
          line-height: 47px;

          margin-bottom: 6px;
     }

     &-content {
          color: #2f353a;
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;

          position: relative;

          &--body {
               & + .modal-skip-button-group {
                    margin-top: 16px;
               }
          }

          &--back {
               font-weight: 700;
               margin: 43px 0 10px;
          }
     }

     &-button {
          background-color: transparent;
          color: #73818f;
          border: 1px solid #73818f;
          border-radius: 22.5px;
          min-width: 130px;

          font-family: 'Roboto';
          font-size: 13px;
          line-height: 16px;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          padding: 9px 20px 8px;
          transition: 0.2s all linear;

          svg {
               margin-right: 6px;
               width: 9px !important;
               height: 9px !important;
               path {
                    fill: #fff;
               }
          }

          &-group {
               display: flex;
               justify-content: center;
               gap: 20px;
          }

          &--back {
               color: #ffffff;
               background-color: #212c36;
               border-color: #212c36;
               &:hover {
                    background-color: rgba($color: #212c36, $alpha: 0.9);
                    border-color: rgba($color: #212c36, $alpha: 0.9);
               }
          }
     }
}

.modal-confirm-publish {
     .modal {
          &-dialog {
               max-width: 530px;
          }
     }
}
