.form-categorization-section {
     padding-bottom: 80px;

     .btn-setup-wizard {
          color: #fff;
          background-color: #20a8d8;
          padding: 7px 16px;
          font-family: Roboto;
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          letter-spacing: 0.19px;
     }
     h1 {
          letter-spacing: 0;
          padding-bottom: 0;
     }
     .setup-wizard {
          display: flex;
          justify-content: flex-end;
          padding-top: 8px;
     }

     .btn-continue {
          color: #ffffff;
          font-family: Roboto;
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          letter-spacing: 0.19px;
          background-color: #121920;
          position: relative;
          padding: 6px 15px 5px;
          min-height: 31px;
          margin-right: 13px;
          .icon-arrow-bar {
               margin-left: 12px;
               margin-top: 2px;
               width: 9px !important;
               height: 9px !important;

               path {
                    fill: #fff;
               }
          }
     }

     .accordion {
          .accordion-item {
               .accordion-header {
                    .title {
                         min-width: 200px;
                         padding-right: 22px;
                    }
               }

               p {
                    color: #000000;
               }
          }
     }

     .accordion-form-category {
          position: relative;
          z-index: 1048;
          background-color: #fff;
          border-radius: 12px;
     }

     .btn-skip {
          font-family: Roboto;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.09px;
          color: #73818f;
          text-decoration: underline;
          text-transform: initial;
     }

     .accordion .accordion-header {
          img {
               padding-right: 9px;
          }
     }

     .table-layout {
          .css-table {
               .take-action {
                    width: 250px;
                    div {
                         text-align: left;
                    }
                    div[class*='singleValue'] {
                         max-width: calc(100% - 25px);
                    }
                    .option-row div {
                         text-overflow: ellipsis;
                         overflow: hidden;
                    }

                    .css-26l3qy-menu{
                         .option-row div {
                              white-space: break-spaces;
                         }
                    }

                    .no-options {
                         div {
                              text-align: center !important;
                         }
                    }
                    .option-row{
                         white-space: pre;
                    }
                    &.disable {
                         .css-14jk2my-container{
                              background-color: hsl(0, 0%, 95%);
                         }
                    }
               }
               .listener-name {
                    width: 200px;

                    &-parent {
                         cursor: pointer;
                    }
               }
               .form-id {
                    width: 100px;
               }
               .form-location {
                    text-align: center;
                    width: 180px;
               }
               .form-name {
                    width: 240px;

                    .form-control.is-invalid {
                         margin-bottom: 5px;
                    }
               }
               .redirects {
                    text-align: center;
                    width: 115px;
                    .tooltip-wrapper {
                         display: initial;
                    }
               }
               .table-body-row {
                    padding-left: 10px;
               }
          }
          .css-table-row {
               .form-id {
                    .tooltip-wrapper {
                         width: 100px;
                    }
               }
               .inherit-category,
               .inherit-category-inventory {
                    text-align: center;
                    width: 250px;
                    font-size: 13px;
                    color: #73818f;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                    input {
                         display: inline-block;
                         width: 14px;
                         height: 14px;
                         border-radius: 6px;
                         margin-right: 8px;
                         border-color: #c8ced3;
                         border: 1px solid #73818f !important;
                         cursor: pointer;
                         vertical-align: middle;
                         appearance: none;
                         -webkit-appearance: none;
                         outline: none;

                         &:checked {
                              background-image: url('/assets/icons/icon-checkbox.svg');
                              background-repeat: no-repeat;
                              background-position: center;
                              border: none;
                              opacity: 1;
                              background-color: #20a8d8;
                              border: none !important;
                         }
                    }
               }
               .redirects-status,
               .reveal-people {
                    width: 120px;
                    text-align: center;
               }
          }
     }

     .categories-form {
          .form-group.categories {
               margin-bottom: 10px;

               .group-button-add-delete {
                    .icon-delete {
                         width: 25px;
                         height: 25px;
                         margin: 0;
                         margin-left: -10px;
                    }

                    &.input-error {
                         margin-bottom: 20px;
                    }
               }
          }
          input {
               border-radius: 12px;
               border: none;
          }
          .categories {
               width: 100%;
               max-width: 650px;

               .row {
                    align-items: center;
               }
          }
          label {
               display: flex;
               align-items: center;
               margin-bottom: 0;
               margin-left: 15px;
               input {
                    margin-left: 15px;
               }
          }
          .btn-edit-categories {
               color: #63c2de;
               font-size: 9.36px;
               font-weight: 700;
               letter-spacing: 0.13px;
               line-height: 11px;
               border: none;
               border-radius: 0;
          }
          .categories-input-wrap {
               display: flex;
               align-items: center;

               &.input-error {
                    .categories-input-group {
                         margin-bottom: 20px;
                    }
               }
          }
          .input-group.categories-input-group {
               margin-right: 15px;
               border-radius: 12px !important;
               box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06) !important;
               border: 1px solid #f0f3f5;
               .form-control {
                    border-radius: 12px !important;
               }
          }
          .categories-input-group {
               position: relative;

               input[type='text'] {
                    background-color: #ffffff;
                    border-radius: 12px;
               }
               .custom-radio {
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding-left: 10px;
                    label {
                         margin-left: 0;
                         &:before {
                              content: '';
                              position: absolute;
                              right: -25px;
                              left: auto;
                              top: 0;
                              width: 17px;
                              height: 17px;
                              border-radius: 6px !important;
                              box-shadow: none !important;
                         }
                         &::after {
                              content: '';
                              position: absolute;
                              right: -25px;
                              left: auto;
                              top: 0;
                              width: 17px;
                              height: 17px;
                              border-radius: 6px !important;
                              background-repeat: no-repeat;
                              background-position: 4px;
                              background-image: url('/assets/icons/icon-checkbox.svg');
                              box-shadow: none !important;
                         }
                    }
               }
               .invalid-feedback {
                    position: absolute;
                    top: 100%;
               }
               &.disable {
                    label {
                         &::after {
                              background-image: none;
                         }
                    }
               }
          }
     }

     .row-rule {
          .info-title h4 {
               display: contents;
          }
     }

     .remove-popup {
          .modal-content {
               padding: 0;
          }
     }
}

.form-inventory-header {
     .import-export-wrapper ul {
          width: 282px;
     }
}

.finish-config {
     padding: 8px 22px;
     border: 1px solid #d8d8d8;
     border-radius: 12px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-bottom: 17px;
     position: relative;
     background-color: #fff;
     .config-title {
          display: flex;
          align-items: center;
          p {
               margin-bottom: 0;
          }
          p:first-child {
               font-family: Roboto;
               font-weight: 700 !important;
               color: #f86c6b;
               font-size: 16px;
               line-height: 24px;
               letter-spacing: 0.27px;
               padding-right: 24px;
          }
          p:not(&:first-child) {
               font-family: Roboto;
               font-weight: 400;
               font-size: 16px;
               color: #73818f;
               letter-spacing: 0;
               line-height: 22px;
          }
     }
     .btn-continue {
          color: #ffffff;
          font-family: Roboto;
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          letter-spacing: 0.19px;
          background-color: #121920;
          position: relative;
          padding: 6px 15px 5px;
          min-height: 31px;
          margin-right: 13px;
          .icon-arrow-bar {
               margin-left: 12px;
               margin-top: 1px;
               width: 9px !important;
               height: 9px !important;

               path {
                    fill: #fff;
               }
          }
          &:active {
               background-color: #121920;
               color: #ffffff;
          }
     }
     .btn-skip {
          font-family: Roboto;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.09px;
          color: #73818f;
          text-decoration: underline;
          text-transform: initial;
     }
}

.finish-config-custom-forms,
.finish-config-form-category {
     z-index: 1056;
     border: 2px solid #d8d8d8;
}

.subscriber {
     .form-categorization-section {
          .table-layout {
               margin-top: 0;
               .css-table {
                    .form-location {
                         .tooltip-wrapper {
                              .block-tooltip {
                                   left: calc(100% - 50px);
                              }
                         }
                    }

                    .inherit-category,
                    .inherit-category-inventory {
                         position: relative;
                         .custom-checkbox {
                              &:hover {
                                   + .block-tooltip {
                                        display: block;
                                        top: 5px;
                                   }
                              }

                              .custom-control-label {
                                   cursor: pointer;
                              }

                              .custom-control-input:disabled {
                                   ~ .custom-control-label {
                                        opacity: 0.5;

                                        &::before {
                                             background-color: #ffffff;
                                             border-color: #8f9ba6;
                                        }
                                   }
                              }

                              .custom-control-input.inherit-disable:disabled:checked {
                                   ~ .custom-control-label {
                                        opacity: 0.5;
                                        &::before {
                                             background-color: #20a8d8 !important;
                                        }
                                   }
                              }
                         }
                    }

                    .inherit-category,
                    .inherit-category-inventory
                    .take-action {
                         .block-tooltip {
                              left: auto;
                              right: calc(100% - 5px);
                              top: 10px;
                              min-width: auto;
                              height: max-content;

                              > svg {
                                   left: auto;
                                   right: -10px;
                                   bottom: 0;
                                   top: auto;
                                   transform: rotate(180deg);
                                   height: 12px !important;
                              }

                              &-text {
                                   padding: 4px 10px;
                                   border-radius: 5px;
                                   border-bottom-right-radius: 0;
                                   width: max-content;
                              }
                         }
                    }
               }
               .css-table-row {
                    .custom-control {
                         &-label {
                              &::before,
                              &::after {
                                   top: 0;
                                   box-shadow: none !important;
                              }
                         }
                    }
                    &.even {
                         background-color: #ffffff;
                    }

                    &.odd {
                         background-color: #f4f5f6;
                    }

                    .invalid-feedback {
                         display: none;
                    }

                    .is-invalid + .invalid-feedback {
                         display: block;
                    }
               }
          }

          .checkbox-header,
          .inherit-category,
          .custom-checkbox {
               .custom-control-label {
                    &::before,
                    &::after {
                         top: 0;
                    }
               }
          }
     }
}

.subscriber .flexible-modal-content {
     .table-layout.no-form-found {
          .center-spinner {
               padding-top: 65px;
          }
     }

     .listener-name {
          width: 220px;
          &-parent {
               cursor: pointer;
          }
     }
     .form-location,
     .take-action {
          position: relative;
          text-align: center;
          div {
               text-align: left;
          }
          div[class*='singleValue'] {
               max-width: calc(100% - 35px);
          }
          .option-row div {
               text-overflow: ellipsis;
               overflow: hidden;
          }
          div[class*='container'] {
               pointer-events: auto;

               &:hover {
                    cursor: pointer;
                    + .block-tooltip {
                         display: block;
                    }
               }
          }
          div[class*='control'] {
               cursor: pointer;
          }
     }

     .form-location {
          .tooltip-wrapper {
               .block-tooltip {
                    left: calc(100% - 30px);
               }
          }
     }

     .inherit-category,
     .inherit-category-inventory {
          position: relative;
          .custom-checkbox {
               &:hover {
                    + .block-tooltip {
                         display: block;
                         top: 3px;
                    }
               }

               .custom-control-label {
                    cursor: pointer;
               }

               .custom-control-input:disabled {
                    ~ .custom-control-label {
                         opacity: 0.4;

                         &::before {
                              background-color: #ffffff;
                              border-color: #8f9ba6;
                         }
                    }
               }

               .custom-control-input.inherit-disable:disabled:checked {
                    ~ .custom-control-label {
                         opacity: 0.5;
                         &::before {
                              background-color: #20a8d8 !important;
                         }
                    }
               }
          }
     }

     .redirects {
          text-align: center;
          .tooltip-wrapper {
               display: initial;
          }
     }
     .css-table {
          .table-body-row {
               padding-left: 5px !important;
               padding-right: 9px;
               &.inherit-category-inventory{
                    padding: 0;
                    .custom-control-inline{
                         margin-right: 4px;
                    }
               }
          }
          .form-id {
               width: 100px;
               .tooltip-wrapper {
                    position: relative;
                    width: 80px;
                    span {
                         white-space: nowrap;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         display: block;
                         cursor: pointer;
                    }
                    .block-tooltip {
                         width: -webkit-max-content;
                         width: -moz-max-content;
                         width: max-content;
                         max-width: 600px;
                         z-index: 999;

                         &-text {
                              display: flex;
                              flex-direction: column;
                              grid-row-gap: 10px;
                              row-gap: 10px;
                         }
                    }
               }
          }
          .form-name {
               width: 320px;

               .form-control.is-invalid {
                    margin-bottom: 6px;
               }
          }
          .take-action {
               width: 400px;
          }
          .inherit-category,
          .inherit-category-inventory {
               text-align: center;
               input {
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    border-radius: 6px;
                    margin-right: 8px;
                    border-color: #c8ced3;
                    border: 1px solid #73818f !important;
                    cursor: pointer;

                    vertical-align: middle;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;

                    &:checked {
                         background-image: url('/assets/icons/icon-checkbox.svg');
                         background-repeat: no-repeat;
                         background-position: center;
                         border: none;
                         opacity: 1;
                         background-color: #20a8d8;
                         border: none !important;
                    }
               }
          }
          .redirects-status,
          .reveal-people {
               text-align: center;
          }

          .redirects-status,
          .reveal-people,
          .push-events-setting {
               .c-switch-slider {
                    &::before {
                         width: 18px;
                         height: 18px;
                         top: 1px;
                         left: 1px;
                    }
               }
          }
     }
     .css-table-row {
          .inherit-category,
          .take-action {
               .block-tooltip {
                    left: auto;
                    right: calc(100% - 5px);
                    top: 10px;
                    min-width: auto;
                    height: max-content;

                    > svg {
                         left: auto;
                         right: -10px;
                         bottom: 0;
                         top: auto;
                         transform: rotate(180deg);
                         height: 12px !important;
                    }

                    &-text {
                         padding: 4px 10px !important;
                         border-radius: 5px;
                         border-bottom-right-radius: 0;
                         width: max-content;
                    }
               }
          }
          > div {
               padding-left: 0 !important;
          }
     }

     .checkbox-header,
     .inherit-category,
     .custom-checkbox {
          .custom-control-label {
               &::before,
               &::after {
                    top: 0.15rem;
               }
          }
     }
}

.table-layout.table-inventory.table-inventory-all {
     padding-bottom: 100px;

     .btn-outline-primary {
          min-width: 104px;
     }
     .css-table {
          .inherit-category,
          .inherit-category-inventory {
               width: 295px;
               font-size: 13px;
          }
          .form-location {
               width: 165px;
               &-people {
                    width: 100px;
               }
          }
          .form-people {
               width: 200px;
          }
     }

     .inherit-category.table-body-row {
          .custom-checkbox {
               &:hover {
                    + .block-tooltip {
                         right: calc(100% - 5px);
                    }
               }
          }
     }
}

.table-layout.table-inventory.table-inventory-all,
.table-layout.table-requiring-action.table-requiring-action-all {
     .btn-outline-primary {
          min-width: 104px;
     }
     .css-table .css-table-row {
          display: table-row;
          &.even {
               background-color: #ffffff;
          }

          &.odd {
               background-color: #f4f5f6;
          }

          .invalid-feedback {
               display: none;
          }

          .is-invalid + .invalid-feedback {
               display: block;
          }
     }

     .redirects-status,
     .reveal-people {
          width: 120px;
     }

     &.table-layout {
          .child-form {
               position: relative;
          }
          .child-form-loading {
               height: 40px;
               .center-spinner {
                    padding-top: 0;
               }
          }
          .center-spinner {
               padding-top: 90px;
          }
     }
     &.table-layout .table-forms-loading-overlay .center-spinner {
          padding-top: 70px;
     }
}

.table-layout.table-requiring-action,
.table-layout.table-requiring-action.table-requiring-action-all {
     .css-table {
          .status.table-body-row {
               width: 180px;
          }

          .form-location.table-body-row {
               width: 140px;
          }

          .manage-settings.table-body-row {
               color: #ffffff;
               width: 300px;

               h5 {
                    font-size: 16px;
               }

               .btn-secondary {
                    border: 1px solid #ced2d8 !important;
               }

               .invalid-feedback {
                    text-align: center;
               }

               > .tooltip-wrapper {
                    margin: 0 auto;
                    > .block-tooltip {
                         left: auto;
                         right: calc(100% + 15px);

                         > svg {
                              right: -11px;
                              left: auto;
                              transform: rotateY(180deg);
                         }

                         > .block-tooltip-text {
                              border-top-right-radius: 0;
                              border-top-left-radius: 12px;

                              button.btn-primary {
                                   padding-left: 35px;
                                   padding-right: 35px;
                              }
                         }
                    }
               }

               .tooltip-wrapper {
                    position: relative;
                    width: max-content;

                    > svg {
                         margin-left: 4px;
                         width: 14px;
                         path {
                              fill: #7f8c9a !important;
                         }
                    }

                    .block-tooltip {
                         min-width: 450px;
                         z-index: 999;
                    }
               }

               .inherit-parent-setting {
                    .tooltip-wrapper {
                         .block-tooltip-text {
                              max-width: 400px;
                         }

                         > svg {
                              margin-top: 4px;
                         }
                    }
               }

               .push-events-setting {
                    .tooltip-wrapper {
                         > svg {
                              margin-top: 5px;
                         }

                         .block-tooltip-text {
                              max-width: 340px;
                         }
                    }
               }
               .ignore-form{
                    .tooltip-wrapper {
                         > svg {
                              margin-top: 2px;
                         }
                    }
               }

               .inherit-parent-setting,
               .push-events-setting {
                    display: flex;

                    label,
                    span {
                         color: #ffffff !important;
                    }
               }

               .inherit-parent-setting,
               .push-events-setting,
               .ignore-form {
                    .tooltip-wrapper {
                         .block-tooltip {
                              background-color: #ffffff;
                              > svg path {
                                   fill: currentColor !important;
                              }
                              &-text {
                                   background-color: inherit;
                                   color: #000000;
                              }
                         }
                    }
               }

               .form-group-requiring {
                    display: flex;
                    justify-content: center;
                    column-gap: 16px;

                    // div[class*='container'] {
                    //      background-color: #ffffff;
                    // }

                    .react-select__menu {
                         margin-top: 2px;
                         border-radius: 12px;
                    }

                    .form-validate {
                         width: 100%;

                         .invalid-feedback {
                              text-align: left;
                         }
                         .option-row {
                              div {
                                   text-overflow: ellipsis;
                                   overflow: hidden;
                                   padding-right: 19px;
                              }
                         }
                    }

                    input.form-control {
                         border: none;
                    }
               }

               .ignore-form {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 15px;
                    margin-right: 15px;
                    .btn-link {
                         border: none;
                         padding: 0;
                         text-transform: none;
                         text-decoration: underline;
                    }
                    .tooltip-wrapper {
                         margin-top: 2px;
                         margin-left: 15px;

                         .block-tooltip {
                              left: -400px;
                              top: 30px;

                              > svg {
                                   left: auto;
                                   right: 50px;
                                   top: -14px;
                                   transform: rotate(90deg);
                              }

                              &-text {
                                   border-top-left-radius: 12px;
                              }
                         }
                    }
               }

               .dots-waiting {
                    color: #fff;
               }
          }

          .loading-settings {
               position: absolute;
               width: 100%;
               height: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               background: rgba(255, 255, 255, 0.4);
               z-index: 1;
          }
     }
}

.table-layout.table-requiring-action.table-requiring-action-all {
     .css-table {
          .take-action {
               width: 250px;
          }
     }

     .inherit-category.table-body-row {
          .custom-checkbox {
               &:hover {
                    + .block-tooltip {
                         right: calc(100% - 25px);
                    }
               }
          }
     }
     .table-last-row {
          .manage-settings.table-body-row {
               > .tooltip-wrapper > .block-tooltip {
                    bottom: 11px;
                    top: auto;

                    > svg {
                         bottom: 0;
                         top: auto;
                         transform: rotate(180deg);
                    }

                    > .block-tooltip-text {
                         border-top-right-radius: 12px;
                         border-bottom-right-radius: 0;
                    }
               }
          }
     }
}

// .form-categorization-section {
.table-inventory,
.table-requiring-action {
     .confirm-save-popup {
          .modal-dialog {
               max-width: 480px !important;
          }
     }
}
// }

.table-inventory {
     .option-create-categories {
          font-size: 11px;
          padding: 0;
     }
     .icon-tooltip-block {
          svg {
               rect {
                    fill: #000000 !important;
               }
          }
     }

     .css-table-wrap {
          display: block;
          width: 100%;
     }

     .css-table-header {
          .block-tooltip.redirect {
               left: -250px;
               > svg {
                    left: auto;
                    right: 25px;
               }
          }
          .take-action,
          .form-name {
               text-align: left;
          }
          .inherit-inventory {
               width: 240px;
          }
     }

     .take-action.table-body-row {
          div {
               padding-left: 0;
          }
          .react-select__menu {
               position: absolute;
               z-index: 999;
               margin-top: 5px;
               border-radius: 12px;
          }
          .react-select__menu > div {
               max-height: 280px;
               padding-top: 0;
               padding-bottom: 0;
          }
          div[class*='placeholder'],
          div[class*='NoOptionsMessage'],
          div[class*='Input'],
          .no-options-msg,
          .option-row,
          .no-options > div:first-of-type {
               padding-left: 14px;
          }
          .inherit-parent {
               div[class*='control'] {
                    background-color: inherit;
               }
               .option-row {
                    visibility: hidden;
               }
          }
          div[class*='container'] {
               pointer-events: auto;

               &:hover {
                    cursor: pointer;
                    + .block-tooltip {
                         display: block;
                    }
               }
          }
          div[class*='control'] {
               cursor: pointer;
          }
          .custom-forms-listener {
               padding-left: 0;
          }
     }

     .take-action.table-body-row,
     .form-name.table-body-row {
          position: relative;
          text-align: left;
          .custom-forms-listener {
               text-align: left;
               margin: 0;
               padding-right: 0;
               padding-bottom: 0;
               padding-top: 0;
               padding-left: 15px;
          }
     }

     .take-action.table-body-row .inherit-parent,
     .form-name.table-body-row.inherit-parent {
          position: relative;
          opacity: 0.5;
          &::after {
               content: 'Inherit Parent';
               position: absolute;
               left: 16px;
               z-index: 10;
               color: #7f8c9a;
          }
     }

     .take-action.table-body-row .inherit-parent {
          background-color: #ffffff;
          &::after {
               top: 6px;
               width: 100%;
          }

          .react-select__menu {
               display: none;
          }
     }

     .form-name.table-body-row.inherit-parent {
          input.form-control {
               text-indent: -9999px;
               background-color: #ffffff;
          }
          &::after {
               top: 8px;
               width: inherit;
               padding: inherit;
               text-align: left;
          }
     }

     .change-drafted.table-body-row {
          width: 80px;
          text-align: center;
          .tooltip-tag .badge.bg-info {
               color: #63c2de;
          }
     }

     .inherit-category.table-body-row {
          .custom-checkbox {
               &:hover {
                    + .block-tooltip {
                         right: calc(100% - 30px);
                    }
               }
          }
     }

     .css-table {
          width: 100%;
          .css-table-row {
               position: relative;
               .center-spinner {
                    top: 5px;
               }
          }
     }
}

.table-requiring-action {
     .option-create-categories {
          font-size: 11px;
          padding: 0;
     }
     .icon-tooltip-block {
          svg {
               rect {
                    fill: #000000 !important;
               }
          }

          .block-tooltip {
               left: -220px;

               > svg {
                    left: calc(50% + 80px);
               }
          }
     }

     .inherit-category.table-body-row {
          .custom-checkbox {
               &:hover {
                    + .block-tooltip {
                         right: calc(100% - 50px);
                         z-index: 999;

                         .block-tooltip-text {
                              background-color: #000000;
                         }
                    }
               }
          }
     }

     span.is-drafted {
          font-style: italic;
          font-weight: bold;
     }
}

.custom-forms-listener.btn-link {
     color: #73818f;
     font-size: 14px;
     font-weight: 400;
     text-transform: capitalize;
     text-decoration: underline;

     &:hover {
          text-decoration: none;
     }
}

.ll-flexible-modal.full-screen {
     .flexible-modal-content {
          .table-body-row {
               width: auto !important;
          }
          .listener-name.table-body-row {
               width: 300px !important;
          }
          .form-id.table-body-row {
               width: 150px !important;
          }
     }
}

.subscriber .form-categorization-section,
.subscriber .flexible-modal-content {
     .table-layout {
          .confirm-save-popup {
               .confirm-save {
                    padding-bottom: 47px !important;

                    h1 {
                         padding-top: 50px;
                    }

                    .confirm-save-icon + h1 {
                         padding-top: 0;
                    }

                    &-content {
                         margin-bottom: 30px;
                    }
               }
          }

          .remove-popup {
               &-description {
                    margin-bottom: 30px;
               }
          }

          .modal-skip,
          .confirm-save-popup,
          .remove-popup {
               .modal-dialog {
                    max-width: 620px;
               }

               .modal-content {
                    max-width: 100%;
               }

               .modal-skip-content,
               .confirm-save-content,
               .remove-popup-description {
                    font-size: 16px;
                    line-height: 23px;
                    white-space: break-spaces;

                    p {
                         color: inherit;
                         font-size: inherit !important;
                         line-height: inherit;
                    }
               }
          }
     }

     .form-categories--card .target-custom-form {
          min-width: 150px;
          max-width: 150px;
     }

     .css-table .table-body-row {
          &.disable {
               .css-14jk2my-container{
                    background-color: hsl(0, 0%, 95%);
               }
          }

          div[class*='container'] {
               border-width: 1px !important;
               min-height: 37px;
          }
          div[class*='control'] {
               border-radius: 12px;
          }

          .tooltip-wrapper,
          .tooltip-wrapper > div {
               padding-left: 0;
          }

          &.form-id .tooltip-wrapper,
          &.form-location .tooltip-wrapper {
               position: relative;
               span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    cursor: pointer;
               }
               .block-tooltip {
                    width: -webkit-max-content;
                    width: -moz-max-content;
                    width: max-content;
                    max-width: 600px;
                    z-index: 999;
                    left: calc(100% - 80px);

                    &-text {
                         display: flex;
                         flex-direction: column;
                         grid-row-gap: 10px;
                         row-gap: 10px;
                    }
               }
               .tooltip-copy {
                    display: flex;
                    align-items: center;
                    grid-gap: 20px;
                    gap: 20px;

                    .tooltip-label {
                         word-break: break-all;
                    }
                    .tooltip-slug {
                         display: inline-flex;
                         img {
                              filter: brightness(0) invert(1);
                              width: 14px;
                              height: auto;
                         }
                    }
               }
               .icon-copy {
                    color: #20a8d8;
                    background-color: #fff;
                    border-radius: 12px;
                    padding: 2px 10px;
                    font-size: 12px;
                    font-weight: 600;
                    cursor: pointer;
               }
          }

          &.form-id .tooltip-wrapper .block-tooltip {
               left: calc(100% + 10px);
          }

          &.form-location {
               .tooltip-wrapper {
                    text-align: center;
                    > svg {
                         width: 16px;
                         height: 16px;
                    }
               }
          }

          &.disable {
               .react-select {
                    &--is-disabled {
                         background-color: #ffffff;
                         opacity: 0.5;
                    }
                    &__control--is-disabled {
                         background-color: transparent;
                    }
                    &__single-value {
                         color: #7f8c9a;
                    }
               }
          }

          &:not(.inherit-parent) .form-control:disabled {
               background-color: #ffffff;
               color: #7f8c9a;
               opacity: 0.5;
          }
     }

     .no-form-found {
          border: 1px solid #f4f5f6;
          border-radius: 16px;
          padding-bottom: 45px;
          .table-body-row > p,
          .center-spinner {
               padding-top: 15px;
               position: absolute;
               text-align: center;
               width: 100%;
          }
     }

     .checkbox-header,
     .inherit-category,
     .custom-checkbox {
          input.custom-control-input {
               &:focus {
                    ~ .custom-control-label {
                         &::before {
                              border-color: #8f9ba6;
                         }
                    }
               }
          }

          .custom-control-label {
               color: #000000 !important;
               &::before,
               &::after {
                    box-shadow: none !important;
               }
          }
     }

     .title-form-action {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 10px;

          > p {
               color: #000000;
               font-size: 15px;
               flex: 1;
               padding-right: 30px;
          }

          .form-action-settings {
               display: flex;
               align-items: center;
               margin-right: 20px;
               .import-export-form {
                    margin-bottom: 15px;
                    margin-right: 20px;
               }
          }

          &.form-inventory-header {
               .checkbox-header {
                    &.checkbox-ignored-form,
                    &.checkbox-settings {
                         padding-bottom: 0;
                    }
               }
          }

          .checkbox-header {
               position: relative;
               display: flex;
               align-items: flex-start;

               &.checkbox-ignored-form {
                    margin-right: 30px;
               }

               &.checkbox-settings {
                    .custom-control-inline {
                         align-items: center;
                    }
                    .custom-control-input {
                         &:disabled {
                              ~ .custom-control-label {
                                   &::before {
                                        background-color: #abadae;
                                        border-color: #abadae;
                                   }
                              }
                         }
                    }
                    .custom-control-label {
                         color: #ffffff !important;
                    }

                    > .block-tooltip {
                         left: auto;
                         right: -5px;
                         bottom: auto;
                         top: 40px;
                         width: max-content;
                         z-index: 5;

                         > svg {
                              left: auto;
                              right: 20px;
                              bottom: auto;
                              top: -12px;
                              transform: rotate(90deg);
                         }

                         > .block-tooltip-text {
                              border-top-left-radius: 12px;
                              background-color: #000000;
                              box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.3);
                         }
                    }
               }

               &.checkbox-category {
                    .tooltip-wrapper {
                         position: relative;
                         margin-right: 15px;
                         margin-left: -10px;

                         > svg {
                              transform: none;
                              margin-bottom: 6px;
                              path {
                                   fill: #7f8c9a !important;
                              }
                         }

                         .block-tooltip {
                              top: 20px;
                              left: auto;
                              right: -25px;
                              min-width: 400px;
                              background-color: #ffffff;
                              border-radius: 12px;

                              > svg {
                                   top: -12px;
                                   bottom: auto;
                                   left: auto;
                                   right: 2px;
                                   transform: rotate(90deg);

                                   path {
                                        fill: currentColor !important;
                                   }
                              }

                              &-text {
                                   background-color: inherit;
                                   color: #000000;
                                   border-radius: inherit;
                                   border-top-right-radius: 0;
                              }
                         }
                    }
               }

               &.checkbox-redirect {
                    padding-top: 15px;
               }

               input.custom-control-input {
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    border-radius: 6px;
                    margin-right: 8px;
                    border-color: #c8ced3;
                    border: 1px solid #73818f !important;
                    cursor: pointer;
                    outline: unset;
                    vertical-align: middle;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    &:checked {
                         background-image: url('/assets/icons/icon-checkbox.svg');
                         background-repeat: no-repeat;
                         background-position: center;
                         border: none;
                         opacity: 1;
                         background-color: #20a8d8;
                         border: none !important;
                    }
               }
          }

          .checkbox-settings {
               .block-tooltip.form-filter-tooltip {
                    box-shadow: 0px -2px 10px 5px rgba(0, 0, 0, 0.1) !important;

                    > svg {
                         path {
                              fill: #ffffff !important;
                         }
                    }
                    .block-tooltip-text {
                         background-color: #ffffff;
                    }
               }
          }

          .form-filter {
               .select-filter {
                    margin-right: 5px;
                    min-width: 180px;
               }

               .select-filter-operator {
                    margin-right: 5px;
                    min-width: 140px;
               }

               .field-value-search {
                    min-width: 200px;

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                         -webkit-box-shadow: 0 0 0 30px white inset !important;
                    }
               }

               .filter-button {
                    border-radius: 3.25rem;

                    label {
                         color: #ffffff !important;
                         text-decoration: none;
                         text-transform: uppercase;
                    }
               }

               svg {
                    position: relative;
                    top: 0;
                    bottom: auto;
                    left: 0;
                    transform: none;
                    margin: 0 0 0 5px;
               }

               label {
                    font-size: 14px;
                    margin-bottom: 0;
                    text-decoration: underline;
                    text-transform: lowercase;
                    color: #000000 !important;
                    font-weight: 400;
                    cursor: pointer;
               }
          }
     }

     .form-inventory-header {
          .subtitle {
               color: #000000;
               font-family: 'Poppins';
               font-weight: 600;
          }
          p {
               color: #18222b;

               &:last-child {
                    margin-bottom: 0;
               }
          }

          + .table-layout {
               margin-top: 11px;
          }
     }

     .accordion-btn {
          background-color: transparent;
          padding: 0;
          margin-right: 12px;

          &:after {
               content: '';
               position: absolute;
               width: 7px;
               height: 7px;
               transform: translateY(-3px) rotate(-45deg);
               border: 2px solid #73818f;
               border-top: none;
               border-left: none;
               transition: transform 0.3s;
          }
          &.show {
               &:after {
                    transform: translateY(-6px) rotate(45deg);
               }
          }
     }

     .save-changes-button-wrapper {
          width: max-content;
          text-align: center;
     }

     .load-more-child {
          border-bottom: 1px solid currentColor;
          border-radius: 0;
          padding: 0;
          margin-left: 5px;
          margin-bottom: 12px;

          &:hover,
          &:focus {
               text-decoration: none;
          }

          i {
               width: auto;
               font-weight: 400;
               margin-left: 5px;
               font-size: 12px;
          }
     }

     .btn-suggest {
          .save-changes-button-wrapper {
               span{
                    color: #ffffff;
               }
          }
     }
}

.subscriber .flexible-modal-content .css-table .table-body-row.form-location .tooltip-wrapper .block-tooltip {
     left: calc(100% - 40px)
}

.table-inventory,
.table-requiring-action {
     .css-table {
          position: relative;
          &.table-overlay {
               position: relative;
               &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 143px;
                    left: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
                    z-index: 998;
               }
          }

          .table-overlay {
               position: relative;
               &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 143px;
                    left: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
                    z-index: 998;
               }
          }

          .table-loading {
               position: relative;
          }
     }

     .btn-show-all,
     .btn-paging-forms {
          font-size: 14px;
          font-weight: 700;
          color: #20a8d8;
          letter-spacing: 0.23px;
          line-height: 24px;
          text-transform: initial;
          .icon-arrow-bar {
               margin-left: 10px;
               width: 7px !important;
               height: 7px !important;

               path {
                    fill: #20a8d8;
               }
          }
          &:hover:not(:disabled) {
               text-decoration: underline;
          }
     }

     .btn-paging-forms {
          font-size: 18px;
     }
     .table-forms-loading-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.5);
          z-index: 9;
     }
     .css-table-wrap .css-table .css-table-row .form-location .tooltip-wrapper .block-tooltip  {
          left: calc(100% - 50px);
     }
}

@media (max-width: 1600px) {
     .subscriber {
          .title-form-action.form-inventory-header {
               .form-action-settings {
                    margin-top: 4px;
               }
          }
          .table-layout.table-inventory {
               &.no-form-found {
                    .css-table-wrap {
                         overflow-x: visible;
                         white-space: normal;
                    }
               }
               .css-table-wrap {
                    overflow-x: auto;
                    white-space: nowrap;
                    margin-bottom: 20px;
                    padding-bottom: 20px;

                    &::-webkit-scrollbar {
                         height: 5px;
                    }

                    &::-webkit-scollbar-track {
                         height: 5px;
                         background-color: #eeeeee;
                         border-radius: 2px;
                         margin-bottom: 8px;
                    }

                    &::-webkit-scrollbar-thumb {
                         background: #cccccc;
                         height: 5px;
                         border-radius: 12px;
                         border: none;
                         min-width: 100px;
                    }
               }
               .css-table-header {
                    .block-tooltip.redirect {
                         > svg {
                              right: -9px;
                              top: 0;
                              transform: rotate(180deg);

                              path {
                                   fill: #000000 !important;
                              }
                         }
                         left: -305px;
                         top: -4px;
                         white-space: break-spaces;

                         .block-tooltip-text {
                              max-width: 300px;
                              background-color: #000000;
                              border-top-right-radius: 0;
                         }
                    }
               }
               .css-table-row {
                    .take-action.table-body-row {
                         div[class*='container'] {
                              width: 200px;
                         }

                         .react-select__menu > div {
                              max-height: 140px;
                         }
                    }
                    .form-name.table-body-row {
                         input.form-control {
                              width: 180px;
                         }
                    }

                    &:last-child,
                    &:nth-last-child(2) {
                         .form-id.table-body-row {
                              .tooltip-wrapper > .block-tooltip {
                                   position: relative;
                              }
                         }
                    }

                    &.menu-is-open {
                         height: 200px;
                    }
               }

               .css-table {
                    .listener-name {
                         width: 300px;
                    }
               }
          }
     }
}

/* Laptop */
@media (max-width: 1399px) {
     .subscriber {
          .finish-config {
               &.finish-config-custom-forms {
                    .config-title {
                         align-items: flex-start;
                         flex-direction: column;
                         > p:first-child {
                              margin-bottom: 5px;
                         }
                    }
                    .btn-config {
                         display: flex;
                         flex-direction: column;
                         padding: 5px 0 3px;

                         .btn {
                              margin-right: 0;
                         }
                         .btn-skip {
                              padding-bottom: 0;
                         }
                    }
               }
          }

          .form-categorization-section {
               .finish-config {
                    .config-title {
                         align-items: flex-start;
                         flex-direction: column;
                         > p:first-child {
                              margin-bottom: 5px;
                         }
                    }
                    .btn-config {
                         display: flex;
                         flex-direction: column;
                         padding: 5px 0 3px;

                         .btn {
                              margin-right: 0;
                         }
                         .btn-skip {
                              padding-bottom: 0;
                         }
                    }
               }

               .accordion {
                    .accordion-item .accordion-header {
                         p.title {
                              min-width: 240px;
                         }
                    }
               }
          }
     }

     .subscriber .form-categorization-section,
     .subscriber .flexible-modal-content {
          .title-form-action {
               .form-action-settings {
                    .checkbox-header.checkbox-ignored-form {
                         margin-right: 10px;
                    }
               }
          }

          .css-table {
               .form-id.table-body-row,
               .form-location.table-body-row {
                    .tooltip-wrapper .block-tooltip {
                         max-width: 400px;
                         .tooltip-label {
                              white-space: break-spaces;
                              text-align: left;
                         }
                    }
               }

               .form-location.table-body-row {
                    .tooltip-wrapper .block-tooltip {
                         max-width: 300px;
                    }
               }
          }
     }
}

/* Tablet */
@media (max-width: 991px) {
}
