.passwordUpdate-wrapper {
     display: flex;
     justify-content: center;
     align-items: center;
     min-height: 100vh;
     .container {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
     }
     .passwordUpdate-header {
          align-self: flex-start;
          .link-back-login {
               margin: 30px 10px;
               font-size: 14px;
               line-height: 21px;
               color: #768391;
               font-weight: 500;
               display: flex;
               align-items: center;
               border: 0;
               background-color: transparent;
               cursor: pointer;
               i {
                    font-weight: 500;
               }
               span {
                    margin-left: 13px;
               }
          }
     }
     .passwordUpdate-card {
          padding: 5px 20px;
          min-width: 350px;
          max-height: fit-content;
          .passwordUpdate-title {
               text-align: center;
          }
          .passwordUpdate-submit {
               text-align: center;
               width: 100%;
          }
          .passwordUpdate-error {
               color: red;
               font-size: 12px;
               margin-top: 10px;
          }
          .passwordUpdate-conditionsMet {
               display: flex;
               gap: 5px;
               align-items: center;
          }
          .passwordUpdate-logo {
               text-align: center;
               padding: 0 0 20px;
          }
          .passwordUpdate-description {
               max-width: 470px;
               text-align: center;
               font-size: 14px;
          }
     }
}
