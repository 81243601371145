.form-categories {
     .btn-back {
          display: flex;
          align-items: center;
          letter-spacing: 0.19px;
          text-transform: uppercase;
          color: #212c36;
          font-family: 'Roboto';
          font-size: 13px;
          font-weight: 700;

          position: absolute;
          top: 28px;
          left: 38px;

          padding: 0;
          padding-left: 18px;

          &:before {
               content: '';
               position: absolute;
               background-image: url('/assets/images/icon-chevron-left.png');
               width: 6px;
               height: 7px;
               left: 0;
               margin-bottom: 1px;
          }
     }

     &-header {
          padding: 26px 0 65px;
     }

     .select-domain {
          .modal-content {
               width: 100%;
               max-width: 420px;
               margin: auto;
          }
     }

     .modal {
          z-index: 1056;
     }

     .modal-dialog {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          max-width: 100%;
     }

     .btn-domain-disable {
          margin-top: 10px;
          background-color: #979797;
          color: #fff;
          padding: 7px 88.5px;
     }

     .modal-ticket-button {
          .button-submit-ticket {
               padding: 7px 88.5px;
          }
     }

     #form-categories--modal-content {
          > .modal-dialog > .modal-content {
               display: block !important;
               position: fixed;
               height: 100%;
               width: 100%;
               border-radius: 0;
               background-color: #ffffff;
               overflow-y: auto;
               border: 0 !important;
               padding-bottom: 20px;
          }
     }

     .modal-body {
          text-align: center;
          padding: 0;
          max-width: 1506px;
          margin: 0 auto;

          & > svg {
               &.icon-close-popup {
                    position: fixed;
                    margin: 0;
                    top: 84px;
                    right: 100px;
               }
          }

          h3 {
               font-family: 'Poppins';
               background: linear-gradient(161deg, #29263e 0%, #233b7d 37%, #20a8d8 100%);
               color: transparent;
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               font-size: 20px;
               font-weight: 700;
               line-height: 47px;
               text-align: center;
               letter-spacing: -0.09463231px;
          }

          h6 {
               color: #000;
               font-weight: 300;
               line-height: 24px;
               letter-spacing: 0.11px;
               margin-bottom: 40px;
          }

          .identify-target-custom-forms {
               h6 {
                    margin-bottom: 0;
               }
               .button-new-rule {
                    .btn-all-or-any {
                         font-size: 10px;
                    }
               }
          }

          p {
               color: #2f353a;
               font-family: 'Roboto';
               font-size: 16px;
               font-weight: 400;
               line-height: 23px;
               text-align: center;
               margin-bottom: 9px;
               & + .btn-next {
                    margin-top: 17px;
               }
          }
     }

     .btn-open-website {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          max-width: 210px;
          width: 100%;
          min-height: 35px;
          background-color: #18222b;
          color: #ffffff;
          margin-top: 16px;

          > svg {
               width: 14px !important;
               height: 14px !important;
               margin-left: 6px;
               position: relative;
               top: -1px;
               path {
                    fill: #ffffff;
               }
          }
     }

     .btn-next {
          display: block;
          max-width: 170px;
          width: 100%;
          min-height: 35px;
          margin: 10px auto 0;

          &--lg {
               max-width: 210px;
               margin-top: 10px;
          }

          &.disabled {
               background-color: #c8ced3;
               opacity: 1;
          }
     }

     .btn-select {
          margin-bottom: 20px;
          width: 100%;
          height: 45px;
          font-size: 13px;
          letter-spacing: 0.19px;
          font-weight: 700;
          color: #18222b;
          border-radius: 22.5px;
          border: none;
          border-radius: 22.5px;
          background: #f0f3f5;
          text-transform: capitalize;
     }

     &--card {
          max-width: 608px;
          padding: 30px 50px 42px;
          margin: 0 auto;
          width: 100%;
          position: relative;
          border-radius: 12px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 6px rgba(0, 0, 0, 0.1) !important;

          &-lg {
               max-width: 688px;
               .btn-next {
                    margin-top: 30px !important;
               }
               .form-categories--foot-note-link {
                    margin-top: 33px;
               }
               .target-forms-wrap {
                    form {
                         max-height: 500px;
                         .list-rules-and-or {
                              max-height: 480px;
                              overflow-y: auto;
                              overflow-x: hidden;
                              padding-right: 20px;
                              @include scrollbar-effect();
                         }
                    }
               }
          }
          .target-custom-form {
               width: 100%;
               min-width: 210px;
          }

          &::before {
               position: absolute;
               content: '';
               top: 0;
               left: 0;
               width: 100%;
               height: 8px;
               border-radius: 12px 12px 0 0;
               background-image: url('/assets/images/line-rainbow.png');
               background-size: cover;
               background-repeat: no-repeat;
               background-position: center;
          }
          .iframe-forms {
               .instruction {
                    .some-small-amount-text,
                    .more-text {
                         display: none;
                    }
               }
          }
          .script-code {
               position: relative;
               .script {
                    padding-top: 10px;
                    background-color: #272822;
               }
          }
          .code-step-custom-form {
               position: absolute;
               z-index: 99;
               right: 5px;
               top: 5px;
               color: #fff;
          }
     }

     .card-body {
          padding: 0 !important;
     }

     .fc {
          &-box-open {
               display: flex;
               align-items: center;
               padding: 15px 18px 12px 15px;
               background-color: #f8f9fa;
               border-radius: 9px;
               margin: 17px 0 22px;

               p {
                    color: #2f353a;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: -0.1px;
                    margin-bottom: 0 !important;
                    text-align: left;
               }

               a {
                    margin-left: 15px;
                    flex-shrink: 0;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.17px;
                    color: #212c36;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    svg {
                         width: 12px !important;
                         height: 12px !important;
                         margin-left: 8px;
                         position: relative;
                         top: -1px;
                    }
               }
          }

          &-list-categories {
               display: flex;
               justify-content: center;
               margin-bottom: 26px;
               position: relative;

               &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 50%;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    width: 1px;
                    height: calc(100% - 32px);
                    background-color: #ebebeb;
               }
          }
          &-list-wrap {
               flex: 1;
               text-align: left;

               &:not(:last-of-type) {
                    padding-right: 20px;
               }

               &:not(:first-of-type) {
                    padding-left: 20px;
               }
          }
          &-list-title {
               color: #18222b;
               font-size: 13px;
               font-weight: 500;
               line-height: 24px;
               margin-left: 15px;
          }
          &-list-group {
               margin-top: 4px;
          }
          &-list-custom {
               max-height: 205px;
               overflow-y: auto;
               overflow-x: hidden;
               margin-bottom: 14px;

               &::-webkit-scrollbar {
                    width: 5px;
               }

               &::-webkit-scrollbar-track {
                    background: #eeeeee;
                    border-left: 2px solid #ffffff;
               }

               &::-webkit-scrollbar-thumb {
                    background: #cccccc;
                    border-left: 2px solid #ffffff;
               }

               &::-webkit-scrollbar-thumb:hover {
                    background: #aaaaaa;
               }
          }
          &-list-item {
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               width: 100%;
               max-width: 244px;

               &:not(:last-child) {
                    margin-bottom: 5px;
               }

               .fc-checkbox-custom {
                    margin-right: 12px;
                    flex: 1;
                    ~ .custom-validate {
                         flex-basis: 100%;
                    }
               }
               .ficon-remove {
                    cursor: pointer;
               }
          }
          &-btn-add {
               display: inline-flex;
               align-items: center;
               cursor: pointer;

               span {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14px;
                    letter-spacing: 0.17px;
                    color: #73818f;
                    text-transform: uppercase;
                    margin-left: 7px;
               }
          }

          &-category-icon {
               padding: 0;
               border: 0;
               background: transparent !important;
               &--add {
                    margin-right: 5px;
               }
               svg {
                    width: 14px !important;
                    height: 14px !important;
               }
          }

          &-table {
               margin-top: 22px;
               text-align: left;
               > span {
                    color: #18222b;
                    font-family: 'Roboto';
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 24px;
                    display: block;
                    margin-bottom: 9px;
               }

               table {
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 0;
                    margin-bottom: 21px;

                    th,
                    td {
                         padding: 7px 17px 8px;
                    }

                    td {
                         color: #73818f;
                         font-family: 'Roboto';
                         font-size: 14px;
                         font-weight: 400;
                         letter-spacing: 0.3px;
                    }

                    thead {
                         color: #ffffff;
                         font-family: 'Roboto';
                         font-size: 13px;
                         font-weight: 500;
                         letter-spacing: 0.18571427px;
                         th {
                              background-color: #18222b;
                              &:nth-of-type(1) {
                                   width: 146px;
                                   border-left: 1px solid #f0f3f5;
                                   border-top-left-radius: 12px;
                              }
                              &:nth-of-type(2) {
                                   width: 115px;
                              }
                              &:nth-of-type(3) {
                                   width: 98px;
                                   padding-left: 8px;
                                   padding-right: 0;
                              }
                              &:nth-of-type(4) {
                                   padding-left: 38px;
                                   width: 220px;
                              }
                              &:nth-of-type(5) {
                                   width: 200px;
                                   padding-left: 38px;
                                   border-top-right-radius: 12px;
                                   border-right: 1px solid #f0f3f5;
                              }
                         }
                    }

                    tbody {
                         tr {
                              td {
                                   padding-top: 8px;

                                   &:nth-of-type(1) {
                                        border-left: 1px solid #f0f3f5;
                                   }

                                   &:nth-of-type(2) {
                                        .tooltip-wrapper {
                                             position: relative;
                                             span {
                                                  cursor: pointer;
                                                  display: block;
                                                  max-width: 115px;
                                                  overflow: hidden;
                                                  white-space: nowrap;
                                                  text-overflow: ellipsis;
                                             }

                                             .tooltip-copy {
                                                  display: flex;
                                                  align-items: center;
                                                  gap: 20px;
                                             }

                                             .icon-copy {
                                                  color: $primary;
                                                  background-color: #fff;
                                                  border-radius: 12px;
                                                  padding: 2px 10px;
                                                  font-size: 12px;
                                                  font-weight: 600;
                                                  cursor: pointer;
                                             }

                                             .block-tooltip {
                                                  width: max-content;
                                                  max-width: 600px;
                                                  &-text {
                                                       display: flex;
                                                       flex-direction: column;
                                                       row-gap: 10px;
                                                  }
                                             }
                                        }
                                   }
                                   &:nth-of-type(3) {
                                        text-align: center;
                                   }
                                   &:nth-of-type(4) {
                                        vertical-align: top;
                                   }
                                   &:nth-of-type(5) {
                                        border-right: 1px solid #f0f3f5;
                                        vertical-align: top;
                                   }
                              }

                              &:nth-of-type(odd) {
                                   td {
                                        background: rgba($color: #c8ced3, $alpha: 0.2);
                                   }
                              }

                              &:last-of-type {
                                   td {
                                        border-bottom: 1px solid #f0f3f5;
                                        &:first-of-type {
                                             border-bottom-left-radius: 12px;
                                        }
                                        &:last-of-type {
                                             border-bottom-right-radius: 12px;
                                        }
                                   }
                              }
                         }
                    }

                    .react-select__menu {
                         margin-top: 3px;
                         border-radius: 12px;

                         .no-options {
                              max-height: 310px;
                              padding-top: 0;
                              padding-bottom: 0;
                         }
                    }
               }

               &-select {
                    min-height: 33px;

                    .react-select {
                         $space-left: 13px;
                         &__placeholder {
                              color: #212c36 !important;
                              font-family: 'Roboto';
                              font-size: 14px;
                              font-weight: 400;
                              letter-spacing: 0.2px;
                         }
                         &__control {
                              min-height: 33px !important;
                              background-image: url('/assets/icons/icon-select-new.svg');
                              background-position-x: calc(100% - 9px);
                              background-size: 4px;
                              .option-row {
                                   > div {
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        max-width: 160px;
                                   }
                              }
                         }

                         &__single-value {
                              max-width: calc(100% - 31px);
                              left: $space-left;
                         }
                         &__value-container {
                              padding-left: $space-left;
                              padding-right: 18px;
                         }

                         &__option {
                              .option-create-categories {
                                   width: 100%;
                                   padding: 0;
                                   font-size: 11px;
                                   text-align: left;
                                   text-transform: uppercase;
                                   text-decoration: none;
                                   font-weight: 500;

                                   i {
                                        font-size: 11px;
                                        margin-right: 5px;
                                   }
                                   &:hover {
                                        text-decoration: underline;
                                   }
                              }
                         }

                         &__menu {
                              border-radius: 12px;
                         }
               
                         &__menu-list {
                              padding: 0;
                         }
                    }
               }
          }
     }

     .tag-html {
          display: flex;
          align-items: center;
          background-color: #f8f9fa;
          border-radius: 9px;
          min-height: 45px;
          padding: 5px 14px;

          &:not(:last-child) {
               margin-bottom: 10px;
          }

          .title {
               font-size: 14px;
               font-weight: 500;
               color: #212c36;
               margin-bottom: 0;
               max-width: 229px;
               width: 100%;
               text-align: left;
          }

          .description {
               font-size: 13px;
               text-align: left;
               color: #7f8c9a;
               margin-bottom: 0;
          }
     }

     .tag-file-upload {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f8f9fa;
          min-height: 207px;
          font-size: 14px;
          font-weight: 500;
          border-radius: 9px;
          margin-top: 14px;
          margin-bottom: 25px;
          // cursor: pointer;

          > img {
               width: 100%;
               max-width: 100%;
               height: auto;
          }
     }

     .tag-note {
          color: #121920 !important;
          font-size: 14px !important;
          margin-top: 24px;
          padding-bottom: 10px;
     }

     .target-forms-wrap {
          margin: 29px 0 30px;
          .conditions-group.card {
               background-color: #f8f9fa;
               // padding: 19px 15px 24px 22px;
               .card-body {
                    padding: 12px 20px !important;
               }
          }

          .button-new-rule {
               .btn-all-or-any {
                    min-width: 45px;
                    background-color: #7f8c9a;
                    color: #ffffff;
                    font-size: 13px;
                    padding: 3px 0;
               }
          }

          .conditions-match {
               justify-content: space-between;
               .rule-col {
                    margin-right: 10px;
                    text-align: left;
                    flex: 1;

                    > select {
                         background-color: #ffffff;
                    }

                    &-attribute {
                         max-width: 130px;
                         width: 100%;
                    }
                    &-operator {
                         max-width: 170px;
                         width: 100%;
                    }
               }
               .button-delete-rule {
                    margin-left: 2px;
                    margin-top: 24px;

                    > svg {
                         width: 25px !important;
                         height: 25px !important;
                    }
               }
               &:not(:nth-child(2)) {
                    .button-delete-rule {
                         margin-top: 0;
                    }
               }

               &-header {
                    label {
                         color: #2f353a !important;
                         font-weight: 500;
                    }
               }

               label {
                    font-size: 11px;
               }
          }
     }

     .iframe-forms {
          margin: 30px 0;
          .switch-status {
               background-color: #ffffff;
               border: 1px solid #f0f3f5;
               box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 6px rgba(0, 0, 0, 0.1);
               padding-left: 32px;
               padding-right: 21px;
               min-height: 72px;
               border-radius: 12px;
               justify-content: space-between;
          }
     }

     .btn-test-form {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
          max-width: 197px;
          min-height: 35px;
          width: 100%;
          margin-top: 20px;

          > svg {
               width: 13px !important;
               height: 13px !important;
          }
     }

     .custom-form-list {
          margin-top: 28px;
          .fc-list-item {
               max-width: 100%;

               &:not(:last-child) {
                    margin-bottom: 7px;
               }
          }
          .fc-checkbox {
               background-color: #f8f9fa;
               border-color: #d8d8d8;
               border-radius: 9px;
               box-shadow: none;
               min-height: 45px;
               cursor: default;
               span {
                    font-size: 15px;
                    font-weight: 500;
                    color: #212c36;
               }
          }
          .fc-checkbox-icon {
               width: 23px;
               height: 23px;
               flex: 0 0 23px;
          }

          + p {
               margin-top: 34px;
          }
     }

     .btn-custom-form-live {
          margin: 20px auto auto;
     }

     .form-support-ticket {
          margin-top: 0;
          margin-bottom: 42px;
          textarea {
               height: 148px;
               background-color: #f0f3f5;
               border-radius: 12px;
          }
     }

     .wait-up-button {
          display: flex;
          justify-content: center;
          .btn {
               margin: 0;
               max-width: 130px;
               font-size: 13px;
          }
          .btn-skip-step {
               margin-right: 20px;
               background-color: #ffffff;
               color: #73818f;
               border: 1px solid #73818f !important;
          }
          .btn-go-back {
               display: flex;
               align-items: center;
               justify-content: center;
               background-color: #212c36;
               color: #ffffff;

               > svg {
                    width: 9px;
                    height: 9px;
                    margin-right: 8px;
                    path {
                         fill: #ffffff;
                    }
               }
          }
     }

     &--foot-note {
          display: block;
          color: #7f8c9a;
          font-family: Roboto;
          margin: 26px auto 0;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;

          &.below-text {
               max-width: 480px;
               padding-right: 37px;
          }
     }

     &--foot-note-link {
          margin-top: 2px;

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 32px;

          button {
               color: #7f8c9a;
               font-family: 'Roboto';
               font-size: 13px;
               font-weight: 400;
               text-align: center;
               text-decoration: underline;
               padding: 0;
               text-transform: none;

               &:hover {
                    color: #7f8c9a;
                    text-decoration: none;
               }
          }
     }

     &--choose-categories {
          padding-left: 46px;
          padding-right: 34px;
          p {
               max-width: 516px;
               margin-left: auto;
               margin-right: auto;
          }
     }

     &--fallback {
          padding-bottom: 39px;
          .fc {
               &-list-title {
                    margin-left: 0;
               }

               &-list-wrap {
                    text-align: center;
                    margin-top: 24px;
                    padding-right: 0 !important;
               }

               &-list-item {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 271px;
               }

               &-list-custom {
                    max-height: 450px;
                    padding-bottom: 10px;
               }

               &-list-group {
                    margin-top: 14px;
                    margin-bottom: 6px;
               }

               &-checkbox-custom {
                    & + button {
                         margin-right: -26px;
                    }
               }

               &-checkbox-wrap {
                    span {
                         width: 100%;
                         max-width: 245px;
                         overflow-x: hidden;
                    }
               }
          }

          .btn-next {
               margin-top: 23px;
          }
     }

     &--few-forms {
          max-width: 1024px;
          padding-left: 60px;
          padding-right: 60px;
          .form-categories--foot-note-link {
               margin-top: 20px;
               button {
                    font-size: 14px;
               }
          }
          .ignore-form {
               position: relative;
               .btn-link {
                    padding-left: 0;
                    text-decoration: underline;
                    text-transform: initial;
               }
               .tooltip-wrapper {
                    position: absolute !important;
                    top: 12px;
                    right: -10px;
                    .arrow-preview {
                         path {
                              fill: #fff !important;
                         }
                    }
               }
               .block-tooltip {
                    width: auto !important;
               }
               .block-tooltip-text {
                    background-color: #fff;
                    color: #19222b;
               }
          }
     }

     .confirm-save-popup.warning-publish-popup {
          .modal-dialog { 
               width: 480px;
               .icon-close-popup {
                    position: absolute;
                    top: 20px;
                    right: 15px;
               }
          }
     }

     .confirm-save-popup.popup-create-categories {
          .modal-dialog {
               max-width: 480px;
          }
          .confirm-save {
               width: 100%;
               max-width: 100%;
               padding: 30px 36px 35px !important;

               &-icon {
                    padding-top: 30px;
               }

               &-content {
                    font-size: 16px;
                    margin-top: 10px;
                    margin-bottom: 22px;
                    max-width: 437px;
                    font-weight: 400;
               }

               form {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
               }

               .form-group {
                    margin-top: 20px;
                    width: 88%;
               }

               input.form-control {
                    border-radius: 3.25rem;
                    min-height: 40px;
                    padding: 6px 30px 6px 21px;
               }
          }
     }
}

.schedule-saved-report .modal-dialog {
     max-width: fit-content;
}

.modal-open-schedule {
     overflow-y: hidden;
}

.schedule-saved-report {
     #emailList {
          .react-select__placeholder {
               color: gray !important;
          }
     }
     .modal-content {
          background: unset;
          .goalsContent {
               .info-title {
                    text-align: left;
               }
               .title {
                    p {
                         padding: 0 70px 20px 70px;
                    }
               }

          }
     }
     .remove-popup, .invite-user-owner-popup {
          .modal-content {
               background: #fff
          }
     }
     .invite-user-owner-popup {
          .icon-close-popup {
               top: 0;
               right: 0;
          }
          .fade {
               background: #00000036;
          }
          .modal-content {
               padding: 30px 35px 10px;
               width: 100%;
               max-width: 550px;
          }
     }
     .list-rules-data {
          max-height: 270px;
          overflow-y: auto;
          &::-webkit-scrollbar {
               width: 2px;
               height: 5px;
               position: relative;
               top: 10px;
          }
          &::-webkit-scrollbar-track {
               background-color: rgb(226, 225, 225);
               border-bottom-left-radius: 9px;
               border-bottom-right-radius: 9px;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #bdbcbc;
               border-radius: 15px;
          }
          p {
               padding-left: 0 130px;
          }
     }
     .remove-popup .modal-dialog {
          max-width: 100%;
     }
     .tooltip-schedule {
          text-align: left;
     }
}

.tooltip-schedule-card {
     margin-left: 10px !important;
     margin-bottom: 3px;
     cursor: pointer;

     svg path {
          fill: #20a8d8 !important;
     }
     .tippy-content {
          max-width: 300px;
     }
     .email-block-tooltip {
          ul {
               margin-bottom: 0;
          }
     }
     .email-list-tooltip {
          overflow-y: auto;
          max-height: 120px;
          &::-webkit-scrollbar {
               width: 1px;
               height: 5px;
               position: relative;
               top: 10px;
          }
          &::-webkit-scrollbar-track {
               background-color: rgb(226, 225, 225);
               border-bottom-left-radius: 9px;
               border-bottom-right-radius: 9px;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #bdbcbc;
               border-radius: 15px;
          }
     }
}

.schedule-detail {
     .btn-invite-user {
          // display: flex;
          // justify-content: end;
          margin-top: 10px;
          text-decoration: underline;
          color: #1b8eb7;
     }
     .modal {
          &::-webkit-scrollbar {
               width: 0.5px;
               height: 5px;
               position: relative;
               top: 10px;
          }
          &::-webkit-scrollbar-track {
               background-color: rgb(226, 225, 225);
               border-bottom-left-radius: 9px;
               border-bottom-right-radius: 9px;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #bdbcbc;
               border-radius: 15px;
          }
     }
     .modal-content {
          max-height: 90vh;
     }
     .form-group {
          .title {
               display: flex;
               flex-direction: column;
          }
          text-align: left;
     }
     .day-of-week {
          background: #f0f3f5;
          color: #0069d9;
          &:hover {
               color: #f0f3f5;
               background: #0069d9;
          }
     }
     .day-of-week.checked {
          color: #f0f3f5;
          background: #0069d9;
          
     }
}


.confirm-save-popup.popup-create-categories {
     .invalid-feedback {
          text-align: left;
     }
}
