.modal-ticket {
     .modal {
          &-dialog {
               max-width: 608px;
          }

          &-content {
               border-radius: 12px;
               overflow: hidden;
               padding: 45px 40px 40px;
               position: relative;

               &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 7px;
                    background-image: url("/assets/images/line-rainbow.png");
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
               }
          }

          &-body {
               padding: 0;
          }
     }

     h3 {
          background: linear-gradient(161deg, #29263e 0%, #233b7d 37.08%, #20a8d8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          font-family: "Poppins";
          font-size: 20px;
          font-weight: 700;
          line-height: 47px;

          margin-bottom: 6px;
     }

     &-icon {
          margin-bottom: 2px;
          svg {
               width: 48px !important;
               height: 38px !important;
          }
     }

     &-content {
          max-width: 488px;
          margin: 0 auto;

          color: #2f353a;
          font-family: "Roboto";
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
     }

     &-editor {
          margin-top: 20px;
          margin-bottom: 14px;
          textarea {
               border: none;
               border-radius: 9px;
               padding: 12px 17px;
               width: 100%;
               height: 93px;
               background: #f8f9fa;
               color: #929497;
               font-family: "Roboto";
               font-size: 15px;
               font-weight: 400;
               line-height: 23px;

               &::-webkit-scrollbar {
                    width: 5px;
                    border-radius: 12px;
               }

               &::-webkit-scrollbar-track {
                    background: #eeeeee;
                    border-radius: 12px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #888888;
                    border-radius: 12px;
               }

               &::-webkit-scrollbar-thumb:hover {
                    background: #333333;
               }
          }
     }

     &-button-group {
          display: flex;
          align-items: center;
          justify-content: space-between;
     }

     &-attach {
          label {
               margin-bottom: 0;
               color: #929497;
               font-family: "Roboto";
               font-size: 13px;
               font-weight: 400;
               line-height: 23px;

               display: flex;
               align-items: center;
               gap: 9px;

               cursor: pointer;

               svg {
                    width: 11px !important;
                    height: 13px !important;
               }
          }

          span {
               position: relative;
               top: 1px;
               cursor: pointer;
          }
     }

     &-button {
          display: flex;
          align-items: center;
          gap: 25px;

          .btn-link {
               padding: 0;
               color: #929497;
               font-family: "Roboto";
               font-size: 13px;
               font-weight: 400;
               line-height: 23px;
               text-decoration: underline;
               text-transform: none;
               &:hover {
                    color: #929497;
                    text-decoration: none;
               }
          }

          .btn-primary {
               font-size: 13px;
               font-weight: 700;
               min-width: 130px;
               padding: 8px 15px;
          }
     }
}

.custom-validate {
     text-align: left;
     display: block;
     font-size: 11px;
     color: $danger;
}
