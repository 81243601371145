.confirmation-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;

    button {
        &.btn-next{
            margin: unset;
        }
    }

    .btn-go-back {
        background-color: #d9e1e6;
        color: #20a8d8;
    }
}

.icon-loading-rotate {
    margin: 6px 0 15px;
    animation: rotate 1.2s linear infinite;
    transform-origin: 49% 58%;
}

@keyframes rotate {
    from {
        rotate: 0;
    }
    to {
        rotate: 360deg;
    }
}

.btn-build-conversions {
    max-width: 200px !important;
}

.cc-list-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 20px 0 !important;
}

.config-conversion {
    &--card {
        max-width: 640px;
        padding: 30px 50px 42px;
        margin: 0 auto;
        width: 100%;
        position: relative;
        border-radius: 12px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 6px rgba(0, 0, 0, 0.1) !important;

        .target-custom-form {
            width: 100%;
            min-width: 210px;
        }

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 8px;
            border-radius: 12px 12px 0 0;
            background-image: url('/assets/images/line-rainbow.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .fc-list-categories {
            &.conversion-list::before {
                content: none;
            }
        }

        .fc-list-wrap {
            display: inline-block;
            .fc-list-custom {
                max-width: unset;
        
                .fc-list-item {
                    &.select-conversion {
                        .conversion-name {
                            max-width: 165px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                    &.create-conversion {
                        min-width: 240px;
                        max-width: unset;
                        .conversion-name {
                            max-width: 254px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        .iframe-forms {
            .instruction {

                .some-small-amount-text,
                .more-text {
                    display: none;
                }
            }
        }

        .script-code {
            position: relative;

            .script {
                padding-top: 10px;
                background-color: #272822;
            }
        }

        .code-step-custom-form {
            position: absolute;
            z-index: 99;
            right: 5px;
            top: 5px;
            color: #fff;
        }
    }

    &--select-conversion {
        padding-left: 46px;
        padding-right: 34px;

        p {
            max-width: 560px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

p {
    &.text-out-cart-trigger {
        font-size: 13px !important;
        cursor: pointer;
        text-decoration: underline;
        text-align: center;
    }
}

.define-configure-conversion {
    &.card {
        border-radius: 12px;
        border: 1px solid #f0f3f5 !important;
        box-shadow:
            0px 10px 10px -5px rgba(0, 0, 0, 0.04),
            0px 20px 25px -5px rgba(0, 0, 0, 0.1) !important;
    }

    h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 3px;
    }
    .card-body {
        padding: 34px 42px 30px;
    }

    .define-configure-conversion--get-started {
        .btn-success {
            margin: 10px 0;
            font-size: 15px;
            line-height: 18px;
            padding: 10px 45px;
            min-height: 44px;
        }

        p {
            &.define-configure-conversion--sub-p {
                font-size: 14px;
            }

            &.define-configure-conversion--subtitle {
                color: #2f353a;
                font-size: 16px;
                line-height: 23px;
            }

            &.text-estimated {
                font-size: 15px;
            }

            &.text-trigger {
                font-size: 13px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}