.fc-checkbox {
     display: flex;
     flex-direction: column;
     justify-content: center;
     width: 100%;
     min-height: 36px;
     padding-left: 14px;
     padding-right: 10px;
     margin: 0;
     border-radius: 12px;
     border: 1px solid #f0f3f5;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
     cursor: pointer;

     &-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 0px;
          span {
               text-align: left;
               word-break: break-all;
               display: block;
               white-space: pre;
          }
     }

     &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 21px;
          width: 21px;
          height: 21px;
          margin-left: 10px;
          border-radius: 50%;
          background: #c8ced3;

          svg {
               visibility: hidden;
          }
     }

     input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;

          &:checked + .fc-checkbox-wrap .fc-checkbox-icon {
               background: #4dbd74;

               svg {
                    visibility: visible;
               }
          }
     }

     span,
     input[type="text"] {
          color: #2f353a;
          font-size: 13px;
          line-height: 18px;
     }

     &-custom:not(.fc-checkbox-not-text) {
          flex-direction: row;
          padding-left: 0;
          cursor: auto;

          input[type="text"] {
               width: 100%;
               border: none;
               padding: 0 14px;
               border-radius: inherit;
          }

          label {
               display: flex;
               flex-direction: column;
               justify-content: center;
               margin: 0;
               cursor: pointer;
          }

          .fc-checkbox-icon {
               margin-left: 0;
          }
     }
}
