//segment chart report
.segment-chart-report {
     padding: 20px;
     background-color: #f9f9f9;
     border-radius: 10px;
     width: 100%;
     max-width: 600px;
     margin: 0 auto;

     .header {
          margin-bottom: 20px;
     }

     .tooltip-wrapper {
          padding: 4px 12px;
          background: rgba(233, 246, 251, 1);
          border-radius: 999px;
          border: 1px solid rgba(32, 168, 216, 1);
          color: rgba(32, 168, 216, 1);
          position: relative;
          &:hover {
               background: rgba(32, 168, 216, 0.3);
               path:first-child {
                    fill: rgba(32, 168, 216, 0.3);
               }
          }
     }

     .block-tooltip {
          &-text {
               position: relative;
               &:hover {
                    path:first-child {
                         fill: unset;
                    }
               }
          }
          .content {
               padding-right: 20px;
          }
          .title {
               path:first-child {
                    stroke: #fff;
                    fill: #000;
               }
               path:last-child {
                    fill: #fff;
               }
          }

          .icon-close {
               position: absolute;
               right: 17px;
               top: 10px;
               path {
                    fill: rgba(107, 114, 128, 1) !important;
               }
          }
     }

     .wrapper-content {
          gap: 100px;
     }

     .percentage {
          &-icon {
               max-width: 100%;
               padding: 4px 8px;
               display: inline-block;
               border-radius: 6px;
               background: rgba(77, 189, 116, 1);
               color: #fff;
               &.trending-down {
                    background: rgba(248, 108, 107, 1);
               }
               &-number {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14.06px;
                    text-align: left;
               }
          }
     }

     .percentage-change {
          .up {
               color: #4caf50;
          }
     }

     .data-numbers {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-end;
          margin-bottom: 20px;
     }

     .icon-period {
          position: relative;
          padding-right: 12px;

          &::after {
               content: '';
               position: absolute;
               width: 10px;
               height: 10px;
               border-radius: 100%;
               right: 0px;
               transform: translateY(-50%);
               top: 50%;
          }

          &.current {
               &::after {
                    background: rgba(255, 193, 7, 1);
                    border: 0.5px solid rgba(179, 134, 0, 1);
               }
          }

          &.previous {
               padding-right: 20px;

               &::after {
                    background: rgba(209, 213, 219, 1);
                    border: 0.5px solid rgba(107, 114, 128, 1);
               }
          }
     }

     .data-numbers {
          .current-period {
               font-size: 2rem;
               margin-right: 10px;
          }

          .previous-period {
               color: #999;
          }
     }

     .charts {
          flex-wrap: wrap;
          justify-content: space-between;
     }

     .chart {
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
          width: 100%;
          height: 60px;
     }

     .bar {
          width: 20px;
          background-color: #fbbf24;
          margin: 0 2px;
          transition: height 0.3s ease;
     }

     .previous-period-chart {
          .bar {
               background-color: #ddd;
          }
     }
}
