.c-switch {
	display: inline-block;
	width: 40px;
	height: 26px;
}
.c-switch-sm {
     width: 32px;
     height: 22px;
}
.c-switch-lg {
     width: 48px;
     height: 30px;
}
.c-switch-input {
     position: absolute;
     z-index: -1;
     opacity: 0;
}
.c-switch-sm .c-switch-slider {
     font-size: 8px;
}
.c-switch-sm .c-switch-slider:before {
     width: 18px;
     height: 18px;
     top: 1px;
     left: 1px;
}
.c-switch-lg .c-switch-slider {
     font-size: 12px;
}
.c-switch-lg .c-switch-slider:before {
     width: 24px;
     height: 24px;
}
.c-switch-slider {
     position: relative;
     display: block;
     height: inherit;
     cursor: pointer;
     border: 1px solid #e4e7ea;
     border-radius: .25rem;
     box-shadow: none !important;
}
.c-switch[class*="-3d"] .c-switch-slider {
     background-color: #f0f3f5;
     border-radius: 50em;
}
.c-switch-3d-primary .c-switch-input:checked+.c-switch-slider {
     background-color: #20a8d8;
}
.c-switch-slider:before {
     position: absolute;
     top: 2px;
     left: 2px;
     width: 20px;
     height: 20px;
     content: '';
     border: 1px solid #e4e7ea;
     border-radius: .125rem;
     box-sizing: border-box;
}
.c-switch-slider, .c-switch-slider:before {
	background-color: #fff;
	transition: .15s ease-out;
}
.c-switch[class*="-3d"] .c-switch-slider:before {
     top: -1px;
     left: -1px;
     width: 26px;
     height: 26px;
     border: 0;
     border-radius: 50em;
     box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}
.c-switch[class*="-3d"].c-switch-sm {
     width: 32px;
     height: 22px;
}
.c-switch[class*="-3d"].c-switch-sm .c-switch-slider:before {
     width: 22px;
     height: 22px;
}
.c-switch[class*="-3d"].c-switch-sm .c-switch-input:checked~.c-switch-slider:before {
     transform: translateX(10px);
}
.c-switch-input:checked~.c-switch-slider:before {
     transform: translateX(14px);
}
.c-switch-pill .c-switch-slider, .c-switch-pill .c-switch-slider:before {
	border-radius: 50em;
}
.c-switch-success .c-switch-input:checked+.c-switch-slider:before {
	border-color: #4dbd74;
}
.c-switch-success input[type='checkbox']:checked + .c-switch-slider {
     background-color: #4dbd74;
     border-color: #4dbd74;
}
.c-switch-input:disabled~.c-switch-slider {
     cursor: not-allowed;
     opacity: .5;
}
.c-switch-sm .c-switch-input:checked ~ .c-switch-slider:before {
     transform: translateX(10px);
}
.c-switch-xl .c-switch-input:checked ~ .c-switch-slider:before {
     transform: translateX(14px);
}
.c-switch-lg .c-switch-input:checked~.c-switch-slider:before {
     transform: translateX(18px);
}