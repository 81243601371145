.custom-browser-buttons {
    display: flex;
    align-items: flex-end;
    height: 57px !important;

    .custom-browser-buttons-list {
        height: 35px;
        align-items: center;

        .icon-add {
            margin-top: 0;
        }
    }

    button {
        @media (max-width: 1199px) {
            /* Your mobile styles here */
            margin-top: 0;
        }
    }

    button.d-inline.addicon.btn {
        margin-left: -14px;
    }
}

.browser-storage-variables {
    .list-fields {
        .form-group {
            .row {
                position: relative;
            }
        }
    }
}

.custom-variable-rules {
    .accordion {
        &>.card {
            border-radius: 12px !important;
            overflow: unset;
        }
    }
    .form-group {
        .invalid-select-custom-variable {
            .css-yk16xz-control,
            .css-1pahdxg-control,
            .react-select__control {
                background-image: none;
                position: relative;
                top: -5px;
                padding: 0;
            }

            .css-g1d714-ValueContainer {
                padding: 0;
            }

            .react-select__menu {
                left: 0px;
            }
        }
    }
}

.custom-browser-buttons.col-md-3.col-lg-2 {
    margin-left: -50px;

    .pb-xl-0 {
        align-items: center;
    }
}

.custom-browser-buttons.col-md-3.custom-browser-buttons-right {
    height: 61px !important;
}

.cvr-create-new {
    .cvr-step-3 {
        .form-group {
            .form-control {
                padding-right: 13px;

                &.is-invalid {
                    padding-right: 30px;
                }
            }
        }
    }
}


select#select-type,
#select-format {
    border-radius: 15px;

    option {
        color: #7f8c9a;
    }
}

.populate-to-fields {
    .css-2b097c-container {
        border: 2px solid #e2e6e9 !important;
    }

    &.is-disabled {
        .css-14jk2my-container {
            background-color: hsl(0, 0%, 95%);
            border-radius: 12px;
        }

        .css-1fhf3k1-control {
            border-radius: 12px;
        }
    }

    .form-group {
        margin-bottom: 0.5rem;

        .form-control {
            padding-right: 13px;
        }
    }

    .invalid-feedback {
        margin: 0;
    }

    .custom-checkbox {
        margin-bottom: 0;

        span {
            color: #3cb1d9;
            cursor: pointer;
            padding-left: 3px;
            line-height: 22px;
            transition: 0.5s all;
            white-space: nowrap;

            &:hover {
                color: #83daf7;
            }
        }

        .custom-control-label {
            line-height: 26px;
        }
    }

    .custom-control-input {
        border: 0;
        outline: 0;
        cursor: pointer;

        &:focus~.custom-control-label::before {
            box-shadow: none;
        }

        ~.custom-control-label {
            cursor: pointer;
        }
    }

    .invalid-feedback {
        margin-top: 0;
    }

    .form-control.is-invalid {
        margin-bottom: 0.5rem;
    }
}

.click-id-settings {
    .rule-row {
        display: flex;
        align-items: flex-start;
        gap: 14px;

        .rule-col {
            width: 100%;
            max-width: 223px;
        }
    }

    #custom-variable-form {
        .list-fields {

            input,
            select {
                &.form-control {
                    border-radius: 12px;
                    border: solid 1px #e2e6e9;
                    min-height: 40px;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                }
            }

            input.form-control {
                &.edit {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: 0;
                }

                +button {
                    border: solid 1px #e2e6e9;
                    border-radius: 0 12px 12px 0;
                    border-left: 0;
                }
            }

            .buttons {
                margin-top: 25px;
                display: flex;
                gap: 5px;

                button {
                    padding: 0;
                    margin: 0;
                }
            }
        }

        .save-changes-button-wrapper {
            display: flex;
            align-items: center;

            .reset-to-default-box {
                margin-top: 0;

                button.disabled {
                    background: none;
                    opacity: 0.8;
                }
            }

            button {
                font-weight: 500;
            }
        }
    }
}