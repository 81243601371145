// If you want to override variables do it here
@import 'base/setting';
@import 'base/mixins';

// Import CoreUI styles
@import "../../node_modules/@coreui/coreui-pro/scss/functions";

// 2. Include any default variable overrides here

@import 'variables';

@import '~@coreui/coreui-pro/scss/coreui.scss';

@import 'mixins/buttons';
@import 'mixins/typography';

// If you want to add something do it here
@import 'theme';

@import 'base/base';
@import 'base/button';
@import 'base/checkbox';
@import 'base/custom';
@import 'base/form';
@import 'base/upgradePackage';
@import 'base/slider';
@import 'base/storybook';
@import 'base/typography';

@import 'lib/popup';

@import 'cms/subscriber/body';
@import 'cms/subscriber/header';
@import 'cms/subscriber/sidebar';
@import 'cms/subscriber/dashboard';
@import 'cms/subscriber/browser-addons';
@import 'cms/subscriber/data-layer-variables';
@import 'cms/subscriber/tag-managers';
@import 'cms/subscriber/compare-plan';
@import 'cms/subscriber/listeners';
@import 'cms/subscriber/website';
@import 'cms/subscriber/browser-storage';
@import 'cms/subscriber/events-variables';
@import 'cms/admin/listeners';
@import 'cms/admin/users';
@import 'cms/subscriber/lookup-rule-table';
@import 'cms/subscriber/cards';
@import 'cms/subscriber/features';
@import 'cms/subscriber/custom-variable-rules';
@import 'cms/subscriber/custom-form-categories';
@import 'cms/subscriber/form-categorization-section';
@import 'cms/subscriber/form-categories-wizard';
@import 'cms/subscriber/fc-checkbox';
@import 'cms/subscriber/users';
@import 'cms/subscriber/profile';
@import 'cms/subscriber/email-notification';
@import 'cms/subscriber/affiliate';
@import 'cms/subscriber/version';
@import 'cms/subscriber/create-account';
@import 'cms/subscriber/choose-how-to-pay';
@import 'cms/subscriber/consent-privacy';
@import 'cms/admin/account';
@import 'cms/subscriber/login';
@import 'cms/subscriber/event_reporting';
@import 'cms/subscriber/selector-rules';
@import 'auth/register.scss';
@import 'auth/activate.scss';
@import 'auth/create-manager.scss';
@import 'auth/certificate.scss';
@import 'media/media.scss';
@import 'cms/subscriber/support-ticket-popup';
@import 'cms/support/ticket';
@import 'cms/subscriber/support-tickets';
@import 'cms/admin/term.scss';
@import 'cms/subscriber/payment-methods';
@import 'cms/admin/term';
@import 'cms/subscriber/notifications';
@import 'cms/admin/redis';
@import 'cms/subscriber/process';
@import 'cms/subscriber/preview';
@import 'cms/subscriber/accordion';
@import 'cms/subscriber/listener';
@import 'cms/subscriber/table-data';
@import 'cms/subscriber/rules';
@import 'cms/subscriber/create-preview-rule';
@import 'cms/subscriber/headline';
@import 'cms/subscriber/tour';
@import 'cms/subscriber/form';
@import 'cms/subscriber/trigger-conversion';
@import 'cms/subscriber/account-dormant-popup';
@import 'cms/subscriber/flexible-modal';
@import 'cms/subscriber/general';
@import 'cms/subscriber/rule-detail';
@import 'cms/subscriber/icons';
@import 'cms/subscriber/select';
@import 'cms/subscriber/record-profile';

@import 'cms/admin/masterClass';
@import 'cms/admin/contract-plan';
@import 'cms/subscriber/masterclass';
@import 'cms/subscriber/gtm-wizard';
@import 'cms/subscriber/destination';
@import 'cms/subscriber/analytics';
@import 'cms/subscriber/goals';
@import 'cms/subscriber/custom-date-picker';
@import 'cms/subscriber/modal-skip';
@import 'cms/subscriber/define-form-categories';
@import 'cms/subscriber/skip-form-categories';
@import 'cms/subscriber/modal-ticket';
@import 'cms/subscriber/manage-goal';
@import 'lib/animation';
@import 'cms/subscriber/data-settings';
@import 'cms/subscriber/configure-conversion';
@import 'cms/admin/reset-password';
@import 'cms/admin/table-data';
@import 'cms/admin/sidebar';

@import 'migration/toggler';
@import 'cms/subscriber/custom-segment-dropdown';
@import 'cms/subscriber/custom-metric-dropdown';
@import 'cms/subscriber/custom-chart-segment';
@import 'cms/subscriber/target-accounts';
