.subscriber {
    &.c-app {
        &:not(.c-legacy-theme) {
            &:not(.c-dark-theme) {
                .header {
                    &.header-fixed {
                        z-index: 1039;
                        border-bottom: 1px solid #e4e7ea;
                    }
                    &.header-sticky{
                        border-bottom: 1px solid #e4e7ea;
                        z-index: 1035;
                    }
                    
                    .get-started-bar {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 50px;
                        background-color: #f86c6b;
                        color: #ffffff;

                        .iconFlag {
                            margin-left: 25px;
                            margin-right: 15px;
                        }

                        .get-started {
                            color: #ffffff;
                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 27px;
                            margin: 0 25px 0 0;
                        }

                        span {
                            font-family: Roboto;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22px;
                        }



                        .complete-btn {
                            background: #e95251;
                            color: #ffffff;
                            font-family: Roboto;
                            font-size: 13px;
                            font-weight: 700;
                            line-height: 16px;
                            text-transform: uppercase;
                            text-decoration: underline;
                            letter-spacing: 0.18571427px;
                            height: 100%;
                            padding: 0 15px 0 20px;
                            margin-left: 15px;
                            border-radius: 0;

                            &:hover {
                                text-decoration: none;
                            }

                            .icon-arrow-bar {
                                margin-left: 12px;
                                width: 6px !important;
                                height: 7px !important;

                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header-toggler {
        min-width: 0px;
    }
}

svg.icon.arrow-account.arrow-setting {
    position: absolute;
    right: -10px;
    width: 71px;
    height: 10px;
    top: -8px;
}

.pt-0.setting-dropdown.dropdown-menu.show:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #fff;
    right: 0px;
    top: 0px;
}

li.header-nav-items.user-nav-item.dropdown.nav-item {
    margin-left: 5px;
}

.pt-0.setting-dropdown.dropdown-menu.show {
    border: 0.5px solid #c8ced3;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin: 7px !important;
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate3d(-220px, 38.5px, 0px) !important;
    min-width: 240px;
    z-index: 1;

    &.preview-dropdown {
        border-color: #f0f3f5;

        >svg path {
            stroke: #f0f3f5 !important;
        }
    }
}

.publish-nav-item {
    .pt-0.setting-dropdown.dropdown-menu.show {
        transform: translate3d(-155px, 40px, 0px) !important;
        min-width: 290px;
        padding: 0;
    }

    .content {
        padding: 20px;

        h4 {
            color: #20a8d8;
        }

        p {
            font-weight: 400;
        }
    }

    button.btn-brand.btn.btn-primary {
        margin: 0;
    }
}

button.header-toggler.ml-3.d-md-down-none {
    margin-left: 20px;
}

button.btn-brand.btn.btn-primary {
    border-radius: 17.5px;
    padding: 6px 19px 5px 17px;
    font-size: 13px;
    font-weight: 700;
    margin-left: 23px;
    margin-right: 15px;
    height: 35px;
    box-shadow: unset;

    svg {
        width: 13px;
        height: 14px;
        transform: translateY(-1px);
    }
}

button.btn-brand.btn.btn-secondary.disabled {
    border-radius: 17.5px;
    padding: 6px 15px 5px 17px;
    // padding-left: 15px;
    // padding-right: 15px;
    font-size: 13px;
    font-weight: 700;
    margin-left: 25px;
    margin-right: 15px;
    height: 35px;

    svg {
        width: 13px;
        height: 14px;
        transform: translateY(-1px);
    }
}

button.btn-datalayer.btn {
    border: 1px solid #63c2de !important;
    border-radius: 17.5px;
    color: #63c2de;
    // max-width: 181px;
    height: 35px;
    font-size: 14px;
    font-weight: 700;
    margin-left: 30px;
    line-height: 20px;
    padding: 0 1.2rem;
    display: flex;
    align-items: center;

    .icon {
        margin: 0;
    }

    span {
        padding-top: 1px;
    }
}

.header-nav {
    margin-right: 10px;

    .nav-item-datalayer {
        svg {
            margin: 0px;
        }
    }

    .nav-item-publish {
        display: flex;
        align-items: center;

        .block-tooltip {
            svg {
                top: 5px;
            }

            .arrow-preview {
                top: 0;
            }
        }

        .versions {
            a {
                text-decoration: underline !important;
                color: #73818f;

                &:hover,
                &.active {
                    text-decoration: none !important;
                    color: #212c36;
                }
            }

            height: 13px;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px;
        }

        .dropdown-header {
            padding: 0.3em !important;
        }
    }

    .setting.dropdown-header {
        padding: 0.25rem;
    }

    button.switch-account-btn.dropdown-toggle.btn {
        background: #d0ecf5;
        border-radius: 9px;
        border: none !important;
    }

    .show button.switch-account-btn.dropdown-toggle.btn {
        border-radius: unset;
    }

    .header-nav-link,
    .header-nav-btn {
        font-weight: 500;
        padding: 0;

        .cil-setting {
            color: #708393;
            &:hover {
                color: #212c36;
            }
            svg {
                width: 17px;
                height: 18px;
            }
        }
    }

    .avatar1 {
        // margin-right: -14px;
        margin-left: -2px;
        position: relative;

        .shape-avatar-icon {
            width: 12px;
            height: 12px;
            background: #ffff;
            padding: 1px;
            position: absolute;
            top: 12px;
            right: -7px;
            border-radius: 50%;
            border: 1px solid #f0f3f5;
        }
    }

    .switch-account-nav {
        // height: 100%;
        border-radius: 9px;

        // border: 1px solid red;
        .switch-account-btn {
            height: 100%;
            // min-width: 282px;
            text-align: left;
            border-radius: 0;
            position: relative;
            border-top: none;
            border-bottom: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            font-weight: 300;
            max-width: 240px;
            min-width: 210px;
            white-space: break-spaces;
            padding-right: 40px;
            text-transform: none;
            word-break: break-word;

            &:after {
                content: '';
                position: absolute;
                top: 45%;
                right: 12px;
                width: 7px;
                height: 7px;
                border: none;
                border-bottom: 2px solid #73818f;
                border-right: 2px solid #73818f;
                transform: translateY(-50%) rotate(45deg);
            }
        }

        .label {
            font-size: 11px;
            margin-bottom: -3px;
            color: #73818f !important;
            font-weight: 500;
            padding-top: 2px;
            font-family: Roboto;
            line-height: 13px;
            padding-left: 7px;
            padding-top: 7px;
        }

        .account-name {
            padding-bottom: 4px;
            color: #212c36;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.2px;
            // padding-left: 7px;
            padding-top: 2px;
            // transform: translateY(5px);
            margin-top: auto;
            margin-bottom: auto;
            // transform: translateX(-2px);
            transform: translate(-2px, 1px);

            .label-dormant {
                display: block;
                color: #b6b6b6;
                font-size: 0.75em;
                margin-top: 5px;
            }
        }

        button.add-account.btn.btn-outline-undefined {
            border-radius: 17.5px;
            border: 1px solid #63c2de;
            background: #ffffff;
            color: #63c2de;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.17142856px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0px 12px;
            height: 35px;

            &:hover {
                background: #f0f3f5;
            }
        }
    }

    .theme-mode-btn {
        .icon:not(.icon-c-s):not(.icon-custom-size) {
            width: 0.9em;
            height: 0.9em;
        }
    }

    .user-nav-item {
        margin: 0 0.8em;
    }

    .account-dropdown,
    .setting-dropdown,
    .account-select {
        box-shadow: none !important;

        .list-account {
            max-height: 450px;
            overflow-y: auto;
            margin-bottom: 10px;

            &::-webkit-scrollbar {
                width: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $gray-300;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                border-left: 2px solid #fff;
                border-right: 2px solid #fff;
            }

            .dropdown-item {
                &::before {
                    content: '';
                    width: calc(100% - 34px);
                    height: 2px;
                    background-color: #f8f9fa;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    position: absolute;
                }
            }
        }

        .dropdown-item {
            line-height: 22px;
            position: relative;
            // padding: 9px 17px;
            padding: 6px 2px;
            display: flex;
            justify-content: space-between;
            white-space: break-spaces;
            word-break: break-word;

            &:hover,
            &:focus,
            &.active {
                background-color: transparent;
                color: $primary;

                // &:not(:last-child) {
                //     &::before {
                //         content: "";
                //         height: 0;
                //     }
                // }
                .settingtille {
                    color: #20a8d8 !important;
                    font-weight: 400;
                }

                .settingdescription {
                    color: #63c2de !important;
                    font-weight: 500;
                }

                .logo-right {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &:active,
            // &:hover {
            //     background-color: $gray-100;
            // }

            &.sign-out {
                // font-weight: 500;
                opacity: 0.7;
                // font-size: rem(12);
            }
        }

        .dropdown-header {
            position: relative;

            .arrow-account {
                position: absolute;
                top: -10px;
                right: 0;
            }
        }
    }

    .account-select {
        .table-accounts {
            >div:not(.table-responsive):first-child {
                .col-auto {
                    border-top: 1px solid #f0f3f5;
                }
                .row.mb-2 {
                    padding-top: 10px;

                    .form-label {
                        display: none;
                    }

                    .form-control {
                        // width: 100%;
                        width: 104%;
                        padding: 0 0 0 5px;
                        margin: 0px -3px;
                        border-radius: 20px;
                        font-size: 14px;
                        text-align: center;

                        &::placeholder {
                            color: #c1c2c4;
                        }
                    }

                    .col-sm-auto {
                        padding: 0;
                    }
                }
            }

            table.table.table-hover {
                margin-bottom: 10px;
            }

            .row.my-2.mx-0 {
                margin: 0 !important;
            }

            .position-relative.table-responsive {
                overflow: hidden;
                // overflow-y: scroll;
            }

            .row {
                padding: 0 12px;
            }

            thead {
                display: none;
            }

            tbody {
                max-height: 393px;
                overflow-y: auto;
                display: block;
                // margin-right: 2px;
                // padding-right: 2px;

                &::-webkit-scrollbar {
                    width: 2px;
                }

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #c8ced3;
                    border-radius: 2px;
                }

                tr,
                td {
                    display: block;
                    width: 100%;
                    border: none;

                    &:hover {
                        background-color: #f0f3f5;
                    }
                }

                td {
                    position: relative;
                    line-height: 22px;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    background-color: unset;
                    box-shadow: unset;
                }

                .text-center {
                    margin-left: auto;
                    margin-right: auto;

                    h2 {
                        font-size: 14px;
                    }

                    svg {
                        display: none;
                    }
                }

                tr {
                    padding: 0 15px;
                    &:hover {
                        background-color: #f0f3f5;
                    }

                    .dropdown-item {
                        background-color: unset;
                        border-bottom: 1px solid #f0f3f5;
                    }

                    &:first-child {
                        .dropdown-item {
                            border-top: 1px solid #f0f3f5;
                        }
                    }
                }
            }

            &.no-filter {
                >div:not(.table-responsive):first-child {
                    display: none;
                }

                tbody {
                    max-height: 450px;
                    margin-right: 0;
                    padding-right: 0;
                }
            }

            .account-name {
                padding-left: 0px;
            }
        }
    }

    //show
    .switch-account-nav.dropdown.show .switch-account-btn:after {
        content: '';
        position: absolute;
        top: 57%;
        right: 12px;
        width: 7px;
        height: 7px;
        border: none;
        border-bottom: 2px solid #73818f;
        border-right: 2px solid #73818f;
        transform: translateY(-50%) rotate(226deg);
    }

    .switch-account-nav.dropdown {
        button.switch-account-btn.dropdown-toggle.btn {
            border: 1px solid #d0ecf5 !important;
            border-bottom: 0 !important;
        }

        &.show {
            button.switch-account-btn.dropdown-toggle.btn {
                background: none;
                border-top-left-radius: 9px;
                border-top-right-radius: 9px;
                border: 1px solid #f0f3f5 !important;
                border-bottom: 0 !important;
            }

            button.switch-account-btn.dropdown-toggle.btn {
                border-radius: none;
            }
        }
    }

    .account-select.dropdown-menu.show {
        border: 1px solid #f0f3f5;
        border-top: unset;
        // margin-top: -6px !important;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
    }

    //endshow

    .account-dropdown,
    .setting-dropdown {
        top: 19px !important;
        left: -75px;
        min-width: 248px;

        .settingtille {
            height: 5px;
            color: #212c36;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.2px;

            .logo-right {
                margin-left: 5px;
                opacity: 0;
                visibility: hidden;
                transform: rotateX(40deg) translateY(-1px);
            }
        }

        .settingdescription {
            height: 0px;
            color: #c8ced3;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
        }

        .dropdown-header {
            span {
                font-size: rem(14);
                color: #18222b;
                font-weight: 500;
            }

            p {
                font-size: 11px;
            }

            .account-user-email {
                color: $secondary;
            }
        }

        .dropdown-item {
            padding-left: 25px;
            padding-right: 30px;
            color: #7a8c9b;

            &:hover {
                &:before {
                    content: none !important;
                }
            }
        }
    }

    // .setting-dropdown {
    //     .dropdown-item:last-child {
    //         margin-bottom: 16px;
    //     }
    // }

    .account-dropdown {
        .dropdown-item:last-child {
            margin-bottom: 0px;
        }
    }

    .account-select {
        width: 100%;
        border: 0;
        border-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &.right {
        margin-left: 16px;
    }

    button.header-toggler.ml-3.d-md-down-none.toggler-left {
        transition: 0.8s;
    }

    button.header-toggler.ml-3.d-md-down-none.toggler-right {
        transition: 0.8s;
        transform: rotateY(180deg);
    }

    button.header-toggler.toggle-sidebar-mobile {
        transition: all 0.8s ease;
    }

    button.header-toggler.toggle-sidebar-mobile.toggler-right {
        transition: 0.8s;
        transform: rotateY(180deg);
    }
}

@keyframes open-dropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.header {
    .account-wrap {
        display: flex;
        align-items: center;
    }

    .acount {
        .your-account {
            width: 92px;
            height: 17px;
            color: #73818f;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            // transform: translate(-3px, 8px);
            transform: translate(-1px, 8px);
        }
    }

    .overflow-auto {
        overflow: hidden !important;
    }

    &.open-dropdown {
        .c-subheader {
            position: relative;
            min-height: 29px !important;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(#000000, 0.2);
                z-index: 100;
                left: 0;
            }
        }

        +.c-body {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                // background-color: rgba(#000000, 0.2);
                background-color: #f8f9fa9e;
                z-index: 100;
                animation: open-dropdown 0.3s ease forwards;
            }
        }

        +.c-body+.c-footer {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                // background-color: rgba(#000000, 0.2);
                background-color: #f8f9fa9e;
                z-index: 100;
            }
        }
    }

    .switch-account-nav {
        margin-right: rem(15);
    }

    .header-dropdown-notif,
    .header-dropdown-tasks {
        a {
            padding: rem(10);
        }

        .dropdown-item {

            &:active,
            &:focus {
                background-color: $gray-100 !important;
            }
        }

        .badge-pill {
            font-size: rem(8.5);
            padding: 3px 8px 2px;
        }
    }

    .user-nav-item {
        margin-left: rem(20);
    }
}

.text-unpublished {
    font-size: 11px;
    font-weight: 500;

    &+svg {
        cursor: pointer;
        width: 11px;
        height: 11px;
        margin-left: 5px;
    }
}

@media screen and (max-width: 1222px) {
    .subscriber {
        &.c-app {
            &:not(.c-legacy-theme) {
                &:not(.c-dark-theme) {
                    .header {
                        .get-started-bar {
                            .get-started {
                                font-size: 15px;
                            }

                            span {
                                font-size: 14px;
                            }

                            .complete-btn {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.btn-outline-success {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    margin: auto;
    transform: translateY(-7px);
}

@media screen and (max-width: 1580px) {
    .header-nav {

        &.nav-account-profile .switch-account-nav .switch-account-btn {
            min-width: 180px;
        }

        .account-select .dropdown-item {
            min-width: 150px;
        }

        .switch-account-nav {
            .account-name {
                font-size: 12px;
            }

            button.add-account.btn.btn-outline-undefined {
                font-size: 11px;
            }
        }

        button.btn {

            &.btn-brand,
            &.btn-brand.disabled,
            &.btn-datalayer {
                font-size: 12px;
            }
        }
    }

    .c-wrapper.v2 {
        .header {
            button.btn-datalayer.btn {
                margin-left: 10px;
            }

            .notification-bell {
                margin-right: 10px;
            }

            .header-nav .user-nav-item {
                margin: 0;
            }

            .acount .your-account {
                font-size: 11px;
                width: auto;
            }
        }

        .dashboard-event-filters>div {
            flex: 0 0 100%;
            max-width: 100%;

            &.filter-event {
                flex: 0 0 calc(100% - 67px);
            }

            &.filter-buttons {
                flex: 0 0 67px;
            }
        }

        .dashboard-event .visitor-session {
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 1430px) {
    .c-wrapper.v2 {
        .header-nav .nav-item-publish {
            flex-direction: column;
            padding: 5px;
            text-align: center;
        }

        .nav-account-profile .btn-tour {
            min-width: inherit;

            // .text {
            //     display: none;
            // }
        }
    }

    .header .account-wrap {
        flex-direction: column;
        align-items: flex-start;
        margin: 5px 0;
    }
}

@media screen and (max-width: 1263px) {
    .header-nav .nav-item-publish .versions a {
        margin-right: 14px;
    }

    .header-nav {
        margin-right: 0;

        .header-nav-item {
            padding-left: 10px !important;
            padding-right: 5px !important;
        }

        &.nav-account-profile {
            .user-nav-item.dropdown {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .nav-item-publish button.btn-brand.btn-primary {
            margin-left: 0;
            margin-right: 0;
        }

        .notification-bell {
            margin-right: 20px;
        }

        button.btn-datalayer.btn {
            padding: 0 12px;
            height: 32px;
        }
    }

    .c-wrapper.v2 {
        .header {
            flex-wrap: wrap;

            >.header-nav {
                gap: 0;

                &.nav-account-profile {
                    margin-right: 10px;
                }
            }
        }

        button.btn-brand.btn-secondary {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 1209px) {
    .c-wrapper.v2 {
        .nav-account-profile .btn-tour {
            min-width: inherit;

            .text {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 992px) and(max-width: 1169px) {
    .header-nav.right {
        margin-left: 9px;
    }

    .nav-item-publish {
        flex-direction: column;
        padding-top: 7px;
        padding-bottom: 4px;

        .versions {
            text-align: center;
        }

        button.btn-datalayer.btn {
            margin-left: 25px !important;
        }
    }

    button.btn-brand.btn.btn-primary {
        margin-left: 13px;
    }

    .c-wrapper.v2.show-nav-child {
        &.minimize-nav {

            .header-nav.nav-account-profile .switch-account-nav .switch-account-btn {
                min-width: 170px;
            }

            .account-select .dropdown-item {
                min-width: 150px;
            }
        }

        &:not(.minimize-nav) {
            .header {
                >.header-nav {
                    margin-left: 10px;

                    &.nav-account-profile {
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }

                button.btn-datalayer.btn {
                    max-width: 122px;
                    height: auto;
                    padding: 3px 5px;
                    margin-left: 0;
                    line-height: 1.3em;
                }

                .header-nav .user-nav-item .c-avatar {
                    width: 32px;
                    height: 32px;
                }

                .switch-account-nav {
                    margin-right: 10px;
                }
            }

            .header-nav {
                .header-nav-item {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                &.nav-account-profile .switch-account-nav .switch-account-btn,
                .account-select .dropdown-item {
                    min-width: 135px;
                }
            }


            .text-unpublished {
                font-size: 9px;
            }

            .btn-tour {
                padding: 4px 8px;
            }
        }
    }
}

@media screen and (max-width: 1074px) {
    .subscriber {
        &.c-app {
            &:not(.c-legacy-theme) {
                &:not(.c-dark-theme) {
                    .header {
                        .get-started-bar {
                            justify-content: space-around;

                            .get-started {
                                font-size: 13px;
                            }

                            span {
                                font-size: 12px;
                            }

                            .complete-btn {
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }
    }

    .header-nav {
        // .nav-item-publish {
        //     flex-direction: column;
        //     padding-top: 7px;
        //     padding-bottom: 4px;
        // }

        // .header-nav {
        //     margin-left: 7px !important;
        // }

        // .header-nav-item {
        //     padding-left: 0 !important;
        // }

        .nav-item-publish {
            .btn-brand {
                font-size: 11px;
                line-height: 9px;
                margin-right: 10px !important;
            }
        }

        button.header-toggler.ml-3.d-md-down-none {
            padding-right: 10px;
        }

        button.btn-brand.btn.btn-secondary {
            margin-left: 3px;
        }

        button.btn-brand.btn.btn-primary {
            margin-left: 3px;
            border-radius: 17.5px;
            padding: 5px 15px 5px 17px;
            // padding-left: 15px;
            // padding-right: 15px;
            font-size: 13px;
            font-weight: 700;
            margin-left: 5px;
            margin-right: 15px;
            height: 35px;
        }

        button.btn-datalayer.text-center.btn {
            margin-left: 25px;
        }

        button.header-toggler.ml-md-3.d-lg-none {
            padding-right: 3px;
        }

        button.btn-brand.ml-3.mr-3.btn.btn-primary {
            margin-left: 0px !important;
        }
    }

    .c-wrapper.v2.show-nav-child.minimize-nav {
        .nav-account-profile .btn-tour {
            min-width: inherit;

            .text {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1007px) {
    // .header-nav {
    //     .nav-item-publish {
    //         padding-top: 7px;
    //         padding-bottom: 4px;
    //         .btn-brand {
    //             svg {
    //                 width: 0.8rem;
    //                 height: 0.8rem;
    //                 margin: 2px 0;
    //             }
    //         }
    //     }
    //     .header-nav-item{
    //         padding-right: 5px;
    //     }
    //     button.header-toggler.ml-3.d-md-down-none {
    //         padding-right: 5px;
    //     }
    //     ul.right.nav-account-profile.header-nav {
    //         margin-left: 20px;
    //     }
    // }

    .header-nav {
        .nav-item-publish {
            .btn-brand {
                svg {
                    width: 0.8rem;
                    height: 0.8rem;
                    margin: 2px 0;
                }
            }
        }

        .dropdown-header {
            position: relative;

            .arrow-setting {
                position: absolute !important;
                top: -10px;
                left: 0;
            }
        }

        button.btn-brand.btn.btn-primary {
            margin-left: 3px;

            &:first-child {
                margin-left: 0;
            }
        }

        button.btn-brand.btn.btn-secondary {
            margin-left: 3px;
        }

        button.btn-datalayer.text-center.btn {
            margin-left: 15px;
        }

        button.header-toggler.ml-md-3.d-lg-none {
            padding-right: 3px;
        }

        button.btn-brand.ml-3.mr-3.btn.btn-primary {
            margin-left: 0px !important;
        }
    }

    .pt-0.setting-dropdown.dropdown-menu.show {
        border: 0.5px solid #c8ced3;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        // margin: 0px !important;
        position: absolute !important;
        inset: 0px auto auto 0px !important;
        transform: translate3d(-220px, 38.5px, 0px) !important;
        min-width: 240px;
    }

    .arrow-setting {
        position: absolute !important;
        top: -10px;
        // left: 222px !important;
        left: 155px !important;
    }
}

@media screen and (max-width: 991px) {
    .header-nav.right {
        margin-left: 0px;
    }

    .header-nav {
        .nav-item-publish {
            .btn-brand {
                svg {
                    width: 0.8rem;
                    height: 0.8rem;
                    margin: 2px 0;
                }
            }
        }

        .dropdown-header {
            position: relative;

            .arrow-setting {
                position: absolute !important;
                top: -10px;
                left: 222px;
            }
        }

        button.btn-brand.btn.btn-primary {
            margin-left: 3px;
        }

        button.btn-brand.btn.btn-secondary {
            margin-left: 3px;
        }

        button.btn-datalayer.text-center.btn {
            margin-left: 5px;
        }

        button.header-toggler.ml-md-3.d-lg-none {
            padding-right: 5px;
        }

        button.btn-brand.ml-3.mr-3.btn.btn-primary {
            margin-left: 0px !important;
        }
    }
}

@media screen and (max-width: 898px) {

    .nav-item-publish {
        flex-direction: column;
        padding-top: 7px;
        padding-bottom: 4px;

        .versions {
            text-align: center;
        }

        // button.btn-datalayer.btn {

        //     margin-left: 25px !important;
        // }
    }


    // .pt-0.setting-dropdown.dropdown-menu.show:after {
    //     content: '';
    //     position: absolute;
    //     width: 20px;
    //     height: 2px;
    //     background-color: #fff;
    //     left: 0px;
    //     top: 0px;
    // }

    .header-nav {
        .nav-item-publish {
            .btn-brand {
                svg {
                    width: 0.8rem;
                    height: 0.8rem;
                    margin: 2px 0;
                }
            }
        }

        // .pt-0.setting-dropdown.dropdown-menu.show {
        //     border: 0.5px solid #c8ced3;
        //     border-top-right-radius: 15px ;
        //     border-top-left-radius: 0px;
        //     border-bottom-left-radius: 15px ;
        //     border-bottom-right-radius: 15px;
        //     margin: 7px!important;
        //     position: absolute !important;
        //     inset: 0px auto auto 0px !important;
        //     transform: translate3d(0px, 38.5px, 0px) !important;
        //     min-width: 240px;
        // }
        // .arrow-setting {
        //     position: absolute !important;
        //     transform: scaleX(-1);
        //     width: 52px;
        //     left: -31px;
        //     height: 10px;
        //     top: -8px;
        // }

        .dropdown-header {
            position: relative;

            .arrow-setting {
                position: absolute !important;
                top: -10px;
                left: 222px;
            }
        }

        button.btn-brand.btn.btn-primary {
            margin-left: 3px;
        }

        button.btn-brand.btn.btn-secondary {
            margin-left: 3px;
        }

        button.btn-datalayer.text-center.btn {
            margin-left: 5px;
        }

        button.header-toggler.ml-md-3.d-lg-none {
            padding-right: 5px;
        }

        button.btn-brand.ml-3.mr-3.btn.btn-primary {
            margin-left: 0px !important;
        }
    }
}

@media screen and (max-width: 881px) {
    // .header-nav {
    //     .nav-item-publish {
    //         padding-top: 7px;
    //         padding-bottom: 4px;
    //     }
    // }

    .header-nav.right {
        margin-left: -7px;
    }

    .nav-item-publish {
        flex-direction: column;
        padding-top: 7px;
        padding-bottom: 4px;

        .versions {
            text-align: center;
        }

        // button.btn-datalayer.btn {

        //     margin-left: 25px !important;
        // }
    }
}

@media screen and (max-width: 760px) {
    .header-nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-height: 56px;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        // .nav-item-publish {
        //     .block-tooltip {
        //         left: auto;
        //         right: 25px;
        //         min-width: 265px;

        //         svg {
        //             right: -10px;
        //             left: auto;
        //             transform: rotateZ(180deg) rotateX(180deg);
        //         }
        //     }
        // }
        button.header-toggler.ml-md-3.d-lg-none {
            padding-left: 23px;
        }
    }
}

@media screen and (max-width: 694px) {
    .header-nav {
        width: 100%;
        justify-content: space-around;

        &.nav-account-profile {
            display: flex;
            justify-content: space-around;

            .switch-account-nav.dropdown {
                width: 100%;
                max-width: 355px;

                .switch-account-btn {
                    width: 100%;
                    max-width: inherit;
                }
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .header .header-toggler {
        min-width: 30px;
    }

    .header-nav {
        padding-left: 10px;

        ul.header-nav {
            padding-left: 0;
        }

        .header-nav-link {
            font-size: 11px;
        }

        .header-nav-item {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }

        .nav-item-publish {
            .btn-brand {
                font-size: 11px;
                line-height: 9px;

                svg {
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }

            .text-unpublished {
                font-size: 10px;
            }
        }

        &.nav-account-profile {
            .switch-account-nav {
                .switch-account-btn {
                    min-width: 205px;

                    .label {
                        font-size: 10px;
                    }
                }

                .account-name {
                    font-size: 12px;
                }
            }

            .account-select {
                .btn-outline-success {
                    font-size: 12px;
                    line-height: 14px;
                }

                .dropdown-item {
                    min-width: 205px;
                }
            }
        }
    }
}