// scss-docs-start btn-variant-mixin
@mixin button-variant($variant) {
	$shadow: map-get($variant, "shadow");

	// --#{$prefix}btn-focus-shadow: #{$shadow};
}
// scss-docs-end btn-variant-mixin

// scss-docs-start btn-outline-variant-mixin
@mixin button-outline-variant($variant) {
	$shadow: map-get($variant, "shadow");
	$hover-border-color: map-get($variant, "hover-border-color");
	$hover-background: map-get($variant, "hover-bg");
	$focus-bg: map-get($variant, "focus-bg");

	--#{$prefix}btn-hover-border-color: #{$hover-border-color};
	--#{$prefix}btn-hover-bg: #{$hover-background};
	// --#{$prefix}btn-focus-shadow: #{$shadow};
	// --#{$prefix}btn-focus-bg: #{$focus-bg};
   }
// scss-docs-end btn-outline-variant-mixin

// scss-docs-start btn-ghost-variant-mixin
@mixin button-ghost-variant($variant) {
	$shadow: map-get($variant, "shadow");
	$border-transparent: map-get($variant, "border-transparent");

	// --#{$prefix}btn-focus-shadow: #{$shadow};
	--#{$prefix}btn-active-border-color: #{$border-transparent};
	--#{$prefix}btn-hover-border-color: #{$border-transparent};
}
// scss-docs-end btn-ghost-variant-mixin

// custom btn ghost
// @mixin btn-ghost-background() {
// 	.btn[class*="btn-ghost-"]:hover {
// 		background-color: unset;
// 		// box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2) !important;
// 	}
// }
// @include btn-ghost-background();