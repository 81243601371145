.subscriber {
     .c-main {
          padding-top: 0;

          .main-breadcrumb {
               padding-left: 18px;
               
               &.c-subheader {
                    margin-bottom: 2rem;
                    background-color: #f8f9fa;
                    border-bottom: 0;
                    min-height: 30px;
                    width: 100%;
               }

               .c-subheader-nav {
                    min-height: 29px;
                    font-size: 11px;
                    color: #73818f;
                    font-family: Roboto;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 13px;
                    transform: translateX(-10px);

                    li {
                         &.active {
                              color: #73818f !important;
                              font-weight: 400;
                         }
                    }
               }

          }
     }

     .c-subheader {
          .breadcrumb-item {
               margin-left: -1px;
          }
     }

     .c-wrapper.v2 {
          .main-breadcrumb.c-subheader {
               margin-bottom: 26px;
          }
     }

}

html:not([dir="rtl"]) .breadcrumb-item+.breadcrumb-item::before {
     padding-right: 0.2rem;
     padding-left: 0.2rem;
}
html:not([dir="rtl"]) .breadcrumb-item + .breadcrumb-item{
     padding-left: 0.1rem;
}