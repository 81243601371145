.general-setting-form {
     .react-select__menu {
          border-radius: 12px;
          padding: 10px 0px;
          margin-top: 2px;
     }
     .css-4ljt47-MenuList,
     .css-11unzgr {
          padding-top: 0;
          padding-bottom: 0;
     }

     &.google-analytics-4,
     &.facebook-ads {
          .label-conversion {
               margin-right: 1rem;

               &.partner-linkedin {
                    label {
                         width: auto;
                         margin-right: 5px;
                    }
               }
          }

          .form-group {
               .form-control {
                    width: 100%;
                    max-width: none;
                    min-width: 280px;
               }

               .input-field {
                    width: 100%;
               }
          }
     }

     &.facebook-ads {
          .label-conversion {
               &.partner-linkedin {
                    label {
                         width: 88px;
                    }

                    .question {
                         width: auto;
                         text-decoration: underline;
                    }
               }
          }
          .form-group-general-pixel {
               padding-left: 15px;
               padding-right: 30px;
          }
     }
     &.microsoft-ads {
          .label-conversion {
               &.partner-linkedin {
                    label {
                         width: auto;
                         min-width: 104px;
                    }
               }
          }
     }

     .form-group-general {
          display: flex;
          align-items: flex-start;
          height: 100%;

          .form-group {
               width: 100%;
               margin-bottom: -1px;
               position: relative;

               .form-group-general-field {
                    display: flex;
                    align-items: flex-start;

                    &-select {
                         display: flex;
                         align-items: flex-start;
                         height: 100%;

                         .css-2b097c-container,
                         div[class*='-container']:not(div[class*='react-select__']) {
                              width: 100%;
                              // max-width: 190px;
                              min-width: 225px;
                         }
                    }
               }

               .invalid-general-setting {
                    display: block;
               }

               .react-select__option {
                    background-color: transparent;
                    color: #2f353a;
               }
          }
     }

     .form-group {
          .form-control {
               max-width: 295px;
               min-width: 295px;
          }

          label {
               margin-bottom: -5px;
               width: 104px;
          }

          .block-tooltip {
               top: 12px;

               svg {
                    position: absolute;
               }
          }
     }

     .label-conversion {
          height: 35px;
          display: flex;
          align-items: center;
          position: relative;

          &.partner-linkedin {
               label {
                    width: 70px;
               }
          }
     }
}

@media screen and (max-width: 1799px) {
     .destination-layout {
          .general-setting-form {
               .form-group {
                    display: flex;

                    [class*='col-lg'] {
                         margin-top: 16px;
                         flex-basis: 100%;
                         max-width: 100%;
                    }
               }
          }
     }
}
