.login-section {
    > .container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    .login-section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
        flex-shrink: 0;
        font-size: 14px;
        svg {
            max-width: 215px;
            flex-shrink: 0;
        }
        .link-back-login {
            flex-shrink: 0;
            text-decoration: none;
            font-size: 14px;
            line-height: 21px;
            color: #768391;
            font-weight: 500;
            display: flex;
            align-items: center;
            border: 0;
            background-color: transparent;
            i {
                font-weight: 500;
            }
            span {
                margin-left: 13px;
            }
        }
    }

    .login-section-main {
        flex-shrink: 0;
        padding-top: 53px;
        padding-bottom: 68px;
        .login-section-card {
            max-width: 415px;
            margin: 0 auto;
            background: #fff;
            padding: 57px 33px 35px;
            text-align: center;
            border-radius: 15px;
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.15);
            position: relative;
            overflow: hidden;
            transition: .5s all ease-in;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 8px;
                background-image: url("/assets/images/line-rainbow.png");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            &.has-google-one-tap {
                .login-card-with-google {
                    margin-top: 7px;
                }
                &.create-an-account {
                    .login-card-with-google {
                        margin-top: 30px;
                    }
                }
            }

            &.password-reset-send-mail {
                .login-section-card-description {
                    max-width: 100%;
                }
                .reset-password-successfully {
                    margin-top: 25px;
                    p {
                        margin-bottom: 0;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }

            &.create-an-account {
                padding-bottom: 60px;
                margin-bottom: 16px;
                max-width: 500px;
                form {
                    input {
                        margin-bottom: 12px;
                    }
                }
            }

            &.member-login {
                padding-bottom: 60px;
                margin-bottom: 16px;
                &.has-google-one-tap {
                    padding-bottom: 28px;
                }

                .login-section-card-description {
                    margin-bottom: 33px;
                }

                .login-section-card-button {
                    padding-top: 4px;
                }

                form {
                    input {
                        margin-bottom: 11px;
                    }
                }
                + .below-form {
                    background: #18222b;
                    text-transform: uppercase;
                    a {
                        color: #fff;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 21px;
                    }
                }
            }

            &.below-form {
                padding: 0;
                &::before {
                    display: none;
                }
                > a {
                    padding: 18px 33px 16px;
                    display: block;
                    font-size: 15px;
                    line-height: 21px;
                    color: #18222b;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }
        .login-section-card-title {
            margin-bottom: 16px;
            * {
                color: #212c36;
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 0;

                background: linear-gradient(161deg, #29263e, #233b7d, #20a8d8);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .login-section-card-description {
            max-width: 278px;
            margin: 0 auto 35px;
            * {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #202124;
                strong {
                    font-weight: 700;
                }
            }
            sup {
                font-size: 7px;
                position: relative;
                top: -8px;
                left: 3px;
                margin-right: 5px;
            }
        }
        form {
            .input-fullname {
                display: flex;
                .input-group {
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
            input {
                border: 0;
                outline: none;
                box-shadow: none;
                background-color: $light;
                border-radius: 50em !important;
                color: #7f8c9a !important;
                min-height: 48px;
                padding-left: 25px;
                padding-right: 25px;
                margin-bottom: 16px;
                &::placeholder {
                    color: #7f8c9a;
                    opacity: 1;
                }
            }
            .invalid-feedback {
                text-align: left;
                margin-top: -10px;
                margin-bottom: 14px;
                padding-left: 0;
            }
        }

        .login-section-card-button {
            display: flex;
            flex-wrap: wrap;
            .btn {
                width: 100%;
                padding: 13px 20px 12px;
            }
            a {
                margin-top: 19px;
                margin-bottom: 18px;
                margin-left: auto;
                margin-right: auto;
                text-transform: capitalize;
                font-size: 14px;
                font-weight: 500;
                color: #7d8a97;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .login-card-with-google {
            margin-top: 20px;
            .btn {
                padding: 9px 20px 10px;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;

                display: flex;
                justify-content: center;
                align-items: center;
                > svg {
                    flex-shrink: 0;
                }

                span {
                    margin-left: 10px;
                }
            }
            .btn-google-outline {
                border: 1px solid #d0d0d0;
            }
            #google-one-tap {
                margin-left: -8px;
                margin-right: -8px;
                iframe {
                    width: 100% !important;
                }
            }
            .google-one-tap {
                width: 100%;
                height: 65px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                gap: 15px;
                border-radius: 8px;
                box-shadow: 0 0 0 1px #dadce0;
            }
            .google-one-tap-title {
                text-align: start; 
                font-size: 14px; 
                font-weight: 600; 
                line-height: 21px;
            }
        }
    }

    .login-section-footer {
        flex-shrink: 0;
        margin-top: auto;
        padding: 25px 0;
        .login-section-footer-wrapper {
            max-width: 415px;
            margin: 0 auto;
            text-align: center;
            > svg {
                max-width: 140px;
            }
            ul {
                display: flex;
                list-style: none;
                margin-top: 25px;
                margin-bottom: 0;
                li {
                    a {
                        font-size: 14px;
                        color: #3c4b64;
                    }
                    &:not(:last-child) {
                        margin-right: 45px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .login-page {
        .card-group {
            flex-direction: column;
        }
        .login-block {
            border-bottom: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 4px !important;
        }
        .signup-block {
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px !important;
            padding: 20px !important;
        }
    }
}
