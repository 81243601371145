.list-all-rules {
     margin-bottom: 30px;

     .listener-name {
          margin-bottom: 10px;

          img {
               height: 18px;
               margin-right: 8px;
          }

          span {
               font-size: 14px;
               font-weight: 500;
               line-height: 17px;
               letter-spacing: 0.5px;
          }
     }
}

.row-rule {
     .tooltip-tag {
          margin-left: 10px;

          + .tooltip-tag {
               margin-left: 5px;
          }
     }

     .option-item .option .icon-delete {
          height: 24px !important;
          width: 24px !important;
     }
}