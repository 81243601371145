.swiper {
     width: 100%;
     margin-left: 0 !important;

     .swiper-wrapper {
          .swiper-slide {
               width: auto !important;

               &:first-child {
                    .btn-filter {
                         margin-left: 24px;
                    }
               }

               .btn-filter {
                    margin-right: 10px;
                    max-width: max-content;
                    width: 100%;
                    background: #f0f3f5;
                    font-size: 13px;
                    font-weight: 700;
                    letter-spacing: 0.18571427px;

                    &.active {
                         background: #20a8d8;
                         color: #fff;
                    }
               }
          }
     }

     .swiper-button-prev,
     .swiper-button-next {
          &::after {
               content: '';
               display: block;
               background-image: url('/assets/icons/icon-prev-slider.svg');
               position: absolute;
               width: 36px;
               height: 36px;
          }

          &::before {
               position: absolute;
               content: '';
               height: 100%;
               width: calc(100% + 18px);
               display: block;
               top: 0;
          }

          &.swiper-button-disabled {
               display: none;
          }
     }

     .swiper-button-prev {
          left: 0;

          &::before {
               left: 0;
               background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          }

          &::after {
               left: 24px;
          }
     }

     .swiper-button-next {
          right: 0;

          &::before {
               right: 0;
               background: linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          }

          &::after {
               transform: rotate(180deg);
               right: 30px;
          }
     }

     &.disable-slider {
          .swiper-button-prev,
          .swiper-button-next {
               display: none;
          }
     }
}

.filter-slider {
     .slider-left-i {
          .swiper-button-prev {
               display: none;
          }

          .swiper-wrapper {
               transform: translate3d(0px, 0px, 0px) !important;
          }
     }

     .slider-right-i {
          .swiper-button-next {
               display: none;
          }

          .swiper-wrapper {
               transform: translate3d(-1030.65px, 0px, 0px) !important;
          }
     }
}
