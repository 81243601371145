.upgrade-package {
    p {
        i {
            font-weight: 300;
        }
    }
    .btn-success {
        border-radius: rem(3);
    }
    .compare-plans {
        font-size: 12px;
        margin-left: 20px;
        color: #73818f;
        text-transform: capitalize;
        font-weight: 400;
        padding: 0;
        &:hover {
            text-decoration: none;
        }
    }
    &.upgrade-package-dashboard {
        .card-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            h6,
            p {
                margin-bottom: 0;
            }
            .upgrade-package-text {
                padding-top: 10px;
                padding-bottom: 10px;

                max-width: 700px;
                padding-right: 20px;
            }

            .compare-plans {
                margin-left: 0;
            }
        }
        .compare-plans {
            margin-right: 20px;
        }
    }
}
