.accordion {
     .icon:not(.icon-c-s):not(.icon-custom-size) {
          &.icon-action {
               width: 20px;
               height: 20px;
               color: #20a8d8;
          }
     }

     .field-event-id {
          .select-react-customize {
               .select-wrapper {
                    .custom-select__placeholder {
                         padding: 0 10px;
                    }
               }
          }
     }

     .input-icons {
          .input-content {
               &.is-invalid {
                    background-image: none;
               }
          }
     }

     .select-event-ecommerce {
          .css-4ljt47-MenuList {
               overflow-x: hidden;
          }

          .custom-select__placeholder {
               padding: 0 10px;
          }

          input {
               padding-left: 10px !important;
          }

          // .css-b8ldur-Input{
          //      padding: 0 10px;
          // }
     }

     .css-4ljt47-MenuList {
          max-height: 310px;
          padding-top: 0;
          padding-bottom: 0;
     }

     .data-action-check-box.custom-checkbox {
          width: max-content;

          input[type='checkbox'] {
               min-width: unset;
               width: auto;
          }

          .custom-control-label {
               &::before,
               &::after {
                    top: 50%;
                    transform: translateY(-50%);
               }
          }
     }

     .destination-conversions {
          .clear-changes {
               cursor: pointer;
               text-decoration: underline;
               &:hover {
                    color: #ff5c5c;
               }
          }

          .btn-dark {
               background-color: #828282;
          }

          .field-event-id {
               display: flex;
               align-items: center;
               padding: 10px 15px;
               position: relative;

               input {
                    min-width: 210px;
                    max-width: 210px;
               }

               .tooltip-wrapper {
                    &:hover {
                         position: absolute !important;
                    }

                    &.tooltip-event {
                         svg {
                              left: -10px;
                         }

                         .block-tooltip-text {
                              border-radius: 12px;
                         }
                    }
               }

               .select-wrapper {
                    input {
                         margin-left: 10px !important;
                    }
               }
          }

          input {
               width: 100%;
               // min-width: 250px;
               &.c-switch-input {
                    width: fit-content;
               }

               &.conversion-input {
                    width: 384px;
               }
          }

          .option-item3 {
               padding: 0 15px;
          }

          .select-option {
               span {
                    font-size: 15px;
                    font-weight: 500;
               }
          }

          .error {
               color: #f86b6c;
          }

          .option {
               &.disabled {
                    color: #a3afb8 !important;
               }
          }

          .row-rule {
               &.template-conversion {
                    // border: dashed 1px #f0f3f5;
                    border-radius: 12px;
                    background: #f0f3f5;
                    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

                    .event-id {
                         padding-right: 0;

                         label {
                              // margin-right: -4px;
                              color: #7f8c9a !important;
                              line-height: 20px;
                              letter-spacing: 0.08px;
                         }
                    }

                    .template-conversion-header {
                         padding-left: 20px;
                         background: #f0f3f5;
                         min-height: 46px;
                         border-top-left-radius: 12px;
                         border-top-right-radius: 12px;

                         .field-event-id {
                              .tooltip-wrapper {
                                   svg {
                                        top: 1px;
                                        left: -10px;
                                   }
                              }
                         }

                         h4 {
                              color: #212c36;
                              font-family: Roboto;
                              font-size: 14px;
                              font-weight: 500;
                              word-break: break-word;
                         }

                         .form-control {
                              color: #000000;
                              border-radius: 12px;
                              min-width: 266px;
                              max-width: 266px;
                              min-height: 30px;
                              font-size: 12px;
                              font-family: Roboto;
                              font-weight: 400;
                              border-radius: 9px !important;
                         }

                         label {
                              font-size: 12px;
                              font-family: Roboto;
                              font-weight: 400;
                         }

                         &.show-accordion {
                              border-radius: 12px;
                         }

                         .invalid-conversion {
                              align-items: start !important;

                              input {
                                   background-image: none;
                              }
                         }

                         .invalid-conversion-name {
                              align-items: start;

                              h4 {
                                   margin-top: 15px;
                              }
                         }

                         .tooltip-wrapper {
                              > svg {
                                   left: -5px;
                                   top: -1px;
                              }
                         }
                    }

                    .custom-control-input,
                    .tooltip-wrapper {
                         z-index: 1;
                    }

                    .template-conversion-body {
                         padding-left: 20px;
                         padding-right: 20px;
                         background: #ffffff;
                         border-bottom-left-radius: 12px;
                         border-bottom-right-radius: 12px;
                         box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

                         .select-react-customize {
                              .form-control {
                                   height: 37px;
                              }
                         }

                         .form-control {
                              height: 37px;
                         }

                         .custom-script-container {
                              .form-control {
                                   height: auto;
                              }

                              background-color: #20a8d8;
                         }

                         .browser-step4-form {
                              position: relative;
                              top: -30px;
                         }

                         .custom-control-label {
                              font-family: Roboto;
                              font-size: 13px;
                              font-weight: 300;
                              color: #000000 !important;
                         }

                         .include-data-tooltip label {
                              cursor: pointer;
                         }
                    }

                    .template-contents {
                         padding-left: 20px;
                         background: #ffffff;
                         border-bottom-left-radius: 12px;
                         border-bottom-right-radius: 12px;
                    }

                    .select-parameter-name {
                         margin-left: 15px;
                         // margin-right: 15px;
                    }

                    .select-parameter-name .select-wrapper input {
                         min-width: unset;
                         max-width: 174px;
                    }

                    .parameter-checkbox {
                         margin-left: 0px;
                         position: relative;
                         z-index: 2;

                         .custom-control-input,
                         .tooltip-wrapper {
                              // z-index: 10;
                              padding-top: 2px;
                         }

                         .tooltip-wrapper {
                              z-index: 11;
                         }

                         .custom-control-input {
                              cursor: pointer;
                         }
                    }

                    .input-icons {
                         // min-width: 376px;

                         svg {
                              height: 14px;
                              width: 14px;
                              margin: 0;
                         }

                         button {
                              margin: 0;
                              position: absolute;
                              right: 0;
                              top: 50%;
                              transform: translateY(-50%);

                              &.conversion-fbads {
                                   z-index: 1;
                                   top: 20px;
                              }
                         }

                         .form-control {
                              &.input-content {
                                   padding: 6px 30px 6px 8px;
                                   border: 2px solid #e4e7e9;
                                   box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
                              }
                         }

                         &.input-icon-child {
                              .insert-variable-dropdown {
                                   ul {
                                        border-top-right-radius: 0px;
                                        border-top-left-radius: 0px;
                                        overflow-x: hidden;
                                        padding: 0;
                                   }

                                   .form-control {
                                        border-radius: 12px;
                                        min-width: 333px;
                                   }
                              }

                              .form-control {
                                   border-radius: 12px 12px 0 0;
                              }
                         }

                         .insert-variable-dropdown {
                              top: 45px;
                         }
                    }

                    .custom-script-container {
                         .input-icons {
                              button.conversion-fbads {
                                   z-index: 1;
                                   top: 22px;
                              }
                         }
                    }

                    .browser-step4-form {
                         margin-bottom: 7px;
                    }

                    // .option-custom {
                    //      .custom-row {
                    //           margin-top: 10px;
                    //      }
                    // }

                    .select-react-customize {
                         .select-wrapper {
                              input[value='Select Event Name'] {
                                   color: rgb(128, 128, 128);
                              }
                         }

                         .form-control {
                              border-radius: 12px;
                              // min-width: 174px;
                              // max-width: 174px;
                              height: 30px;
                         }
                    }

                    .hover-show-tooltip {
                         position: relative;

                         .input-tooltip {
                              .form-control.is-invalid {
                                   background-position-x: 85%;
                              }
                         }
                    }

                    .conversion-destionation {
                         label {
                              color: #000000 !important;
                              line-height: 20px;
                              letter-spacing: -0.1px;
                              min-width: 30px;

                              &.sub-name {
                                   margin-right: 14px;
                              }
                         }
                    }

                    .conversion-more-setting {
                         label {
                              color: #000000 !important;
                              line-height: 20px;
                              letter-spacing: 0.08px;
                         }
                    }
               }
          }

          .custom-checkbox {
               width: max-content;

               input[type='checkbox'] {
                    min-width: unset;
                    width: auto;
               }

               .custom-control-label {
                    &::before,
                    &::after {
                         top: 50%;
                         transform: translateY(-50%);
                    }
               }
          }

          &.input-icons-custom {
               svg {
                    left: -20px;
                    top: 44%;
               }

               a {
                    text-decoration: underline;
                    color: #73818f;
               }
          }

          .input-custom-col {
               width: auto;
               flex: inherit;
          }

          .conversion-custom-param-col {
               padding: 0 0 0 15px;
               max-width: 376px;
               // flex: inherit;

               input {
                    padding-right: 40px !important;
                    text-overflow: ellipsis;
               }
          }
     }

     .cardLoading {
          border: 1px solid #e4e7ea !important;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
          position: relative;
          margin-bottom: 1.5rem;
          border-radius: 12px;
          padding: 12px 20px;
          background-color: #f0f3f5;

          .cardLoading__item {
               background: #fff;
               border-color: #e5e6e9 #dfe0e4 #d0d1d5;
               border-radius: 3px;
               margin: 0 auto;
               max-width: 100%;
               min-height: 20px;

               .cardLoading__animated {
                    animation-duration: 5s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: infinite;
                    animation-name: placeHolderShimmer;
                    animation-timing-function: linear;
                    background: #f6f7f8;
                    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #f0f3f5 33%);
                    background-size: 1200px 104px;
                    height: 100%;
                    position: relative;

                    .name {
                         position: relative;
                         height: 22px;

                         &::after {
                              content: none;
                         }
                    }
               }
          }
     }

     .accordion-item {
          position: relative;
          border: 1px solid #e2e6e9;
          border-radius: 12px;
          background-color: transparent;
          margin-bottom: 10px;
          min-height: 45px;
          overflow: hidden;

          .details-conversion-row {
               margin-bottom: 15px;

               &.details-conversion-row-end {
                    margin-bottom: 10px;
               }
          }

          .destination-conversions {
               .row-rule {
                    padding: 4px 0px 0px;
               }

               .details-conversion {
                    .details-conversion-row {
                         margin-bottom: 15px;

                         &:last-child {
                              margin-bottom: 10px;
                         }
                    }

                    .row {
                         margin-left: -30px;
                    }

                    p {
                         margin-bottom: 10px;
                    }

                    .btn-add-row {
                         margin-left: -15px;
                    }
               }

               .description {
                    // max-width: 80%;
                    font-size: 12px;
               }

               .parameter-checkbox + .details-conversion {
                    .insert-variable-dropdown ul {
                         // min-width: 290px;
                         border-radius: 12px;
                    }
               }
          }

          .ecommerce {
               .row-rule .select-react-customize {
                    color: #2f353a;
               }

               .option-custom {
                    margin-right: 15px;
                    input[type="checkbox"] {
                         min-height: auto !important;
                         height: auto !important;
                         width: auto;
                         min-width: 40px !important;
                    }
               }
          }

          &.accordion-item-rule {
               overflow: unset;
               background-color: #f0f3f5;
               border: none;

               ul {
                    padding-left: 0;
                    margin: 0;
                    display: flex;
                    flex-wrap: wrap;
                    list-style-type: none;
                    margin-left: -5px;
                    margin-right: -5px;

                    li {
                         flex: 0 0 50%;
                         font-weight: 500;
                         padding: 5px;

                         p {
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              max-width: 90%;
                         }

                         a {
                              color: #a3afb8;
                         }

                         .content {
                              position: relative;
                              display: inline-flex;
                              align-items: center;
                              justify-content: space-between;
                              height: 35px;
                              width: 100%;
                              font-weight: 700;
                              padding: 10px 15px;
                              background-color: #fff;
                              border-radius: 8px;
                              max-width: 445px;
                         }

                         .block-tooltip {
                              top: 9px;
                              min-width: 235px;

                              svg {
                                   left: -10px;
                              }

                              .block-tooltip-text {
                                   padding: 0.6375rem 0.7625rem;
                              }
                         }
                    }
               }

               .accordion-body {
                    border-top: none;
               }

               .collapse {
                    overflow: hidden;

                    .option-item {
                         white-space: nowrap;

                         .block-tooltip {
                              top: -10px;
                              left: auto;
                              right: 0;
                              transform: translatey(-100%);
                              min-width: inherit;
                              background-color: #18222b;
                              border-radius: 12px;

                              svg {
                                   transform: rotate(-90deg) scaleY(-1);
                                   left: auto;
                                   right: 24px;
                                   top: auto;
                                   bottom: -14px;
                              }

                              .block-tooltip-text {
                                   max-height: inherit;

                                   p {
                                        max-width: inherit;
                                   }
                              }
                         }
                    }
               }
          }

          &.show {
               overflow: inherit;
               margin-bottom: 22px;
               box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

               > .accordion-header {
                    &.accordion-rule {
                         border-bottom: none;
                    }

                    &::after {
                         transform: translateY(-25%) rotate(225deg);
                    }
               }
          }

          &.disabled {
               opacity: 0.5;
               pointer-events: none;

               .accordion-header:after {
                    display: none;
               }
          }

          p {
               padding-left: 0;
               font-family: Roboto;
               font-size: 15px;
               font-weight: 400;
               line-height: 20px;
               letter-spacing: 0.1px;
          }

          h5 {
               font-family: Roboto;
               font-size: 15px;
               font-weight: 600;
               line-height: 22px;
          }

          label {
               color: #73818f !important;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;
               letter-spacing: 0.5px;
          }

          input,
          select {
               &.form-control {
                    min-height: 37px;
               }
          }

          .accordion-header {
               justify-content: flex-start;
               min-height: 45px;
               color: #000;
               width: 100%;
               border: none;
               border-top-left-radius: 12px;
               border-top-right-radius: 12px;
               display: flex;
               align-items: center;
               padding: 12px 20px;
               font-size: 18px;
               line-height: 1.2em;
               position: relative;
               background-color: #f0f3f5;

               &.consent {
                    padding-right: 40px;

                    .title {
                         padding: 0;
                         min-width: 330px;
                    }
               }

               &:after {
                    content: '';
                    position: absolute;
                    top: 22px;
                    right: 22px;
                    width: 7px;
                    height: 7px;
                    transform: translateY(-50%) rotate(45deg);
                    border: 2px solid #000;
                    border-top: none;
                    border-left: none;
                    transition: transform 0.3s;
               }

               p {
                    margin-bottom: 0;
               }

               .title {
                    font-family: 'Roboto';
                    min-width: 285px;
                    padding-right: 50px;
                    text-align: left;
                    color: #18222b;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.26666668px;
                    font-weight: 500;
               }

               .description {
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.086666666px;
                    text-align: left;
                    padding-right: 20px;
               }
          }

          .accordion-body {
               padding: 19px 20px 24px 20px;
               z-index: 9;
               text-align: left;
               border-top: 1px solid #e2e6e9;

               a.option {
                    &:hover {
                         text-decoration: none;
                    }
               }

               .block-tooltip-text {
                    p {
                         color: #fff;
                    }
               }

               h4 {
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
               }

               .destination-conversions {
                    .description {
                         // max-width: 80%;
                         font-size: 13px;

                         a {
                              font-weight: 500;
                              text-decoration: underline;
                         }
                    }

                    p {
                         // color: #18222b;
                         font-family: Roboto;
                         font-size: 14px;
                         line-height: 17px;
                         letter-spacing: 0.5px;

                         &.description {
                              font-size: 12px;
                              font-weight: 300;
                              letter-spacing: 0.08px;
                              margin-bottom: 0;
                         }
                    }

                    .manage-conversions {
                         font-weight: 500;
                         margin-bottom: 5px;
                         color: #212c36;
                    }

                    .type-conversions {
                         font-weight: 500;
                         margin-bottom: 11px;
                         margin-top: 20px;
                         color: #212c36;
                    }

                    .form-group {
                         position: relative;

                         &.custom-script-container {
                              .input-icons-custom {
                                   position: relative;
                                   min-width: 106px;

                                   .button-insert-variable {
                                        label {
                                             margin-top: 0px !important;
                                        }
                                   }

                                   button {
                                        position: absolute;
                                        bottom: 0;
                                        left: -20px;
                                   }

                                   svg {
                                        left: 0;
                                        top: 44%;
                                        width: 14px;
                                        height: 14px;
                                        margin: 0;
                                   }

                                   label {
                                        text-decoration: underline;
                                        color: #73818f;
                                        margin-bottom: 0;
                                   }

                                   .insert-variable-dropdown {
                                        min-width: 309px;
                                        right: 0;
                                        left: auto;
                                        top: 33px;

                                        ul {
                                             padding: 0;
                                             overflow-x: hidden;
                                             position: initial;
                                             width: 100%;
                                             border: none !important;
                                             margin-bottom: 0;
                                             border-radius: 12px;
                                             box-shadow: none !important;
                                             max-height: 280px;
                                             margin-top: 0;

                                             &.have-scroll {
                                                  width: calc(100% - 5px);

                                                  li {
                                                       padding-right: 9px;
                                                  }
                                             }

                                             &.script-custom-variable {
                                                  min-width: auto;
                                             }
                                        }
                                   }

                                   .type-variable {
                                        svg {
                                             top: 50%;
                                             transform: translateY(-50%);
                                             left: auto;
                                        }

                                        strong {
                                             margin-right: 0px;
                                        }
                                   }

                                   .button-insert-variable {
                                        label {
                                             margin-left: 5px;
                                             margin-bottom: 11px;
                                             text-decoration: none;
                                             cursor: pointer;

                                             &:hover {
                                                  text-decoration: underline;
                                             }
                                        }
                                   }
                              }
                         }

                         .form-control {
                              padding: 6px 20px 6px 8px;
                              border-radius: 12px;
                              border: 1px solid #e2e6e9 !important;

                              &.form-control[type='radio'] {
                                   border: none !important;
                                   background-color: transparent;
                              }

                              &.is-invalid {
                                   border-color: #f86c6b !important;
                                   padding-right: 46px;
                              }

                              &:disabled {
                                   background-image: url(/assets/icons/lock-dropdown.svg);
                                   background-size: 12px 13px;
                                   background-color: #e4e7ea;
                                   background-repeat: no-repeat;
                                   background-position: center right 20px;
                              }
                         }
                    }

                    .purchase-google-ads {
                         display: flex;
                         align-items: center;
                         padding: 10px 0;
                    }

                    .tooltip-wrapper {
                         &.tooltip-purchase {
                              .block-tooltip {
                                   top: -7px;
                                   left: 50%;
                                   transform: translate(-50%, -100%);
                                   /* min-width: inherit; */
                                   background-color: #18222b;
                                   border-radius: 12px;

                                   svg {
                                        width: 42px;
                                        bottom: 7px;
                                        transform: translate(-50%, 100%) rotate(263deg);
                                        color: #18222b;
                                        top: 82%;
                                        left: 52%;
                                   }

                                   .block-tooltip-text {
                                        border-top-left-radius: 12px;
                                   }
                              }

                              .block-tooltip-text {
                                   border-top-left-radius: 12px;
                              }

                              svg {
                                   top: 1px;
                              }
                         }

                         .hover-show-tooltip {
                              position: relative;
                         }
                    }

                    .row-rule {
                         .tooltip-wrapper {
                              &.tooltip-event {
                                   position: absolute;
                                   right: 13px;
                                   top: 50%;
                                   transform: translateY(-50%);

                                   .block-tooltip {
                                        transform: translateX(-75%);
                                        top: -85px;

                                        svg {
                                             width: 42px;
                                             top: 55px;
                                             bottom: auto;
                                             left: 70%;
                                             transform: translate(-50%, 100%) rotate(-90deg);
                                             color: #18222b;
                                        }
                                   }

                                   &.tooltip-invalid {
                                        top: 26px;
                                   }
                              }
                         }
                    }

                    .details-conversion {
                         position: relative;

                         label {
                              margin-right: 16px;

                              &::after {
                                   content: '';
                                   position: absolute;
                                   top: 47%;
                                   right: 15px;
                                   width: 7px;
                                   height: 7px;
                                   transform: translateY(-50%) rotate(45deg);
                                   border: 2px solid #000;
                                   border-top: none;
                                   border-left: none;
                                   transition: transform 0.3s;
                              }

                              &.show {
                                   &::after {
                                        top: 54%;
                                        transform: translateY(-50%) rotate(225deg);
                                   }
                              }

                              &.add-row {
                                   &::after {
                                        content: none;
                                   }
                              }
                         }
                    }

                    .row-rule {
                         min-height: 46px;
                         padding-top: 0;

                         .tooltip-wrapper {
                              &:hover {
                                   position: relative;
                                   z-index: 9;
                              }
                         }

                         .consider-conversion {
                              top: 3px;
                         }
                    }

                    .default-rules {
                         .headline .tooltip-wrapper {
                              margin-left: 7px;
                         }
                    }

                    .options-data-wrapper {
                         p {
                              margin-bottom: 0;
                         }
                    }

                    a.option {
                         &:hover {
                              text-decoration: none;
                         }
                    }

                    .input-icons {
                         position: relative;

                         .icon:not(.icon-c-s):not(.icon-custom-size) {
                              height: 13px;
                              width: 13px;
                         }

                         svg {
                              position: absolute;
                              right: 12px;
                              top: 50%;
                              transform: translateY(-50%);
                         }

                         .value-no-options-available {
                              svg {
                                   position: relative;
                                   top: 50%;
                                   right: 5px;
                              }
                         }

                         .form-control {
                              &.input-content {
                                   &.is-invalid {
                                        background-position: right calc(2em + 0.1875rem) center;
                                   }
                              }
                         }

                         &.input-icons-errors {
                              .form-control.is-invalid {
                                   background-position: right calc(2em + 0.1875rem) center;
                                   padding-right: calc(2.6em + 0.75rem);
                              }

                              &.input-icons-field {
                                   svg {
                                        top: 50%;
                                   }
                              }

                              .value-no-options-available {
                                   svg {
                                        top: 50%;
                                   }
                              }
                         }

                         li {
                              min-height: 38px;

                              &.variable-filter-bottom {
                                   height: 0px;
                                   min-height: auto;
                              }
                         }

                         .type-variable {
                              position: relative;
                              padding: 0;

                              strong {
                                   word-break: break-word;

                                   &:nth-of-type(2) {
                                        white-space: nowrap;
                                   }
                              }

                              svg {
                                   right: 15px;
                              }

                              svg {
                                   &.icon:not(.icon-c-s):not(.icon-custom-size) {
                                        height: 8px;
                                        width: 8px;
                                   }
                              }
                         }

                         .insert-variable-dropdown {
                              margin-top: -1px;
                              list-style: none;
                              position: absolute;
                              left: 0;
                              padding: 0px;
                              z-index: 99;
                              border: 1px solid #e4e7ea !important;
                              box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
                              max-height: 280px;
                              background-color: #fff;
                              width: 100%;
                              border-radius: 12px;
                              // overflow: hidden;
                              margin-bottom: 1rem;
                              &::after {
                                   content: '';
                                   position: absolute;
                                   width: 100%;
                                   height: 15px;
                                   background-color: transparent;
                                   left: 0;
                                   bottom: -15px;
                              }
                         }

                         ul {
                              padding: 0;
                              overflow-x: hidden;
                              position: initial;
                              width: 100%;
                              border: none !important;
                              margin-bottom: 0;
                              border-radius: 12px;
                              box-shadow: none !important;
                              max-height: 280px;
                              margin-top: 0;

                              &.have-scroll {
                                   width: calc(100% - 5px);

                                   li {
                                        padding-right: 9px;

                                        &.variable-filter {
                                             padding-right: 9px;
                                        }
                                   }
                              }

                              &::-webkit-scrollbar {
                                   width: 3px;
                              }

                              &::-webkit-scrollbar-track {
                                   width: 3px;
                                   background-color: #e2e6e9;
                                   border-radius: 2px;
                                   margin-top: 8px;
                                   margin-bottom: 8px;
                              }

                              &::-webkit-scrollbar-thumb {
                                   background: #73818f;
                                   width: 3px;
                                   border-radius: 12px;
                                   border: none;
                                   min-height: 50px;
                              }

                              li {
                                   padding: 5px 17px 5px 17px;

                                   &:hover {
                                        background: #f3f5f6;

                                        span {
                                             color: #20a8d8 !important;
                                        }
                                   }

                                   &.variable-filter {
                                        background: #fff;
                                        z-index: 10;
                                        padding: 10px 17px 10px 17px;
                                   }
                              }
                         }

                         .insert-variable-dropdown ul {
                              padding: 0;

                              li {
                                   &.border-bottom {
                                        padding: 5px 12px 5px 17px;
                                   }

                                   .form-control {
                                        min-width: auto;
                                   }
                              }
                         }
                    }

                    .parameter-field {
                         .include-data-tooltip {
                              input {
                                   z-index: 10;
                              }

                              label {
                                   cursor: pointer;
                              }
                         }

                         .browser-step4-form {
                              svg {
                                   width: auto;
                                   height: 14px;
                              }
                         }
                    }

                    .select-react-customize {
                         .select-wrapper > div:first-child {
                              background: #fff;
                              background-image: url(/assets/icons/icon-select.svg);
                              background-repeat: no-repeat;
                              background-position-x: calc(100% - 9px);
                              background-position-y: center;
                              background-size: 6px;

                              &.is-invalid {
                                   border: 1px solid #f86c6b !important;
                                   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f86c6b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f86c6b' stroke='none'/%3e%3c/svg%3e");
                                   background-repeat: no-repeat;
                                   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                              }
                         }

                         .select-wrapper {
                              > input[disabled],
                              > .stretch-container.disabled {
                                   background-image: none;
                              }
                         }
                    }
               }

               .description {
                    p {
                         word-break: break-word;
                         // color: #18222b;
                         font-family: 'Roboto';
                         font-size: 14px;
                         font-weight: 400;
                         line-height: 17px;
                         letter-spacing: 0.5px;

                         code {
                              font-size: 15px;
                         }
                    }
               }
          }

          .drag-drop-guide {
               display: flex;
               align-items: center;
               margin-bottom: 18px;
               color: #121920;
               font-family: Roboto;
               font-size: 16px;
               font-weight: 500;
               line-height: 22px;

               svg {
                    margin-top: -1px;
                    margin-right: 10px;
                    color: #c8ced3;
                    width: 15px;
                    height: 15px;
               }

               strong {
                    font-weight: 500;
                    margin-right: 5px;
               }
          }

          // .info-data-wrap {
          //      width: 45%;
          // }

          // .action-data-wrap {row-rule
          //      width: 35%;
          //      position: relative;
          // }

          .options-data-wrapper {
               width: 65%;

               &.expand {
                    width: 80%;
               }

               .option-item3:not(:first-child):before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 20px;
                    transform: translateY(-50%);
                    background-color: #b5dce9;
               }

               .option-custom.rule-check {
                    width: 250px;
                    
                    input[type="checkbox"] {
                         width: 20%;
                         min-height: auto !important;
                         height: auto !important;
                    }
               }
          }

          .row-rule {
               .option-item2.option-item {
                    &::before {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 50%;
                         left: 0;
                         width: 2px;
                         height: 20px;
                         transform: translateY(-50%);
                         background-color: #b5dce9;
                    }

                    .block-tooltip .block-tooltip-text p {
                         text-transform: none;
                    }
               }

               .option-custom {
                    color: #a3afb8;

                    &.option-item:hover:not(.switch-item) .option {
                         background-color: transparent;
                         text-decoration: none;
                    }

                    a.option {
                         color: #a3afb8;
                         align-items: center;
                         padding: 13px 14px;
                         font-size: 15px;

                         .error {
                              color: #f86b6c;
                         }
                    }

                    p.template {
                         padding: 15px 14px;
                    }

                    .icon-action {
                         width: 20px;
                         height: 20px;
                         color: #20a8d8;
                    }

                    .select-label {
                         color: #2f353a;
                    }
               }
          }

          .triggers-rules {
               .row-rule {
                    h4 {
                         font-family: Poppins;
                         font-size: 15px;
                         font-weight: 600;
                         line-height: 22px;
                    }
               }

               p {
                    font-family: Roboto;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.1px;
               }

               .datalayer-variables {
                    p {
                         font-size: 14px;
                         max-width: 480px;
                    }
               }

               .default-rules {
                    .accordion-body {
                         background-color: #fff;
                    }

                    .accordion-item-rule {
                         background-color: #fff;

                         .collapse {
                              overflow: inherit;
                         }
                    }

                    .row-rule {
                         .option-item {
                              &.status-default-rule {
                                   .block-tooltip {
                                        .block-tooltip-text {
                                             span {
                                                  width: 300px;
                                                  white-space: initial;
                                                  display: block;
                                             }
                                        }
                                   }
                              }
                         }

                         .option-item:last-child {
                              &.hover-show-tooltip {
                                   .option {
                                        padding-right: 0;
                                   }
                              }

                              &:after {
                                   content: none;
                              }
                         }
                    }

                    .row-rule-trigger-accordion {
                         border-radius: 12px;

                         .content {
                              background-color: #f0f3f5;

                              .tooltip-wrapper {
                                   position: relative;
                                   margin-left: 10px;

                                   .block-tooltip {
                                        min-width: 303px;
                                   }

                                   p {
                                        white-space: unset;
                                        max-width: none;
                                   }
                              }
                         }
                    }
               }

               .row-rule {
                    .info-wrap {
                         // min-width: 45%;
                         max-width: 70%;
                    }

                    h4 {
                         // max-width: 80%;
                         padding: 10px 0;
                    }

                    .label-gtm {
                         background-color: #58afe0;
                         font-weight: 500;
                         border-radius: 3.25rem;
                         padding: 0.375rem 0.75rem;
                         font-size: 0.875rem;
                         line-height: 1.5;
                         text-align: center;
                         vertical-align: middle;
                         color: #fff;
                    }

                    .label-gtm-1 {
                         background-color: #2f353a;
                    }
               }
          }

          .row-rule {
               border-radius: 12px;
               background: #f0f3f5;
               padding-left: 12px;
               margin-bottom: 8px;

               .info-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 15px;
               }

               &.row-rule-trigger {
                    margin-bottom: 0;

                    .info-rule {
                         min-width: 55%;
                    }

                    .accordion-rule {
                         width: auto;
                         justify-content: flex-end;

                         span {
                              margin-right: 22px;
                              color: #708393;
                              font-family: Roboto;
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 17px;
                              text-align: right;
                              letter-spacing: 0.2px;
                         }

                         &:after {
                              content: '';
                              position: absolute;
                              top: 21px;
                              right: 26px;
                              width: 6px;
                              height: 6px;
                              transform: translateY(-50%) rotate(45deg);
                              border: 1px solid #73818f;
                              border-top: none;
                              border-left: none;
                              transition: transform 0.3s;
                         }

                         &.show {
                              &::after {
                                   top: 22px;
                                   transform: translateY(-25%) rotate(225deg);
                              }
                         }
                    }
               }

               &.row-rule-default {
                    h4 {
                         max-width: 80%;
                    }

                    .accordion-rule {
                         background-color: #fff;
                    }

                    // .info-wrap {
                    //      min-width: 30%;
                    // }

                    .option-item .form-group {
                         .partner-input {
                              border: none;
                         }
                    }

                    .options-data-wrapper {
                         width: 70%;
                    }

                    .have-line {
                         .option-item3,
                         .option-item2 {
                              &::before {
                                   content: '';
                                   display: block;
                                   position: absolute;
                                   top: 50%;
                                   left: 0;
                                   width: 2px;
                                   height: 20px;
                                   transform: translateY(-50%);
                                   background-color: #b5dce9;
                              }
          
                              .block-tooltip .block-tooltip-text p {
                                   text-transform: none;
                              }
                         }
                    }

                    .option-item3,
                    .option-item2 {
                         &::before {
                              content: none;
                         }
                    }

                    .option-item3 {
                         padding: 0 15px;
                         position: relative;

                         input {
                              background-color: #f1f3f5;
                              width: 250px;
                              padding-right: 65px;
                         }

                         button {
                              position: absolute;
                              right: 16px;
                              top: 50%;
                              transform: translateY(-50%);
                              cursor: pointer;
                              font-weight: 500;
                              margin: 0;
                              padding: 8px;

                              &::before {
                                   content: '';
                                   display: block;
                                   position: absolute;
                                   top: 50%;
                                   left: 0;
                                   width: 2px;
                                   height: 20px;
                                   transform: translateY(-50%);
                                   background-color: #b5dce9;
                              }
                         }
                    }
               }

               .icon-grip-lines {
                    width: 12px;
                    height: 6px;
                    margin-right: 12px;
                    min-width: 12px;
               }

               h4 {
                    margin-bottom: 0;
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
               }

               .tooltip-wrapper {
                    position: relative;
                    padding-bottom: 3px;
                    margin-left: 10px;

                    svg {
                         width: 12px;
                         left: -10px;
                    }

                    > svg path {
                         fill: #20a8d8 !important;
                    }
               }

               &.template-conversion .tooltip-wrapper > svg path {
                    fill: #73818f !important;
               }

               .option-item {
                    position: relative;
                    display: flex;
                    margin-left: -1px;
                    cursor: initial;

                    &:before {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 50%;
                         left: 0;
                         width: 1px;
                         height: 20px;
                         transform: translateY(-50%);
                         background-color: #b5dce9;
                    }

                    &:hover:not(.switch-item) .option {
                         background-color: #b5dce9;
                    }

                    &.switch-item {
                         .option {
                              border-top-right-radius: 12px;
                              border-bottom-right-radius: 12px;
                         }
                    }

                    .option {
                         align-items: center;
                    }

                    .block-tooltip {
                         top: 0;
                         left: 50%;
                         transform: translate(-50%, -100%);
                         min-width: inherit;
                         background-color: #18222b;
                         border-radius: 12px;

                         .block-tooltip-text {
                              padding: 6px 8px;
                              text-align: center;
                              white-space: nowrap;
                              border-radius: 5px;

                              p {
                                   font-size: 10px;
                                   font-weight: 500;
                                   line-height: 12px;
                                   letter-spacing: 0.16666667px;
                                   color: #fff;
                              }
                         }

                         .arrow-tooltip-preview {
                              width: 42px;
                              top: auto;
                              bottom: 7px;
                              left: 50%;
                              transform: translate(-50%, 100%) rotate(180deg);
                              color: #18222b;
                         }

                         &.switch-item-disable {
                              top: 0;
                              right: 100%;
                              transform: translate(50%, -100%);
                              left: auto;

                              .arrow-tooltip-preview {
                                   left: 65%;
                              }
                         }
                    }
               }

               .option {
                    position: relative;
                    display: flex;
                    padding: 15px 14px;
                    align-items: center;

                    &-item-delete {
                         padding: 0 10px;
                    }
               }

               .icon-delete {
                    width: 20px;
                    height: auto;
               }

               .switch-item .option {
                    padding: 12px 14px;
               }
          }

          .row-rule-trigger-accordion {
               font-weight: 500;
               padding: 5px 20px 27px 40px;

               .description {
                    margin-bottom: 15px;
               }
          }

          .learn-how-to-write {
               padding: 0 15px 3px 0;
               text-decoration: underline;
               text-transform: none;
               position: relative;
               color: #7f8c9a;

               &::after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 1px);
                    right: 0px;
                    width: 6px;
                    height: 6px;
                    transform: translateY(-50%) rotate(45deg);
                    border: 2px solid #7f8c9a;
                    border-left: none;
                    border-bottom: none;
                    margin-top: 2px;
               }
          }

          .default-rules {
               background-color: #f0f3f5;
               padding: 20px 20px 14px;
               margin: 20px -20px;

               .headline {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;

                    .tooltip-wrapper {
                         position: relative;
                         padding-bottom: 3px;
                         margin-left: 5px;

                         > svg {
                              height: 12px;

                              path {
                                   fill: #73818f !important;
                              }
                         }
                    }
               }

               h5 {
                    color: #18222b;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.26666668px;
                    margin-bottom: 0;
               }

               .row-rule {
                    background-color: #fff;
                    padding: 0 15px;

                    h4 {
                         margin: 0;
                    }

                    .option-item:last-child:after {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 50%;
                         right: 0;
                         width: 1px;
                         height: 20px;
                         transform: translateY(-50%);
                         background-color: #b5dce9;
                    }
               }
          }

          .reset-to-default-box {
               color: #73818f;
               font-size: 13px;
               font-weight: 400;
               line-height: 19px;
               letter-spacing: 0;
               padding-left: 4px;
               margin-top: 14px;

               .description {
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    letter-spacing: inherit;
               }

               .btn {
                    font-size: inherit;
                    color: inherit;
                    text-transform: inherit;
                    text-decoration: underline;
                    padding: 0;
                    margin-left: 3px;

                    &:hover {
                         text-decoration: none;
                    }
               }
          }

          .list-fields .variable label {
               margin-left: 2px;
          }

          .table {
               &>:not(caption) > * > * {
                    padding: 0.6rem 0.5rem;
               }
          }
     }

     .drag-drop-actions {
          margin-top: 20px;

          .btn {
               border-radius: 20px;
               font-size: 13px;
               font-weight: 700;
               line-height: 16px;
               text-align: center;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;
               padding: 7px 17px;
               min-height: 35px;
               display: flex;
               align-items: center;

               &:not(:last-child) {
                    margin-right: 10px;
               }

               i {
                    font-weight: 600;
                    margin-left: -3px;
                    margin-right: 8px !important;
               }
          }

          .btn-save-change {
               margin-right: 2px;

               svg {
                    height: 9px;
                    margin-right: 5px;
               }
          }

          .btn-link {
               color: #7f8c9a;
               font-size: 13px;
               font-weight: 400;
               line-height: 17px;
               letter-spacing: 0.4px;
               text-align: center;
               text-decoration: underline;
               text-transform: inherit;
               padding: 0;

               svg {
                    height: 8px;
                    margin-left: 6px;
               }
          }

          .learn-how-to-write {
               padding-right: 15px;
               margin-left: 9px;
          }
     }

     .consent {
          &-rules {
               .block-tooltip {
                    left: calc(100% + 10px);
                    top: 5px;

                    .arrow-preview {
                         left: -10px;
                    }
               }
          }
          &-notifi {
               background-color: #f0f3f5;
               padding: 10px 20px;
               border-radius: 10px;
               margin-bottom: 20px;
               .cb-listen {
                    width: 20px;
               }
               .title {
                    font-weight: 500;
                    margin: 0 30px 0 10px;
               }
               p,
               .form-group {
                    margin-bottom: 0;
               }
               .pgc-option {
                    margin-right: 20px;
                    label {
                         font-weight: 500;
                         color: #000 !important;
                    }
               }
          }
          &-link {
               text-decoration: underline;
               color: #73818f;
               margin-left: auto;
          }
     }

     .feature-support-vidyard {
          max-width: 253px;
          display: flex;
          justify-content: space-between;

          label {
               margin-left: 15px;
          }
     }

     .list-rules-and-or {
          .new-rule-group {
               padding-bottom: 20px;
          }

          .rule-row {
               margin-bottom: 9px;
          }

          .rule-col {
               width: 100%;
               max-width: 223px;
               margin-right: 14px;
          }

          label {
               color: #73818f !important;
               font-size: 11px;
               font-weight: 500;
               line-height: 13px;
               letter-spacing: 0.15714286px;
               margin-bottom: 5px;
          }

          .form-control {
               border-radius: 12px;
               border: solid 1px #e2e6e9;
               min-height: 40px;
               font-size: 14px;
               letter-spacing: 0.5px;
          }

          .button-rule-wrapper {
               margin-top: 3px;

               button {
                    font-weight: 700x;
                    font-size: 13px;
                    margin-right: 5px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.18571427px;
                    padding: 7px 14px;

                    &.btn-danger {
                         padding: 6.5px 10.5px;

                         svg {
                              width: 9px;
                              height: 9px;
                         }
                    }
               }
          }
     }

     .save-changes-button-wrapper {
          margin-top: 20px;

          button {
               padding: 9px 23px;
               color: #ffffff;
               font-size: 13px;
               font-weight: 700;
               line-height: 16px;
               text-align: center;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;
               min-width: 144px;

               &.disabled {
                    cursor: default;
                    opacity: 1;
                    border-radius: 22px;
                    background-color: $gray-300;

                    &:hover {
                         background-color: $gray-300;
                    }
               }

               &:not(:first-of-type) {
                    margin-left: 13px;
               }

               &.btn-restore {
                    padding: 9px 18px;
               }
          }
     }

     .table-data {
          .variable-status-header .icon-tooltip-block > svg {
               width: 12px;
               height: 12px;
               margin-top: -2px;
          }
     }

     .data-layer-events-layout .table-data {
          .css-table-row > div.bubble-message .code-preview {
               width: 310px;
          }
     }

     .form-horizontal {
          .list-fields {
               .list-field-actions {
                    display: flex;
                    gap: 10px;

                    .btn {
                         padding: 0;
                    }
               }
          }
     }

     .embed-responsive {
          max-width: 750px;
          margin: 0 auto;
     }

     .data-layer-variables #custom-variable-form {
          .row {
               align-items: center;
          }

          .form-group .col-md-4 {
               > div[class*='col-'] {
                    padding-top: 0;
               }
          }
     }

     .data-layer-variables.custom-user-source #custom-variable-form {
          .row {
               align-items: start;
          }
     }

     .conversion {
          span {
               word-break: break-all;
          }
     }

     .list-rules {
          .block-tooltip {
               z-index: 9;
               cursor: initial;

               .block-tooltip-text {
                    width: fit-content;
                    max-height: initial;
               }
          }
     }

     .list-rules,
     .default-rules,
     .consent-rules,
     .engagement-settings {
          .info-title {
               padding: 4px 10px 4px 0;
               max-width: 1100px;

               h4 {
                    display: contents;
               }

               > * {
                    display: inline-block;
               }

               & + .tooltip-tag {
                    margin-left: 0;
               }
          }
     }

     .reload-variables {
          float: right;
          margin-top: -8px;
     }

     .scroll-depth-settings {
          .variable-input-group {
               max-width: 190px;
               min-width: 170px;

               input {
                    border-radius: 1.25rem;
                    border-top-right-radius: 1.25rem !important;
                    border-bottom-right-radius: 1.25rem !important;
               }
          }

          .custom-control-input,
          .custom-control-label {
               cursor: pointer;
          }

          .custom-control-label {
               line-height: unset;
          }

          .option-buttons {
               display: flex;
               grid-gap: 5px;
               gap: 5px;

               button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 35px;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 1px;

                    svg {
                         &.icon-delete {
                              height: 30px;
                              width: 30px;
                         }

                         &.icon-add {
                              height: 21px;
                              width: 21px;
                         }
                    }
               }

               .btn-primary i {
                    margin-right: 1px;
               }
          }

          .list-fields {
               .invalid-feedback {
                    position: absolute;
                    bottom: -17px;
               }
          }
     }

     .native-browser-storage {
          .form-group {
               .row {
                    margin-bottom: 20px;
               }
          }

          .invalid-feedback {
               position: absolute;
               top: 100%;
               white-space: nowrap;
          }
     }

     .btn-add-row label.add-row {
          color: #73818f;
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: 0.18571427px;
          margin-left: 7px;
          margin-bottom: 0;
          cursor: pointer;
          white-space: nowrap;
     }

     div[class$='container'] {
          div[class$='-menu'] {
               overflow: hidden;

               &:has(.no-options) {
                    overflow: unset;
               }
          }

          div[class*='MenuList'],
          div[class$='control'] > div[class*='css']:nth-of-type(2) > div[class*='css'],
          div[class$='-menu'] > div[class*='css'],
          .css-11unzgr,
          .css-1qmqbrx,
          .css-cz7poj,
          .css-j2v3zm,
          .css-1k1gqol {
               &::-webkit-scrollbar-track {
                    box-shadow: none;
                    border-radius: 5px;
                    background-color: transparent;
               }

               &::-webkit-scrollbar {
                    width: 3px;
               }

               &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                    background-color: #c8ced3;
               }
          }

          div[class$='-option'] {
               font-size: 14px;
               color: #7f8c9a;
               letter-spacing: 0.2px;
               cursor: pointer;
               word-wrap: break-word;

               &.react-select__option,
               &.react-select__option,
               &:hover {
                    color: #20a8d8;
                    background-color: #f3f5f6;
               }
          }
     }

     .button-insert-variable {
          & + .insert-variable-dropdown {
               li:not(.variable-filter) span {
                    color: #7f8c9a;

                    &:hover {
                         color: #20a8d8;
                         background-color: #f3f5f6;
                    }
               }
          }
     }
}

@media (max-width: 1600px) {
     .accordion {
          .destination-conversions {
               .purchase-google-ads {
                    input {
                         min-width: auto;
                    }

                    .consider-conversion {
                         min-width: auto;
                    }

                    // flex-direction: column-reverse;
               }

               .field-event-id {
                    flex-direction: column;
                    padding: 10px 15px;

                    .form-group {
                         padding-left: 0 !important;
                         justify-content: flex-start;

                         .form-control {
                              margin-right: 0 !important;
                              width: 150px;
                         }
                    }
               }
          }
     }
}

@media (max-width: 1450px) {
     .accordion .accordion-item {
          .row-rule-trigger-accordion .content .tooltip-wrapper {
               .arrow-preview {
                    left: 31% !important;
                    top: auto;
                    bottom: -13px;
                    transform: rotate(-90deg);
               }

               .block-tooltip {
                    top: -10px;
                    left: -90px;
                    right: auto;
                    transform: translatey(-100%);
               }
          }
     }
}

@media (max-width: 1280px) {
     .accordion {
          .destination-conversions {
               input {
                    min-width: auto;
               }

               .purchase-google-ads {
                    flex-direction: column-reverse;

                    .option-item3 {
                         padding: 0;
                         width: 100%;
                         margin-right: 10px;
                    }
               }
          }
     }
}

.request-data-form {
     input[type='date'] {
          cursor: pointer;
     }

     .icon-copy-data-request {
          cursor: pointer;
     }

     .css-2b097c-container {
          border: 1px solid #e2e6e9 !important;
          border-radius: 12px;
          min-height: 37px;

          .css-yk16xz-control {
               border-radius: 12px;
          }
     }

     .form-control {
          min-height: 37px;
     }

     label {
          color: #73818f !important;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.5px;
     }

     .estimated-date-range {
          input {
               cursor: pointer;
          }

          .calendar-wrapper {
               position: absolute;
               background-color: #fff;
               width: calc(100% - 31px);
               z-index: 1;
               top: 38px;
          }

          svg {
               position: absolute;
               top: 50%;
               transform: translate(0, -50%);
          }

          .date-remove {
               position: relative;
          }
     }

     @media only screen and (max-width: 1500px) and (min-width: 1380px) {
          .col-md-12.col-xl-6 {
               flex: 0 0 66.66667%;
               max-width: 66.66667%;
          }

          .col-md-6.col-xl-3 {
               flex: 0 0 33.33333%;
               max-width: 33.33333%;
          }
     }

     @media only screen and (max-width: 1380px) and (min-width: 1200px) {
          .col-md-12.col-xl-6 {
               flex: 0 0 100%;
               max-width: 100%;
          }

          .col-md-8.col-xl-4,
          .col-md-6.col-xl-3 {
               flex: 0 0 50%;
               max-width: 50%;
          }
     }

     .date-range-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
     }
}

@media (max-width: 1199px) {
     .accordion {
          .accordion-item.accordion-item-rule ul li {
               flex: 0 0 100%;
          }
     }
}

@media screen and (max-width: 1299px) {
     .accordion .destination-conversions .row-rule.template-conversion .template-conversion-header .form-control {
          min-width: 0;
          text-overflow: ellipsis;
     }
}
