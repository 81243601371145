.subscriber .listener-layout {
     margin-bottom: 80px;

     .headline {
          position: relative;

          .title {
               display: flex;
               align-items: center;
               justify-content: space-between;
          }

          .preview-listener {
               position: absolute;
               top: -12px;
               right: 0;

               .btn-get-help {
                    text-transform: inherit;
                    color: #7f8c9a;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    text-align: center;
                    text-decoration: underline;

                    &:hover {
                         text-decoration: none;
                    }
               }

               .btn-preview {
                    border-radius: 50px;
                    background-color: #18222b;
                    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
                    color: #fff;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0.18571427px;
                    padding: 6px 16px;
                    margin-left: 4px;
                    display: inline-flex;
                    align-items: center;
                    min-height: 35px;
               }
          }

          .description {
               font-size: 16px;
               font-weight: 400;
               letter-spacing: 0.13px;
               line-height: 1.3em;
               padding-top: 3px;
               margin-bottom: 20px;

               * {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0.13px;
                    line-height: 1.3em;
                    padding-top: 3px;
                    margin-bottom: 20px;
               }
          }
     }

     .accordion {
          .accordion-item.show {
               position: relative;
               // z-index: 1048;
               background-color: #fff;
          }
          .custom-forms {
               z-index: 1048;
               .accordion-body {
                    background-color: unset !important;
               }
          }
     }

     .accordion-body {
          .description {
               p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.1px;
               }

               .drag-note {
                    font-size: 16px;
               }
          }
     }

     .button-wrapper {

          button,
          .btn {
               min-width: 129px;
               color: #ffffff;
               font-size: 13px;
               font-weight: 700;
               line-height: 16px;
               text-align: center;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;
               padding: 10px 25px;

               &.disabled {
                    cursor: default;
                    opacity: 1;
                    border-radius: 22px;
                    background-color: $gray-300;

                    &:hover {
                         background-color: $gray-300;
                    }
               }
          }
     }

     .populate-fields-layout {
          .button-wrapper {
               .btn-create-new-rule {
                    margin-right: 10px;
               }

               .btn-save-changes {
                    margin-right: 10px;
               }
          }
     }

     .iframe-forms,
     .geographic-accuracy {
          .switch-status {
               &.enable {
                    margin-bottom: 20px;
               }

               .c-switch {
                    margin-left: 30px;
               }
          }
     }

     .geographic-accuracy {
          .precise-location {
               label {
                    line-height: inherit;

                    &:before,
                    &:after {
                         cursor: pointer;
                    }
               }

               #secondsAfterSessionBegins {
                    margin-bottom: 10px;
               }

               input {
                    max-width: 240px;
               }

               input:disabled {
                    background-image: url(/assets/icons/lock-dropdown.svg);
                    background-size: 12px 13px;
                    background-repeat: no-repeat;
                    background-position-y: 50%;
                    background-position-x: calc(100% - 15px);
                    padding-right: 35px;
               }
          }
     }

     .additional-code-required {
          .form-group {

               margin-bottom: 0;

               label {
                    line-height: inherit;
               }

               label::after {
                    cursor: pointer;
               }

               .custom-control {
                    &.active .custom-control-label {
                         color: #121920 !important;
                    }

                    .custom-control-label {
                         color: #73818f !important;
                    }
               }

               &.confirm-script {
                    margin-top: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    label {
                         margin-bottom: 0;

                         p {
                              margin-bottom: 0;
                              line-height: 28px;
                         }
                    }

                    button {
                         padding: 8px 20px;
                         color: #fff;
                         background-color: #20a8d8;
                    }
               }
          }
     }

     .track-your-order-status-page {
          .accordion {
               .img-step-shopify {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                         width: 80%;
                    }
               }

               .card {
                    border-radius: 10px;
                    margin-bottom: 0;
               }
          }
     }

     .user-weather {
          .days-length {
               margin-bottom: 15px;

               input {
                    margin-left: 20px;
                    max-width: 80px;
               }
          }

          .temperature {
               padding: 15px;
               padding-bottom: 0;

               .temperature-scale {
                    strong {
                         margin-right: 20px;
                    }

                    button {
                         min-width: 110px;

                         &:first-of-type {
                              margin-right: 10px;
                         }
                    }
               }

               .temperature-form {
                    margin-top: 15px;
                    width: 100%;
                    max-width: 350px;

                    label {
                         margin-bottom: 0;
                    }
               }
          }
     }

     .box-listener-preview {
          position: absolute;
          top: -13px;
          right: 0;

          .btn-get-help {
               padding: 0;
               margin-right: 16px;
               color: #7f8c9a;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;
               text-align: center;
               text-decoration: underline;
               text-transform: inherit;

               &:hover,
               &:active,
               &:focus,
               &:focus-visible {
                    text-decoration: none;
               }
          }

          .btn-preview {
               display: inline-flex;
               align-items: center;
               padding: 6px 16px;
               border-radius: 17.5px;
               background: #18222b;
               box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
               color: #ffffff;
               font-size: 13px;
               font-weight: 700;
               line-height: 16px;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;
               min-height: 35px;
          }
     }

     .video-settings {
          .video-progress-setting .invalid-feedback {
               position: absolute;
               bottom: -18px;
          }
     }
}

.instruction {

     h4,
     .more-text,
     .instruction-video,
     #blah2 {
          margin-top: 20px;
     }

     #blah2 {
          width: 100% !important;
     }
}

@media screen and (max-width: 1439px) {

     .c-main>.container-fluid {
          padding-top: 0px;
     }

     .subscriber {
          .listener-layout {
               .box-listener-preview {
                    top: -35px;
               }
          }

          h1 {
               font-size: 30px;
          }
     }

}