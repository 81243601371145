.record-profile {
     .date-range-record-profile {
          width: max-content;
          padding: 0 15px;
          border-radius: 10px;
          border: 1px solid #e2e6e9;
          margin-left: auto;
          zoom: 0.9;
          & .custom-date-wrapper {
               position: absolute;
               right: -18px;
               z-index: 101;
               padding: 0 15px 12px 15px;
               margin-bottom: 0;
               &-top {
                    top: unset;
                    bottom: 0;
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
               }
               &-bottom {
                    top: -1px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
               }
          }
     }

     .accordion-profile {
          margin-top: 20px;
     }
     h4 {
          font-family: 'Roboto';
     }
     .hide-delete-filter {
          margin-right: 59px;
     }
     .message-undefine-form {
          color: #000;
     }

     // .k-grid {
     //      td {
     //           white-space: nowrap;
     //      }
     // }

     .k-table-thead {
          background: #8d9ba8;
     }
     .k-grid-header {
          padding-right: 5px;
          background: #8d9ba8;
     }
     // .k-grid-content {
     //      overflow-y: hidden;
     // }
     .k-grid-content::-webkit-scrollbar {
          width: 5px;
          height: 7px;
          position: relative;
          top: 10px;
     }
     .k-grid-content::-webkit-scrollbar-track {
          background-color: rgb(226, 225, 225);
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
     }
     .k-grid-content::-webkit-scrollbar-thumb {
          background-color: #bdbcbc;
          // border-bottom-left-radius: 9px;
          // border-bottom-right-radius: 9px;
          border-radius: 15px;
     }
     .k-grid {
          border-radius: 12px;
          overflow: hidden;
     }
     .k-grid-header-wrap {
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
     }
     .report-table-bottom {
          padding-left: 30px;
     }
     .k-table {
          .k-column-title {
               font-weight: 500;
          }
          .link-data {
               text-decoration: underline;
               cursor: pointer;
               &:hover {
                    text-decoration: none;
               }
          }
     }

     .table-reveal-people {
          // .k-grid-header col:nth-of-type(1),
          // .k-grid-container col:nth-of-type(1) {
          //      width: 100%;
          //      max-width: 20%;
          // }
          .k-grid-header {
               padding-right: 0;
          }
          .k-grid-content {
               overflow-y: auto;
          }
     }

     .table-form-location {
          .k-grid-header col:nth-of-type(1),
          .k-grid-container col:nth-of-type(1) {
               width: 100%;
               max-width: 40%;
          }
          .k-grid-header {
               padding-right: 0;
          }
          .k-grid-content {
               overflow-y: auto;
          }
     }
     .table-all-page {
          .k-grid-header-table,
          .k-grid-table {
               max-width: 100%;
          }
          .k-grid-header col:nth-of-type(1),
          .k-grid-container col:nth-of-type(1) {
               width: 100%;
               max-width: 70%;
          }
          .k-grid-header {
               padding-right: 0;
          }
          .k-grid-content {
               overflow-y: auto;
          }
     }
     .view-detail {
          .tag {
               cursor: pointer;
               border-radius: 4px;
               border: 1px solid #c3dafe;
               background-color: #ebf4ff;
               padding: 2px 4px;
               line-height: 15px;
          }
          .tags-wrapper .tag:not(.no-click):hover {
               color: #fff;
               border: 1px solid #434190;
               background-color: #434190;
          }
     }
     .analytics {
          margin-top: 20px;
          .report-header {
               padding: 0;
               border-bottom: unset;
          }
          .menu-report {
               border-top-left-radius: 12px;
               border-top-right-radius: 12px;
          }
          .filter-report {
               .filter-button {
                    font-size: 12px;
               }
          }
          .menu-report-list {
               label {
                    font-size: 12px;
               }
          }
          .report-table {
               .k-grid {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
               }
               .k-grid-header-wrap {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0px;
               }
               .k-grid-footer-wrap {
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
               }
               .k-virtual-content::-webkit-scrollbar {
                    width: 5px;
                    height: 7px;
                    position: relative;
                    top: 10px;
               }
               .k-grid-draggable-header,
               .k-grid-footer {
                    padding-right: 0;
               }
               .k-virtual-content::-webkit-scrollbar-track {
                    background-color: rgb(226, 225, 225);
                    border-bottom-left-radius: 9px;
                    border-bottom-right-radius: 9px;
               }
               .k-virtual-content::-webkit-scrollbar-thumb {
                    background-color: #bdbcbc;
                    // border-bottom-left-radius: 9px;
                    // border-bottom-right-radius: 9px;
                    border-radius: 15px;
               }

               .k-virtual-content {
                    overflow-y: auto;
               }
          }
     }
     .record-profile-info {
          p {
               font-family: 'Roboto';
               font-weight: 400;
               font-size: 16px;
               color: #000;
               letter-spacing: 0;
               line-height: 22px;
          }
          .link-data {
               cursor: pointer;
               text-decoration: underline;

               &:hover {
                    text-decoration: none;
               }
          }
          .date {
               display: flex;
               p {
                    padding-right: 50px;
               }
          }
          .list-company {
               height: 100%;
               max-height: 100px;
               overflow-y: auto;
          }

          .list-company::-webkit-scrollbar {
               width: 5px;
               height: 7px;
               position: relative;
               top: 10px;
          }
          .list-company::-webkit-scrollbar-track {
               background-color: rgb(226, 225, 225);
               border-bottom-left-radius: 9px;
               border-bottom-right-radius: 9px;
          }
          .list-company::-webkit-scrollbar-thumb {
               background-color: #bdbcbc;
               // border-bottom-left-radius: 9px;
               // border-bottom-right-radius: 9px;
               border-radius: 15px;
          }
          &.form-profile {
               div {
                    width: 80%;
               }
          }
     }
     .list-count-data {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          grid-gap: 20px;
          gap: 20px;
          margin-top: 35px;

          .box-count-data {
               border: 1px solid;
               display: flex;
               align-items: center;
               justify-content: center;
               flex-direction: column;
               margin-bottom: 20px;
               padding-top: 15px;

               .item-name {
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 500;
                    color: #121212;
               }

               .link-data {
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                         text-decoration: none;
                    }
               }

               h4 {
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 500;
                    color: #121212;
                    text-align: center;
               }
          }
     }

     .page-path-name {
          display: flex;

          & + .page-path-name {
               margin-top: 16px;
          }

          p {
               margin-bottom: 0;
          }

          .title {
               font-family: 'Roboto';
               min-width: 250px;
               text-align: left;
               color: #18222b;
               font-size: 16px;
               line-height: 24px;
               letter-spacing: 0.26666668px;
               font-weight: 500;
          }
          .description {
               margin-bottom: 0;
               font-family: 'Roboto';
               font-weight: 400;
               font-size: 16px;
               color: #000;
               letter-spacing: 0;
          }
     }
     .table-conversion,
     .table-top-page,
     .table-source {
          border: 1px solid #f0f3f5;
          .css-table {
               margin-bottom: 0;
          }
          .pagination {
               padding-top: 20px;
               margin-bottom: 0;
               background-color: #fff;
          }

          .k-grid-header {
               padding-right: 0;
          }
          .k-grid-content {
               overflow-y: auto;
          }
     }
     .source-path {
          height: 100%;
          max-height: 100px;
          overflow-y: auto;
     }
     .source-path::-webkit-scrollbar {
          width: 5px;
          height: 7px;
          position: relative;
          top: 10px;
     }
     .source-path::-webkit-scrollbar-track {
          background-color: rgb(226, 225, 225);
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
     }
     .source-path::-webkit-scrollbar-thumb {
          background-color: #bdbcbc;
          // border-bottom-left-radius: 9px;
          // border-bottom-right-radius: 9px;
          border-radius: 15px;
     }
     .table-session-conversion {
          h4 {
               margin-top: 15px;
          }
     }

     .address-person {
          word-break: break-all;
     }

     span.scrollbar {
          height: 100%;
          max-height: 100px;
          overflow-y: auto;

          &::-webkit-scrollbar {
               width: 5px;
               height: 7px;
               position: relative;
               top: 10px;
          }

          &::-webkit-scrollbar-track {
               background-color: rgb(226, 225, 225);
               border-bottom-left-radius: 9px;
               border-bottom-right-radius: 9px;
          }

          &::-webkit-scrollbar-thumb {
               background-color: #bdbcbc;
               // border-bottom-left-radius: 9px;
               // border-bottom-right-radius: 9px;
               border-radius: 15px;
          }
     }

     .table-data {
          margin-top: 0;
          margin-bottom: 0;
          border-radius: 12px;

          p,
          span {
               font-size: 14px !important;
          }

          .css-table {
               margin-bottom: 0;
          }

          .css-table-header,
          .css-table-body .css-table-row {
               > div {
                    padding: 9px;
                    padding-left: 20px;
               }
          }

          .css-table-header {
               background-color: #8d9ba8;
               color: #000;

               > div {
                    text-align: center;
               }

               span {
                    font-family: 'Roboto';
                    min-width: 285px;
                    padding-right: 0;
                    text-align: center;
                    text-align: left;
                    color: #18222b;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.26666668px;
                    font-weight: 500;
               }
               .time-page {
                    min-width: 200px;
               }
               .table-header-view,
               .table-header-session {
                    min-width: 150px;
               }
               .name-company,
               .domain-company {
                    text-align: left;
               }

               .page-view,
               .page-session,
               .page-user {
                    min-width: 100px;
               }
          }
          .css-table-body {
               .css-table-row {
                    > div {
                         vertical-align: middle;
                    }
                    p {
                         font-family: 'Roboto';
                         font-weight: 400;
                         color: #000;
                         letter-spacing: 0;
                         line-height: 22px;
                         margin-bottom: 0;
                    }
                    .link-data {
                         cursor: pointer;
                         text-decoration: underline;

                         &:hover {
                              text-decoration: none;
                         }
                    }
               }
               .row-item {
                    text-align: center;
               }
          }
     }

     .small-google-map {
          width: 100%;
          max-width: 800px;
          height: 450px;
     }

     .accordion {
          .accordion-item {
               transition: all 0.5s;
          }
          .accordion-body {
               p + p {
                    margin-top: 16px;
                    margin-bottom: 0;
               }
          }
     }

     .row-details {
          display: flex;
          text-align: left;
          align-items: flex-start;

          + .row-details {
               margin-top: 20px;
          }

          span {
               line-height: 21px;
          }

          svg .fa-secondary {
               opacity: 0.4;
          }

          .row-label {
               min-width: 200px;

               svg {
                    margin-right: 8px;
                    margin-bottom: 1px;
               }

               span {
                    font-weight: 500;

                    &.indented {
                         margin-left: 24px;
                    }
               }
          }

          .row-value span {
               line-height: 21px;
          }

          .gb-flag {
               display: inline-block;
               background-size: cover;
               background-repeat: no-repeat;
               height: 100%;
          }
     }

     &.company-profile {
          .record-profile-info {
               .right-box p {
                    margin-bottom: 5px;
               }
          }

          .base-avatar-wrapper {
               position: relative;
               pointer-events: none;

               .base-avatar {
                    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
                    background-size: cover;
                    border-radius: 0.25rem;
                    overflow: hidden;
                    cursor: pointer;
                    width: 3rem;
                    height: 3rem;
               }
          }

          .company-name-wrapper {
               h4 {
                    margin-bottom: 0;
               }

               a {
                    text-decoration: underline;
                    font-weight: 500;
                    align-self: flex-start;
                    cursor: pointer;
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                    display: inline-block;
                    margin-top: 3px;

                    &:hover {
                         text-decoration: none;
                         color: #167495;
                    }
               }
          }

          .the-social-bar {
               justify-content: flex-start;

               .base-social-icon {
                    margin: 2px 3px;
                    width: 100%;
               }

               .base-icon {
                    width: 22px;
                    height: 16px;

                    svg {
                         color: rgb(26, 32, 44);

                         &.icon-linkedin:hover {
                              color: #0077b5;
                         }

                         &.icon-linkedin-sales-navigator:hover {
                              color: #004b7c;
                         }

                         &.icon-facebook:hover {
                              color: #4267b2;
                         }

                         &.icon-instagram:hover {
                              color: #c13584;
                         }

                         &.icon-twitter:hover {
                              color: #1da1f2;
                         }

                         &.icon-youtube:hover {
                              color: red;
                         }

                         &.icon-pinterest:hover {
                              color: #e60023;
                         }
                    }
               }
          }
     }

     .funnel-chart {
          margin-bottom: 20px;
     }

     .form-location-path {
          white-space: unset !important;

          a > svg {
               width: 14px !important;
               height: 14px !important;
               margin-left: 6px;
               position: relative;
               top: -1px;
          }
     }

     .icon-sort {
          margin-left: 2px;
          transform: rotate(180deg);
          height: 12px;

          &.asc,
          &.desc {
               path {
                    fill: #424242;
               }
          }

          &.desc {
               transform: rotate(0);
          }
     }

     .k-loading-color {
          opacity: 0.3;
     }

     .table-step-form,
     .table-data-profile {
          position: relative;
          .filter-loading {
               position: absolute;
               z-index: 10;
               left: 50%;
               top: 50%;
               transform: translateX(-50%);
               width: 100%;
               max-width: 200px;
               .progress {
                    height: 10px;
               }
          }
     }
     .page-loading-container{
          position: relative;
     }
}

// fix bug show tooltip table report Record profile #42762
.tooltip-profile {
     z-index: 4021 !important;
}

.loading-profile {
     .event-profile-session,
     .event-profile-user,
     .event-profile-person,
     .event-profile-company,
     .event-profile-id,
     .event-profile-date {
          position: relative;
          overflow: hidden;
     }
}

.loading-profile {
     .event-profile-session,
     .event-profile-user,
     .event-profile-person,
     .event-profile-company,
     .event-profile-id,
     .event-profile-date {
          &::before {
               background-color: #dddddd;
               content: '';
               width: 100%;
               height: 100%;
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
          }
     }
}

.loading-profile {
     .event-profile-session,
     .event-profile-user,
     .event-profile-person,
     .event-profile-company,
     .event-profile-id,
     .event-profile-date {
          &::after {
               content: '';
               width: 100%;
               height: 100%;
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               background: linear-gradient(to right, #dddddd, #ffffff 50%, #dddddd);
               animation: skeletonLoading 4s infinite alternate;
          }
     }
}

@keyframes skeletonLoading {
     from {
          left: -50%;
     }
     to {
          left: 50%;
     }
}
