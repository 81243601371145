.icon-close-popup {
     position: absolute;
     top: 30px;
     right: 30px;
     cursor: pointer;
}

body:has(.invoice-section .popup-invoice-detail .modal) .os-scrollbar {
     opacity: 0;
     visibility: hidden;
 }

.popup-invoice-detail {
     .modal-dialog {
          max-width: 700px;

          .modal-body {
               padding: 80px 50px 30px;

               p {
                    margin-bottom: 0;
               }

               .invoice-create,
               .invoice-status,
               .invoice-details {
                    margin-bottom: 20px;

                    label {
                         margin-bottom: 0;
                         color: #000 !important;
                    }

                    p {
                         line-height: 1.5em;
                    }
               }

               .invoice-status {
                    margin-bottom: 5px;

                    label,
                    select {
                         color: #000 !important;
                    }

                    p {
                         white-space: pre-wrap;
                         display: flex;

                         label {
                              margin-right: 5px;
                              white-space: nowrap;
                         }

                         .wrap-text {
                              white-space: normal;
                              word-break: break-word;
                         }
                    }
               }

               .invoice-detail .upgrade-suggest .upgrade-package {
                    border-radius: 12px;
               }

               table {
                    tr {
                         th,
                         td {
                              border: 0;
                              padding: 8px 10px;

                              &:last-child {
                                   text-align: right;
                              }
                         }

                         &:not(:last-child) {
                              border-bottom: 1px solid #444c5547;
                         }

                         td {
                              label,
                              p {
                                   font-size: 14px;
                              }

                              label {
                                   font-weight: 500;
                              }
                         }
                    }

                    thead {
                         tr {
                              background-color: #18222b;
                              color: #fff;

                              th {
                                   &:last-child {
                                        width: 100px;
                                   }
                                   background-color: #18222b;
                                   color: #fff;
                              }
                         }
                    }
               }

               .invoice-total {
                    padding-right: 10px;
                    font-weight: 700;
               }

               .btn-fake-link {
                    color: $primary;
                    text-transform: none;
               }
          }
     }
}

.popup-account-past {
     text-align: center;

     .modal-dialog {
          max-width: 686px;

          .modal-body {
               padding: 20px 45px;
          }

          .description {
               p {
                    margin-bottom: 14px;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }

               text-align: left;
               margin-bottom: 20px;
          }
     }
}

.popup-update-payment {
     .modal-dialog {
          max-width: 628px;
     }

     .modal-body {
          padding: 80px 57px 62px;

          .icon-payment {
               text-align: center;
               margin-bottom: 25px;

               svg {
                    color: #73818f;
               }
          }

          form {
               .form-control {
                    height: calc(1.5em + 0.75rem + 10px);

                    &.is-invalid {
                         padding-right: inherit;
                         background-image: none;
                    }
               }

               textarea {
                    &.form-control {
                         height: calc(1.5em + 0.75rem + 70px);
                    }
               }

               .form-group {
                    margin-bottom: 14px;
               }

               .row {
                    > div {
                         &:last-child {
                              .form-group {
                                   margin-bottom: 0;
                              }
                         }
                    }
               }
          }

          .popup-update-payment-description {
               text-align: center;
               margin-bottom: 28px;

               p {
                    margin-bottom: 10px;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }
          }

          .popup-input-card-number {
               padding-right: 5px;
               flex: 0 0 63%;
               max-width: 63%;

               .input-card-number {
                    position: relative;

                    .logo-card {
                         display: flex;
                         align-items: center;
                         position: absolute;
                         top: 50%;
                         right: 15px;
                         transform: translateY(-50%);
                         width: calc(100% - 155px);
                         justify-content: flex-end;

                         img {
                              -webkit-filter: grayscale(100%);
                              /* Safari 6.0 - 9.0 */
                              filter: grayscale(100%);
                              max-height: 18px;
                              opacity: 0.5;

                              &:not(:last-child) {
                                   margin-right: 3px;
                              }

                              &.card-active {
                                   filter: none;
                                   opacity: 1;
                              }
                         }
                    }

                    .invalid-feedback {
                         position: absolute;
                    }
               }
          }

          .popup-input-expiration {
               padding-left: 5px;
               padding-right: 5px;
               flex: 0 0 20%;
               max-width: 20%;
          }

          .popup-input-cvc {
               padding-left: 5px;

               .form-group {
                    margin-bottom: 32px;
               }
          }

          .btn-fake-link {
               margin-right: 30px;
               font-weight: 700;
               color: #d1d6da;
          }

          .btn-primary {
               min-width: 179px;
          }
     }
}

.set-password-popup {
     .modal-dialog {
          max-width: 582px;
     }

     .modal-body {
          padding: 60px 60px 75px;

          .icon-locked {
               margin-bottom: 33px;
               text-align: center;

               svg {
                    color: #73818f;
               }
          }

          .set-password-popup-description {
               text-align: center;
               margin-bottom: 40px;

               h2 {
                    color: #272626;
                    margin-bottom: 15px;
               }

               p {
                    color: #272626;
                    margin-bottom: rem(10);

                    &:last-child {
                         margin-bottom: 0;
                    }
               }
          }

          .form-set-password {
               text-align: left;
               max-width: 395px;
               margin: 0 auto;

               .form-actions {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
               }

               > .form-group {
                    margin-bottom: 9px;
               }

               .form-control {
                    color: #272626;

                    &::placeholder {
                         color: #272626;
                    }

                    height: calc(1.5em + 0.75rem + 10px);
               }

               textarea {
                    &.form-control {
                         height: calc(1.5em + 0.75rem + 70px);
                    }
               }

               .btn-light,
               .btn-primary {
                    padding: 8px 20px;
                    min-width: 182px;
               }
          }
     }
}

.change-email-popup {
     .modal-dialog {
          max-width: 582px;
     }

     .modal-body {
          padding: 60px 60px 75px;

          .change-email-title {
               text-align: center;

               h2 {
                    color: #272626;
                    margin-bottom: 15px;
               }
          }

          .change-email-description {
               text-align: center;
               margin-bottom: 40px;

               p {
                    color: #272626;
                    margin-bottom: 10px;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }
          }

          form {
               text-align: left;
               max-width: 395px;
               margin: 0 auto;

               .form-actions {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
               }

               > .form-group {
                    label {
                         margin-bottom: 5px;
                    }

                    margin-bottom: 9px;
               }

               .form-control {
                    color: #272626;

                    &::placeholder {
                         color: #8192a0;
                    }

                    height: calc(1.5em + 0.75rem + 10px);
               }

               textarea {
                    &.form-control {
                         height: calc(1.5em + 0.75rem + 70px);
                    }
               }

               .btn-light,
               .btn-primary {
                    padding: 8px 20px;
                    min-width: 182px;
               }
          }
     }
}

.confirm-save-popup {
     .modal-dialog {
          max-width: 480px;
          max-height: 520px;
     }

     &.savedview {
          form {
               text-align: left;

               >.form-group {
                    label {
                         margin-bottom: 5px;
                    }

                    margin-bottom: 9px;
               }

               .form-control {
                    color: #272626;

                    &::placeholder {
                         color: #8192a0;
                    }

                    height: calc(1.5em + 0.75rem + 10px);
               }

               textarea {
                    &.form-control {
                         height: calc(1.5em + 0.75rem + 70px);
                    }
               }
          }
     }

     .confirm-save {
          padding: 0 34px 57px !important;

          .confirm-save-icon {
               padding: 60px 0 25px;
          }

          .headline {
               margin-bottom: 25px;

               h2 {
                    font-weight: 300;

                    + h3 {
                         margin-top: 10px;
                    }
               }

               h3 {
                    font-weight: 300;
               }
          }

          .confirm-save-content {
               p {
                    margin-bottom: rem(10);

                    &:last-child {
                         margin-bottom: 0;
                    }

                    color: #2f353a;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 23px;
                    text-align: center;
                    margin-top: 10px;
                    word-break: break-word;

                    strong {
                         word-break: break-word;
                    }
               }

               margin-bottom: 40px;
          }

          .btn {
               // width: 156.5px;
               height: 45px;
               border-radius: 22.5px;
               min-width: 156.5px;
               text-transform: uppercase;

               &.btn-primary {
                    margin-right: 20px;
                    padding: 8px 20px;

                    &:hover {
                         background-color: #2bad58;
                    }
               }

               &.btn-light {
                    padding: 8px 20px;

                    // background-color: #fff;
                    &:hover {
                         background-color: #d9e1e6;
                    }
               }

               &.btn-cancel {
                    padding: 8px 20px;

                    // background-color: #fff;
                    &:hover {
                         background-color: #d9e1e6;
                    }
               }

               &:last-child {
                    margin-right: 0;
               }
          }
     }

     &.term-and-condition {
          .confirm-save {
               padding: 40px 80px !important;

               button {
                    &:disabled {
                         color: #ffffff;
                    }
               }
          }

          .modal-dialog {
               max-width: 970px;
               padding: 0 15px;
          }

          .confirm-save-content {
               text-align: left;
               height: 400px;
               overflow: auto;
               margin-bottom: 15px;
               background-color: #fafafa;
               padding: 15px 18px 15px 30px;
               border-radius: 5px;

               &::-webkit-scrollbar {
                    width: 12px;
               }

               &::-webkit-scrollbar-track {
                    background-color: #fafafa;
               }

               &::-webkit-scrollbar-thumb {
                    border-radius: 15px;
                    background-color: #b8b8b8;
                    border: 3px solid transparent;
                    background-clip: content-box;
               }

               ol,
               ul {
                    font-size: 16px;
                    font-weight: 400;
                    color: #2f353a;
               }

               a {
                    font-size: 1rem;
                    text-decoration: underline;

                    &:hover {
                         text-decoration: none;
                         color: #167495 !important;
                    }
               }

               h2 {
                    margin-bottom: 0.5rem;
               }

               h3,
               h4,
               h5 {
                    font-weight: 300;
               }

               p {
                    text-align: unset !important;
               }
          }

          .term-checkbox {
               padding-left: 20px;
               text-align: left;
               margin-bottom: 25px;

               input {
                    margin-top: 0.2rem;

                    &:not([disabled]) {
                         cursor: pointer;

                         + label {
                              cursor: pointer;
                         }
                    }
               }

               label {
                    color: #000000 !important;
               }
          }

          .effective-date {
               p {
                    font-weight: 400;
                    font-size: 0.875rem;
               }
          }
     }

     &.leave-component {
          .btn-light:focus,
          .btn-light.focus {
               color: #20a8d8;
          }
     }
}

.confirm-are-your-sure-popup,
.more-chat-listeners-popup {
     .modal-content {
          width: 460px;
          margin: 0 auto;
          position: relative;
          border-radius: 12px;
          height: fit-content;

          &:before {
               position: absolute;
               content: '';
               top: 0;
               left: 0;
               width: 100%;
               height: 8px;
               border-radius: 12px 12px 0 0;
               background-image: url('/assets/images/line-rainbow.png');
               background-size: cover;
               background-repeat: no-repeat;
               background-position: center;
          }

          .modal-body {
               margin-bottom: 0;
               .btn-light {
                    color: $white !important;
               }
          }

          .confirm-save-content {
               margin-bottom: 20px;
               color: #2f353a;
               font-family: Roboto;
               font-size: 16px;
               font-weight: 400;
               line-height: 23px;
               text-align: center;

               &.back {
                    margin-bottom: 35px;
               }
          }

          h3 {
               color: #3d475e;
               font-family: Poppins;
               font-size: 20px;
               font-weight: 700;
               line-height: 47px;
               text-align: center;
               letter-spacing: -0.09463231px;
          }

          button {
               width: 306px;
               padding: 15px;
               margin-bottom: 20px;
               border-radius: 22.5px;
               background: #18222b;
               color: #ffffff;
               font-family: Roboto;
               font-size: 13px;
               font-weight: 700;
               line-height: 16px;
               text-align: center;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;

               &:not(:disabled):not(.disabled):active {
                    background: #18222b;
                    color: #ffffff;
               }

               &:first-child {
                    margin-bottom: 8px;
               }

               &.btn-save {
                    padding: 10px 0;
               }
          }
     }

     .confirm-save {
          padding: 35px !important;

          .confirm-save-icon {
               padding: 20px 0 22px;
          }
     }
}

.more-chat-listeners-popup {
     .modal-content {
          width: 532px;
          height: fit-content;

          .modal-body {
               padding: 53px 45px 10px;

               .btn-save {
                    width: 420px;
               }
          }

          p {
               color: #2f353a;
               font-family: Roboto;
               font-size: 16px;
               font-weight: 400;
               line-height: 23px;
               text-align: center;
               margin-bottom: 25px;
          }

          .form-chat-tool {
               width: 420px;
               padding: 20px 10px;
               text-align: center;
               margin: 0 auto;
               border-radius: 22.5px;
               background: #f0f3f5;
               border: 0;
               color: #7f8c9a;
               font-family: Roboto;
               font-size: 15px;
               font-weight: 400;

               &:focus {
                    &::placeholder,
                    &::-webkit-input-placeholder,
                    &:-moz-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder {
                         color: transparent;
                    }
               }
          }

          button:not(:disabled):not(.disabled):active {
               color: #7f8c9a;
               background: none;
          }

          .btn-navigation-step {
               font-size: 14px;
               letter-spacing: 0.2px;
               font-weight: 400;
               color: #7f8c9a;
               background: none;
               font-family: Roboto;
               text-transform: none;
               text-decoration: underline;
               width: initial;
               padding: 10px 0;
               border: none;

               &:hover {
                    text-decoration: none;
               }

               i {
                    color: #708393;
                    margin-right: 5px;
               }
          }

          .btn-save {
               margin-top: 15px;
               margin-bottom: 10px;
          }
     }
}

.get-script-popup {
     height: 215px;

     .script-code {
          position: relative;
          border-radius: 15px;
          padding: 20px 10px 10px 20px;
          margin: 0 auto;
          width: 672px;

          .icon-close-popup {
               z-index: 9999;
               width: 19px;
               height: 19px;
               top: 13px;
          }

          .ace_scrollbar {
               display: none;
          }

          .ace-solarized-light {
               background-color: #f1f3f4;
          }

          .copy-script {
               position: absolute;
               right: -13px;
               top: 0;
               z-index: 99999;
               padding-top: 12px;
               padding-right: 66px;
          }

          .icon-copy {
               cursor: pointer;
               float: right;
               color: #7f8c9a;
               font-family: Roboto;
               font-size: 12px;
               font-weight: 400;
               text-align: center;
               text-transform: capitalize;
               margin-right: 10px;
          }

          #blah2 {
               width: 100% !important;
          }

          .script-buttons {
               position: absolute;
               top: 0px;
               right: 30px;
               z-index: 10;
          }

          button {
               padding: 0;

               svg {
                    width: auto;
                    height: auto;
                    margin: 0;
                    vertical-align: middle;
               }
          }

          svg {
               path,
               polygon {
                    fill: #7f8c9a !important;
               }
          }

          .ace_scroller {
               &.ace_scroll-left {
                    box-shadow: none;
               }
          }

          .ace_scrollbar {
               &.ace_scrollbar-h {
                    height: 6px !important;
                    width: 100%;

                    &::-webkit-scrollbar {
                         height: 6px;
                         background-color: $gray-200;
                    }

                    &::-webkit-scrollbar-thumb {
                         background-color: $gray-300;
                    }
               }
          }
     }

     .modal-content {
          width: 672px;
          margin: 0 auto;
          position: relative;
          border-radius: 12px;
          height: fit-content;

          .modal-body {
               margin: 0;
          }
     }
}

.upgrade-popup {
     padding: 50px;

     h3 {
          font-weight: 400;
          font-size: 28px;
     }

     .upgrade-popup-body {
          p {
               font-size: 14px;
          }
     }

     .sidebar-body {
          p {
               font-size: 14px;
               font-weight: 400 !important;
          }
     }

     // .group-btn {
     //     display: grid;
     // }
     .btn-compare {
          text-align: center;
          margin-top: 10px;
          color: #73818f;
     }
}

.invite-user-owner-popup {
     .modal-dialog {
          max-width: 582px;
     }

     .modal-body {
          padding: 60px 60px 0px;

          .invite-owner-description {
               h2 {
                    color: #272626;
                    margin-bottom: 15px;
                    text-align: center;
                    font-weight: 300;
                    font-size: 2.1865rem;
               }

               p {
                    color: #272626;
                    margin-bottom: 10px;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }
          }

          p + .invite-user-form {
               margin-top: 15px;
          }

          .invite-user-form {
               text-align: left;
               margin: 0 auto;

               label {
                    margin-bottom: 5px;
               }

               .form-control {
                    color: #272626;

                    &::placeholder {
                         color: #272626;
                    }

                    height: calc(1.5em + 0.75rem + 10px);
               }

               textarea {
                    &.form-control {
                         height: calc(1.5em + 0.75rem + 70px);
                    }
               }

               select option {
                    color: #7f8c9a;
               }
          }

          .confirm-payment-switch {
               margin-top: 20px;
               display: flex;
               align-items: center;
               justify-content: space-between;
               text-align: left;
               gap: 15px;

               .c-switch {
                    min-width: 41px;
               }
          }
     }

     .modal-footer {
          display: flex;
          position: relative;
          justify-content: space-between;
          margin-top: 24px;
          padding: 0 0 75px;
          width: 100%;
          max-width: 395px;
          margin: 24px auto 0;
          border: 0;

          .btn-light,
          .btn-primary {
               padding: 8px 20px;
               min-width: 182px;
               margin: 0;
               text-transform: uppercase;
          }

          .spinner-border {
               position: absolute;
               right: -50px;
          }
     }

     &.popup-verify-ownership {
          .modal-footer {
               display: flex;

               button {
                    width: 100%;

                    &:first-child {
                         order: 2;
                    }

                    &:last-child {
                         order: 1;
                         margin-bottom: 10px;
                    }
               }
          }
     }
}

.remove-popup {
     .modal-dialog {
          max-width: 494px;
     }

     .modal-body {
          padding: 55px 37px 30px;
          text-align: center;

          h2 {
               margin-bottom: 18px;
               font-weight: 300;
          }

          .remove-popup-icon {
               margin-bottom: 30px;
          }

          .remove-popup-description {
               p {
                    color: #000 !important;
                    font-size: 15px !important;
                    margin-bottom: rem(10);
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    font-family: Roboto;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }

               strong {
                    word-break: break-word;
               }

               margin-bottom: 35px;
          }

          .btn-delete {
               padding: 8px 25px;
               font-weight: 500;
               text-transform: uppercase;
          }

          .btn-accept {
               button {
                    min-width: 315px;
                    margin-right: 0 !important;
                    box-shadow: none !important;
               }

               .spinner-border {
                    position: absolute;
                    top: 25%;
                    right: 0;
               }
          }

          .btn-cancel {
               color: #808c99 !important;
               padding: 0;
               text-transform: capitalize;
               font-size: 12px;
               font-weight: 400;
          }
     }
}

.variable-in-rules {
     .modal-body {
          .variable-in-rules-description {
               margin-bottom: 20px;
          }
     }
}

.popup-publish-version {
     &.savedview {
          .modal .modal-dialog {
               max-width: 480px;
               margin: 1.75rem auto;
          }
     }

     .modal .modal-dialog {
          max-width: 582px;
          margin: 1.75rem auto;
     }

     .modal .modal-padding-37{
          padding: 37px !important;
     }

     .modal .modal-body {
          padding: 60px 60px 75px;
          position: relative;

          > .icon-close-popup {
               position: absolute;
               top: 30px;
               right: 30px;
               cursor: pointer;
          }

          .popup-publish-version-description {
               text-align: center;
               margin-bottom: 40px;

               h2 {
                    color: #272626;
                    margin-bottom: 15px;
               }

               p {
                    color: #272626;
                    margin-bottom: 10px;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }
          }

          form {
               text-align: left;
               max-width: 395px;
               margin: 0 auto;

               .form-actions {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
               }

               > .form-group {
                    label {
                         margin-bottom: 5px;
                    }

                    margin-bottom: 9px;
               }

               .form-control {
                    color: #272626;

                    &::placeholder {
                         color: #8192a0;
                    }

                    height: calc(1.5em + 0.75rem + 10px);
               }

               textarea {
                    &.form-control {
                         height: calc(1.5em + 0.75rem + 70px);
                    }
               }

               .btn-light,
               .btn-primary {
                    padding: 8px 20px;
                    min-width: 182px;
               }
          }
     }
}

.popup-publish-savedview

.popup-create-variable {
     &.popup-create-variable {
          &.select-domain {
               .confirm-save {
                    padding: 30px 36px 35px !important;
               }
          }
     }

     &.confirm-save-popup {
          .confirm-save {
               .confirm-save-icon {
                    padding-top: 30px;
               }

               .btn {
                    &.btn-primary {
                         width: auto;
                    }
               }
          }
     }

     // .modal-dialog{
     //     max-width: 602px;
     // }
     .modal-body {
          padding: 60px 52px;

          form {
               align-items: center;
               justify-content: center;
               display: flex;
               flex-direction: column;

               .form-control {
                    border-radius: 3.25rem;
                    min-height: 40px;
                    padding: 6px 30px 6px 21px;
               }

               .form-group {
                    width: 88%;

                    .invalid-feedback {
                         text-align: left;
                    }
               }

               .form-actions {
                    a {
                         &:hover {
                              text-decoration: none;
                         }

                         text-decoration: underline;
                    }
               }
          }

          .popup-publish-version-description {
               display: flex;
               align-items: center;
               justify-content: center;
               flex-direction: column;

               h2 {
                    font-weight: bold;
               }

               p {
                    max-width: 437px;
                    margin-top: 10px;
                    font-weight: 400;

                    &.config-required {
                         max-width: none;
                    }
               }
          }
     }

     .modal-body {
          padding: 60px 60px;
     }
}

.popup-upgrade-package {
     .modal-dialog {
          max-width: 582px;
     }

     .modal-body {
          padding: 60px 60px 75px;

          .popup-upgrade-package-description {
               text-align: center;
               margin-bottom: 40px;

               h2 {
                    color: #272626;
                    margin-bottom: 15px;
               }

               p {
                    color: #272626;
                    margin-bottom: 10px;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }
          }

          .popup-upgrade-package-button {
               text-align: left;
               max-width: 395px;
               margin: 0 auto;
               display: flex;
               justify-content: space-between;

               .btn-light,
               .btn-primary {
                    padding: 8px 20px;
                    min-width: 182px;
               }
          }
     }
}

.cancel-account {
     .cancel-account-click-text {
          text-decoration: underline;
          cursor: pointer;
     }

     .confirm-save-popup {
          .confirm-save {
               .confirm-save-content {
                    margin-bottom: 25px;
               }

               form {
                    .input-edit-account-name {
                         margin-bottom: 55px !important;
                    }

                    .invalid-feedback {
                         text-align: left !important;
                    }
               }

               button {
                    min-width: 165px;
                    text-transform: uppercase;

                    &.btn-light {
                         margin-right: 20px;
                    }

                    &.btn-danger {
                         padding: 8px 20px;
                    }
               }
          }
     }
}

.instruction-popup {
     .modal-dialog {
          max-width: 800px;

          .modal-content {
               height: fit-content;
          }

          .modal-body {
               padding: 30px;
               padding-bottom: 40px;

               .icon-close-popup {
                    top: 20px;
                    right: 15px;
               }

               h4,
               .some-small-amount-text,
               .more-text,
               .instruction-video,
               #blah2 {
                    margin-bottom: 20px;
               }

               #blah2 {
                    width: 100% !important;
               }

               .button {
                    display: inline-block;
                    background-color: #20a8d8;
                    color: #fff;
                    padding: 15px 30px;
                    border-radius: 20px;

                    &:hover {
                         text-decoration: none;
                    }
               }

               @media (hover: hover) {
                    .button:hover {
                         background-color: #1985ac;
                    }
               }
          }
     }

     &.script-manually {
          .modal-dialog {
               max-width: 800px;
               margin: 1.75rem auto;

               .modal-content {
                    border-radius: 10px;
               }

               .modal-body {
                    padding: 40px 35px 35px 30px;

                    .icon-close-popup {
                         position: absolute;
                         top: 15px;
                    }

                    .instruction-video {
                         margin-bottom: 0;
                    }
               }
          }
     }
}

.still-working-popup {
     .blue-notice {
          padding: 24px 25px 10px 25px;
          border-radius: 12px;
          background: #d0ecf5;

          h4 {
               color: #121920;
               font-family: Poppins;
               font-size: 15px;
               font-weight: 600;
               line-height: 22px;
               text-align: center;
          }

          p {
               color: #121920;
               font-family: Roboto;
               font-size: 12px;
               font-weight: 400;
               line-height: 18px;
               text-align: center;
               letter-spacing: -0.1px;
               margin-bottom: 10px;
          }
     }
}

@media all and (max-width: 991px) {
     .confirm-save-popup {
          &.term-and-condition {
               .confirm-save {
                    padding: 40px !important;
               }
          }
     }
}

@media all and (max-width: 575px) {
     .confirm-save-popup {
          &.term-and-condition {
               .modal-dialog {
                    padding: 0;
               }

               .confirm-save {
                    padding: 30px 30px 20px !important;
               }

               .confirm-save-content {
                    padding: 15px 8px 15px 20px;

                    ol {
                         padding-inline-start: 16px;
                    }

                    ul {
                         padding-inline-start: 17px;
                    }
               }
          }
     }
}

.confirm-save-popup .confirm-save #termContent.confirm-save-content p {
     text-align: unset;
}