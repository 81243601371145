.choose-how-to-pay.choose-pay-header {
    width: 532px;
    margin: 0 auto;
    padding: 46px 40px 53px;
    position: relative;
    border-radius: 12px !important;
    margin-top: 21px !important;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1) !important;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7px;
        background-image: url(/assets/images/line-rainbow.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
}

.account-header {
    position: relative;
    max-width: 1084px;
    margin: auto;

    svg.icon.icon-custom-size {
        margin: 45px 0 55px;
        width: 184px;
        height: 31.05px !important;

        &.invite-setup-icon {
            height: 20px !important;
        }
    }
}

.create-account-popup .modal-body .btn-back.btn-back-choose {
    position: absolute;
    top: 43px;
    left: -4px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #73818f;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border-radius: 25px;

    i {
        font-weight: 500;
    }
}

.subscriber .full-screen-wrapper .btn-back.btn-back.btn-back-choose {
    position: absolute;
    top: 43px;
    left: -2px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #73818f;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border-radius: 25px;

    i {
        font-weight: 500;
    }
}


// .subscriber .full-screen-wrapper svg.icon.icon-custom-size {
//     margin: 45px 0 55px;
//     width: 184px;
//     height: 31.05px !important;
// }


// css footerr
.create-account-popup {
    #create-account-content {
        .footer {
            margin-bottom: 30px;

            svg.icon.icon-custom-size {
                width: 137px;
                height: 23px !important;
                margin: 185px 0px 30px;
            }

            .text-footer {
                color: #18222b;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 300;
                line-height: 17px;
                text-align: right;
                margin-right: 35px;
                transform: translateX(20px);
            }
        }

    }
}

.subscriber .full-screen-wrapper .footer {
    margin-bottom: 30px;

    svg.icon.icon-custom-size {
        width: 137px;
        height: 23px !important;
        margin: 110px 0px 30px;
    }

    .text-footer {
        color: #18222b;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        line-height: 17px;
        text-align: right;
        margin-right: 35px;
        transform: translateX(20px);
    }
}

.sidebar-popup.billing-infomation.choose-pay-body {
    margin-top: -50px;



    .btn-save {
        margin-top: 17px;
        width: 452px;
        height: 45px;
        font-size: 13px;
        letter-spacing: 0.19px;
        font-weight: 700;
        color: #fff;
    }



    .payment-description {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #7f8c9a;
        margin-bottom: 10px;
        width: 416px;
        letter-spacing: 0px;
    }

    .annual-billing span.c-switch-slider {
        transform: translate(-5px, 10px);
    }

    .annual-billing .annual-billing-text {
        margin-left: 8px;
        font-size: 15px;
        font-weight: 500;
        color: #212c36;
        line-height: 18px;
    }

    .annual-billing+.annual-description {
        margin-left: 60px;
        font-size: 13px;
        color: #000;
        font-weight: 400;
        font-family: Roboto;
        line-height: 30px;
        letter-spacing: 0.086666666px;
        display: inline-block;
        transform: translateY(-5px);
    }


    .description-price span {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.086666666px;
        display: inline-block;
        transform: translateY(-5px);
    }

    .description-price span:first-child {
        margin-left: 60px;
    }

    form {
        margin-top: 25px;

        input[name="stripeCardCVC"] {
            padding-left: 32px;
        }

        input[name="stripeCardExpiration"] {
            padding-left: 16px;
        }

        input {
            border-width: 1px;
            border-color: #c8ced3;
            border-radius: 22.5px;
            font-weight: 400;
            letter-spacing: 0.5px;
            padding-right: 15px;
            background: #f0f3f5;
            color: #7f8c9a;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;

            height: 45px;
            border-radius: 22.5px;
            background: #f0f3f5;
            border: none;
        }

        .form-group.credit-card-name {
            margin-bottom: 11px;
        }

        .cvc-card {
            display: inline-block;
            width: 21.5%;
            padding-left: 5px;
        }

        .expiration-card {
            display: inline-block;
            width: 21.5%;
            padding-right: 5px;
            vertical-align: top;
        }

        .input-card-number {
            position: relative;
            display: inline-block;
            width: 57%;
            padding-right: 10px;
            vertical-align: top;

            .logo-card img {
                width: 18px;
                height: 11px;
                filter: grayscale(100%);
                position: absolute;
                top: -23px;
                left: 195px;
            }

            .logo-card img.card-active {
                filter: none;
                opacity: 1;
                top: -23px;
            }

            .position {
                .logo-card img {
                    width: 18px;
                    height: 11px;
                    filter: grayscale(100%);
                    position: absolute;
                    top: 6px;
                    left: 195px;
                }

                .logo-card img.card-active {
                    filter: none;
                    opacity: 1;
                    top: 8px;
                }
            }

        }


    }


}



.choose-how-to-pay {
    width: 516px;
    margin: 0 auto;
    padding: 46px 53px 20px;

    &.aside-version {
        width: 100%;
        padding: 0 0 20px;
    }

    &.buy-office-hours {
        .payment {

            button[type="submit"],
            button.purchase-btn {
                margin-top: 20px;
            }
        }
    }

    .annual-billing {
        span.c-switch-slider {
            transform: translate(-5px, 10px);
        }
    }

    h3.text-choose {
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3em;
        letter-spacing: -0.4px;
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: block;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    h6.text-des {
        color: #2f353a;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    h3 {
        margin-top: 32px;
        margin-bottom: 17px;
        font-size: 35px;
        font-weight: 300;
        color: #1f1e1e;
    }

    .title {
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0;
        margin-top: 38px;
        margin-bottom: 15px;
        padding-left: 10px;
    }

    .subtitle {
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.11px;
        color: #000;
        margin-bottom: 72px;
    }

    .sidebar-header {
        .icon.logo-how-to-pay {
            width: 50px;
            height: 50px;
        }

        form {
            // margin-top: 27px;
            margin-top: 30px;
        }

        // .form-group input.form-control{
        //     border: none;
        // }
        .form-group input.form-control:focus {
            border: 1px solid #c8ced3;
        }
    }

    .use-current-card {
        margin-top: 13px;
        background-color: #f0f3f5;
        width: 100%;
        padding: 0 23px;
        height: 50px;
        font-size: 15px;
        letter-spacing: 0.2px;
        text-transform: none;
        text-align: left;
        color: #212c36;
        font-family: Roboto;
        font-weight: 500;
        line-height: 18px;
        border-radius: 12px;

        i {
            color: #708393;
            font-weight: 600;
        }

        &__title {
            margin: 20px 0px -9px 2px;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.1px;
        }
    }

    .annual-billing {
        position: relative;
        margin-bottom: -5px;
        margin-top: 70px;
        padding-left: 5px;

        label {
            vertical-align: middle;
        }

        .annual-billing-text {
            margin-left: 9px;
            font-size: 15px;
            font-weight: 500;
            color: #212c36;
            line-height: 18px;
        }

        .cost-per-year {
            position: absolute;
            top: 80%;
            right: 0;
            transform: translateY(-50%);
            font-size: 22px;
            font-weight: 500;
            color: #000;

            &.annually {
                top: 40%;
            }
        }

        &+.annual-description {
            margin-left: 60px;
            font-size: 13px;
            color: #000;
            font-weight: 400;
            letter-spacing: 0.09px;
        }
    }

    .description-price {
        span {
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0.09px;
            line-height: 20px;
            color: #000;

            &:first-child {
                margin-left: 60px;
            }
        }
    }

    .billing-infomation {
        text-align: left;
    }

    form {

        .form-group.credit-card-name {
            margin-bottom: 10px;
        }

        .input-card-number {
            .position {
                .logo-card {
                    left: 10px;
                    top: 10px;
                }
            }
        }

        // margin-top: 30px;
        margin-top: 33px;

        .update-card-details {
            font-size: 15px;
            font-weight: 500;
            color: #26313e;
            margin-bottom: 16px;
        }

        label {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.3px;
            color: #2f353a;
            margin-bottom: 10px;
        }

        input {
            height: 40px;
            border-width: 1px;
            border-color: #c8ced3;
            border-radius: 22.5px;
            font-weight: 400;
            letter-spacing: 0.5px;
            // padding-left: 15px;
            padding-right: 15px;
            background: #f0f3f5;
            color: #7f8c9a;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;

            &[name="stripeCardNumber"] {
                // padding-left: 23px;
                // height: 45px;
                // padding-right: 13px;

                padding-left: 19px;
                height: 45px;
                padding-right: 13px;
                // padding-top: 1px;
            }

            &::placeholder {
                color: #73818f;
            }

            &[name="stripeCardName"] {
                // padding-left: 23px
                padding-left: 17px;
                height: 45px;
                // padding-top: 1px;
            }

            &[name="stripeCardCVC"] {
                padding-left: 16px;
                height: 45px;
                // padding-top: 0;
            }

            &[name="stripeCardExpiration"] {
                height: 45px;
                // padding-top: 0;
                padding-left: 14px;
            }



        }

        input,
        select {
            &.is-invalid {
                border-color: #f86c6b;
                padding-right: 13px !important;
                background-position: right 2px center !important;
                background: none;
            }
        }

        .input-card-number {
            position: relative;
            display: inline-block;
            // width: 210px;
            // margin-right: 10px;
            width: 58%;
            padding-right: 10px;
            vertical-align: top;

            .position {
                position: relative;

                // .form-control {
                //     letter-spacing: .1em;
                // }
            }

            &__img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
            }

            .logo-card {
                display: inline-block;
                position: absolute;
                left: 12px;
                top: 40px;

                img {
                    width: 18px;
                    height: 11px;
                    filter: grayscale(100%);
                    opacity: 0.5;
                    position: absolute;
                    top: 5px;
                    left: 175px;

                    &.card-active {
                        filter: none;
                        opacity: 1;
                        top: 7px;
                    }
                }

                margin-bottom: 20px;
            }
        }

        .expiration-card {
            display: inline-block;
            // width: 100px;
            // margin-right: 10px;
            width: 25%;
            padding-right: 10px;
            vertical-align: top;

            .form-control::placeholder {
                text-align: center;
            }
        }

        .cvc-card {
            display: inline-block;
            width: 17%;
            height: 45px;
        }
    }

    .btn-save {
        height: 45px;
        color: #fff;
        width: 100%;
        font-size: 13px;
        letter-spacing: 0.19px;
        font-weight: 700;
        border-radius: 22.5px;
    }

    .payment-description {
        margin-top: 13px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.17px;
        line-height: 18px;
        color: #7f8c9a;
        margin-bottom: 23px;
    }

    .current-payment-method-wrapper {
        padding: 18px 10px 18px 20px;
        margin-top: 26px;
        border-radius: 12px;
        background-color: #f0f3f5;
        // border: none !important;
        box-shadow: none !important;

        .payment-method-title {
            margin-bottom: 0;
            color: #212c36;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
        }

        .current-payment-card {
            margin-top: 9px;
        }
    }

    .current-payment-card {
        .payment-method-title {
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.32px;
            color: #363c43;
        }

        .payment-hidden {
            background-color: transparent;
            border: none;
            width: 50%;
            padding-left: 0;
        }

        .payment-number {
            font-size: 15px;
            letter-spacing: 0.54px;
            font-weight: 400;
            color: #73818f;
        }

        .btn-change {
            position: absolute;
            top: calc(50% - -10px);
            right: 11px;
            transform: translateY(-75%);
            color: #7f8c9a;
            text-decoration: underline;
            text-transform: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
        }
    }

    .content {
        text-align: center;
        margin: 10px 0 17px;

        p {
            font-size: 14px;
            font-weight: 400;
        }
    }
}

// .choose-how-to-pay.aside-version form .input-card-number .logo-card img {
//         width: 25px;
//         height: 15px;
//         filter: grayscale(100%);
//         opacity: 0.5;
//         position: absolute;
//         top: -26px;
//         left: 175px;
//         &.card-active {
//             filter: none;
//             opacity: 1;
//         }
// }

.masterclass-aside {
    &.choose-how-to-pay {
        padding-top: 30px;
        padding-bottom: 0;
        min-height: calc(100vh - 45px);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .current-payment-method-wrapper {
            margin-top: 0;
        }

        .use-current-card {
            margin-top: -5px;
            background-color: transparent;
        }

        .payment {
            position: relative;
        }

        .purchase-btn {
            border-radius: 50em;
        }

        ul {
            margin: 8px 0 0px;

            li span {
                color: #26313e;
                line-height: 1.3em;
            }
        }
    }

    p {
        color: #2f353a;
        font-weight: 500;
    }

    h3 {
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3em;
        letter-spacing: -0.4px;
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: block;
        margin-top: 30px;
        margin-bottom: 5px;
    }

    ul {
        margin: 20px 0 10px;
        padding: 10px 0;
        border-top: 1px solid #c8ced3;
        border-bottom: 1px solid #c8ced3;

        li {
            padding: 5px 0;

            span {
                font-weight: 500;
                color: #2f353a;
                font-size: 15px;
            }
        }
    }

    .use-current-card {
        padding: 0;
        margin-bottom: 15px;
        height: auto;

        a {
            color: #343f52;

            &:hover {
                text-decoration: none;
            }

            i {
                margin-right: 7px;
            }
        }
    }

    .total-payment {
        margin: 15px 0 10px;

        p {
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 600;
            line-height: 17px;
            color: #2f353a;
        }

        .total {
            font-size: 18px;
            letter-spacing: -0.07570585px;
            text-align: right;
            color: #121920;
        }

        .price {
            margin-right: 10px;
        }
    }

    form {
        margin: 0;

        input {
            border-radius: 25px;
            background-color: #f0f3f5;
            border: none;

            &:focus {
                background-color: #f0f3f5;
            }

            &.is-invalid {
                background-color: #f0f3f5;
            }

            &[name="stripeCardName"],
            &[name="stripeCardNumber"] {
                padding-left: 20px;
                padding-right: 20px;
            }

            &[name="stripeCardExpiration"],
            &[name="stripeCardCVC"] {
                text-align: center;
            }
        }

        .input-card-number .logo-card {
            display: inline-block;
            position: absolute;
            right: 17px;
            top: 8px;
            left: auto;
            margin-bottom: 0;

            img {
                width: 15px;
            }
        }

        .input-card-number {
            position: relative;
            display: inline-block;
            width: 210px;
            margin-right: 10px;
            vertical-align: top;
            padding-right: 0;
        }

        .expiration-card {
            display: inline-block;
            width: 100px;
            margin-right: 10px;
            vertical-align: top;
            padding-right: 0;
        }

        .cvc-card {
            display: inline-block;
            width: 77px;
        }

        .input-card-number .logo-card img.card-active {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
            top: 7px;
        }

        .input-card-number .logo-card img {
            // width: 18px;
            height: 16px;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: .5;
            position: absolute;
            top: 5px;
            left: -10px;
        }
    }

    .box-gift {
        margin-top: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        left: 0;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 25px;
            margin-bottom: 6px;
            margin-right: 12px;
        }

        >h6 {
            font-size: 16px;
            font-weight: 700;
            color: #18222b;
            margin-bottom: 0;
        }
    }

    .masterclass-special-price {
        margin-bottom: 27px;

        h4 {
            margin-bottom: 4px;

            +p {
                margin-bottom: 0;
            }
        }
    }
}

.subscriber {
    .action-required-popup {
        .action-required {
            max-width: 720px;
            margin: 0 auto;
            padding: 0 80px 67px;

            .confirm-save-icon {
                padding: 50px 0 35px;

                svg {
                    margin: 0;
                    width: 46px;
                    height: 46px !important;
                }
            }

            h2 {
                margin-bottom: 25px;
                font-weight: 300;
            }

            .action-required-description {
                p {
                    margin-bottom: rem(10);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                margin-bottom: 55px;
            }

            .action-required-button {
                button {
                    min-width: 165px;
                    text-transform: uppercase;

                    &.btn-primary {
                        margin-right: 20px;
                        padding: 8px 20px;
                    }
                }
            }
        }
    }
}

.payment-hidden {
    display: flex;
    height: 100%;
    align-items: center;
    border: 2px solid #dce0e4;
    padding-left: 7px;
    background-color: #f0f3f5;
    border-radius: 3px;

    img {
        max-height: 18px;
        margin-right: 10px;
    }

    .hidden-payment-number {
        margin-right: 9px;
        display: flex;

        .hidden-circle {
            width: 5px;
            height: 5px;
            background-color: #7f8c9a;
            margin-right: 2px;
            border-radius: 50%;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
        }
    }
}

.payment-number {
    color: #7f8c9a;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
}

.icon-close-popup {
    margin: 0;
}

.setup-new-account {
    width: 532px;
    margin: 0 auto;


    img.play-icon {
        padding-left: 10px;
    }

    .footer.text-center.new-account-footer {
        margin-top: -107px;
    }

    a.btn.btn-success.text-uppercase,
    button.btn.btn-success.text-uppercase {
        border-radius: 22.5px;
        background: #18222b;
        margin-bottom: 10px;

        color: #ffffff;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.18571427px;
        border: 1px solid #c8ced3 !important;
        padding-left: 8px;
    }

    button.btn.btn-success.text-uppercase {
        width: 100%;
        line-height: 31px;
    }

    button.btn-save.btn {
        height: 45px;
        border-radius: 22.5px;
        background: #ffffff;
        color: #18222b;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.18571427px;
        border: 1px solid #c8ced3 !important;
    }



    h3 {
        font-family: Poppins;
        font-weight: 700;
        letter-spacing: .04em;
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        letter-spacing: -.5px;
        margin-bottom: 18px;
        font-size: 36px;
        margin-top: 16px;

    }

    &>p {
        margin-bottom: 31px;
        color: #18222b;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
    }

    >a {
        color: #2f353a;
        text-decoration: underline;
        font-size: 13px;
        margin: 10px 0 -10px 0;
        display: block;

        &+.button-row {
            margin-top: 20px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .open-new-screen {
        text-align: right;
        margin-bottom: 5px;

        a {
            color: #2f353a;
            text-decoration: underline;
            font-size: 12px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .video-wrapper {
        // margin-bottom: 30px;
        background-color: #fff;
        border: 0.5px solid #c8ced3;
        overflow: hidden;
        // height: 338px;
        border-radius: 12px;
        background: #ffffff;
        background-blend-mode: normal;

        .icon-play {
            width: 74px;
            height: 74px;
            background-color: #f0f3f5;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            svg {
                width: 14px;
                position: absolute;
                top: 50%;
                left: calc(50% + 2px);
                transform: translate(-50%, -50%);
            }
        }
    }

    .button-row {
        margin-top: 16px;
        text-align: center;

        .checkbox-show-again {

            .check-login {
                width: 15px;
                height: 15px;
                padding: 0px;
                // background: #73818f;
                border-radius: 50%;
                border: 1px solid #73818f !important;
            }

            input {
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 8px;
                border-color: #c8ced3;
                border: 1px solid #73818f !important;
                cursor: pointer;

                vertical-align: middle;
                appearance: none;
                -webkit-appearance: none;
                outline: none;

                &:checked {
                    background-image: url('/assets/icons/icon-check.svg');
                    background-repeat: no-repeat;
                    background-position: 51% 45%;
                    border: none;
                    opacity: 1;
                    background-color: #73818f;
                    border: none !important;
                }
            }

            label {
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 0;
                cursor: pointer;
                color: #7f8c9a !important;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
            }
        }

        .btn-save {
            background-color: #20a8d8;
            margin-top: 0;
        }

        @media (hover: hover) {
            .btn-save:hover {
                // color: #fff;
                background-color: #e5edf0;
                ;
            }
        }

        .btn-wrapper {
            margin-top: 26px;

            a {
                display: flex;
                height: 45px;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media all and (max-width: 991px) {
    .setup-new-account .button-row .btn-wrapper a {
        margin-bottom: 15px;
    }
}