.versions {
    .card {
        .card-body {
            tr {
                td {
                    h2 {
                        .icon-custom-size {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    table {
        .badge-warning,
        .badge-secondary,
        .btn-square {
            padding: 8px 10px 6px !important;
            font-size: 10px;
            line-height: 10px;
        }
        th,
        td {
            vertical-align: middle;
        }
        td {
            padding-top: 12px;
            padding-bottom: 11px;
        }
        .btn-square {
            max-width: 50px;
            width: 100%;
        }
    }
}
