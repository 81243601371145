.ll-flexible-modal-overlay {
     opacity: 0.32;
     -moz-transition: all 0.4s;
     -o-transition: all 0.4s;
     -webkit-transition: all 0.4s;
     transition: all 0.4s;
     background: #000;
     bottom: 0;
     left: 0;
     position: fixed;
     right: 0;
     top: 0;
     visibility: hidden;
     z-index: 4021;

     &:nth-last-of-type(4) {
          opacity: 0.32;
          visibility: visible;
     }

     &:nth-last-of-type(2) {
          opacity: 0;
          visibility: hidden;
     }
}

.ll-flexible-modal {
     &--animated {
          .ll-flexible-modal {
               -moz-transition: -moz-transform 0.4s;
               -o-transition: -o-transform 0.4s;
               -webkit-transition: -webkit-transform 0.4s;
               transition: all 0.4s;
          }
     }

     &--pulling {
          .ll-flexible-modal:before {
               content: '';
               display: block;
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               z-index: 9;
          }

          .flexible-resize:after {
               content: '';
               display: block;
               left: 0;
               top: 0;
               bottom: 0;
               width: 100vw;
          }
     }

     .step-create-destination {
          &::after {
               content: '';
               position: absolute;
               z-index: 2000;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               background: rgba(0, 0, 0, 0.6);
          }
          .custom-destination.card {
               position: relative;
               z-index: 3000;
          }
     }

     &.hide {
          display: none;
     }
}

.ll-flexible-modal,
.ll-sheet-wrapper {
     flex-direction: column;
     display: flex;
     flex-wrap: nowrap;
}

.ll-flexible-modal {
     background: #f1f3f4;
     bottom: 0;
     left: auto;
     right: 0;
     max-width: 100%;
     overflow-x: hidden;
     overflow-y: auto;
     position: fixed;
     top: 0;
     width: 100%;
     z-index: 4021;
     border: 1px solid #f0f3f5;
     border-top-left-radius: 12px;
     border-bottom-left-radius: 12px;

     &.full-screen .flexible-resize {
          display: none;
     }

     .flexible-resize {
          width: 6px;
          height: 100%;
          position: absolute;
          background-color: #f0f3f5;
          cursor: col-resize;
          z-index: 4;
          left: 0;
          top: 0;

          &:before {
               content: '';
               width: 3px;
               height: 22px;
               background-color: #73818f;
               position: absolute;
               top: 50%;
               left: 1px;
               z-index: 9;
               border-radius: 4px;
               cursor: col-resize;
               transform: translateY(-50%);
          }
     }

     &:nth-last-of-type(3) {
          box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.2), 0 0 24px 2px rgba(0, 0, 0, 0.14), 0 0 30px 5px rgba(0, 0, 0, 0.12);
     }

     &:last-of-type {
          -webkit-transform: translate(100vw, 0);
          transform: translate(100vw, 0);
          opacity: 0;
          pointer-events: none;
     }

     &:last-of-type {
          -moz-transition: -moz-transform 0.4s, box-shadow 0s, opacity 0s;
          -o-transition: -o-transform 0.4s, box-shadow 0s, opacity 0s;
          -webkit-transition: -webkit-transform 0.4s, box-shadow 0s, opacity 0s;
          transition: transform 0.4s, box-shadow 0s, opacity 0s;
          -moz-transition-delay: 0s, 0.4s, 0.4s;
          -o-transition-delay: 0s, 0.4s, 0.4s;
          -webkit-transition-delay: 0s, 0.4s, 0.4s;
          transition-delay: 0s, 0.4s, 0.4s;
     }

     &.full-screen {
          border-radius: 0;

          .flexible-modal-header {
               .sidebar-brand-full {
                    max-width: 137px;
               }

               .full-screen-message {
                    margin: 0 0 0 57px;
                    font-size: 12px;
                    font-weight: 400;
               }

               .btn-full-screen {
                    color: #f86c6b;
               }
          }

          .flexible-modal-body {
               .rule-detail {
                    width: 100%;
                    max-width: 1475px;
                    margin: 0 auto;
               }
          }
     }

     .ll-sheet-wrapper {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
     }

     .flexible-modal-header {
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          flex: 0 0 64px;
          -webkit-box-ordinal-group: 0;
          -moz-box-ordinal-group: 0;
          -ms-flex-order: 0;
          -webkit-order: 0;
          order: 0;
          background: #fff;
          border-bottom-color: #dadce0;
          position: relative;
          max-height: 85px;
          padding: 32px 30px;
          border-bottom: 1px solid #f0f3f5;
     }

     .flexible-modal-body {
          background-color: #fff;
          height: 100%;
          margin-right: 5px;
          padding: 20px 30px 35px;
          overflow-y: auto;
          overflow-x: hidden;

          &::-webkit-scrollbar {
               width: 5px;
          }

          &::-webkit-scrollbar-track {
               width: 5px;
               background-color: #e2e6e9;
               border-radius: 2px;
          }

          &::-webkit-scrollbar-thumb {
               background: #73818f;
               border-radius: 2px;
               border: 1px solid #e2e6e9;
          }

          &.remove-scroll {
               overflow-y: hidden;
          }

          .flexible-modal-content {
               padding-bottom: 30px;
               margin-bottom: 50px;
          }
     }

     .ll-flexible-modal-with-header::before {
          background: #fff;
          border-bottom: 1px solid #dadce0;
          content: ' ';
          height: 64px;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: -1;
     }

     .ll-flexible-modal-with-footer::after {
          background: #fff;
          border-top: solid 1px #dadce0;
          bottom: 0;
          content: ' ';
          height: 56px;
          left: 0;
          position: absolute;
          right: 0;
          width: 100%;
          z-index: -1;
     }

     .flexible-modal-header--lined {
          border-bottom-color: #dadce0;
     }

     .center-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
     }

     .btn-close,
     .btn-full-screen {
          cursor: pointer;
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 0;
     }

     .btn-close {
          svg {
               width: 8.93px !important;
               height: 8.65px !important;
               margin-right: 9px;
          }
     }

     .btn-full-screen {
          svg {
               width: 14px !important;
               height: 14px !important;
               margin-left: 12px;
               margin-bottom: 4px;
          }
     }
}

.unsaved-changes-popup {
     position: relative;
     z-index: 4022;

     .modal {
          pointer-events: none;
     }

     .modal-dialog {
          pointer-events: none;
     }
}
