.tag-managers {
     .headline {
          margin-bottom: 2rem;
          a.text-primary{
               // font-weight: 500;
          }
     }
     h1{
          padding-bottom: 15px !important;
     }
}
