.global-reporting {
    .reporting-table-event {
        padding: 0;
        
        .css-table {
            margin-bottom: 0;
        }
    
        // .variable-name {
        //     width: 20%;
        // }
    
        // .variable-friendlyname {
        //     width: 27%;
        // }
    
        .reporting-values {
            width: 15%;
        }
    
        .variable-path {
            width: 10%;
        }
    
        .reporting-values {
            .c-switch {
                padding-top: 7px;
            }
        }
    
        .variable-value-check {
            cursor: pointer;
            margin-left: 10px;
            font-size: 16px;
        }
    
        .variable-friendlyname {
            span {
                word-break: break-all;
            }
        }
    }
}

.variable-value-chart {
    .modal-dialog {
        max-width: 80%;

        .modal-body {
            padding-top: 3rem;
            
            .icon-close-popup {
                top: 20px;
            }
        }
    }
    // .apex-chart {
    //     .apexcharts-tooltip-marker {
    //        flex: 0 0 12px;
    //     }
    //     .apexcharts-tooltip-text-label {
    //         white-space: normal;
    //         word-break: break-all;
    //     }
    // }
}