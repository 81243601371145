// scss-docs-start headings typography
@mixin heading-line-height($variant) {
	$line-height: map-get($variant, "line-height");

	--#{$prefix}heading-line-height: #{$line-height};
}
// scss-docs-end headings typography


@each $state, $variant in $heading-variants {
	#{$state} {
	  @include heading-line-height($variant);
	}
}