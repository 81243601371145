.subscriber .headline-wrapper {
     position: relative;
     margin-bottom: 23px;

     .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
     }

     .description {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.13px;
          line-height: 22px;
          margin-top: 0;
         
          * {
               font-size: 16px;
               font-weight: 400;
               letter-spacing: 0.13px;
               line-height: 22px;
               padding-top: 3px;
          }

          p + p  {
               margin-bottom: 10px;
          }

          p {
               margin-bottom: 0;
          }

          a {
               text-decoration: underline !important;
               color: $primary;

               &:hover {
                    text-decoration: none !important;
                    color: #167495 !important;
               }
          }
     }
}
