.create-account-popup {

    &.account-setup,
    &.installation,
    &.welcome {
        #create-account-content {
            padding-top: 80px;
        }

        .setup-new-account {
            padding-top: 70px;
        }

        .modal-body {
            h3 {
                font-size: 35px;
                margin-bottom: 17px;
            }

            p {
                padding: 0 5px;
                margin-bottom: 8px;
            }
        }
    }

    &.create-preview-rule {
        .modal-content {
            background-color: #f0f3f5;
            height: 100%;
            min-height: fit-content;
        }
    }

    .des {
        font-weight: 400;
    }

    .main-container {
        height: fit-content;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
    }

    .main-content {
        &:not(.full-width) {
            max-width: 692px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 100px;
            justify-content: center;
        }

        &.width-medium {
            max-width: 800px;

            & .custom-destination-modal {
                max-width: 600px;
                margin-right: 30px;

                .invalid-feedback {
                    position: absolute;
                }
            }
        }

        .list-package {
            justify-content: center;

            .package-col {
                padding: 0;

                &.package-col-5 {
                    max-width: 20%;

                    p.des {
                        min-height: 56px;
                    }

                    .plan-features-header {
                        padding-right: 12px;
                    }

                    img.listener,
                    img.star {
                        transform: unset !important;
                    }
                }

            }
        }

        .blue-notice {
            width: 440px;
            margin: 0 auto 20px;
            padding: 24px 25px 10px 25px;
            border-radius: 12px;
            background: #d0ecf5;

            h4 {
                color: #121920;
                font-family: Poppins;
                font-size: 15px;
                font-weight: 600;
                line-height: 22px;
                text-align: center;
            }

            p {
                color: #121920;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.1px;
                margin-bottom: 10px;
            }
        }
    }

    .create-rule-browser {
        .select-wrapper {
            .form-control {
                // color: #000000;
                // white-space: nowrap;
                // overflow: hidden;
                // &:after{
                //     content: '';
                //     position: absolute;
                //     width: 35px;
                //     height: 85%;
                //     background: #fff;
                //     top: 2px;
                //     right: 2px;
                //     background-image: url(/assets/icons/icon-select.svg);
                //     background-repeat: no-repeat;
                //     background-position-x: calc(100% - 12px);
                //     background-position-y: center;
                //     background-size: 6px;
                //     border-bottom-right-radius: 22.5px;
                //     border-top-right-radius: 22.5px;
                // }
            }
        }
    }

    .main-header {
        padding: 73px 0 78px;
    }

    .modal {
        z-index: 1051;
    }

    .btn-back {
        position: relative;
        flex: 1;
        text-align: left;
        padding-left: 20px;
        padding-top: 9px;
    }

    .hero-logo {
        flex: 2;
        text-align: left;
        padding-left: 150px;
    }

    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: 100%;
    }

    #create-account-content {
        position: fixed;
        height: 100%;
        width: 100%;
        border-radius: 0;
        background-color: #f0f3f5;
        overflow-y: auto;
        border: 0 !important;

        &:has(.account-see-pricing.upgrade-package)>.modal-body {
            background: url(/assets/images/background-pricing.png) no-repeat;
            background-size: 100%;
            background-color: #f7f9fb
        }

        h1 {
            background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            font-size: 35px;
            display: block;
        }
    }

    .modal-body {
        text-align: center;
        padding: 0;

        &>svg {
            &.icon-close-popup {
                position: fixed;
                margin: 0;
                top: 84px;
                right: 100px;
            }
        }

        h3 {
            font-family: Poppins;
            background: linear-gradient(161deg, #29263e 0%, #233b7d 37%, #20a8d8 100%);
            color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 20px;
            font-weight: 700;
            line-height: 47px;
            text-align: center;
            letter-spacing: -0.09463231px;
        }

        h6 {
            color: #000;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0.11px;
            margin-bottom: 40px;
        }
    }

    .main-modal {
        align-items: center;
    }

    .btn-logout {
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: #73818f;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
    }

    .btn-back {
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: #73818f;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            background-image: url('/assets/images/icon-chevron-left.png');
            width: 6px;
            height: 7px;
            top: 50%;
            left: 0;
            transform: translate(50%, -30%);
        }

        i {
            color: #708393;
            margin-right: 5px;
            font-size: 17px;
            // width: 6px;
            // height: 7px;
        }
    }

    .btn-sign-out {
        margin-top: 20px;
        font-weight: 400;
        color: #f86c6b;
    }

    .billing-infomation {
        .btn-save {
            background-color: #4dbd74
        }
    }

    .btn.btn-save {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        font-size: 13px;
        letter-spacing: 0.19px;
        font-weight: 700;
        color: #fff;
        border-radius: 22.5px;
        border: none;
        background: #18222b;

        &.btn-green {
            background: #4dbd74;
            padding: 10px 0;

            &:hover {
                background-color: #2bad58;
            }
        }

        &.btn-white {
            border: 1px solid #c8ced3 !important;
            color: #73818f;
            background: #fff;
            padding: 10px 0;

            &:hover {
                background-color: #d9e1e6;
            }
        }

        &>p {
            font-size: 13px !important;
            color: #FFFFFF !important;
            margin-bottom: 0 !important;
            font-weight: bold !important;
        }

        &>span {
            font-size: 12px;
            color: #ffffff;
            text-transform: none;
            font-weight: 300;
        }

        .dots-waiting {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .btn.btn-save.disabled {
        background: #c8ced3;
        font-size: 15px;
        opacity: 1;
    }

    .btn-select {
        margin-bottom: 20px;
        width: 100%;
        height: 45px;
        font-size: 13px;
        letter-spacing: 0.19px;
        font-weight: 700;
        color: #18222b;
        border-radius: 22.5px;
        border: none;
        border-radius: 22.5px;
        background: #f0f3f5;
        text-transform: capitalize;
    }

    .foot-note {
        display: block;
        color: #7f8c9a;
        font-family: Roboto;
        margin: 20px auto 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;

        &.below-text {
            max-width: 480px;
            padding-right: 37px;
        }
    }

    .color-line {
        margin: 0 auto;
        margin-right: 37px;
        width: 532px;
        padding: 53px;
        position: relative;
        border-radius: 12px;
        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1) !important;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 8px;
            border-radius: 12px 12px 0 0;
            background-image: url('/assets/images/line-rainbow.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .account-name,
    .account-time-zone,
    .account-role,
    .account-see-how-it-work,
    .account-domain,
    .account-gtm,
    .account-script,
    .account-script-manually,
    .account-container,
    .account-gtm-no-publish,
    .account-brings,
    .account-almost-done,
    .account-configure-measurement,
    .account-form,
    .account-gtm-experience,
    .account-organization,
    .account-configuring,
    .account-test-plan,
    .account-paid-plan,
    .account-consent,
    .account-dormant,
    .cancel-account-dormant {
        margin: 0 auto;
        margin-right: 37px;
        width: 532px;
        padding: 53px;
        position: relative;
        border-radius: 12px;
        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1) !important;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 8px;
            border-radius: 12px 12px 0 0;
            background-image: url('/assets/images/line-rainbow.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .number-of-step {
            text-align: right;
            color: #7f8c9a;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            opacity: 0.5002979;
            padding-right: 0;
            margin-bottom: 5px;
        }

        .card-body {
            width: 100%;
            padding: 0 20px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.11px;
            line-height: 1.5;
            color: #000;
            margin-bottom: 35px;
        }

        .select-react-customize {
            margin-bottom: 10px;

            // &.blur {
            //     opacity: 0.5;
            // }
            .select-react {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                input {
                    height: 28px;
                }

                .custom-select__indicators {
                    display: none;
                }

                .custom-select__menu {
                    text-align: left;
                }
            }

            .select-wrapper {
                >input {
                    text-align: left;
                }
                .custom-select__menu {
                    .custom-select__menu-list {
                        .custom-select__option {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        input[type="text"],
        textarea {
            height: 44px;
            text-align: center;
            border-color: #c8ced3;
            color: #1b2632;
            border-radius: 22.5px;
            background: #f0f3f5;
            background-blend-mode: normal;

            &::placeholder {
                color: #7f8c9a;
                font-size: 15px;
                font-weight: 400;
            }

            &.is-invalid {
                border-color: #f86c6b;
            }
        }

        .adjust-domain {
            border: 1px solid #d8d8d8;
            background: none;
            color: #212c36;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            text-align: center;

            &.navigation {
                border-radius: 22.5px;
                background: #d0ecf5;
                background-blend-mode: normal;
                color: #212c36;
            }
        }

        .btn-navigation-step {
            margin-top: 20px;
            font-size: 14px;
            letter-spacing: 0.2px;
            font-weight: 400;
            color: #7f8c9a;
            font-family: Roboto;
            text-transform: none;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            i {
                color: #708393;
                margin-right: 5px;
            }
        }
    }

    .account-see-how-it-work {
        width: 650px;
    }

    .account-role,
    .account-gtm-experience,
    .account-organization,
    .account-domain,
    .account-gtm,
    .account-configuring,
    .account-test-plan,
    .account-paid-plan,
    .account-consent {
        padding: 22px 20px 25px 20px;

        h3 {
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 15px;
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }

        .btn-select {
            width: 420px;
            margin: 0 auto;
            margin-bottom: 13px;
            padding: 12px 10px 30px 13px;
        }
    }

    .account-organization {
        h3 {
            margin-bottom: 25px;
        }

        .btn-select {
            text-transform: none;
        }
    }

    .account-gtm-experience {
        p {
            padding: 0 65px;
            margin-bottom: 32px;
        }

        .btn-select {
            text-transform: none;
        }
    }

    .account-name {
        padding: 22px 16px 36px 20px;

        .form-control {
            border: none !important;
        }

        .number-of-step {
            margin-bottom: 5px;
        }

        h3 {
            margin-bottom: 5px;
        }

        .des {
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }

        button.btn,
        input,
        textarea {
            width: 420px;
            border: 0;
        }

        textarea {
            height: 6em;
            padding-top: 0.8em;
        }

        input,
        textarea {
            margin: 0 auto;
        }

        button {
            margin-top: 17px;
        }

        .foot-note {
            margin-top: 23px;
            padding: 0 5px;
            color: #7f8c9a;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }
    }

    .account-domain,
    .account-configuring {
        padding: 22px 20px 53px;

        &.parent {
            .form-control {
                border: none !important;
            }
        }

        p {
            padding: 0 15px;
            margin-bottom: 32px;
        }

        button,
        input {
            width: 420px;
        }

        input {
            margin: 0 auto;
        }

        button {
            margin-top: 17px;
        }

        &.redirect {
            padding: 22px 20px 33px;

            .number-of-step {
                margin-bottom: 0px;
            }

            p {
                margin-bottom: 38px;
            }

            .domain-setting {
                margin-bottom: 19px;
            }

            .foot-note {
                margin-bottom: 10px;
                font-size: 14px;
            }

            input {
                &.navigation {
                    margin-bottom: 38px;
                    border: none !important;
                }
            }

            .btn-navigation-step {
                margin-top: 15px;
            }
        }

        &.checking {
            padding: 22px 20px;

            p {
                margin-bottom: 25px;
            }

            .number-of-step {
                margin-bottom: 5px;
            }

            .lottie-animation {
                max-width: 50%;
                height: 100px;
                margin: 0 auto;
            }

            svg {
                transform: scale(2) !important;
            }

            input {
                border-radius: 22.5px;
                border: 1px solid #d8d8d8;
                background: #ffffff;
                color: #7f8c9a;
                text-overflow: ellipsis;
            }

            .blue-notice {
                margin: 20px auto 10px;

                p {
                    margin-bottom: 10px;
                }
            }
        }

        &.cannot-access {
            padding: 22px 20px 39px;

            .notice {
                background: rgba(252, 210, 210, 0.35);
                width: 420px;
                margin: 0 auto 25px;
                padding: 32px 0 3px;

                .title {
                    p {
                        color: #73818f;
                        font-family: Roboto;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 23px;
                        text-align: center;
                        margin-bottom: 21px;

                        &:first-child {
                            margin-bottom: 0;
                        }

                        .c-red {
                            font-weight: 500;
                            color: #f86c6b;
                        }

                        .c-black {
                            font-weight: 500;
                            color: #212c36;
                        }
                    }
                }
            }

            .enter-new-domain {
                padding: 0 15px;
                margin-bottom: 15px;
            }

            .btn-save {
                padding: 12px 10px 30px 10px;
            }

            .btn-navigation-step {
                margin-top: 15px;
            }
        }

        .notification {
            width: 420px;
            margin: 43px auto 0;
            padding: 23px 20px 5px 18px;

            p {
                color: #121920;
                font-family: Roboto;
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                padding: 0;
                margin-bottom: 20px;
            }
        }

        &.success {
            padding: 22px 15px 35px;

            h3 {
                color: #4dbd74;
                background: none;
                -webkit-text-fill-color: #4dbd74;
                margin-bottom: -5px;
                margin-top: 20px;
            }

            .lottie-animation {
                width: 85px;
                height: 85px;
                margin: 0 auto;
            }
        }
    }

    .account-configuring {
        .foot-note {
            padding: 0 50px;
        }
    }

    .cannot-access,
    .skip {
        h3 {
            color: #f86c6b;
            background: none;
            -webkit-text-fill-color: #f86c6b;
        }

        .icon-warning-circle {
            background-color: #fff;
            background-image: url('/assets/icons/icon-warning.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 70px;
            height: 70px;
            cursor: default;
            text-transform: uppercase;
            margin: 0 auto;
        }

        .notice {
            padding: 22px;
            border-radius: 12px;
            background: #f0f3f5;

            h4 {
                color: #121920;
                font-family: Poppins;
                font-size: 15px;
                font-weight: 600;
                line-height: 22px;
                text-align: center;
            }

            p {
                color: #2f353a;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 23px;
                text-align: center;
            }

            ul {
                li {
                    color: #2f353a;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    margin-bottom: 10px;

                    &::marker {
                        color: #73818f;
                        font-size: 150%;
                    }
                }
            }
        }
    }

    .account-script {
        padding: 42px 15px;
        counter-reset: action;

        &.verify-changes {
            padding: 42px 15px 47px;

            .foot-note {
                display: block;
                margin-top: 20px;

                span {
                    text-align: left;
                }

                button {
                    white-space: nowrap;
                }
            }

            .btn-learn-more {
                padding: 0 5px 0px;
                line-height: 0;
                text-transform: none;
                top: -2px;
                position: relative;

                &:hover {
                    text-decoration: none;
                }

                i {
                    top: -10px;
                    position: absolute;
                }
            }
        }

        &.success {
            padding: 22px 25px 66px;

            h3 {
                color: #4dbd74;
                background: none;
                -webkit-text-fill-color: #4dbd74;
                margin-bottom: 6px;
                margin-top: 18px;
            }

            p {
                margin-bottom: 9px;
            }

            .btn-save {
                min-width: 314px;
                border-radius: 22.5px;
                color: #ffffff;
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0.18571427px;
            }

            .lottie-animation {
                width: 85px;
                height: 85px;
                margin: 0 auto;
            }
        }

        &.verify {
            padding: 20px 0px 13px;

            .number-of-step {
                padding-right: 20px;
            }

            h3 {
                margin-bottom: 5px;
            }

            .lottie-animation {
                width: 100%;
                height: 100px;

                svg {
                    transform: scale(2) !important;
                }
            }
        }

        &.skip {
            .icon-warning-circle {
                margin-bottom: 16px;
            }

            h3 {
                margin-bottom: 4px;
            }

            p {
                margin-bottom: 14px;
            }

            .btn-save {
                max-width: 306px;
                border-radius: 22.5px;
                color: #ffffff;
                font-family: Roboto;
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 0.18571427px;
            }

            .btn-navigation-step {
                margin-top: 11px;
                padding-right: 3px;
            }
        }

        &.cannot-verify,
        &.verification-failed,
        &.unknown-failure {
            &:before {
                background-image: url('/assets/images/line-rainbow-new.png');
            }

            h3 {
                color: #f86c6b;
                background: none;
                -webkit-text-fill-color: #f86c6b;
                line-height: 30px;
                margin-bottom: 20px;
            }

            .btn-save {
                max-width: 348px;
                color: #ffffff;
                font-family: Roboto;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 0.18571427px;
            }

            p {
                margin-bottom: 10px;
            }
        }

        &.cannot-verify {
            padding: 16px 20px 53px;
        }

        &.alt {
            padding: 22px 20px 43px;

            .card-body {
                padding: 0 20px;
            }

            .number-of-step {
                margin-bottom: 3px;
                text-align: right;
                color: #7f8c9a;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
            }

            p {
                margin-bottom: 35px;
                color: #2f353a;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                text-align: center;
            }

            .form-control {
                color: #212c36;
                font-family: Roboto;
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                width: 420px;
                margin: 0 auto;
                border-radius: 22.5px;
                padding: 0px 0px 5px 5px;
                background: #f0f3f5;
            }

            .btn-save.alt {
                max-width: 420px;
                margin-top: 15px;
                font-size: 13px;
                font-weight: 700;
            }
        }

        &.verification-failed {
            padding: 17px 20px 42px;

            .number-of-step {
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 20px;
            }

            .notice {
                padding: 22px 0px 10px;

                h4 {
                    color: #121920;
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
                    text-align: center;
                    margin-bottom: 14px;
                }

                li:not(:last-child) {
                    margin-bottom: 7px;
                }
            }

            .btn-save {
                max-width: 286px;
                margin-top: 13px;
                color: #ffffff;
                padding: 16px 5px 30px 9px;
                font-size: 13px;
            }

            .btn-step-navigation {
                margin-top: 18px;

                .btn {
                    padding: 10px;

                    &:last-child {
                        padding: 10px 10px 11px;
                    }
                    &:active {
                        color: #7f8c9a;
                        background: unset;
                    }
                }
            }
        }

        &.unknown-failure {
            padding: 22px 19px 53px;
        }

        p {
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }

        .inside-container {
            color: #20a8d8;
            font-weight: 400;
        }

        .card-body {
            padding: 0 28px;
        }

        .notice {
            background: #f0f3f5;
            border-radius: 12px;
            width: 440px;
            padding: 20px 15px 10px 0px;
            margin: 0 auto;

            ul {
                list-style: none;

                li {
                    text-align: left;
                    color: #2f353a;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    padding-right: 25px;
                    position: relative;

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        background: #73818f;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        top: 6px;
                        left: -15px;
                    }
                }
            }

            p {
                color: #121920;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                letter-spacing: -0.1px;
                margin-bottom: 20px;
            }
        }

        .foot-note {
            margin-top: 13px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                color: #7f8c9a;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
            }

            button {
                color: #7f8c9a;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }

        .list-action {
            .action {
                width: 400px;
                border-radius: 9px;
                border: 1px solid #d8d8d8;
                color: #212c36;
                font-family: Roboto;
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                margin: 0 auto 10px;
                padding: 14px 6px 11px 18px;
                text-align: left;

                &:before {
                    counter-increment: action;
                    content: counter(action) '. ';
                }
            }
        }

        .btn-save {
            max-width: 260px;
            padding: 16px 5px 30px 9px;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
        }

        .btn-step-navigation {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                color: #7f8c9a;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                text-decoration: underline;
                text-transform: none;

                &:hover {
                    text-decoration: none;
                }

                a {
                    color: #7f8c9a;
                }
            }
        }
    }

    .account-script-popup {
        &.learn-more {
            .modal-content {
                width: 1000px;
                margin: 0 auto;
                height: fit-content;
                border-radius: 10px;
            }

            .modal-body {
                padding: 20px 46px;
            }

            p {
                margin: 10px 0 0 0;
            }

            .modal-content {
                display: flex;
                flex-direction: row-reverse;
            }

            .modal-header {
                position: absolute;
                border: none;
                margin: 0;
                padding: 10px 25px 10px 10px;
                .btn-close {
                    cursor: pointer;
                    z-index: 9;
                    transform: scale(0.8);
                }
            }
        }
    }

    .account-gtm {
        &.connect {
            padding: 22px 20px 43px;

            .icon-gtm {
                margin-bottom: 8px;
            }

            h3 {
                margin-bottom: 18px;
            }

            .number-of-step {
                margin-bottom: 10px;
                padding-right: 0;
            }

            p {
                padding: 0 16px;
                // margin-bottom: 35px;
            }

            .btn-connect-gtm {
                display: flex;
                justify-content: center;
                align-items: center;
                // width: 420px;
                // padding: 24px 20px 20px 0;
                width: 230px;
                margin: 0 auto;
                // color: #000000;
                color: rgba(0, 0, 0, 0.54);
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                text-align: center;
                position: relative;
                text-transform: none;
                background: unset;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.24),
                    0px 0px 1px rgba(0, 0, 0, 0.12) !important;
                // border: 1px solid #f0f3f5!important;
                // border-radius: 2px !important;
                // &::after {
                //     content: '';
                //     position: absolute;
                //     background-image: url('/assets/images/external_link_icon.png');
                //     background-repeat: no-repeat;
                //     background-size: contain;
                //     background-position: center;
                //     top: 15px;
                //     right: 68px;
                //     width: 14px;
                //     height: 14px;
                //     cursor: default;
                //     text-transform: uppercase;
                //     margin: 0 auto;
                // }

                &:hover {
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24),
                        0px 0px 2px rgba(0, 0, 0, 0.12) !important;
                }

                svg {
                    margin-right: 20px;
                    height: 18px;
                    width: 18px;
                }
            }

            .foot-note {
                padding: 0 20px;

                &__mb {
                    margin-bottom: 10px;
                }

                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .btn-navigation-step {
                padding-left: 21px;
                // margin-top: 22px;
                margin-top: 10px;
            }
        }

        &.success {
            padding: 0px 20px 52px 20px;

            h3 {
                color: #4dbd74;
                background: none;
                -webkit-text-fill-color: #4dbd74;
                margin-bottom: -4px;
                margin-top: -10px;
            }

            .number-of-step {
                position: relative;
                top: 20px;
            }

            p {
                margin-bottom: 7px;
            }

            .btn-save {
                width: 348px;
                padding: 12px 10px 31px 10px;
            }

            .lottie-animation {
                width: 85px;
                height: 85px;
                margin: 0 auto;
            }
        }

        &.making-changes {
            padding: 18px 20px 30px;

            h3 {
                margin-bottom: 5px;
            }

            .lottie-animation {
                width: 100%;
                height: 100px;

                svg {
                    transform: scale(2) !important;
                }
            }
        }
    }

    .account-gtm-no-publish {
        padding: 22px 20px 49px;

        &:before {
            background-image: url('/assets/images/line-rainbow-new.png');
        }

        h3 {
            color: #f86c6b;
            background: none;
            -webkit-text-fill-color: #f86c6b;
            margin-bottom: 0;
        }

        p {
            margin-bottom: 15px;
        }

        .des {
            margin-bottom: 20px;
        }

        .email {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            flex-wrap: wrap;
            margin-bottom: 40px;

            a {
                flex: 0 0 calc(50% - 10px);
                margin-right: 10px;
                color: #212c36;
                font-family: Roboto;
                font-size: 15px;
                font-weight: 400;
                line-height: 28px;
                text-align: center;
                text-decoration: underline;
                white-space: nowrap;
            }
        }

        .script-code {
            width: 420px;
            margin: 0 auto;

            .float-right {
                padding-top: 10px;
                padding-right: 11px;

                img {
                    cursor: pointer;
                }
            }

            svg {
                width: 28px;
                height: 28px;
            }

            .icon-copy {
                cursor: pointer;
                color: #fff;
            }
        }

        .note-copy {
            padding: 15px 40px 20px 20px;
            border-radius: 10px;
            background: #212c36;
            text-align: left;

            p {
                color: #ffffff;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                margin-bottom: 0;
            }
        }
    }

    .account-script-manually {
        padding: 20px 10px 35px 12px;

        h3 {
            margin-bottom: 7px;
        }

        p {
            padding: 0 30px;
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            margin-bottom: 30px;
        }

        .script-code {
            position: relative;
            background-color: #f1f3f4;
            border-radius: 15px;
            padding: 0 10px;
            margin: 0 auto;
            width: 473px;

            &:before {
                content: '';
                position: absolute;
                z-index: 999;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 120px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
            }

            .script {
                padding-top: 17px;
                padding-left: 5px;
            }

            .ace_scrollbar {
                display: none;
            }

            .ace-solarized-light {
                background-color: #f1f3f4;
            }

            .copy-script {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
                padding-top: 7px;
                padding-right: 13px;
            }

            .icon-copy {
                cursor: pointer;
                float: right;
                color: #7f8c9a;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                text-align: center;
                text-transform: capitalize;
                margin-right: 10px;
            }

            // .script {
            //     padding-right: 25px;
            // }
            #blah2 {
                width: 100% !important;
            }

            .script-buttons {
                position: absolute;
                top: 0px;
                right: 30px;
                z-index: 10;
            }

            button {
                padding: 0;

                svg {
                    width: auto;
                    height: auto;
                    margin: 0;
                    vertical-align: middle;
                }
            }

            .ace_scroller {
                &.ace_scroll-left {
                    box-shadow: none;
                }
            }

            .ace_scrollbar {
                &.ace_scrollbar-h {
                    height: 6px !important;
                    width: 100%;

                    &::-webkit-scrollbar {
                        height: 6px;
                        background-color: $gray-200;
                    }

                    get-started-bar &::-webkit-scrollbar-thumb {
                        background-color: $gray-300;
                    }
                }
            }
        }

        .btn-save {
            width: 328px;
            margin-top: 22px;
            color: #ffffff;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            padding: 15px 10px 30px 15px;
        }

        .btn-help {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto 5px;
            border-radius: 22.5px;
            border: 1px solid #212c36 !important;
            background: #ffffff;
            color: #212c36;
            padding: 23px 10px 20px 10px;

            svg {
                margin-right: 5px;

                path {
                    fill: #212c36;
                }
            }
        }

        .btn-navigation-step {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 auto;
            margin-top: 14px;
            padding-left: 16px;
            text-decoration: none;

            span {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            i {
                padding-top: 1px;
                margin-right: 0;
            }
        }
    }

    .gtm-container-list {
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        max-width: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .scrollbar {
        position: relative;

        .gtm-container-list {
            height: 190px;
            overflow-y: scroll;
            position: relative;
            transition: all 0.4s;
            padding-right: 15px;

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: #f5f5f5;
                left: -100px;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px #f0f3f5;
                background-color: #f5f5f5;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #c8ced3;
                background-clip: content-box;
                border-radius: 5px;
            }
        }

        &.faded-top {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 20px;
                background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                z-index: 1;
            }
        }

        &.faded-bottom {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 20px;
                background: linear-gradient(1deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                z-index: 1;
            }
        }
    }

    .account-container {
        padding: 43px 28px 27px;

        h3 {
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 25px;
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }

        .gtm-container-list {
            .btn-select {
                padding: 12.5px 20px 12.5px 0px;
                width: 100%;
                text-transform: none;

                &.active::after {
                    right: 7px;
                }
            }
        }

        .btn-select {
            min-width: 200px;
            height: auto;
            border-radius: 9px;
            border: 1px solid #d8d8d8 !important;
            background: #ffffff;
            margin-bottom: 10px;
            padding: 12px 22px;
            color: #212c36;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            appearance: none;

            &.active {
                background: #d0ecf5;
                position: relative;
                border: 1px solid #d0ecf5 !important;

                &::after {
                    content: '';
                    position: absolute;
                    background-image: url('/assets/images/tick-check-v2.png');
                    background-repeat: no-repeat;
                    background-size: 10px;
                    background-position: center;
                    width: 12px;
                    height: 12px;
                    cursor: default;
                    top: 50%;
                    right: 16px;
                    transform: translate(-50%, -50%);
                    text-transform: uppercase;
                    transition: all 0.5s ease;
                    margin: 0 auto;
                }
            }
        }

        .btn-save {
            width: 328px;
            margin-top: 28px;
            border-radius: 22.5px;
        }

        .foot-note {
            margin-top: 42px;
            padding: 0 5px;
            color: #7f8c9a;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }

        .btn-navigation-step {
            margin-top: 18px;
        }

        &.no-container {
            .foot-note {
                margin-top: 25px;
            }
        }

        .form-control {
            border: none;
            max-width: 400px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }

    .account-brings {
        padding: 22px 15px 20px;

        h3 {
            margin-bottom: 14px;
        }

        p {
            margin-bottom: 15px;
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }

        .list-bring {
            width: 420px;
            margin: 0 auto;
        }

        &.other {
            .text-area-input {
                border-radius: 22.5px;
                background: #f0f3f5;
                width: 420px;
                min-height: 148px;
                margin: 0 auto;
                padding: 10px;
                padding-right: 6px;
            }

            textarea {
                &.form-control {
                    min-height: 100%;
                    height: 128px;
                    background: transparent;
                    border: none;
                    padding-right: 15px;
                }

                &::-webkit-scrollbar {
                    width: 3px;
                }

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px #f0f3f5;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8ced3;
                    outline: 1px solid #c8ced3;
                }
            }
        }

        .btn-select {
            margin-bottom: 10px;
            color: #18222b;
            font-family: Roboto;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.18571427px;
            padding: 15px 10px 30px 10px;
            text-transform: none;
        }

        .btn-navigation-step {
            margin: 0 auto;
            position: relative;
            padding: 6px 12px 5px 2px;

            &:after {
                content: '';
                position: absolute;
                background-image: url('/assets/images/icon-chevron-left.png');
                width: 6px;
                height: 7px;
                top: 54%;
                right: 0;
                transform: translate(50%, -50%) rotate(180deg);
            }

            &:hover {
                text-decoration: none;
            }
        }

        .btn-save.next {
            width: 420px;
            margin: 20px auto 16px;
        }
    }

    .account-almost-done {
        padding: 45px 15px 61px;

        &.account-dyn {
            padding-top: 22px;

            .btn-save {
                margin-top: 20px;
            }
        }

        h3 {
            margin-bottom: 15px;
        }

        .des {
            padding: 0 15px 0 11px;
            margin-bottom: 25px;
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
        }

        .des-revenue {
            padding-top: 20px;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 23px;
        }

        .card-body {
            margin: 0 auto;
        }

        .notice {
            width: 440px;
            margin: 0 auto;
            padding: 24px 25px 10px 25px;
            border-radius: 12px;
            background: #d0ecf5;

            .icon-container {
                margin-top: -2px;
                margin-right: 20px;
            }

            .d-flex {
                p {
                    text-align: left !important;
                }
            }

            h4 {
                color: #121920;
                font-family: Poppins;
                font-size: 15px;
                font-weight: 600;
                line-height: 22px;
                text-align: center;
            }

            p {
                color: #121920;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                margin-bottom: 10px;
                width: 100%;
            }
        }

        .btn-save {
            width: 212px;
            margin-top: 29px;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.18571427px;
            padding: 12px 4px 32px 13px;
        }

        .btn-navigation-step {
            font-weight: 500;
            color: #000;
        }
    }

    .account-configure-measurement {
        padding: 39px 12px 22px;

        h3 {
            margin-bottom: 5px;
        }

        .des {
            padding: 0 12px;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            margin-bottom: 10px;
        }

        .foot-note {
            margin-bottom: 22px;
            font-size: 12px;
        }

        .card-body {
            margin: 0 auto;
        }

        .next {
            width: 462px;
            margin-top: 15px;
            padding: 13px 10px 32px 16px;
        }

        .notification {
            width: 462px;
            margin: 25px auto 23px;
            padding: 20px 15px 10px;
        }

        &.weather {
            padding: 22px 20px 37px;

            .description {
                color: #2f353a;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                text-align: center;
                margin-bottom: 25px;
                padding: 0 10px;
            }

            .list-measurement {
                margin: 0 auto 7px;

                .measurement {
                    width: 410px;
                    margin: 0 auto 7px;
                    padding: 9px 11px 7px 15px;
                }
            }

            .btn-save {
                width: 410px;
                margin: 18px auto 0;
            }
        }
    }

    .account-form {
        padding: 22px 20px 27px;

        h3 {
            margin-bottom: 5px;
        }

        p {
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            margin-bottom: 25px;
        }

        &.chat-listener {
            padding: 22px 20px 33px;

            .list-form {
                margin-bottom: 10px;
            }

            .btn-save {
                margin-top: 12px;
            }

            .foot-note {
                text-transform: none;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &.video-listener {
            .card-body {
                padding: 0 10px;
            }

            .des {
                margin-bottom: 10px;
            }

            .list-form {
                margin-top: 23px;
                margin-bottom: 10px;
                padding-left: 10px;

                label .icon-box {
                    width: 136px;
                }
            }

            .btn-save {
                margin-top: 10px;
            }

            .foot-note.btn {
                margin: 15px auto 0;
                text-transform: none;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &.ecommerce-listener {
            .des {
                margin-bottom: 10px;
            }

            .list-form {
                margin-top: 22px;
                margin-bottom: 10px;
            }

            .btn-save {
                margin-top: 10px;
            }

            .foot-note.btn {
                margin: 15px auto 0;
                text-transform: none;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .filter-search {
            position: relative;

            svg {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                width: 30px;
            }
        }

        .form-control.search {
            text-align: left;
            padding: 0px 20px;
            height: 35px;
            color: #73818f;
            border: 1px solid #c8ced3;
            background: #ffffff;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: -0.4px;
        }

        .notification {
            width: 441px;
            margin: 25px auto 0;
            padding: 23px 32px 2px;

            p {
                text-align: justify;
            }
        }

        a {
            &.foot-note {
                text-decoration: underline;
            }
        }

        .btn-save {
            height: 47px;
            width: 441px;
        }
    }

    .account-time-zone {
        padding: 22px 20px 36px;

        .select-group {
            .select-react-customize {
                position: relative;
                .custom-select__menu {
                    text-align: start !important;
                }
                &.country {
                    path {
                        fill: #212c36;
                    }
                }

                // &.blur {
                //     opacity: 0.5;
                //     &:focus,
                //     &:hover,
                //     &:active,
                //     &:checked {
                //         opacity: 1;
                //     }
                // }
            }
        }

        .select-wrapper {
            .form-control {
                padding: 10px 22px;
                border: none !important;
            }

            &>div {
                width: 93% !important;
                left: 3.5%;
            }
        }

        .number-of-step {
            margin-bottom: 5px;
        }

        h3 {
            margin-bottom: 6px;
        }

        p {
            margin-bottom: 28px;
        }

        input,
        .btn-save {
            width: 420px;
            margin: 0 auto;
        }

        input {
            padding: 15px 15px 10px 23px;
        }

        .btn-save {
            margin-top: 25px;
        }
    }

    .list-measurement {
        width: 462px;
        margin: 0 auto 10px;

        * {
            cursor: pointer;
        }

        .measurement {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            border-radius: 9px;
            border: 1px solid #d8d8d8;
            background: #f8f9fa;
            margin-bottom: 6px;
            padding: 9px 12px 7px;

            .label {
                font-size: 15px;
                font-weight: 500;
            }

            .topic-label {
                padding: 0px 10px 0px 2px;
            }

            .label-top-card {
                margin-left: 10px;
            }

            svg {
                &:focus {
                    border: none;
                    outline: none;
                }

                &:focus-visible {
                    border: none;
                    outline: none;
                }

                path {
                    &:hover {
                        fill: #121920 !important;
                    }
                }
            }

            .form-check-input {
                position: relative;
                border: 1px solid #c8ced3;
                background: #ffffff;
                width: 23px;
                height: 23px;
                border-radius: 50%;
                top: -3px;
                vertical-align: middle;
                appearance: none;
                -webkit-appearance: none;
                outline: none;

                &:checked {
                    background-image: url('/assets/icons/icon-tick.svg');
                    border: none;
                    opacity: 1;
                }
            }
        }
    }

    .forms-listener {
        position: relative;

        &.faded-top {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 20px;
                background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                z-index: 1;
            }
        }

        &.faded-bottom {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 20px;
                background: linear-gradient(1deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                z-index: 1;
            }
        }
    }

    .list-form {
        max-height: 373px;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 28px;
        margin-bottom: 15px;
        padding-top: 5px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px #f0f3f5;
            background-color: #f0f3f5;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c8ced3;
            border-radius: 3px;
        }

        &.ecommerce {
            overflow-y: hidden;
            padding-left: 8px;

            label {
                flex: 0 0 calc(50% - 10px);

                &:nth-child(odd) {
                    padding-right: 15px;
                }

                .icon-box {
                    width: 213px;
                }
            }
        }

        &.video {
            overflow-y: hidden;
        }

        svg {
            margin-top: auto;
            margin-bottom: auto;
        }

        label {
            display: flex;
            position: relative;
            flex-basis: calc(33.33% - 3px);
            margin-bottom: 14px;
            cursor: pointer;

            input[type='checkbox'] {
                display: none;

                &:checked~.icon-box {
                    border: none;

                    .tickmark {
                        &::after {
                            opacity: 1;
                        }

                        &::before {
                            opacity: 0.4;
                        }
                    }
                }
            }

            .icon-box {
                position: relative;
                display: flex;
                width: 130px;
                height: 114px;
                padding: 17px 20px 15px;
                flex-direction: column;
                align-items: center;
                border-radius: 9px;
                border: 1px solid #d8d8d8;
                background: #f8f9fa;
                user-select: none;

                .tickmark {
                    &:after {
                        content: '';
                        position: absolute;
                        background-image: url('/assets/images/icon-tick.png');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        top: 10px;
                        right: 10px;
                        height: 17px;
                        width: 17px;
                        opacity: 0;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        background: #18222b;
                        border-radius: 9px;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        height: 100%;
                        width: 100%;
                    }
                }

                span {
                    color: #212c36;
                    font-family: Roboto;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 12px;
                    text-align: center;
                    margin-top: auto;
                }

                .listener-logo {
                    width: 100px;
                    height: 50px;
                }
            }
        }

        .item-not-found {
            color: #73818f;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            margin-bottom: 10px;
        }
    }

    .notification {
        margin-top: 40px;
        padding: 20px 35px 2px;
        border-radius: 12px;
        background: #d0ecf5;

        h4 {
            color: #121920;
            font-family: Poppins;
            font-size: 15px;
            font-weight: 600;
            line-height: 22px;
        }

        .des {
            color: #121920;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.1px;
            padding: 0 17px;
        }

        p {
            opacity: 0.7;
            color: #121920;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.5px;
        }

        .custom-form-group {
            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                color: #121920;
                font-family: Poppins;
                font-size: 10px;
                font-weight: 600;
                line-height: 15px;
                cursor: pointer;
            }

            input[type='checkbox'] {
                cursor: pointer;
            }
        }

        .form-check-input {
            margin-left: 7px;
            position: relative;
            border: 1px solid #c8ced3;
            background: #ffffff;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            top: -3px;
            vertical-align: middle;
            appearance: none;
            -webkit-appearance: none;
            outline: none;

            &:checked {
                background-image: url('/assets/icons/icon-tick.svg');
                border: none;
                opacity: 1;
            }
        }

        .list-measurement {
            width: 392px;

            .measurement.default {
                background: none;
                border: none;
                border-radius: 0;
                padding: 10px 0 8px;
                position: relative;
                width: 100%;
                margin-bottom: 0px;
                cursor: unset;

                &:first-child {
                    padding-top: 15px;
                }

                &:not(:last-child) {
                    &::after {
                        content: '';
                        opacity: 0.14750454;
                        left: 0;
                        bottom: 0;
                        border-bottom: 1px solid #73818f;
                        width: 100%;
                        display: block;
                        position: absolute;
                    }
                }

                p {
                    opacity: 1;
                    color: #212c36;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                    margin-bottom: 0;
                    cursor: default;
                }

                svg {
                    path {
                        fill: #abc4cc !important;

                        &:hover {
                            fill: #121920 !important;
                        }
                    }
                }
            }
        }
    }

    .sidebar-close {
        position: fixed;
    }

    .list-package {
        h3 {
            font-weight: 500;
            color: #474544;
            -webkit-text-fill-color: #474544;
        }
    }

    .footer-create-account {
        display: block;
        margin-top: auto;
        padding-left: 12px;

        .nav-link-footer {
            display: block;
            margin-top: 30px;

            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0;

                li {
                    list-style: none;

                    &:not(:first-child) {
                        margin-right: 33px;
                        margin-left: 20px;
                    }

                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
            }
        }

        a {
            color: #18222b;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            line-height: 17px;
            text-align: right;
            letter-spacing: 0.19999999px;
        }
    }

    .domain-input {
        display: flex;
        align-items: center;
        border-radius: 50px;
        background: #f0f3f5;
        padding-left: 30px;
        width: 420px;
        margin: 0 auto;

        label {
            margin-bottom: 0;
            color: #212c36;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            max-width: 215px;
            text-align: left;
        }

        input {
            padding-left: 5px;
            text-align: left;
            border: none;
            background: transparent;
        }
    }

    .account-test-plan {
        .notification {
            margin-top: 20px;
            margin-bottom: 20px;

            .measurement {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }

                p {
                    text-align: left;
                    margin-bottom: 0;
                }

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            padding: 20px 35px;
        }

        .plan-buttons {
            button {
                text-transform: none;
            }

            .choose-plan {
                padding: 17px 60px;
                margin-bottom: 10px;
            }

            .stick-test-plan {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .account-paid-plan {
        .plan-buttons {
            button {
                text-transform: none;
            }

            .add-credit-card {
                padding: 17px 60px;
                margin-bottom: 10px;
            }

            .select-new-plan {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .proceed-test-plan {
        &.account-script {
            &.skip {
                h3 {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 30px;
                }
            }
        }

        .notification {
            margin-top: 20px;
            margin-bottom: 8px;

            .measurement {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }

                p {
                    text-align: left;
                    margin-bottom: 0;
                }

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .icon-container {
                    .icon {
                        fill: #f86c6b;
                    }
                }
            }

            padding: 18px 35px;
        }
    }

    .account-consent {
        .btn-learn-more {
            padding: 0 5px 0px;
            line-height: 0;
            text-transform: none;
            top: -1px;
            position: relative;
            color: #7f8c9a;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            text-decoration: underline;
            text-transform: capitalize;

            &:hover {
                text-decoration: none;
            }

            i {
                top: -10px;
                position: absolute;
            }
        }

        .btn-navigation-step {
            margin-top: 35px;
        }

        .btn-save {
            margin-top: 5px;
        }
    }

    .consent-listener {
        .select-react-customize {
            margin-bottom: 0;

            &:after {
                right: 23px;
            }

            .select-wrapper {
                .form-control {
                    text-align: center;
                }
            }

            .custom-select__menu-list {
                padding-bottom: 0;
            }
        }

        button {
            &.foot-note {
                text-transform: none;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .select-react-customize {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            background-image: url('/assets/images/icon-chevron-bottom.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 7px;
            height: 8px;
            right: 36px;
            transform: translateY(-50%);
            top: 50%;
            cursor: pointer;
        }

        .select-wrapper {
            .form-control {
                padding: 10px 22px;
                border: none !important;
            }
        }
    }

    .dont-find-consent {
        input {
            border: none;
        }
    }
}

.btn-outside {
    margin-top: 20px;

    p {
        font-size: 14px;
    }

    .btn-navigation-step {
        font-size: 14px;
        letter-spacing: 0.2px;
        font-weight: 400;
        color: #7f8c9a;
        font-family: Roboto;
        text-transform: none;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        i {
            color: #708393;
            margin-right: 5px;
        }
    }
}

.custom-tooltip {
    .tippy-box {
        border-radius: 10px;

        .tippy-content {
            border-radius: 10px;
            max-width: 300px;
            background: #73818f;
            color: #ffffff;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.2px;
            padding: 10px 15px;
            text-align: left;
        }

        .tippy-arrow {
            color: #73818f;
            position: relative;

            &::before {
                content: '';
                width: 35px;
                height: 10px;
                position: absolute;
                left: -80%;
                background-image: url('/assets/images/tooltip-arrow.png');
                background-repeat: no-repeat;
                background-size: contain;
                border: none;
                border-radius: 0;
            }
        }
    }

    .tippy-box[data-placement^='top']>.tippy-arrow::before {
        bottom: -17px;
        transform: rotateX(180deg);
    }

    .tippy-box[data-placement^='bottom']>.tippy-arrow::before {
        top: -7px;
    }
}

.tour-destination {
    .form-group {
        h3 {
            font-family: Poppins;
            background: linear-gradient(161deg, #29263e 0%, #233b7d 37%, #20a8d8 100%);
            color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 20px;
            font-weight: 700;
            line-height: 47px;
            text-align: center;
            letter-spacing: -0.09463231px;
        }
    }

}

// .list-package {
//     display: flex;
//     justify-content: center;
//     margin: 0 -10px;
//     flex-wrap: wrap;
//     .package-item {
//         width: 227px;
//         margin: 10px;
//         padding: 36px 19px 26px;
//         border: 1px solid #e8ebed;
//         border-radius: 4px;
//         box-shadow: 0 10px 10px -4px rgba(0, 0, 0, 0.03);
//         position: relative;
//         overflow: hidden;
//         background-color: #fff;
//         ul {
//             li {
//                 i {
//                     font-weight: 400;
//                 }
//             }
//         }
//         &.hot-package {
//             border-color: #ffdd75;
//             background-color: #fff9e6;
//             transform: scale(1.015);
//             box-shadow: 0 16px 20px -9px rgba(0, 0, 0, 0.23);
//             padding: 41px 20px 26px;
//             .btn-select-upgrade {
//                 background-color: #4dbd74;
//                 margin-top: -4px;
//                 height: 33.5px;
//                 &:hover {
//                     background-color: #3ea662;
//                 }
//             }
//             h5 {
//                 margin-bottom: 16px;
//             }
//             ul {
//                 margin-top: 32px;
//                 margin-bottom: 79px;
//                 li {
//                     margin-bottom: 15.5px;
//                 }
//             }
//         }
//     }
//     .current-package {
//         background-color: #fbfcfc !important;
//         opacity: 0.7;
//     }
//     .package-label {
//         position: absolute;
//         left: -2px;
//         top: 0;
//         font-size: 11px;
//         font-weight: 700;
//         text-transform: uppercase;
//         border-bottom-right-radius: 8px;
//         padding: 3px 7px;
//     }
//     .hot-package-label {
//         color: #000 !important;
//         background-color: #ffc107;
//         top: -1px;
//     }
//     .current-label {
//         color: #fff !important;
//         background-color: #20a8d8;
//     }
//     h5 {
//         text-transform: uppercase;
//         letter-spacing: 0.26px;
//         font-size: 18px;
//         font-weight: 500;
//         color: #000;
//         margin-bottom: 17px;
//     }
//     h3 {
//         font-size: 35px;
//         color: #474544;
//         -webkit-text-fill-color: #474544;
//         font-weight: 500;
//     }
//     p {
//         font-size: 12px;
//         font-weight: 300;
//         letter-spacing: 0.08px;
//         line-height: 17px;
//         color: #73818f;
//         padding: 0 18px;
//     }
//     ul {
//         margin-top: 34px;
//         margin-bottom: 77px;
//         padding-top: 5px;
//         border-top: 1px solid #c8ced3;
//         list-style-type: none;
//         padding-left: 0;
//         li {
//             display: flex;
//             justify-content: space-between;
//             text-align: left;
//             margin-bottom: 16px;
//             span {
//                 line-height: 15px;
//                 color: #000;
//                 &:first-child {
//                     width: 152px;
//                     font-size: 11px;
//                     letter-spacing: 0.07px;
//                     font-weight: 300;
//                     display: flex;
//                     align-items: center;
//                 }
//                 &:last-child {
//                     font-size: 12px;
//                     letter-spacing: 0.08px;
//                     font-weight: 400;
//                     display: flex;
//                     align-items: center;
//                 }
//             }
//         }
//     }
//     .package-category {
//         margin-top: 16px;
//         position: relative;
//         text-align: center;
//         &::before {
//             content: '';
//             display: block;
//             position: absolute;
//             top: 49%;
//             left: 50%;
//             width: 100%;
//             height: 25px;
//             transform: translate(-50%, -50%);
//             background-color: #b5b5b5;
//         }
//         span:first-child {
//             position: relative;
//             width: 100%;
//             color: #fff;
//             display: block;
//         }
//     }
//     .btn-select-upgrade {
//         width: 100%;
//         background-color: #20a8d8;
//         color: #fff;
//         font-size: 13px;
//         font-weight: 700;
//         letter-spacing: 0.19px;
//         height: 34px;
//         &.disabled {
//             background-color: #c8ced3 !important;
//             opacity: 1;
//         }
//     }
// }
@media screen and (max-width: 1199px) {
    .create-account-popup {
        .main-content {
            display: block;
        }
    }
}