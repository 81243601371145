.subscriber {

     .listeners,
     .features,
     .tag-managers,
     .browser-addons,
     .affiliate {
          .card {
               height: calc(100% - 1.5rem);
               position: relative;
               // box-shadow: 0px 4px 6px -3px rgba(0, 0, 0, 0.1) !important;
               border: 1px solid #f0f3f5 !important;
               box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06) !important;

               .card-body {
                    min-height: 200px;
                    display: flex;
                    flex-direction: column;

                    .card-title {
                         >div {
                              height: max-content;
                         }

                         h4 {
                              margin-bottom: 0;
                              // padding-right: 10px;
                         }
                    }
               }
          }

          .card-icon {
               align-items: center;
          }

          .card-header-actions {
               margin-right: 0;
               align-items: center;
               display: flex;
               justify-content: flex-end;
          }

          .label-premium {
               margin-top: 5px;
          }

          .filter-card {
               .card-body {
                    min-height: 1px;
               }
          }
     }

     .listeners {
          .filter-card.card {
               // box-shadow: none !important;
               border: 1px solid #e4e7ea;
               border-radius: 12px !important;
               box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
               margin-top: 5px;
               padding: 2px 0 2px 0;
          }
     }

     .insert-variables-box {
          min-height: 35px;

          .browser-step4-value strong {
               margin-right: 15px;
               font-weight: 800;
          }

          .insert-variable-dropdown-icon {
               display: flex;
               justify-content: flex-end;
               align-items: center;
          }

          .browser-step4-form {
               margin-left: 5px;

               button {
                    padding: 0;
                    margin-left: 5px;
                    background-color: transparent;
                    border: 0;
                    border-bottom: 1px dotted #000;
               }
          }

          .content-c {
               height: 35px;
               display: flex;
               align-items: center;
          }

          .insert-variable-dropdown ul {
               margin-top: 3px;
               list-style: none;
               position: absolute;
               left: 15px;
               padding: 10px;
               z-index: 99;
               border: 1px solid #e4e7ea !important;
               box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.13) !important;
               max-height: 280px;
               overflow-y: auto;
               background-color: #fff;
               width: 380px;
               border-radius: 5px;

               li {
                    padding-bottom: 5px;
                    word-break: break-all;
                    cursor: pointer;
               }

               .variable-filter {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;

                    &::before {
                         content: "";
                         display: block;
                         position: absolute;
                         z-index: 0;
                         background-color: #fff;
                         width: calc(100% + 20px);
                         height: 50px;
                         top: -10px;
                         left: -10px;
                    }

                    input {
                         position: relative;
                    }
               }

               &::-webkit-scrollbar {
                    width: 5px;
                    background-color: transparent;
               }

               &::-webkit-scrollbar-thumb {
                    background-color: $gray-600;
                    border-radius: 3px;
                    border-left: 2px solid #fff;
                    border-right: 2px solid #fff;
               }
          }
     }

     .cvr-create-new-dataAction {
          .btn-dark {
               background-color: #828282;
          }

          .btn-dark.color-dark {
               background-color: #2f353a;
          }

          .triggers-multiselect {
               #triggersName {
                    height: 60px;
                    font-weight: 500;
               }

               #blockByRules {
                    height: 60px;
               }

               .css-1pahdxg-control,
               .css-yk16xz-control {
                    height: 56px;
                    overflow-y: scroll;
               }

               .css-1rhbuit-multiValue {
                    border: 1px solid #b4b4b4;
                    border-radius: 5px;

                    .css-xb97g8 {

                         &:hover,
                         &:focus {
                              background-color: transparent;
                              color: unset;
                         }
                    }
               }
          }

          .browser-step4-value strong {
               margin-right: 15px;
               font-weight: 800;
          }

          .insert-variable-dropdown-icon {
               display: flex;
               justify-content: flex-end;
               align-items: center;
          }

          .browser-step4-form {
               button {
                    padding: 0;
                    margin-left: 5px;
                    background-color: transparent;
                    border: 0;
                    border-bottom: 1px dotted #000;
               }
          }

          .content-c {
               height: 35px;
               display: flex;
               align-items: center;
          }

          .insert-variable-dropdown ul {
               margin-top: 3px;
               list-style: none;
               position: absolute;
               right: 0;
               padding: 10px;
               z-index: 99;
               border: 1px solid #e4e7ea !important;
               box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.13) !important;
               max-height: 280px;
               overflow-y: auto;
               background-color: #fff;
               width: 380px;
               border-radius: 5px;

               li {
                    padding-bottom: 5px;
                    word-break: break-all;
                    cursor: pointer;
               }

               .variable-filter {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;

                    &::before {
                         content: "";
                         display: block;
                         position: absolute;
                         z-index: 0;
                         background-color: #fff;
                         width: calc(100% + 20px);
                         height: 50px;
                         top: -10px;
                         left: -10px;
                    }

                    input {
                         position: relative;
                    }
               }

               &::-webkit-scrollbar {
                    width: 5px;
                    background-color: transparent;
               }

               &::-webkit-scrollbar-thumb {
                    background-color: $gray-600;
                    border-radius: 3px;
                    border: solid 1px #e2e6e9;
               }
          }
     }

     .cvr-create-new {
          &.cvr-create-new-dataAction {
               .cvr-step-card {
                    min-height: 200px;
                    border-radius: 4px;
               }
          }

          .conversion-value {
               .insert-variable {
                    position: absolute;
                    right: 25px;
                    top: 32px;
                    cursor: pointer;

                    &.active svg path {
                         color: #20a8d8 !important;
                    }
               }

               .form-control.is-invalid {
                    padding-right: 45px;

                    &~.insert-variable {
                         right: 45px;
                    }
               }
          }

          .rule-step {
               align-items: center;

               h4 {
                    color: #000;

                    &.inactive {
                         color: #9ea8b1;
                    }
               }

               .given-data {
                    font-size: 1rem;
                    color: #3cb1d9;
                    font-weight: 600;
                    text-align: end;
                    max-width: 500px;
               }

               .btn-edit {
                    color: #3cb1d9;
                    font-weight: 500;
                    text-transform: uppercase;
                    background-color: #f0f3f5;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    margin-left: 5px;
               }
          }

          .form-group.hide {
               display: none;
          }
     }

     .cvr-create-new-dataAction {
          .btn-dark {
               background-color: #828282;

               &.active {
                    background-color: #2f353a;
                    opacity: 1;
               }
          }

          .triggers-multiselect {
               #triggersName {
                    height: 60px;
                    display: flex;
                    .react-select__control {
                         flex-basis: 100%;
                    }
               }
               #blockByRules {
                    height: 60px;
                    display: flex;
                    .react-select__control {
                         flex-basis: 100%;
                    }
               }
               .css-1pahdxg-control,
               .css-yk16xz-control,
               .css-1fhf3k1-control {
                    height: 56px;
                    overflow-y: auto;
                    background-image: none;
               }
          }

          .browser-step4-value strong {
               margin-right: 15px;
               font-weight: 800;
          }

          .insert-variable-dropdown-icon {
               display: flex;
               justify-content: flex-end;
               align-items: center;
          }

          .browser-step4-form {
               button {
                    padding: 0;
                    margin-left: 5px;
                    background-color: transparent;
                    border: 0;
                    border-bottom: 1px dotted #000;
               }
          }

          .content-c {
               height: 35px;
               display: flex;
               align-items: center;
          }

          .insert-variable-dropdown ul {
               margin-top: 3px;
               list-style: none;
               position: absolute;
               right: 0;
               padding: 10px;
               z-index: 99;
               border: 1px solid #e4e7ea !important;
               box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.13) !important;
               max-height: 280px;
               overflow-y: auto;
               background-color: #fff;
               width: 380px;
               border-radius: 5px;

               li {
                    padding-bottom: 5px;
                    word-break: break-all;
                    cursor: pointer;
               }

               .variable-filter {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;

                    &::before {
                         content: "";
                         display: block;
                         position: absolute;
                         z-index: 0;
                         background-color: #fff;
                         width: calc(100% + 20px);
                         height: 50px;
                         top: -10px;
                         left: -10px;
                    }

                    input {
                         position: relative;
                    }
               }

               .variable-filter-bottom {
                    position: -webkit-sticky;
                    position: sticky;
                    bottom: -5px;

                    &::before {
                         content: "";
                         display: block;
                         position: absolute;
                         z-index: 0;
                         background-color: #fff;
                         width: calc(100% + 20px);
                         height: 10px;
                         left: -10px;
                         top: 5px;
                    }
               }
          }
     }
}

.hubspot-color {
     &.icon {
          color: #ff6b45;
     }
}

.color-transparent {
     color: transparent !important;
}

.label-top-card {
     padding: 5px 6px 4px;
     line-height: 15px;
     text-transform: uppercase;
     box-shadow: none !important;
     height: max-content;
     cursor: default;
     font-family: Roboto;
     font-size: 9.603px;
     font-weight: 700;
     line-height: 12px;
     text-align: center;
}

.color-premium {
     background-color: $yellow;
     color: #000;
     font-weight: 700;
}

.color-coming-soon {
     background-color: $blue;
     color: #fff;
     opacity: 0.7;
}

.color-added {
     background-color: $green;
     color: #fff;
}

.add-on {
     .support-link {
          color: #73818f;
          text-decoration: underline;

          &:hover {
               text-decoration: none;
          }
     }

     .add-on-btn {
          box-shadow: none !important;
          border: 1px solid !important;
          color: #20a8d8;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 11px;
          position: relative;
          padding-left: 30px;
          padding-top: 8px;

          &:before {
               content: "+";
               display: block;
               font-size: 23px;
               position: absolute;
               top: 45%;
               transform: translateY(-50%);
               left: 8px;
               font-weight: 600;
          }

          &.remove {
               color: $danger;

               &:before {
                    content: "";
                    display: block;
                    height: 1px;
                    width: 11px;
                    background-color: $danger;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 12px;
               }
          }

          &.disable {
               cursor: default;
               color: #c8ced3;
          }
     }
}

input[type="checkbox"]:not(:checked)+.c-switch-slider {
     background-color: #c8ced3;
     border-color: #c8ced3;
     // transform: translateY(10px);
}

.c-switch-danger input[type="checkbox"]:not(:checked)+.c-switch-slider {
     background-color: #f86c6b;
     border-color: #f86c6b;
}

.c-switch-success input[type="checkbox"]:checked+.c-switch-slider {
     background-color: $success;
     border-color: $success;
     // transform: translateY(10px);
}

.c-switch-slider {
     box-shadow: none !important;
}

.c-switch-success .c-switch-input:checked+.c-switch-slider::before {
     border-color: $success;
}

@media screen and (max-width: 1199px) {
     .subscriber .listeners {
          .filter-card &.card {
               .listener-show-enable {
                    padding-left: 0 !important;
                    margin-top: 15px;

                    &.destinations {
                         margin-top: 0;
                    }
               }
          }
     }
}

@media screen and (min-width: 1350px) {
     .subscriber {
          .cvr-create-new {
               &.cvr-create-new-dataAction {
                    .cvr-step-card {
                         .form-group {
                              &.border-bottom {
                                   position: relative;

                                   .button-group {
                                        position: absolute;
                                        top: 45px;
                                        right: -100px;
                                        width: 84px;
                                   }
                              }
                         }
                    }
               }
          }
     }
}