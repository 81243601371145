.gtm-wizard,
.switch-type-wizard {
     z-index: 10000;
     position: relative;

     #gtm-wizard-content,
     #switch-type-wizard-content {
          .modal-dialog:not(.modal-dialog-centered) > .modal-content {
               position: fixed;
               height: 100%;
               width: 100%;
               border-radius: 0;
               background-color: #f0f3f5;
               overflow-y: auto;
               border: 0 !important;
          }
     }

     .main-content {
          max-width: 692px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 100px;
     }

     .account-gtm {
          &.success {
               padding-top: 20px;
          }
     }

     .modal-body {
          .unknown-failure {
               h3 {
                    line-height: 30px;
                    margin-bottom: 20px;
               }
          }
     }

     .verify-changes {
          .list-action {
               margin-bottom: 20px;
          }

          .radio-group {
               text-align: start;
               padding-left: 88px;

               label {
                    padding-top: 1px;
               }

               .btn-learn-more {
                    top: 0;

                    i {
                         top: -11px;
                    }
               }
          }

          .form-check-input {
               border-color: #2f353a;
               &:checked {
                    background-color: unset;
                    border-color: #2b5ac6;
                    background-size: 18px;
               }
               
          }
     }

     .account-script-popup {
          &.learn-more {
               .modal-body {
                    padding: 20px 30px;
                    text-align: start;
               }
          }
     }
}

.switch-type-box {
     background: #f0f3f5;
     height: 47px;
     border-bottom: none;
     padding: 0.75rem 1.25rem;
     border-radius: 12px;
     margin-bottom: 20px;

     label {
          font-family: 'Roboto';
          font-size: 13px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: 0.086666666px;

          &.active {
               color: #20a8d8 !important;
          }
     }

     .switch-type-box-icon {
          font-weight: 500;
          font-size: 15px;

          label {
               color: #18222b;
               font-weight: 500;
               font-size: 15px;
               margin-right: 18px;
               margin-left: 11px;
               letter-spacing: 0.2px;
          }

          strong {
               color: #18222b;
               font-family: Roboto;
               font-size: 15px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.25px;
               margin-right: 18px;
               margin-left: 11px;
          }
     }

     input {
          min-width: auto !important;
     }

     a {
          color: #708393;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
     }

     .form-group {
          &.customize-input {
               margin-right: 10px;

               input[type='radio'] {
                    -webkit-appearance: none;
                    margin: 0;
                    position: relative;
                    width: 25px !important;
                    height: 25px !important;
                    border: none !important;
                    box-shadow: none !important;
                    background: #f0f3f5;
                    background-image: none !important;
                    cursor: pointer;

                    &::before {
                         content: '';
                         width: 18px;
                         height: 18px;
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                         background-image: url('/assets/icons/unchecked-send-data.svg');
                         background-repeat: no-repeat;
                         background-size: 17px;
                         background-position-x: calc(100% - 0px);
                         background-position-y: 50%;
                    }

                    &.active::before {
                         background-image: url('/assets/icons/checked-send-data.svg');
                    }
               }

               label {
                    color: #000000 !important;
                    margin-top: 2px;
                    margin-left: 5px;
                    cursor: pointer;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.086666666px;

                    &.active {
                         color: #20a8d8 !important;
                    }
               }
          }
     }
}

.switch-type-wizard {
     .des {
          padding: 0 12px;
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
          margin-bottom: 10px;
     }

     .btn-navigation-step {
          margin: 0 auto;
          position: relative;
          padding: 6px 12px 5px 2px;
          font-size: 14px;
          letter-spacing: 0.2px;
          font-weight: 400;
          color: #7f8c9a;
          font-family: Roboto;
          text-transform: none;
          text-decoration: underline;
          margin-top: 10px !important;
          display: block;

          &:hover {
               text-decoration: none;
          }

          &::after {
               content: '';
               position: absolute;
               background-image: url('/assets/images/icon-chevron-left.png');
               width: 6px;
               height: 7px;
               top: 54%;
               right: 0;
               transform: translate(50%, -50%) rotate(180deg);
          }

          &.back {
               padding: 6px 2px 5px 14px;

               &::after {
                    left: 0;
                    transform: translate(50%, -50%) rotate(0deg);
               }
          }
     }

     .cancel-process {
          width: 500px;
          margin: -115px auto 0;

          .foot-note {
               margin-top: 0px;
          }
     }

     .confirm-save-popup .modal-dialog {
          max-width: 505px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 100px;
          justify-content: center;

          h1 {
               font-size: 20px !important;
               font-weight: 700 !important;
               letter-spacing: -0.9px !important;
          }
     }

     .foot-note a {
          text-decoration: underline;

          &:hover {
               text-decoration: none;
          }
     }

     .footer {
          margin-bottom: 30px;

          svg.icon.icon-custom-size {
               width: 137px;
               height: 23px !important;
               margin: 185px 0px 30px;
          }

          .text-footer {
               color: #18222b;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 300;
               line-height: 17px;
               text-align: right;
               margin-right: 35px;
               transform: translateX(20px);
          }
     }

     .card {
          position: relative;
          border-radius: 12px;
          box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1) !important;
          padding: 22px 15px 20px;

          &:before {
               position: absolute;
               content: '';
               top: 0;
               left: 0;
               width: 100%;
               height: 8px;
               border-radius: 12px 12px 0 0;
               background-image: url('/assets/images/line-rainbow.png');
               background-size: cover;
               background-repeat: no-repeat;
               background-position: center;
          }

          .card-body {
               width: 100%;
               padding: 0 20px;
          }
     }

     // Steps
     .get-started {
          width: 532px;

          p {
               margin-bottom: 20px;
          }

          .changes-box {
               border-radius: 12px;
               background: #d0ecf5;
               padding: 10px 23px;
          }

          .change-item {
               background: none;
               border: none;
               border-radius: 0;
               padding: 10px 0 8px;
               position: relative;
               width: 100%;
               margin-bottom: 0px;
               cursor: unset;
               text-align: start;

               &:not(:last-child) {
                    &::after {
                         content: '';
                         opacity: 0.14750454;
                         left: 0;
                         bottom: 0;
                         border-bottom: 1px solid #73818f;
                         width: 100%;
                         display: block;
                         position: absolute;
                    }
               }

               p {
                    opacity: 1;
                    color: #212c36;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                    margin-bottom: 0;
                    cursor: default;
               }
          }
     }

     .require-value-input {
          width: 600px;

          .general-setting-form {
               margin-top: 25px;
               
               .form-group {
                    margin-bottom: 25px;
               }

               .form-group-general {
                    align-items: center;

                    +.form-group-general {
                         margin-top: 25px;
                    }
               }

               .label-wrapper {
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin-right: 8px;
                    margin-bottom: 2px;

                    label {
                         width: max-content;
                         margin-right: 5px;
                         margin-bottom: -2px;
                    }
               }

               .input-field {
                    position: relative;
                    width: 100%;
                    max-width: 400px;
                    margin-left: auto;

                    .invalid-feedback {
                         position: absolute;
                         text-align: start;
                    }
               }

               .save-changes-button-wrapper button {
                    min-width: 130px;
               }

               &.google-analytics-4 {
                    .label-wrapper {
                         width: 30%;
                    }
                    .input-field {
                         max-width: 380px;
                    }
               }
          }
     }

     .migrate,
     .migrate-make-changes,
     .migrate-success {
          width: 630px;
     }

     .account-script.verify-changes {
          width: 550px;
     }

     .account-gtm.success {
          padding: 42px 15px 47px;
     }
     .label-wrapper {
          width: 25%;
     }
     .input-field {
          width: 75%;
     }
}
