.subscriber-dashboard {
    .account-item {
        padding: 18px 20px 23px;

        .item-name {
            font-size: 18px;
            font-weight: 700;
            color: #121212;
            margin-bottom: 1.1em;
        }

        .number {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0.1em;
            color: initial;
        }

        .text-muted {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 0.2em;
        }
    }

    .button-edit-account-name {
        padding-left: 0;

        button {
            padding-left: 42px;
            padding-right: 42px;

            &.disabled {
                background-color: $gray-400 !important;
                opacity: 1 !important;
            }
        }
    }

    .payment-hidden {
        display: flex;
        align-items: center;
        border: 1px solid #e2e6e9;
        background-color: #f0f3f5;
        height: 40px;
        border-radius: 12px;
        padding: 0 12px;

        img {
            max-height: 18px;
            margin-right: 10px;
        }

        .hidden-payment-number {
            margin-right: 7px;
            display: flex;

            .hidden-circle {
                width: 3px;
                height: 3px;
                background-color: #73818f;
                margin-right: 2px;
                border-radius: 50%;
            }
        }
    }

    .invoice-information {
        h5 {
            margin-bottom: rem(20);
        }

        .btn-primary {
            padding-left: 20px;
            padding-right: 20px;
        }

        input,
        textarea {
            &.form-control:disabled {
                background-image: url('/assets/icons/lock-dropdown.svg');
                background-size: 12px 13px;
                background-position-x: calc(100% - 12px);
                background-position-y: center;
                background-repeat: no-repeat;
            }
        }
    }

    .invoice-section {
        &.card {
            border: 1px solid #e4e7e9 !important;
            overflow: hidden;

            .card-body {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;

                h5 {
                    margin-left: rem(20);
                }
            }
        }

        .upgrade-suggest {
            margin-top: 30px;

            .card-body {
                padding: 30px;

                p {
                    font-size: 14px;
                    color: #000;
                }
            }
        }

        thead {
            text-align: left;

            th div {
                font-weight: 700;
            }
        }
        tbody {
            td {
                color: $black;
            }
        }
    }

    .invoice-table {
        table {
            margin-bottom: 0;

            thead {
                tr {
                    background-color: #18222b;
                    color: #eeeff0;

                    th {
                        font-weight: 400;
                        border: 0;

                        &:not(:last-child) {
                            border-right: 2px solid #444c55;
                        }
                    }
                }
            }

            tr {

                th,
                td {
                    padding: 8px 20px;
                }

                td {
                    border-top: 0;
                    border-bottom: 2px solid #f2f3f5;
                    border-right: 2px solid #f2f3f5;
                    border-left: 2px solid #f2f3f5;

                    &:first-child {
                        border-left: 0;
                    }

                    &:last-child {
                        border-right: 0;
                    }

                    svg {
                        margin-left: 15px;
                        margin-bottom: 3px !important;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }

            .btn-fake-link {
                color: $primary;
                text-decoration: underline;
                text-transform: capitalize;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }

        ul {
            &.pagination {
                padding: 20px;
                margin-bottom: 0;
                border-top: 2px solid #f2f3f5;
            }
        }
    }

    .invoice-detail {
        .invoice-detail-logo {
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .upgrade-package {
        p {
            color: $gray-600;
        }

        .upgrade-package-button {
            button {
                padding-top: 7px;

                &.compare-plans {
                    padding-top: 0;
                }
            }
        }
    }

    .account-plan-usage {
        h6 {
            margin-bottom: 8px;
        }

        .progress {
            margin-bottom: 6px;
        }

        .row+h5 {
            margin-bottom: 32px;
        }

        .usage-statistics {
            >div {
                margin-bottom: 7px;
            }

            h6 {
                &+p {
                    margin-top: -6px;
                }
            }
        }
    }

    #payment-method {
        position: absolute;
        top: -100px;
    }

    .redeemed-coupon-list {
        list-style: none;
        padding: 0;
    }
}

.dashboard-event {
    .col-sm-9 {
        padding-right: 25px;
    }

    .css-yk16xz-control,
    .css-1pahdxg-control {
        padding: 2px 20px 2px 8px;

        .css-g1d714-ValueContainer,
        .css-1hwfws3 {
            padding: 0;
        }
    }

    h4 {
        padding-left: 20px;
        margin-bottom: 0;
    }

    .visitor-session {
        height: 100%;
        padding-left: 20px;
    }

    &-filters {
        >div {
            min-width: 497px;
        }

        .filter-buttons {
            flex: 0 0 67px;
            min-width: inherit;
            padding: 23px 15px 0;

            button {
                padding: 0;
                width: 37px;
                height: 37px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    margin: 0;
                }
            }
        }
        .visitor-session{
            .form-group{
                >div{
                    border: 1px solid #e4e7e9 !important;
                    box-shadow: none !important;
                    border-radius: 14px;
                    background-color: #fff;
                    min-height: 37px;

                    &.is-invalid {
                        border-color: #f86c6b !important;
                    }

                    div[class*=control] {
                        border-radius: 12px;
                    }

                    .react-select__option {
                        background-color: transparent !important;
                        // color: #20A8D8 !important;
                        padding: 8px 12px;

                        &:hover {
                            background-color: #f3f5f6 !important;
                        }
                    }
                }
                >div.react-select--is-disabled{
                    border: 2px solid #e4e7e9 !important;
                }
            }
        }
    }

    @media only screen and(max-width: 1200px) {
        h4 {
            margin-bottom: 10px;
        }
    }

    @media only screen and(max-width: 991px) {
        h4 {
            padding-left: 0;
        }

        .visitor-session {
            padding-left: 0;
        }
    }

    .list-events {
        display: inline-block;
        min-width: 260px;
        max-width: 500px;
        background-color: #f0f3f5;
        text-align: left;
        margin-bottom: 0;

        .css-g1d714-ValueContainer,
        .css-1hwfws3 {
            padding-right: 25px;
        }

        .react-select__menu {
            z-index: 20;
        }
    }

    label {
        margin-left: 10px;
        margin-right: 5px;
    }

    .react-datepicker-wrapper {
        display: inline-block;
        width: 202px;

        input {
            background-color: #f0f3f5;
            background-image: url("/assets/icons/calendar.svg");
            background-repeat: no-repeat;
            background-size: 13px;
            background-position-x: calc(100% - 12px);
            background-position-y: 50%;
            cursor: default;
            text-transform: uppercase;
        }
    }

    .btn-group {
        z-index: 20;
        margin-left: 1rem;
    }

    .date-range-wrapper {
        position: relative;
        display: inline-block;
        min-width: 115px;

        .date-range-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .date-range {
            width: auto;
            background-color: #f0f3f5;
            text-align: center;
            padding: 4px 20px;
            border: 2px solid #e4e7e9 !important;

            &.pdr-3 {
                padding-right: 30px;
            }
        }
    }

    .remove-custom-date {
        position: absolute;
        padding: 5px 10px 3px 3px;
        top: 0;
        right: 0;
        transition: all 0.3s ease;
        border-radius: 0;

        &:hover {
            background-color: #dce0e4;
        }

        &:focus,
        &:active {
            .icon {
                outline: none;
            }
        }
    }

    .custom-date-wrapper {
        position: absolute;
        padding: 20px 15px;
        display: flex;
        align-items: center;
        width: 450px;
        flex-direction: row;
        right: 0;
        top: 40px;
        z-index: 99;

        .icon-close-popup {
            top: 5px;
            right: 5px;
        }

        .calendar-wrapper {
            width: 274px;
            margin-right: 20px;

            input {
                margin-bottom: 10px;
            }
        }

        .date-buttons {
            button {
                width: 100%;
                margin: 5px 0;
            }
        }
    }
}

.account-balance {
    h4 {
        margin-bottom: 20px;
    }

    h3 {
        font-size: 60px;
        color: #474544;
        font-weight: 500;
    }

    span {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0.2em;
    }
}

.apex-chart {
    position: relative;

    .loading-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .apexcharts-menu-item {
        text-align: center;
    }
}

.account-timestamps {
    .switch-box {
        min-width: 90px;
    }

    .box-setting-data {
        max-width: 900px;
    }

    .box-seting {
        max-width: 750px;
    }

    .btn-collapse {
        position: relative;

        p {
            text-transform: initial;
            text-decoration: underline;
        }

        &.show {
            &:after {
                content: "";
                position: absolute;
                top: 60%;
                right: -20px;
                width: 9px;
                height: 9px;
                border: none;
                border-top: 2px solid #73818f;
                border-left: 2px solid #73818f;
                transform: translateY(-50%) rotate(45deg);
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 40%;
            right: -20px;
            width: 9px;
            height: 9px;
            border: none;
            border-bottom: 2px solid #73818f;
            border-right: 2px solid #73818f;
            transform: translateY(-50%) rotate(45deg);
        }
    }

    .form-control {
        min-height: 37px;
    }
}

.box-account-timezone {
    .error {
        font-size: 12px;
        color: red;
    }
}

@media only screen and(max-width: 1320px) {
    .dashboard-event {
        h4 {
            padding-left: 0;
        }

        .visitor-session {
            padding-left: 0;
        }

        .dashboard-event-filters>div {
            flex: 0 0 100%;
            max-width: 100%;
            min-width: inherit;
        }
    }
}

@media screen and (max-width: 991px) {
    .dashboard-event {
        .date-range-wrapper {
            .date-range {
                min-width: 100px;
                padding-right: 7px;
            }
        }
    }
}