.redis-data {
    padding-left: 0;
    li {
        list-style-type: none;
    }
    ul {
        margin-bottom: 20px;
        padding-left: 15px;
    }
    > li {
        margin-bottom: 20px;
        border-bottom: 1px solid #e4e7ea;
    }
}
