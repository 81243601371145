.selector-rules {
    #learn-more-btn {
        border: none;
        background-color: transparent;
        color: $blue;
        font-weight: 300;
        text-decoration: underline;

        &:focus,
        &:hover {
            color: #167495;
            text-decoration: none;
        }
    }
}

.selector-values-layout {
    .list-fields .custom-browser-buttons button {
        margin-bottom: -3px;
        padding-left: 0;
    }
}

@media screen and (min-width: 768px) {
    .selector-rules {
        .custom-selector-value, 
        .custom-browser-buttons {
            padding-right: 0;
        }
    }
}