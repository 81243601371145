.users {
    .invite-new-user {
        margin-top: 20px;

        button {
            font-weight: 500;
            padding: 8px 15px;
        }

        label {
            color: #73818f;
        }
    }

    .group-button {
        .compare-plan {
            color: #73818f;

            &:hover {
                color: #73818f;
                text-decoration: none;
            }
        }
    }

    .owner-body {
        padding-bottom: 0;
    }

    .individual-user-data,
    .owner-data {
        border-right: 1px solid #f2f3f5;

        p {
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 14px;
        }
    }

    .users-infomation-header {
        background-color: #18222b;
        margin-right: -20px;
        margin-left: -20px;

        .data-default {
            color: #eeeff0;
            padding: 10px 15px;

            h5 {
                margin-bottom: 0;
                font-size: 13px;
                font-weight: 500;
            }
        }

        .data-default:not(:last-child) {
            border-right: 1px solid #444c55;
        }
    }

    .users-infomation-body {
        margin-right: -20px;
        margin-left: -20px;
        border-bottom: 1px solid #f2f3f5;
    }

    .table-users-response {
        overflow-x: auto;
    }

    .table-users {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;

        th {
            background-color: #18222b;
            color: #eeeff0;
            padding: 10px 15px;
            white-space: nowrap;

            h5 {
                margin-bottom: 0;
                font-size: 13px;
                font-weight: 500;
            }
        }

        td {
            padding: 8px 15px;
            border: 1px solid #f2f3f5;

            p {
                margin-top: 10px;
                margin-bottom: 10px;
                font-weight: 400;
                font-size: 14px;
            }
        }

        .cell-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 15px;

            &.small-gap {
                gap: 5px;
                white-space: nowrap;
            }

            &.no-wrap {
                flex-wrap: nowrap;
            }

            .copy-tooltip,
            .tooltip-wrap {
                margin-left: 0;
            }

            .block-tooltip {
                white-space: normal;
            }
        }

        .owner-tranfer .tooltip-wrap .block-tooltip {
            min-width: 460px;
            top: auto;
            bottom: 0px;
            left: auto;
            right: 30px;
            transform: none;
            .block-tooltip-text {
                border-top-left-radius: 12px;
                border-bottom-right-radius: 0;
            }

            svg {
                transform: translateX(100%) rotate(180deg);
            }
        }

        p:last-child,
        label {
            margin: 0;
        }

        button {
            white-space: nowrap;
            margin: 0;
        }

        .btn-change-owner {
            padding: 0;
        }

        .tooltip-wrap {
            position: relative;
            display: inline-block;
            margin-left: 5px;

            .block-tooltip {
                top: -20px;
                left: auto;
                right: 0;
                transform: translateY(-100%);

                svg {
                    left: auto;
                    right: 2px;
                    top: 68px;
                    bottom: 0px;
                    transform: rotate(-90deg) scaleY(-1);
                    &.arrow-preview-horizontal {
                        right: 4px;
                        top: 98px;
                    }
                }
            }
        }

        .billing-rights .block-tooltip {
            min-width: 400px;
        }

        .data-default:not(:last-child) {
            border-right: 1px solid #444c55;
        }
    }

    .btn-change-owner {
        cursor: pointer;
        text-decoration: underline;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none !important;
        font-weight: 400;
        text-transform: capitalize;

        &:hover {
            box-shadow: none !important;
        }
    }

    .btn-remove-user {
        cursor: pointer;
        text-decoration: underline;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #f86c6b;
        letter-spacing: 0.5px;
    }

    .copy-active-link {
        display: flex;
        align-items: center;

        a {
            text-decoration: underline;
        }

        .icon-copy {
            font-size: 0.9em;
            margin-left: 10px;
        }

        .copy-tooltip {
            position: relative;
            margin-left: 10px;

            .block-tooltip {
                left: auto;
                right: 25px;
                top: 9px;
                min-width: 320px;

                svg {
                    right: -10px;
                    left: auto;
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}

.customer-support {
    .headline {
        display: inline-block;
        margin-right: 30px;
    }

    .allow-switch {
        display: inline-block;
        vertical-align: bottom;
        margin-top: 15px;
    }

    button {
        margin-top: 1rem;
        padding: 8px 15px;

        span {
            padding-right: 10px;
        }
    }

    p {
        margin-bottom: 3px;
    }
}

.customer-support-card {
    .account-plan-usage {
        &-header {
            display: flex;
            gap: 15px;
            align-items: center;
            justify-content: space-between;
        }

        .account-info {
            display: flex;
            gap: 25px;
            justify-content: center;

            p {
                margin: 0;
            }
        }

        .button-group {
            display: flex;
            gap: 15px;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.form-create-account {
    .invalid-feedback {
        text-align: left;
        padding-left: 42px;
    }
}

.account {
    margin-top: -10px;

    .domain-disabled {
        height: 34px;
        background-color: #c8ced3 !important;
    }

    .card {
        //    max-width:1220px;
        margin: 0px -10px 24px 0px;
    }


    span.text-domain {
        display: block;
        border-radius: 12px;
        border: 1px solid #e2e6e9;
        background: #ffffff;
        width: 100%;
        padding: 8px;
        color: #212c36;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.5px;
    }

    .users-infomation-body.row:nth-child(odd) {
        background-color: #f4f5f6;
    }

    // .users-infomation-body input.form-control:disabled{
    //     background:  #ffffff;
    //     border-radius: 4px;
    //     color: #212c36;
    //     font-family: Roboto;
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 17px;
    //     letter-spacing: 0.5px;
    // }

    h4.pb-3.title {
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 205px;
        height: 30px;
        font-family: 'Poppins';
        font-size: 20px;
        margin-bottom: 25px;
        font-weight: 700;
    }

    h1 {
        font-family: "Poppins";
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 430px;
        height: 45px;
    }

    .invite-new-user {
        margin-top: 20px;

        button {
            font-weight: 500;
            padding: 8px 15px;
        }

        label {
            color: #73818f;
        }
    }

    .group-button {
        .compare-plan {
            color: #73818f;

            &:hover {
                color: #73818f;
                text-decoration: none;
            }
        }
    }

    p {
        color: #2f353a;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 25px;
    }

    .owner-body {
        padding-bottom: 0;

        .domain-custom {
            padding-left: 45px !important;
        }
    }

    .individual-user-data,
    .owner-data {
        p {
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 14px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            .custom-text {
                padding-left: 0;
            }
        }

        .custom-text {
            padding-left: 10px;
            color: #73818f;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.3px;
        }
    }

    .users-infomation-header {
        &.user-my-account {
            height: 45px;
            padding: 0 10px;
            align-items: center;
            margin-top: -7px;

            h5 {
                color: #ffffff;
                font-family: Roboto;
                font-weight: 500 !important;
                line-height: 16px;
                letter-spacing: 0.18571427px;
            }
        }

        background-color: #18222b;
        margin-right: -20px;
        margin-left: -20px;

        .data-default {
            color: #eeeff0;
            padding: 10px 15px;

            h5 {
                margin-bottom: 0;
                font-size: 13px;
                font-weight: 400;
            }
        }
    }

    .users-infomation-body {
        margin-right: -20px;
        margin-left: -20px;
        border-bottom: 1px solid #f2f3f5;
        padding-top: 12.5px;
        padding-bottom: 12.5px;

        .individual-user-data {
            margin: auto 0;

            a {
                color: #20a8d8;
            }
        }

        &.custom-body {
            line-height: 14px;
        }
    }

    .btn-change-owner {
        cursor: pointer;
        text-decoration: underline;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none !important;
        font-weight: 400;
        text-transform: capitalize;

        &:hover {
            box-shadow: none !important;
        }
    }

    .btn-remove-user {
        cursor: pointer;
        text-decoration: underline;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #f86c6b;
        letter-spacing: 0.5px;
    }

    .copy-active-link {
        display: flex;
        align-items: center;

        a {
            text-decoration: underline;
        }

        .icon-copy {
            font-size: 0.9em;
            margin-left: 10px;
        }

        .copy-tooltip {
            position: relative;
            margin-left: 10px;

            .block-tooltip {
                left: auto;
                right: 25px;
                top: 9px;

                svg {
                    right: -10px;
                    left: auto;
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .active-plan {
        padding: 10px 0px !important;
    }

    .active-plain-body {
        padding: 0 4px !important;
    }
}

@media screen and (max-width: 1380px) {
    .account {
        .input-group {
            transform: translateY(10px);
        }
    }
}

@media screen and (max-width: 1199px) {
    .customer-support-card {
        .account-plan-usage {
            &-header {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}