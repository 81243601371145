.admin {
     .listeners {
          .react-datepicker-wrapper {
               display: block;
          }
     }
     .icon-listener {
          position: relative;
          max-width: 300px;
          float: left;
          img {
               max-width: 100%;
               max-height: 180px;
               width: auto;
               height: auto;
               display: block;
               min-width: 30px;
               min-height: 30px;
               background: #f1f1f1;
               margin: 0;
               padding: 0;
          }
          .remove-img-btn {
               position: absolute;
               top: 0;
               right: 0;
               padding: 1px 5px;
               border-radius: 50%;
          }
     }
}