.css-gg45go-NoOptionsMessage {
    padding: 8px 7px;
}

.custom-variable-rules {

    // label.mb-0.opct.opacity-70.mr-2 {
    //     transform: translateY(10px);
    // }
    p {
        color: #2f353a;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;

        // padding-left: 75px;
        &.input-message {
            font-size: 13px;
        }

        a {
            text-decoration: underline !important;

            &:hover {
                cursor: pointer;
                text-decoration: none !important;
            }
        }
    }

    p.mb-4 {
        color: #2f353a;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        padding-right: 75px;
    }

    h4 {
        margin-bottom: 10px;
    }

    .btn-edit {
        padding: 5px 20px;
        margin-right: 16px;
    }

    a.btn.btn-primary.btn-edit {
        font-weight: 500;
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 5px;
        margin-right: 20px;
        width: 76.41px;
        height: 35px;
        border-radius: 17.5px;
        color: #ffffff;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    h1 {
        font-family: "Poppins";
        font-weight: 600;
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        width: auto;
        // height: 45px;
    }

    h4 {
        margin-bottom: 10px;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
    }

    button.mb-3.rounded-pill.btn.btn-success {
        font-size: 13px;
        border-radius: 17.5px !important;
    }

    .btn-delete {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 120.07px;
        height: 35px;
        border-radius: 17.5px;
        background: #ffffff;
        color: #f86c6b;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background-color: #f86c6b;
            ;
            color: #fff;
        }
    }

    .moveable-rules {
        &>div {
            margin-bottom: 18px;
        }

        &.usersource-default {
            padding: 20px;
            padding-bottom: 10px;
            background-color: #c7c7c7;
        }
    }

    .learn-write-rule {
        padding: 9px 25px;
        margin-right: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .rule-id {
        color: #8192a0;
        font-size: 13px;
        width: 35%;
        text-align: right;
    }

    .rule-description {
        width: 130%;
    }

    .description {
        color: #18222b;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 2px;
    }

}

.data-settings {
    .sun-editor {
        .sun-editor-common {
            display: none;
        }
    }
}

.form-edit-lookup-title {
    &.trigger-rule {
        h1 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 95%;
        }
    }

}

.form-edit-lookup-description {
    p {
        color: #18222b;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
}

.cvr-create-new {
    padding-bottom: 60px;
    margin-bottom: 80px;

    h1 {
        font-family: "Poppins";
        font-weight: 600;
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: auto;
        padding-bottom: 2px;
        line-height: 42px;
    }

    .rule-id {
        color: #c8ced3;
        font-size: 14px !important;
        white-space: nowrap;
        margin-top: 15px;
    }

    .cvr-step-card {
        min-height: 310px;
        border-radius: 4px;

        .form-control {
            color: #212c36;
        }

        [class*="-control"],
        [class$="-control"] {

            [class*="-ValueContainer"],
            [class$="-ValueContainer"],
            >div:first-child {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        button.px-4.btn.btn-primary.btn-square {
            border-radius: 17.5px;
        }

        .btn-skip {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .description {
        color: #18222b;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-left: 5px;
        margin-bottom: 23px;
    }

    div[class*="-control"] {
        div[class*="-singleValue"] {
            // max-width: calc(100% - 30px);
        }
    }

    .cvr-step-1 {
        min-height: 1px;

        h5 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            // padding-left: 7px;
        }

        p {
            margin-bottom: 0;
            margin-bottom: 0;
            margin-left: 57px;
            // transform: translateX(57px);
            margin-top: 2px;
            color: #2f353a;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            margin-left: 66px;
        }

        button {
            &:not(.btn-next-step) {
                text-transform: uppercase;
                font-weight: 500;
                padding-top: 13px;
                padding-bottom: 13px;
            }
        }

        button.px-4.btn-next-step.btn.btn-primary {
            width: auto;
        }

        button.btn-dark,
        button.btn-primary {
            min-width: 220px;
            height: 45px;
            border-radius: 22.5px;
            color: #ffffff;
            font-family: Roboto;
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            margin-right: 5px;
            // margin-left: 7px;
        }

        button.btn-dark {
            &:hover {
                background-color: #20A8D8;
            }
        }
    }

    select {
        cursor: pointer;

        option {
            color: #000000;
        }
    }

    .cvr-step-2,
    .cvr-step-3,
    .cvr-step-4 {
        .media-block {
            max-width: 422px !important;
            height: 266px !important;
            margin-left: auto;
            margin-right: auto;
            box-shadow: none !important;
            border: 1px #e4e7e9 solid !important;
        }

        .icon-play {
            width: 60px;
            height: 60px;
            background-color: #f0f3f5;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            svg {
                width: 14px;
                position: absolute;
                top: 50%;
                left: calc(50% + 2px);
                transform: translate(-50%, -50%);
            }
        }

        .form-control {
            &.form-control[type='number'] {
                padding-right: 12px;
            }
        }

        .css-1pahdxg-control,
        .css-yk16xz-control,
        .react-select__control {
            cursor: pointer;
            padding-right: 25px;
        }

        svg.icon.icon-custom-size.ml-1.pointer {
            margin-top: -5px;
        }
    }

    .cvr-step-4 {
        .card-body {
            form {
                .text-left {
                    margin-bottom: 15px;
                }

                .create-custom-rule {
                    &.create-custom-rule-invalid {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        min-height: 21px;
                        flex-wrap: wrap;
                        text-align: left;
                    }

                    text-align: end;

                    .invalid-feedback {
                        width: auto;
                        margin-top: 0;
                    }

                    .create-custom-rule-link {

                        text-decoration: underline;
                        font-size: 12px;
                    }
                }
            }
        }

        label {
            &.disabled {
                color: #979da1 !important;
            }
        }

        input[type="text"]:disabled {
            &::placeholder {
                color: #979da1;
            }

            background: transparent;
            border-color: rgba($color: #e4e7e9, $alpha: 0.5);
        }

        .invalid-custom-variables {
            margin-top: 0.25rem;
            font-size: 80%;
            font-weight: 400;
        }

        .browser-step4-label {
            margin-right: 10px;
        }

        .browser-step4-value {
            display: flex;
            justify-content: space-between;

            &+textarea {
                word-break: break-all;
            }

            strong {
                margin-right: 15px;
                font-weight: 800;
            }

            .insert-wrapper {
                display: flex;
            }

            .browser-step4-form {
                display: flex;
                gap: 15px;
            }
        }

        .insert-variable-dropdown-icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
                padding: 0;
                margin-left: 5px;
                background-color: transparent;
                border: 0;
                border-bottom: 1px dotted #000;
            }
        }

        .insert-variable-dropdown {
            position: relative;

            ul {
                margin-top: 3px;
                list-style: none;
                position: absolute;
                right: 0;
                padding: 10px;
                z-index: 99;

                border: 1px solid #e4e7ea !important;
                box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;

                max-height: 280px;
                overflow-y: auto;

                background-color: $white;

                width: 380px;
                border-radius: 12px;

                &.value-no-options-available {
                    padding-top: 14px;
                    left: 50%;
                    transform: translateX(-50%);

                    li {
                        text-align: center;
                        cursor: default;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &.no-options {
                        text-align: center;
                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $gray-300;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border-left: 2px solid #fff;
                    border-right: 2px solid #fff;
                }

                .variable-filter {
                    position: sticky;
                    top: 0;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        z-index: 0;
                        background-color: #fff;
                        width: calc(100% + 20px);
                        height: 50px;
                        top: -10px;
                        left: -10px;
                    }

                    input {
                        position: relative;
                    }
                }

                li {
                    padding-bottom: 5px;
                    word-break: break-all;

                    &:not(.variable-filter):not(.no-options) {
                        span {
                            &:hover {
                                cursor: pointer;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    &.cvr-create-new-storage {
        .cvr-step-3 {
            .invalid-feedback {
                position: absolute;
            }
        }
    }

    &.monitoring-rule {
        .cvr-step-2 {
            .invalid-feedback {
                position: absolute;
                top: 100%;
            }
        }
    }

    .conditions-group .conditions-match .select-type {
        width: auto;
        min-width: 172px;
    }

    .cvr-step-3 {
        .step3-form {
            display: flex;
        }

        .step3-form-label {
            min-width: 130px;
            margin-right: 15px;
        }

        .step3-form-input {
            width: 100%;

            .is-invalid {
                margin-bottom: 0;

                +.invalid-feedback {
                    margin-top: 0;
                }
            }
        }

        #customMessage {
            resize: vertical;
        }

        .invite-user {
            background-color: transparent;
        }

        // .css-gg45go-NoOptionsMessage + .invite-user {
        //     display: none;
        // }
    }

    .select-react-customize {
        .select-wrapper {

            >input,
            .stretch-container {
                // padding: 7px 25px 6px 10px;
                padding: 6px 25px 6px 10px;
                height: auto;

            }

            .css-2b097c-container {
                border: 1px solid #e4e7e9 !important;
                overflow: hidden;
            }

            .stretch-container {
                border: 2px solid #e4e7e9;
                word-break: break-all;

                // &.form-control {
                //     height: auto;
                // }
            }
        }
    }
}

.events-multiselect,
.variables-multiselect {
    // width: calc(100% + 10px);
    .css-1okebmr-indicatorSeparator {
        width: 0;
    }

    .css-tlfecz-indicatorContainer,
    .css-1hb7zxy-IndicatorsContainer {
        svg {
            opacity: 0;
        }

        background-image: url("/assets/icons/icon-select.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 12px);
        background-position-y: center;
        background-size: 6px;
    }

    p {
        color: $danger !important;
        font-weight: 400;
        margin-top: 4px;
    }
}

.events-multiselect {
    &.invalid-select-default {
        p {
            &:last-child {
                display: none;
            }
        }
    }

    .input-field-wrapper {
        span {
            bottom: 1px;
        }
    }
}

.custom-variables-add-domain {
    .custom-variables-description {
        margin-top: -7px;
    }
}

.css-g1d714-ValueContainer {
    cursor: pointer;

    .custom-select__placeholder {
        color: #2f353a !important;
    }
}

.code-preview.no-options-info.card {
    border-top-left-radius: unset;
}

// /no-options-message

.learn-how-button {
    background-color: #fee1e1;
    padding: 0;
    color: #863f3f;
    border: none;
    font-weight: 500;
    text-decoration: underline;
}

.custom-variable-rules,
.listener-reporting {
    .message-warning {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.select-no-options {
    &.is-invalid {
        border: 2px solid #f86c6b !important;
    }
}

.insert-variable-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2px;
    gap: 15px;

    .insert-wrapper {
        display: flex;
        gap: 15px;

        strong {
            margin-right: 15px;
        }
    }
}

.monitoring-step-3 {
    .insert-variable-dropdown-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            padding: 0;
            margin-left: 5px;
            background-color: transparent;
            border: 0;
            border-bottom: 1px dotted #000;
        }
    }

    .se-wrapper {
        .se-wrapper-inner {
            &.sun-editor-editable {
                p {
                    color: #212c36;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0.2px;
                    font-family: 'roboto';
                }
            }
        }
    }

    .react-select__menu {
        z-index: 10;
    }

    .insert-variable-dropdown {
        position: relative;

        &.second,
        &.third {
            .no-options .no-options-info {
                left: inherit;
                right: calc(100% - 125px);
                top: calc(100% + 3px);
                border-top-left-radius: 12px;

                .arrow-preview {
                    left: auto !important;
                    right: -9px;
                    transform: rotateY(180deg);
                }

            }
        }

        ul {
            margin-top: 3px;
            list-style: none;
            position: absolute;
            right: 0;
            padding: 10px;
            z-index: 99;

            border: 1px solid #e4e7ea !important;
            box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;

            max-height: 245px;
            overflow-y: auto;

            background-color: $white;

            width: 380px;
            border-radius: 12px;

            &.value-no-options-available {
                padding-top: 10px;

                li {
                    text-align: left;
                    cursor: default;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.no-options {
                    text-align: center;
                }
            }

            &::-webkit-scrollbar {
                width: 7px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $gray-300;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                border-left: 2px solid #fff;
                border-right: 2px solid #fff;
            }

            .variable-filter {
                position: sticky;
                top: 0;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: 0;
                    background-color: #fff;
                    width: calc(100% + 20px);
                    height: 50px;
                    top: -10px;
                    left: -10px;
                }

                input {
                    position: relative;
                }
            }

            li {
                padding-bottom: 5px;

                &:not(.variable-filter):not(.no-options) {
                    span {
                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
            }

            li {
                &.no-options {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.label-holder {
    visibility: hidden;
    margin-bottom: 1.43rem;
}

.button-new-rule-wrapper {
    padding: 0 15px;
}

.button-new-rule {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    button {
        margin-bottom: 0.4rem;
        margin-top: 0.1rem;
        cursor: pointer;
    }
}

.card-add-group {
    box-shadow: none !important;

    .card-header {
        border-radius: 12px !important;
    }
}

.conditions-group {
    border: 1px solid #f0f3f5 !important;
    box-shadow: none !important;

    // .conditions-match {
    //     flex-wrap: wrap;
    // }
}

.custom-rule {
    .cvr-step-3 {
        .select-react-customize {
            .select-wrapper {
                div[class*=MenuList] {
                    max-height: 240px;

                    &.custom-select__menu-list {
                        max-height: 300px;
                    }
                }
            }
        }
    }

    .cvr-step-4 {
        .browser-step4-value {
            display: flex;
            justify-content: space-between;
        }

    }
}

.c-wrapper.v2 {
    .custom-variable-rules {
        h1 {
            letter-spacing: -0.9px;

            +.description {
                margin-bottom: 20px;

                p {
                    line-height: 22px;
                    margin-bottom: 10px;
                }
            }
        }

        .description {
            margin-bottom: 12px;

            div[class*=-control] {
                border-radius: 12px;
                border: dashed 1px #e2e6e9;
                padding: 15px;
                overflow: hidden;
            }
        }

        .preview-listener-select {
            border-radius: 15px;
            max-width: 317px;
            margin-bottom: 18px;
            border: none !important;

            &:after {
                width: 5px;
                height: 5px;
            }

            div[class*=-placeholder] {
                color: #73818f;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0.5px;
            }

            div[class*=control] {
                padding: 5px 30px 6px 7px;
                border: 2px solid #e2e6e9 !important;
                border-radius: 12px;
                overflow: hidden;
            }

            div[class*=singleValue] {
                margin-left: 5px;
            }

            .option-row {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0.5px;

                .icon {
                    margin-right: 8px;
                }
            }
        }
    }

    .select-listener-wrapper {
        position: relative;
        z-index: 9;

        label {
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.1px;
        }

        [data-lastpass-icon-root] {
            display: none !important;
        }
    }

    .c-switch-sm .c-switch-slider::before {
        width: 18px;
        height: 18px;
        top: 1px;
        left: 1px;
    }

    #custom-variable-form .form-group .col-md-4 {
        padding-top: 0;
    }

    .form-edit-lookup {
        .row {
            justify-content: space-between;
        }

        &-title {
            h1 {
                white-space: normal;
                overflow: initial;
                text-overflow: initial;
                max-width: initial;
                height: auto;
            }
        }

        // &-button {
        //     flex: 0 0 460px;
        // }

        &-description {
            flex: 0 0 100%;
        }

    }


    #custom-variable-form .variable-input-group {

        input,
        .btn-edit {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 1750px) {
    .cvr-create-new .cvr-step-1 p {
        margin-left: 8px;
    }
}

@media screen and (max-width: 1350px) {
    .cvr-create-new {
        .form-edit-lookup {
            .row {
                >div {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .form-edit-lookup-title {
                order: 1;
            }

            .form-edit-lookup-description {
                order: 2;
            }

            .form-edit-lookup-button {
                order: 3;
                margin-bottom: 20px;

                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 1730px) {
    .cvr-create-new .cvr-step-4 .insert-variable-dropdown {
        &.second {
            .no-options .no-options-info {
                left: inherit;
                right: calc(100% - 125px);
                top: calc(100% + 3px);

                .arrow-preview {
                    left: inherit;
                    right: -9px;
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .cvr-create-new .cvr-step-4 .insert-variable-dropdown {
        &.third {
            .no-options .no-options-info {
                left: inherit;
                right: calc(100% - 125px);
                top: calc(100% + 3px);

                .arrow-preview {
                    left: inherit;
                    right: -9px;
                    transform: rotateY(180deg);
                }
            }
        }
    }

    // .cvr-create-new {
    //     .cvr-step-1 {
    //         p {
    //             margin-left: 83px !important;
    //         }
    //     }
    // }
}



@media screen and (max-width: 991px) {
    .rule-step {
        .no-options {
            .no-options-info {
                left: -50px;
                top: calc(100% + 10px);

                .arrow-preview {
                    left: calc(50% + 20px);
                    top: -10px;
                    transform: rotate(90deg);
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .cvr-create-new {
        .cvr-step-4 {
            .browser-step4-value {
                align-items: flex-end;

                .insert-wrapper {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .cvr-create-new {
        .cvr-step-4 {
            .to-this-value {
                max-width: 800px;
            }
        }
    }
}

.custom-option {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    .badge {
        padding: 3px 8px;
        border-radius: 20px;
    }
}

.flexible-modal-content {
    .card-header {
        .tooltip-wrapper {
            &.custom-report{
                position: relative;
                margin-right: 20px;
                & > svg path {
                    fill: #20a8d8 !important;
                }
            }
        }
    }
}