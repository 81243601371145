.contract-plan-detail {
     .contract-plan-form {
          .form-action {
               margin-bottom: 70px;
          }

          .documents {
               ul {
                    padding-left: 15px;
               }

               .attachment-file ul {
                    margin-left: 0;
                    padding-left: 0;
               }
          }
     }

     .check-box {
          display: flex;
          align-items: center;

          input[type='checkbox'] {
               display: inline-block;
               width: 17px;
               height: 17px;
               min-height: inherit;
               margin-right: 8px;
               border-color: #c8ced3;
               border: 1px solid #212c36 !important;
               cursor: pointer;
               vertical-align: middle;
               -moz-appearance: none;
               appearance: none;
               -webkit-appearance: none;
               outline: none;

               &.form-control {
                    border-radius: 4px;
                    border: 1px solid #20a8d8 !important;

                    &:checked {
                         background-image: url(/assets/icons/icons-check-update.svg);
                         background-color: #20a8d8;
                    }
               }

               &:checked {
                    background-image: url(/assets/icons/icon-check.svg);
                    background-repeat: no-repeat;
                    background-position: 50% 45%;
                    border: none;
                    opacity: 1;
                    background-color: #212c36;
                    border: none !important;
               }
          }

          label {
               margin-bottom: 0;
               line-height: 1;
               vertical-align: middle;
               transform: none;
               margin-right: 10px;
               cursor: pointer;
               &.step-2 {
                    color: #000 !important;
                    font-weight: 500;
               }
          }
     }

     textarea {
          resize: vertical;
          min-height: 100px;
     }

     .invoice-receipt-email-wrapper {
          width: 100%;
          max-width: 350px;
     }

     .horizontal-wrapper {
          label {
               white-space: nowrap;
               margin-bottom: 0;
               margin-right: 10px;
          }

          select {
               width: 100%;
               max-width: 200px;
          }

          input {
               width: 100%;
               max-width: 400px;
          }

          .invalid-feedback {
               position: absolute;
          }
     }

     .price-input {
          max-width: 120px;
          padding-right: 12px !important;
     }
}
