.ticket-filter {
     .row-gap {
          row-gap: 15px;
     }
     .rdrDefinedRangesWrapper {
          display: none;
     }
     .dateRangeCustom {
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 1000;
          border: 1px solid transparent;
          background-color: white;
          padding: 15px;
          border-radius: 15px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     }
}

.ticket-table-wrap {
     overflow-x: auto;
     margin-bottom: 10px;

     .ticket-table {
          min-width: 1220px;
     }

     .list-tickets {
          min-width: 1109px;
          min-height: 800px;
          position: relative;
     }
     .loading {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
     }
}

.account-plan-usage {
     .badge {
          padding: 5px 10px;
     }
     .badge-info {
          color: #fff;
     }
}

.header-list-tickets {
     text-transform: uppercase;
     font-size: 16px;
     font-weight: 500;
     color: rgb(143, 143, 143);
     padding: 1.25rem;
     padding-bottom: 7px;
     border-bottom: 1px solid #e4e7ea;
     box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.13) !important;

     .pagination {
          button {
               padding: 3px 5px;
               background-color: #f7f8f9;

               svg {
                    color: #000;
               }
          }

          .number {
               text-transform: initial;

               .number-pagination {
                    background: transparent;
                    border: none;
                    color: #61c5ff !important;
                    width: auto;
                    padding: 0px 5px;
                    font-size: 16px;
                    line-height: 1;
                    height: auto;
                    outline: none;
                    padding-right: 0;
                    text-align: right;

                    &:focus {
                         outline: none;
                         box-shadow: none;
                    }
               }
          }
     }
}

.ticket-card {
     transition: 0.3s all;
     font-size: 15px;

     .ticket-status {
          position: relative;
     }

     &.status-1 {
          opacity: 0.7;

          &:hover {
               opacity: 1;
          }
     }

     button {
          text-transform: initial;
          position: relative;

          &.reply {
               border-radius: 50%;
               padding: 4px 8px;
          }
     }

     .badge {
          font-size: 15px;
          font-weight: 400;
          padding: 6px 12px;
          line-height: 21px;
          color: #fff;

          &.number-comment {
               padding: 3px 6px;
               line-height: 1;
               font-size: 14px;
               position: absolute;
               margin-left: 10px;
               top: -5px;
               right: -10px;
               font-weight: 500;
          }

          &.number-comment-cs {
               padding: 3px 6px;
               line-height: 1;
               font-size: 14px;
               margin-left: 10px;
          }

          &.number-comment-cl {
               padding: 3px 6px;
               line-height: 1;
               font-size: 14px;
               position: absolute;
               margin-left: 10px;
               top: -5px;
               right: -10px;
               font-weight: 500;
          }
     }

     .ticket-type {
          padding: 0;
          margin: 0;
          font-weight: 500;
     }

     .id {
          word-break: break-all;
     }
}

.attachments {
     font-size: 16px;

     h4 {
          margin-right: 30px;
     }

     .attachment-media {
          flex-wrap: wrap;

          & + .attachment-file {
               margin-top: -5px;
          }
     }

     .attachment-file {
          margin-top: 2px;

          a {
               font-size: 16px;
               font-weight: 300;
          }
     }

     .attachment-item {
          border: 1px solid rgb(156, 156, 156);
          padding: 3px;
          cursor: pointer;
          margin-right: 20px;
          margin-bottom: 15px;
          width: 120px;
          height: 120px;
          position: relative;

          &.attachment-video {
               width: 350px;
               height: auto;
          }

          .img-bg {
               width: 100%;
               height: 100%;
               background-position: center;
               background-size: cover;
               background-repeat: no-repeat;
          }

          .img-loading {
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
          }

          &.video {
               width: auto;
          }

          .video-box {
               width: auto;
               height: 100%;
               background-color: #4a4a4a;
               transition: 0.5s all;
               position: relative;

               video {
                    position: relative;
                    width: auto;
                    height: 100%;
               }

               .video-bg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    background-color: #5d5d5d80;

                    svg {
                         width: 2rem;
                         height: 2rem;
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                    }
               }

               &:hover {
                    background-color: #5e5e5e;
               }
          }
     }
}

.attachments-popup {
     .modal {
          overflow: hidden !important;
     }

     .modal-content {
          margin: 0 auto;
     }

     .modal-header {
          position: absolute;
          top: 10px;
          right: -10px;
          z-index: 9;
          border: none;
     }

     .modal-body {
          padding: 50px;
     }

     .slider-box {
          max-width: 90%;
          min-height: 80vh;
          margin: 0 auto;
          display: flex;
          align-items: center;

          video {
               max-width: 100%;
               max-height: 100%;
          }

          .carousel-control-prev {
               left: 25px;
               transform: translate(-50%, -50%);
          }

          .carousel-control-next {
               right: 25px;
               transform: translate(50%, -50%);

               svg {
                    transform: rotateZ(180deg);
               }
          }

          .carousel-control-next,
          .carousel-control-prev {
               width: auto;
               cursor: pointer;
               transition: 0.5s all;
               bottom: initial;
               top: 50%;
          }

          .carousel {
               margin: 0 auto;
               position: inherit;
          }

          .carousel-item {
               img {
                    object-fit: contain;
                    // height: 90vh;
                    width: 80vw;
                    max-height: calc(100vh - 150px);
                    max-width: 100%;
                    margin: 0 auto;
               }
          }
     }

     .view-image {
          position: relative;

          img {
               max-width: 680px;
               max-height: 800px;
          }
     }

     .card {
          margin: 0;
     }

     .card-header {
          position: relative;
     }

     .icon-close-popup {
          position: absolute;
          right: 30px;
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
     }
}

.attachment-file {
     ul {
          margin: 0;
          margin-left: 24px;
          padding: 0;

          li {
               list-style: none;
          }
     }
}

.comment-item {
     padding: 10px 0;
     border-bottom: 1px solid #f5f5f5;

     &:last-child {
          border: none;
     }

     ul,
     ol {
          padding-left: 18px;
     }

     p {
          text-align: justify;
     }
}

.comment-response {
     &.card-accent-success {
          border-left: 2px solid #4dbd74 !important;
     }

     &.card-accent-info {
          border-left: 2px solid #63c2de !important;
     }

     .message {
          text-align: justify;
          font-size: 16px;
          font-weight: 300;

          ul,
          ol {
               padding-left: 25px;
               font-size: inherit;
               font-weight: inherit;
          }

          p {
               font-size: inherit;
               font-weight: inherit;
          }
     }

     .content {
          font-size: 16px;
     }

     .time-submit {
          position: absolute;
          right: 20px;
          bottom: 15px;
     }
}

.add-new-comment {
     ul {
          padding-left: 15px;
     }

     .ticket-invalid-feedback {
          display: block;
          padding-top: 3px;
          color: #f86c6b;
          font-size: 11px;
     }
}

.ticket-details .list-img-cmt {
     padding: 0;
     margin: 0;
}

.my-ticket {
     .badge-info {
          color: #fff !important;
     }
}

.tickets-table {
     .list-tickets {
          min-height: 400px;
          position: relative;

          h2 {
               position: absolute;
               left: 50%;
               top: 50%;
               transform: translate(-50%, -50%);
          }
     }
}

.ticket-reply-popup {
     .icon-close-popup {
          top: 20px;
          right: 20px;
     }

     .modal-body {
          padding: 0;
     }

     .card {
          margin-bottom: 0;
     }

     .card-body {
          max-height: calc(80vh - 60px);
          overflow-y: auto;

          &::-webkit-scrollbar {
               width: 8px;
               background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
               background-color: $gray-300;
               border-top-left-radius: 3px;
               border-top-right-radius: 3px;
               border-bottom-left-radius: 3px;
               border-bottom-right-radius: 3px;
               border-left: 2px solid #fff;
               border-right: 2px solid #fff;
          }
     }
}

@media (min-width: 576px) {
     .attachments-popup .modal-lg {
          max-width: 90%;
     }
}

@media screen and (max-width: 991px) {
     .attachments-popup {
          .modal-dialog {
               max-width: 90%;
          }

          .slider-box {
               min-height: 400px;
          }
     }
}

@media (max-width: 575px) {
     .attachments {
          flex-direction: column;

          .attac {
               margin-top: 20px;
          }

          .attachment-media {
               margin-left: 0 !important;
          }

          .attachment-file {
               ul {
                    margin-left: 0;
               }
          }
     }
}
