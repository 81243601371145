.masterclass {
    h1 {
        + p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #2f353a;
            margin-bottom: 28px;
        }
    }
    .btn.btn-dark{
        color: #fff;
        &:hover{
            color: #fff;
        }
    }
    .dont-have-access {
        display: grid;
        place-items: center;
        max-width: 950px;
        padding-top: 45px;
        padding-bottom: 100px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        z-index: 1;
        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 50%;
            width: 85%;
            height: 100%;
            background-image: url(/assets/icons/master-class/bg-shapes.svg);
            background-repeat: no-repeat;
            background-position: top right;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: "Poppins";
            font-weight: 700;
            background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
        }
        h1 {
            text-align: center;
            font-size: 36px;
            letter-spacing: -1px;
            margin-bottom: 0.7em;
            line-height: 1.3;
        }
        h3 {
            font-size: 35px;
            line-height: 1.3em;
            margin-bottom: 40px;
        }
        h4 {
            font-size: 26px;
            letter-spacing: -1px;
            margin-bottom: 2px;
        }
        h6 {
            font-size: 18px;
            margin-bottom: 25px;
        }
        .content {
            text-align: center;
            max-width: 753px;
            margin-bottom: 20px;
        }
        .content-below {
            text-align: center;
            max-width: 820px;
            margin-bottom: 17px;
            p {
                margin: 0;
            }
        }
        p {
            color: #2f353a;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 19px;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        .highlight {
            display: inline-block;
            position: relative;
            z-index: 1;
            &::after {
                content: "";
                position: absolute;
                z-index: -1;
                display: block;
                width: 95px;
                height: 9px;
                background-color: #ffc107;
                left: -2px;
                bottom: 5px;
            }
        }
        button {
            border-radius: 4.5rem;
            padding: 25px 30px 22px;
            margin-bottom: 50px;
            text-transform: inherit;
            min-width: 525px;
            &.btn-500 {
                min-width: 500px;
            }
            p {
                color: #fff;
            }
            .main-text {
                font-size: 21px;
                font-weight: 500;
                margin-bottom: 4px;
            }
            .sub-text {
                font-size: 14px;
                letter-spacing: 0.1px;
                margin-bottom: 0;
            }
        }
        .highlighted-text {
            color: #20a8d8;
            font-family: "Poppins";
            font-weight: 500;
            font-size: 22px;
            line-height: 1.5em;
            letter-spacing: 0;
            max-width: 720px;
            margin-bottom: 45px;
        }
        .activity-list {
            width: 100%;
            max-width: 100%;
            margin-left: 90px;
            .mobile {
                display: none;
            }
            .step-arrow {
                position: absolute;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .step-row {
                display: flex;
                justify-content: space-between;
                position: relative;
                &:not(:first-of-type) {
                    margin-top: 90px;
                    margin-bottom: 48px;
                }
                > .step-arrow {
                    width: 556px;
                    bottom: -80px;
                    left: 43px;
                }
            }
            .step {
                width: 430px;
                position: relative;
                &:nth-child(2n + 1) {
                    .step-content {
                        max-width: 430px;
                        padding-right: 30px;
                    }
                    .step-arrow {
                        height: 30px;
                        top: 30px;
                        right: -92px;
                    }
                }
                &:nth-child(2n + 2) {
                    .step-content {
                        max-width: 400px;
                        padding-left: 30px;
                    }
                }
                &.step-1 {
                    .step-arrow {
                        width: 120px;
                    }
                }
                &.step-3 {
                    .step-arrow {
                        width: 170px;
                    }
                }
            }
            .step-content {
                display: flex;
                align-items: center;
                text-align: left;
                .icon {
                    border-radius: 12px;
                    border: solid 1px #d9e1e7;
                    background: #ffffff;
                    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 85px;
                    width: 85px;
                    height: 85px;
                    margin-right: 27px;
                    &.complete {
                        background-color: #c9ebd5;
                    }
                }
                p {
                    color: #18222b;
                    font-family: "Poppins";
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                    letter-spacing: -0.08516908px;
                    margin-bottom: 0;
                }
            }
        }
        .box-charge {
            margin-top: 36px;
            color: #18222b;
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: -0.07570585px;
            width: 525px;
            .card {
                border: solid 1px #f0f3f5;
                border-radius: 12px;
                background-color: #f8f9fa;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
                margin-bottom: 25px;
            }
            .card-body {
                padding: 33px 33px 23px 33px;
                text-align: left;
            }
            ul {
                margin: 0;
                padding: 0;
            }
            li {
                list-style: none;
                padding: 5px 0;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .total {
                font-family: "Poppins";
                font-size: 20px;
                font-weight: 600;
                line-height: 17px;
                text-align: right;
                letter-spacing: -0.07570585px;
                padding-top: 18px;
                margin-top: 18px;
                margin-bottom: 15px;
                border-top: solid 0.5px #c8ced3;
            }
            .highlighted-text {
                color: #20a8d8;
                font-family: "Poppins";
                font-size: 20px;
                font-weight: 600;
                line-height: 17px;
                text-align: right;
                letter-spacing: -0.09463231px;
                margin-bottom: 0;
            }
        }
        .masterclass-special-price {
            margin-top: 48px;
            padding: 0;
            h4 {
                font-size: 30px;
                letter-spacing: -0.3px;
                margin-bottom: 3px;
            }
        }
    }
    .have-access {
        min-height: 100vh;
        padding-bottom: 100px;
        .course-content {
            border-radius: 15px;
            border: solid 1px #f6f6f6 !important;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
            .card-body {
                padding: 20px 23px 63px;
            }
            .section-wrapper {
                position: relative;
                border: 1px solid rgba(200, 206, 211, 0.35);
                border-radius: 15px;
                // overflow: hidden;
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
                .block-tooltip {
                    .block-tooltip-text {
                         border-top-left-radius: 12px;
                    }
               }
            }
            .section-toggle {
                background-color: #f0f3f5;
                width: 100%;
                border: none;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                display: flex;
                justify-content: space-between;
                padding: 12px 14px 8px 19px;
                span:not(.badge) {
                    color: #18222b;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.2666667px;
                    text-transform: capitalize;
                }
                .badge {
                    padding: 4.5px 4px 4px;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 1;
                    text-align: center;
                    letter-spacing: 0.1428571px;
                    color: #fff;
                    border-radius: 9.5px;
                    background: #c8ced3;
                    min-width: 82px;
                    text-transform: uppercase;
                    &.badge-success {
                        background-color: #4dbd74;
                    }
                }
            }
            .section-tooltip {
                bottom: 28px;
                left: 40px;
            }
            .lesson {
                position: relative;
                color: #000;
                text-decoration: none;
                .wrapper {
                    padding: 20px;
                }
                &:nth-child(even) {
                    .wrapper {
                        background-color: #f8f9fa;
                    }
                }
                .lesson-tooltip {
                    bottom: 42px;
                    left: 40px;
                }
            }
            h5 {
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                margin-bottom: 12px;
            }
            .section-name {
                text-align: left;
                padding-right: 20px;
            }
            .section-name,
            .lesson-name {
                display: flex;
                .new-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 27px;
                    .plus-icon {
                        padding-bottom: 3px;
                        margin-right: 7px;
                    }
                    .content-text {
                        color: #212c36;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.17142856px;
                        padding-bottom: 3px;
                    }
                }
            }
        }
        .coupons {
            .coupon-card {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px;
                border: solid 1px #f0f3f5 !important;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
                p {
                    margin-bottom: 0;
                    color: #18222b;
                }
                svg {
                    &.simple-copy-button {
                        width: 28px;
                        height: 28px;
                    }
                }
                label {
                    color: #73818f !important;
                }
            }
        }
    }
    .master-class-below {
        .card {
            border-radius: 15px;
            text-align: center;
            height: 100%;
            border-radius: 15px;
            border: solid 1px #f6f6f6 !important;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
            p.content {
                min-height: 70px;
                color: #121920;
            }
            a {
                font-size: 13px;
                font-weight: 700;
                color: #7f8c9a;
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
                text-decoration: none;
            }
        }
        .card-body {
            .icon {
                height: 62px;
                margin-top: 20px;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    max-width: 193px;
                    max-height: 100%;
                }
            }
            h5 {
                font-family: Poppins;
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                text-align: center;
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
            }
            .content {
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
                text-align: center;
            }
            .icon{
                width: auto;
            }
        }
        .has-coupons {
            .headline {
                display: flex;
                align-items: left;
                justify-content: flex-start;
                margin-bottom: 7px;
                .icon {
                    height: 30px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                h5 {
                    padding-top: 2px;
                    padding-left: 15px;
                    margin-left: 0;
                }
            }
            p.content {
                min-height: inherit;
                margin-bottom: 20px;
                text-align: left;
            }
            .list-coupon-cards {
                margin-left: -5px;
                margin-right: -5px;
                .coupon-col {
                    padding: 0 5px 10px;
                }
            }
        }
    }

    .coupon-card {
        &.card {
            border-radius: 5px;
            text-align: left;
            padding: 0 15px;
            height: 50px;
            margin: 0;
        }
        .code {
            line-height: 0;
        }
        label {
            font-size: 10px;
            font-weight: 300;
            line-height: 1.1em;
            margin-bottom: 0.3em;
        }
        .redeem-code {
            font-size: 13px;
            font-weight: 500;
            line-height: 1.1em;
        }
        p {
            &.disabled {
                color: #c8ced3 !important;
            }
        }
        svg.simple-copy-button {
            path {
                fill: #000 !important;
            }
        }
    }
}

.masterclass-special-price {
    border-radius: 10px;
    background-color: #f8f9fa;
    padding: 25px;
    text-align: center;
    margin-bottom: 20px;
    h4 {
        font-family: "Poppins";
        font-size: 22px;
        font-weight: 700;
        line-height: 1.3em;
        margin-bottom: 10px;
        background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: block;
    }
    .save {
        font-weight: 700;
        color: #4dbd74;
    }
    p {
        font-family: "Roboto";
        &:last-child {
            margin-bottom: 0;
        }
    }
    .time-end {
        display: block;
    }
}

.time-countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .time-block {
        min-width: 95px;
        border: solid 1px #f1f1f1;
        border-radius: 12px;
        background: #ffffff;
        box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.07);
        display: flex;
        font-family: "Poppins";
        font-weight: 500;
        color: #f86c6b;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: 0 5px;
    }
    .time {
        font-size: 27px;
        line-height: 1em;
        letter-spacing: -0.1277536px;
        margin-right: 8px;
    }
    .label {
        font-size: 12px;
        line-height: 1em;
        letter-spacing: -0.05677939px;
    }
    &.sidebar-block {
        margin-top: 10px;
        margin-bottom: 5px;
        .time-block {
            min-width: 90px;
            padding: 7px;
        }
    }
}

.notification-certificate {
    padding: 12px 24px 12px 17px;
    background-color: #f0f3f5;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    p {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 27px;
        color: #18222b;
        margin: 0 20px 0 14px;
        strong {
            font-weight: 600;
        }
    }
    span {
        color: #121920;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
    .link-certificate {
        display: flex;
        align-items: center;
        a {
            color: #121920;
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            text-align: right;
            text-transform: uppercase;
            letter-spacing: 0.1857143px;
            text-decoration: none;
        }
    }
    &.course-completed {
        border: solid 1px #f0f3f5;
        background-color: #c9ebd5;
    }
    &.no-link {
        padding-top: 15px;
        padding-bottom: 17px;
        .certified-content {
            padding-left: 15px;
            p {
                margin-left: 0;
            }
        }
    }
}

.set-end-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
    .react-datepicker-wrapper,
    .select-time {
        flex: 1;
        margin-left: 10px;
        margin-right: 10px;
    }
    .react-datepicker-wrapper {
        max-width: 200px;
    }
    .select-time {
        max-width: 100px;
    }
}

.masterclass-not-completed {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 100px;
    h1 {
        margin-bottom: 10px;
    }
    h2 {
        font-weight: 500;
    }
    h3 {
        font-family: "Poppins";
        display: block;
        color: #212c36;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: -0.2px;
        max-width: 800px;
        margin: 0 auto 7px;
        &.gradient {
            font-weight: 700;
            background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    h4 {
        color: #212c36;
        font-family: "Poppins";
        font-size: 22px;
        font-weight: 500;
        line-height: 1.4em;
        text-align: center;
        letter-spacing: -0.1040955px;
        margin: 0 auto 30px;
    }
    > p:not(.master-class-footnote) {
        color: #2f353a;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.1px;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        &.master-class-footnote {
            max-width: inherit;
        }
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .box-learn-masterclass {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        background-color: #121920;
        display: inline-block;
        min-width: 500px;
        max-width: 550px;
        color: #fff;
        border-radius: 4.5rem;
        padding: 23px 40px;
        margin-top: 30px;
        margin-bottom: 30px;
        a {
            text-decoration: none;
            color: currentColor;
        }
        .main-text {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 0.2857143px;
            margin-bottom: 6px;
        }
        .sub-text {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            margin: 0;
        }
    }
    .customer-comments {
        max-width: 830px;
        margin: 0 auto;
        padding-left: 35px;
        margin-top: 30px;
        .comment {
            display: flex;
            align-items: flex-start;
            color: #18222b;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 24px;
        }
        .content {
            position: relative;
            padding-top: 35px;
            p:not(.author) {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                color: inherit;
                margin-bottom: 0.5em;
            }
            &:before {
                content: "";
                background-image: url(/assets/icons/master-class/quote.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
                width: 31px;
                height: 26px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .avatar {
            width: 90px;
            height: 90px;
            min-width: 90px;
            margin-top: 30px;
            margin-right: 30px;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .text-right {
            .avatar {
                margin-left: 30px;
                margin-right: 0;
            }
        }
        .author {
            color: #20a8d8;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            margin-bottom: 0;
        }
    }
}

.partner-not-available {
    padding-top: 38px;
    h4 {
        max-width: 800px;
    }
}

.certification-not-earned {
    h3.gradient {
        font-size: 25px;
    }
}

.office-hours-not-available {
    position: relative;
    min-height: calc(100vh - 137px);
    padding-bottom: 100px;
    h1 {
        margin-bottom: 7px;
        min-width: inherit;
    }
    h3 {
        line-height: 1.5;
        margin-bottom: 15px;
    }
    > p {
        line-height: 23px;
    }
    .box-learn-masterclass {
        margin-top: 15px;
    }
    .master-class-footnote {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        max-width: inherit;
        transform: translateX(-50%);
        margin-bottom: 29px;
    }
}

.certificate-earned {
    padding-bottom: 100px;
    h1 {
        margin-bottom: 5px;
        + p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #2f353a;
            margin-bottom: 28px;
        }
    }
    .notification-certificate {
        margin-bottom: 35px;
    }
    .next-steps {
        h5 {
            font-family: "Poppins";
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            margin-bottom: 15px;
        }
        .row {
            margin-left: -10px;
            margin-right: -10px;
            > div {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .card {
        border: solid 1px #f0f3f5 !important;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
    }
    .display-certifications {
        height: 100%;
        border-radius: 12px;
        margin-bottom: 0;
        .card-body {
            padding: 27px 30px 33px;
        }
        h5 {
            margin-bottom: 32px;
        }
        h6 {
            color: #212c36;
            font-size: 16px;
            font-weight: 500;
        }
        .share-socials {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #212c36;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            h6 {
                margin: 0;
            }
            .list-socials {
                display: flex;
                button {
                    border: none;
                    padding: 0;
                    background: none;
                    &:not(:last-child) {
                        margin-right: 11px;
                    }
                }
            }
        }
        .display-on-website {
            color: #121920;
            font-size: 13px;
            font-weight: 400;
            line-height: 19px;
            p,
            span {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }
            .copy-code {
                padding: 19px 0;
                margin: 23px 0;
                border-top: 1px solid #d8d8d8;
                border-bottom: 1px solid #d8d8d8;
                display: flex;
                justify-content: space-between;
                align-items: center;
                label {
                    margin-bottom: 2px;
                }
                p {
                    margin-bottom: 0;
                }
                .description {
                    margin-right: 10px;
                }
            }
            .copy {
                display: flex;
                align-items: center;
                margin-right: -10px;
                span {
                    margin-right: 4px;
                }
                svg {
                    width: 35px;
                    height: 35px;
                }
            }
            svg.simple-copy-button {
                path {
                    fill: #000 !important;
                }
            }
            .download {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h6 {
                    margin: 0 10px 0 0;
                }
                .btn {
                    padding: 12px 15px;
                    border-radius: 30px;
                }
            }
        }
    }
    .apply-partner {
        height: 100%;
        border-radius: 12px;
        text-align: center;
        margin-bottom: 0;
        .card-body {
            padding: 70px 20px 20px;
        }
        .icon {
            margin-bottom: 47px;
            width: auto;
            height: auto;
        }
        .content {
            max-width: 420px;
            margin: 0 auto 25px;
        }
        h5 {
            margin-bottom: 10px;
        }
        p {
            color: #121920;
            font-size: 13px;
            font-weight: 400;
            line-height: 19px;
        }
        a {
            color: #7f8c9a;
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 0.1857143px;
        }
    }
}

.course-content {
    .lesson {
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .lesson-name {
                flex: 0 0 55%;
                max-width: 55%;
                color: #212c36;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.2666667px;
            }
            .lesson-contain {
                flex: 0 0 25%;
                max-width: 25%;
                color: #18222b;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                padding-left: 8px;
                span {
                    font-style: italic;
                    font-size: 12px;
                }
            }
            .lesson-status {
                flex: 0 0 20%;
                max-width: 20%;
                text-align: right;
                padding-right: 21px;
                svg {
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }
}

.course-lesson {
    color: #2f353a;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding-bottom: 100px;
    .intro-hero {
        position: relative;
        margin-bottom: 25px;
        h1 {
            margin-bottom: 6px;
            + p {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }
        }
    }

    .description {
        margin-bottom: 25px;
    }
    p {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
    .lesson-title {
        background-color: #f0f3f5;
        padding: 16px 25px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        cursor: pointer;
        h5 {
            font-family: "Poppins";
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            color: #000;
            margin: 0;
            padding-right: 50px;
        }
        .icon {
            opacity: 1;
            width: 10px;
            color: #7f8c9a;
            transition: all 0.5s ease;
            &.open {
                transform: rotate(180deg);
            }
        }
    }
    .lesson-question {
        background-color: #ffffff;
        border: solid 1px rgba(200, 206, 211, 0.35);
        border-radius: 12px;
        overflow: hidden;
        color: #2f353a;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 15px;
        .content-wrap {
            padding: 35px 25px;
            border-top: solid 1px rgba(200, 206, 211, 0.35);
            &.video-wrap {
                padding: 25px;
            }
            p {
                span {
                    color: #20a8d8;
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover {
                        color: #1b8eb7;
                        text-decoration: none;
                    }
                }
            }
        }
        .form-group {
            padding-top: 5px;
            padding-bottom: 15px;
        }
        input[type="text"] {
            font-size: 16px;
        }
        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            background-color: #4dbd74;
        }
        .w-chrome {
            border-radius: 12px;
        }
        p {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
        .quiz-notice {
            margin-bottom: 3px;
        }
        ul {
            list-style-type: none;
            padding-left: 0;
        }
        button {
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.1857143px;
            padding: 15px 20px;
            margin-right: 10px;
            &.btn-pill:disabled {
                background-color: #d8d8d8;
                opacity: 1;
            }
            &.btn-step {
                color: #fff;
                background-color: #4dbd74;
                border-radius: 25px;
                &:not(:disabled):hover {
                    background-color: #3d915a;
                }
            }
        }
        .btn-pill {
            margin-top: 10px;
            padding: 14px 21px;
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.1857143px;
        }
        .custom-control {
            margin-bottom: 10px;
        }
        .custom-checkbox,
        .custom-radio {
            * {
                cursor: pointer;
            }
        }
        .custom-control-label {
            padding-left: 10px;
            &::before {
                width: 22px;
                height: 22px;
                top: 1px;
                border-radius: 50%;
                background-color: #c8ced3;
                border: none;
            }
            &:after {
                top: 1px;
                width: 22px;
                height: 22px;
            }
        }
        .custom-control-input {
            &:checked ~ .custom-control-label::before {
                background-color: #4dbd74;
            }

            &:focus ~ .custom-control-label::before {
                box-shadow: none;
            }
        }
        .result {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.2666667px;
            margin-bottom: 0;
            &.fail {
                color: #f86c6b;
            }
            &.success {
                color: #4dbd74;
            }
        }
        &.disabled {
            position: relative;
            .lesson-title {
                pointer-events: none;
                opacity: 0.4;
            }
            .video-wrap {
                pointer-events: none;
                opacity: 0.4;
            }
            .spinner-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .question-label {
            color: #18222b;
            margin-bottom: 0;
        }
    }
    .video-wrap {
        .video {
            padding-right: 5px;
            .wrapper {
                position: relative;
                height: 0;
                padding-bottom: 56.25%;
                .the-player {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                iframe {
                    border-radius: 10px;
                }
            }
        }
        .note {
            label {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: -0.1777778px;
                margin-bottom: 8px;
            }
            ol {
                padding-left: 15px;
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                li {
                    position: relative;
                    padding-left: 29px;
                    margin-bottom: 0.5em;
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0.5em;
                        left: 2px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #18222b;
                    }
                }
            }
            a {
                color: #20a8d8;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            p {
                span {
                    text-decoration: none;
                    cursor: auto;
                }
            }
        }
    }
    .badge {
        padding: 4.5px 4px 4px;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.1428571px;
        color: #fff;
        border-radius: 9.5px;
        min-width: 82px;
        text-transform: uppercase;
        margin-bottom: 6px;
        &.completed {
            background-color: #4dbd74;
        }
        &.not-started,
        &.in-progress {
            background-color: #7f8c9a;
        }
    }
    .custom-radio .custom-control-input:checked ~ .custom-control-label::after,
    .custom-control-input:not(:disabled):active ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }
}

.partner-available {
    padding-bottom: 100px;
    h1 {
        min-width: 600px;
        margin-bottom: 4px;
    }
    h2 {
        color: #212c36;
        font-family: "Poppins";
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        letter-spacing: -0.1040955px;
        margin-bottom: 18px;
    }
    h3 {
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin-bottom: 20px;
    }
    p,
    li {
        color: #2f353a;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 0.7em;
    }
    ul,
    ol {
        padding-left: 0;
        list-style-type: none;
        li {
            position: relative;
            padding-left: 24px;
            margin-bottom: 20px;
        }
    }
    ul {
        li {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 5px;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #2f353a;
            }
        }
    }
    ol {
        counter-reset: item;
        li {
            counter-increment: item;
            &:before {
                content: counter(item);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                color: #73818f;
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 600;
                line-height: 1.2;
            }
        }
    }
    .description {
        margin-bottom: 30px;
    }
    .card {
        margin-bottom: 15px;
        .card-body {
            padding: 20px 24px 12px;
        }
    }
    .gray-card {
        border: solid 1px #f0f3f5 !important;
        border-radius: 12px;
        background-color: #f8f9fa;
    }
    .white-card {
        border: solid 1px #f0f3f5 !important;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.06) !important;
        .card-body {
            padding-bottom: 40px;
        }
    }
    .ready-to-apply {
        h3 {
            margin-bottom: 6px;
        }
        .steps-container {
            margin-top: 30px;
            font-size: 13px;
            font-weight: 400;
            line-height: 23px;
            span {
                color: #7f8c9a;
            }
            .btn {
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 0.1857143px;
                border-radius: 22.5px;
                padding: 14px 24px;
                margin-right: 24px;
                &.btn-success {
                    background-color: #4dbd74;
                    &:hover {
                        background-color: #3d915a;
                    }
                }
                &.next {
                    color: #fff;
                    background-color: #20a8d8;
                    padding: 10px 20px;
                    margin-left: 20px;
                    &:hover {
                        background-color: #1b8eb7;
                    }
                }
                &.back {
                    padding: 0;
                }
            }
            .list-accounts {
                padding: 20px 15px;
                min-height: 80px;
                .select-account {
                    // border-radius: 0;
                    padding: 10px;
                    margin-right: 15px;
                    // margin-bottom: 10px;
                    border: 1px solid #1985ac !important;
                }
            }
        }
        .step {
            .next {
                span {
                    color: #fff !important;
                }
            }
        }
        .final-step {
            display: flex;
            align-items: center;
            svg {
                width: 48px;
                height: 48px;
            }
            .content {
                padding-left: 17px;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .begin-application {
            display: flex;
            align-items: center;
            .note {
                margin-top: 4px;
                margin-left: 3px;
            }
        }
    }
    .no-shadow {
        box-shadow: none !important;
    }
}

.office-hours-available {
    position: relative;
    min-height: calc(100vh - 137px);
    padding-bottom: 100px;
    h1 {
        margin-bottom: 5px;
        min-width: inherit;
        + p {
            color: #2f353a;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 20px;
        }
    }
    .total-credits {
        display: flex;
        border: solid 1px #f0f3f5;
        border-radius: 12px;
        color: #121920;
        background-color: #f8f9fa;
        justify-content: space-between;
        align-items: center;
        padding: 23px 30px 30px 28px;
        margin-bottom: 15px;
        .credit-details {
            display: flex;
            svg {
                width: 31px;
                height: 31px;
                margin-top: 10px;
            }
            .content {
                padding-left: 15px;
            }
            h3 {
                color: inherit;
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                margin-bottom: 0;
                strong {
                    font-weight: 700;
                }
            }
            p {
                color: #121920;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                margin-bottom: 0;
            }
        }
        .buy-more {
            color: inherit;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            display: flex;
            align-items: center;
            span {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                margin-top: 3px;
            }
            .btn {
                color: inherit;
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 0.1857143px;
                padding: 14px 25px;
                margin-right: 20px;
                border: solid 1px #121920 !important;
                border-radius: 22px;
                &:hover {
                    color: #fff;
                    background-color: #121920;
                }
            }
        }
    }
    .master-class-footnote {
        text-align: left;
        position: absolute;
        bottom: 0;
    }
    .schedule-office-hours {
        h3 {
            color: #000;
        }
    }
}

.schedule-office-hours {
    border-radius: 12px;
    color: #2f353a;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    border: solid 1px #f0f3f5 !important;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
    h3 {
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        + p {
            margin-bottom: 20px;
        }
    }
    p {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
    label {
        color: inherit;
        font-size: inherit;
        font-weight: 500;
        line-height: inherit;
        margin-bottom: 8px;
    }
    .action-convert {
        display: flex;
        align-items: flex-start;
        .btn {
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.1857143px;
            padding: 15px 26px;
            border-radius: 25px;
        }
        .text {
            padding-left: 20px;
            padding-top: 3px;
            p {
                color: #121920;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
                margin-bottom: 0;
            }
        }
    }
    .hour-cards {
        padding-top: 17px;
        margin-top: 31px;
        border-top: solid 1px #d8d8d8;
    }
    .card-body {
        padding: 21px 24px 25px;
    }
}

.hour-cards {
    > label {
        margin-bottom: 11px;
    }
    .coupon-card {
        border-radius: 5px;
        text-align: left;
        padding-left: 15px;
        padding-right: 7px;
        width: 173px;
        height: 50px;
        margin-right: 11px;
        margin-bottom: 11px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        label {
            font-size: 10px;
            font-weight: 400;
            line-height: 1em;
            text-transform: uppercase;
            letter-spacing: 0.1428571px;
            margin-bottom: 1px;
        }
        .code {
            line-height: 0;
        }
        .redeem-code {
            color: #121920;
            font-size: 13px;
            font-weight: 500;
            line-height: 1em;
            text-transform: uppercase;
            letter-spacing: 0.1857143px;
            margin-bottom: 0;
        }
        .disabled {
            color: #c8ced3;
        }
        svg.simple-copy-button {
            path {
                fill: #000 !important;
            }
        }
    }
    .hour-card {
        position: relative;
        font-family: "Roboto";
        height: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        .card-body {
            padding: 25px 20px;
            border: 1px dashed #4dbd74;
            display: flex;
            flex-direction: column;
        }
        .icon {
            text-align: right;
            margin-bottom: -7px;
            color: #768192;
            width: auto;
        }
        h4 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        p {
            font-size: 15px;
            color: #768192;
            margin-bottom: 10px;
        }
        button {
            margin-top: 8px;
            display: block;
            width: 100%;
        }
        .card-bottom {
            margin-top: auto;
        }
    }
}

.master-class-footnote {
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    color: #7f8c9a;
    text-align: center;
}

.master-class-confirm-popup {
    .confirm-save {
        h2 {
            margin-bottom: 10px;
            + p {
                margin-bottom: 20px;
            }
        }
        &.schedule-detail {
            padding: 0 65px 60px !important;
            .btn {
                width: 100%;
            }
        }
        .date-container {
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                position: relative;
            }
            .hour-btn-container {
                display: flex;
                margin-bottom: 10px;
                &.active {
                    .hour-btn {
                        margin-right: 5px;
                    }
                    .btn {
                        min-width: auto;
                    }
                }
            }
            .left-col {
                text-align: start;
            }
        }
        .loading-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.is-a-partner {
    .custom-file-label {
        left: 15px;
        right: 15px;
    }
    h6 {
        margin-bottom: 18px;
    }
    .logo-upload {
        padding: 5px 0;
        margin-left: 20px;
        img {
            max-height: 135px;
        }
    }
    button[type="submit"] {
        min-width: 180px;
        margin-top: 15px;
    }

    .custom-checkbox .custom-control-label {
        line-height: 25px;
    }
}

.course-lesson {
    .video-wrap {
        .lesson-box {
            display: block;
            .video {
                width: 100%;
                max-width: 784px;
            }
            .description {
                width: 100%;
                padding: 25px 0;
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .course-lesson {
        .video-wrap {
            .lesson-box {
                display: flex;
                .video {
                    width: 70%;
                    max-width: 1400px;
                }
                .description {
                    width: 30%;
                    padding: 0 20px;
                }
            }
        }
    }
    .masterclass .master-class-below .has-coupons .list-coupon-cards {
        display: flex;
        flex-wrap: wrap;
        .coupon-col {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

@media screen and (max-width: 1400px) {
    .office-hours-available .total-credits .buy-more {
        text-align: center;
        min-width: 25%;
        span {
            display: block;
        }
        button {
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: 1199px) {
    .masterclass {
        .dont-have-access {
            h1 {
                font-size: 35px;
            }
            h4 {
                font-size: 26px;
            }
            p {
                font-size: 16px;
            }
            button {
                padding: 15px;
            }
            .highlighted-text {
                font-size: 20px;
            }
            .activity-list {
                .step-row {
                    flex-direction: column;
                    align-items: center;
                    > .step-arrow {
                        display: none;
                    }
                    &:not(:first-of-type) {
                        margin-top: 0;
                    }
                }
                .step {
                    margin-bottom: 30px;
                    &:nth-child(2n + 2) {
                        .step-content {
                            padding-left: 0;
                        }
                    }
                }
                .desktop {
                    display: none;
                }
                .mobile {
                    display: block;
                }
            }
        }
    }
    .course-lesson .video-wrap .note {
        padding: 25px 0;
    }
}

@media screen and (max-width: 767px) {
    .masterclass {
        .dont-have-access {
            max-width: 100%;
            h1 {
                font-size: 35px;
            }
            button {
                padding: 10px 20px;
            }
        }
    }
}

@media screen and (width: 768px) {
    .certificate-earned {
        .display-certifications {
            .display-on-website {
                .copy {
                    flex-direction: column;
                    margin-right: 0;
                    span {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
