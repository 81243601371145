.admin {
     .sidebar:not(.sidebar-v2) {
          &.sidebar-light {
               .sidebar-nav .sidebar-nav-link {
                    &.c-active:hover {
                         background-color: $primary;
                    }
               }
          }

          .sidebar-nav {
               overflow: -moz-scrollbars-none;
               -ms-overflow-style: none;
               scrollbar-width: none;
               // &::-webkit-scrollbar {
               //      width: 6px;
               // }
               // &::-webkit-scrollbar-track {
               //      background: transparent;
               // }
               // &::-webkit-scrollbar-thumb {
               //      background: #999;
               //      border-radius: 6px;
               // }
               .sidebar-nav-link.c-active {
                    &:hover {
                         background-color: #0a0f13;
                    }
               }

               &-dropdown {
                    &-toggle {
                         display: flex;
                         flex: 1 1;
                         align-items: center;
                         text-decoration: none;
                         transition:
                              background 0.3s,
                              color 0.3s;
                         border-bottom: 1px solid #121920;
                         gap: 15px;

                         &:hover {
                              background-color: $primary;
                         }

                         &:after {
                              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
                              margin-right: 5px;
                              transform: rotate(0deg);
                              margin-left: auto;
                              display: block;
                              flex: 0 1 8px;
                              height: 8px;
                              content: '';
                              background-repeat: no-repeat;
                              background-position: 50%;
                              transition: transform 0.3s;
                         }
                    }

                    &-items {
                         max-height: 0;
                         padding: 0;
                         overflow-y: hidden;
                         list-style: none;
                         transition: max-height 0.3s ease-in-out;
                    }

                    &.c-show .sidebar-nav-dropdown-toggle:after {
                         transform: rotate(-90deg);
                    }

                    &.c-show > .sidebar-nav-dropdown-items {
                         max-height: 1500px;
                    }
               }
          }

          &.sidebar-lg:not(.sidebar-right).sidebar-fixed {
               margin-left: -256px;

               ~ .c-wrapper {
                    margin-left: 0;
               }
          }

          &.sidebar-lg-show:not(.sidebar-right).sidebar-fixed {
               ~ .c-wrapper {
                    margin-left: 200px;
               }
          }

          &.sidebar-show:not(.sidebar-right).sidebar-fixed {
               margin-left: 0;
          }
     }

     .c-wrapper {
          transition: margin 0.3s;

          .header .header-toggler-icon {
               background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

               &:hover {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.7)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
               }
          }
     }
}

@media screen and (min-width: 1801px) {
     .admin {
          .sidebar:not(.sidebar-v2) {
               &.sidebar-lg-show:not(.sidebar-right).sidebar-fixed {
                    ~ .c-wrapper {
                         margin-left: 250px;
                    }
               }
          }
     }
}

@media screen and (min-width: 992px) {
     .admin {
          .sidebar:not(.sidebar-v2) {
               &.sidebar-lg-show:not(.sidebar-right).sidebar-fixed {
                    margin-left: 0;
               }
          }
     }
}
