.skip-form-categories {
     border-radius: 12px;
     box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
     border: 1px solid #f0f3f5 !important;

     .card-body {
          padding: 16px 40px 18px !important;
     }

     p {
          font-size: 13px;
          line-height: 18px;
          color: #73818f;
     }

     .btn-link-skip {
          padding: 0;
          color: #7f8c9a;
          font-size: 13px;
          font-weight: 400;
          text-transform: capitalize;
          text-decoration: underline;
          &:hover {
               text-decoration: none;
          }
     }
}
