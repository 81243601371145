.icon:not(.icon-c-s):not(.icon-custom-size) {
     &.icon-delete {
          height: 30px;
          width: 30px;
          cursor: pointer;
     }

     &.icon-add {
          width: 21px;
          height: 21px;
          cursor: pointer;
     }
}

.list-icon-add-remove {
     height: 37px;
     display: flex;
     justify-content: left;
     align-items: center;
}

.field-insert-variable-label {
     min-height: 16px;
     min-width: 90px;
     background: #73818f;
     box-shadow: 0px 16px 20px -4px rgba(0, 0, 0, 0.1);
     box-shadow: 0px 8px 8px -3px rgba(0, 0, 0, 0.04);
     color: #ffffff !important;
     font-family: Roboto;
     font-size: 9px;
     font-weight: 500;
     line-height: 16px;
     text-align: center;
     text-transform: uppercase;
     letter-spacing: 0.15px;
     position: absolute;
     right: 36px;
     margin: 0;
     border-radius: 4px;
     transform: translateY(-50%);
     top: 50%;
}

.input-icons {
     .icon-insert-variable-arrow {
          position: absolute;
          right: 33px;
          transform: translateY(-50%);
          top: 50%;
     }

     .insert-variable-dropdown {
          ul {
               li {
                    &.active-variable {
                         background-color: #f3f5f6;

                         span {
                              color: #20a8d8 !important;
                         }
                    }
               }
          }
     }
}

.custom-script {
     .tooltipInsertShortCode {
          z-index: 1000;
          position: absolute;
          top: 20px !important;
          right: 100%;
     }

     .field-insert-variable-label {
          right: calc(100% + 29px);
          top: 45%;
     }

     .input-icons {
          .icon-insert-variable-arrow {
               right: calc(100% + 26px);
               top: 45%;
          }

          .insert-variable-dropdown {
               li {
                    strong {
                         margin-right: 0;
                    }
               }
          }
     }
}

.insert-variable-field {
     position: relative;

     .field-insert-variable-label {
          right: calc(100% + -2px);
     }

     .icon-insert-variable-arrow {
          position: absolute;
          right: calc(100% + -5px);
          transform: translateY(-50%);
          top: 50%;
     }
}

.cvr-create-new {
     .browser-step4-form {
          &.insert-variable-field {
               .insert-variable-dropdown {
                    margin-top: -1px;
                    list-style: none;
                    position: absolute;
                    padding: 0px;
                    z-index: 99;
                    border: 1px solid #e4e7ea !important;
                    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
                    max-height: 280px;
                    background-color: #fff;
                    width: 100%;
                    border-radius: 12px;
                    overflow: hidden;
                    margin-bottom: 1rem;
                    min-width: 280px;
                    right: 0;
               }

               .button-insert-variable {
                    height: 25px;
               }

               ul {
                    padding: 0;
                    overflow-x: hidden;
                    position: initial;
                    width: 100%;
                    border: none !important;
                    margin-bottom: 0;
                    border-radius: 12px;
                    box-shadow: none !important;
                    margin-top: 0;

                    &.have-scroll {
                         width: calc(100% - 5px);
                         li {
                              padding-right: 9px;
                         }
                    }

                    &::-webkit-scrollbar {
                         width: 3px;
                    }

                    &::-webkit-scrollbar-track {
                         width: 3px;
                         background-color: #e2e6e9;
                         border-radius: 2px;
                         margin-top: 8px;
                         margin-bottom: 8px;
                    }

                    &::-webkit-scrollbar-thumb {
                         background: #73818f;
                         width: 3px;
                         border-radius: 12px;
                         border: none;
                         min-height: 50px;
                    }

                    .variable-filter {
                         padding: 10px 17px 10px 17px;
                    }
               }

               li {
                    padding: 5px 17px 5px 17px;
                    min-height: 38px;
                    word-break: break-all;
                    cursor: pointer;

                    &.variable-filter {
                         &:hover {
                              background: #ffffff;
                         }
                    }

                    &:hover {
                         background: #f3f5f6;

                         span {
                              color: #20a8d8 !important;
                         }
                    }

                    &.active-variable {
                         background-color: #f3f5f6;

                         span {
                              color: #20a8d8 !important;
                         }
                    }
               }

               .type-variable {
                    svg {
                         &.icon:not(.icon-c-s):not(.icon-custom-size) {
                              height: 8px;
                              width: 8px;
                         }
                    }

                    strong {
                         margin-right: 0;
                    }
               }

               strong {
                    margin-right: 0;
               }

               .variable-filter {
                    background-color: #ffffff;
               }
          }
     }
}

.rule-detail .cvr-step-card {
     .field-insert-variable {
          &-label {
               label {
                    color: #ffffff !important;
                    font-family: Roboto;
                    font-size: 9px;
                    font-weight: 500;
                    line-height: 11px;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 0.15px;
                    position: absolute;
                    right: 15px;
                    margin: 0;
               }
          }
     }

     .custom-script {
          .tooltipInsertShortCode {
               top: 0 !important;
          }
          .field-insert-variable-label,
          .icon-insert-variable-arrow {
               transform: none;
          }
     }
}

.button-insert-variable {
     height: 37px;
     display: flex;
     align-items: center;
     cursor: pointer;
     z-index: 1;

     label {
          text-transform: initial;
          margin-left: 5px;
          margin-top: 4px !important;
          margin-bottom: 1px !important;
          cursor: pointer;

          &:hover {
               text-decoration: underline;
          }
     }
}

.button-insert-variable:disabled {
     label {
          &:hover {
               text-decoration: none;
          }
     }
}

.insert-variable {
     .insert-variable-child {
          background-color: #ffffff;

          ul {
               border-radius: 12px;
               border: 1px solid #e2e6e9;
          }

          .type-variable {
               padding: 10px 12px;
          }

          .variable-filter {
               padding: 10px 12px;
          }
     }
}
