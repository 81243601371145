p.variables-used {
     color: #708393;
     font-family: Roboto;
     font-size: 14px;
     font-weight: 400;
     line-height: 17px;
     text-align: right;
     letter-spacing: 0.2px;

     i {
          font-weight: 300;
     }
}

.custom-datalayer-variables {
     .accordion {
          .data-layer-variables {
               #custom-variable-form {
                    .row {
                         align-items: initial;
                    }
               }
          }
     }
}

.data-layer-variables {
     p.variables-used {
          color: #708393;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          text-align: right;
          letter-spacing: 0.2px;

          i {
               font-weight: 300;
          }
     }

     a {
          text-decoration: underline !important;
     }

     p {
          color: #18222b;
          font-family: Roboto;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 20px;
          padding-left: 5px;
     }

     #custom-variable-form {
          .form-group {
               .col-md-4 {
                    padding-top: 4px;
               }
          }

          .variable-input-group {
               .sort-code-error {
                    right: 125px !important;
               }

               .edit-error {
                    border: 1px solid !important;
                    border-left: 0 !important;
               }

               input.new-variable {
                    padding-right: 40px;
                    + .insert-variable {
                         right: 10px;
                    }
               }
               input.is-invalid {
                    + .insert-variable {
                         right: 30px;
                         bottom: -15px;
                    }
               }
               .insert-variable {
                    position: absolute;
                    top: 50%;
                    bottom: -5px;
                    right: 90px;
                    transform: translateY(-50%);
                    z-index: 5;
                    display: none;
                    cursor: pointer;
               }

               &:hover {
                    .insert-variable {
                         display: block;
                    }
               }
          }
     }

     button.d-inline.deleteicon.btn {
          margin-right: -14px;
          margin-left: -20px;
     }

     button.d-inline.addicon.btn {
          margin: 0;
          padding-left: 12px;
          padding-right: 0px;
     }

     .compare-plans {
          align-self: center;
     }

     .variable {
          align-items: center;

          label {
               margin-bottom: 0;
               margin-right: 15px;
          }
     }

     .sample-use-case {
          background-color: #d2eef7;

          p {
               // color: #115770;
               // font-weight: 400;
               // font-size: 15px;
               margin-bottom: 0;

               color: #121920;
               font-family: Roboto;
               font-size: 16px;
               font-weight: 400;
               line-height: 22px;
               opacity: 0.7;
               padding-top: 4px;
               padding-left: 8px;
          }

          h6 {
               margin-bottom: 3px;
               color: #121920;
               font-family: Poppins;
               font-size: 18px !important;
               font-weight: 600;
               padding-left: 7px;
          }

          &.card {
               box-shadow: none !important;

               .card-body {
                    padding-bottom: 18px;
                    padding-top: 20px;
               }
          }
     }

     .save-changes-button {
          padding: 0;
     }

     .form-control {
          min-height: 37px;
     }

     .group-button-add-delete {
          height: 37px;
          display: flex;
          align-items: center;

          span {
               color: #73818f !important;
               font-weight: 700;
               line-height: 16px;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;
               margin-left: 7px;
               margin-bottom: 0;
               &.spinner-border {
                    color: red !important;
               }
          }
     }
}

.browser-storage-variables {
     .save-changes-button {
          padding: 0;
     }

     .group-button-add-delete {
          height: 37px;
          display: flex;
          align-items: center;

          span {
               color: #73818f !important;
               font-weight: 700;
               line-height: 16px;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;
               margin-left: 7px;
               margin-bottom: 0;
               &.spinner-border {
                    color: red !important;
               }
          }
     }
}

//  css you have unsaved changes
//end changes
.confirm-save-popup {
     &.select-domain {
          .confirm-save {
               padding: 40px 36px 57px !important;

               .confirm-save-content {
                    margin-bottom: 22px;
               }
          }
     }

     &.end-session {
          .modal-dialog {
               max-width: 640px;
          }

          .confirm-save {
               padding: 68px 45px 57px !important;

               .confirm-save-content {
                    margin-bottom: 22px;
               }
          }

          button:not(.event-toggle) {
               font-size: 18px;
               min-width: 395px;
               margin-bottom: 22px;
               background-color: #20a8d8;

               &:hover {
                    background-color: #43b6d7;
                    border-color: #39b2d5;
               }
          }

          .btn-cancel {
               text-decoration: underline;
               font-size: 16px;
               color: #7f8c9a;
               cursor: pointer;
               display: inline-block;

               &:hover {
                    text-decoration: none;
               }
          }

          h3 {
               font-family: 'Poppins';
               font-weight: 700;
               letter-spacing: -0.5px;
               background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               margin-bottom: 20px;
          }

          p {
               font-size: 18px;
               font-weight: 400;
               letter-spacing: 0.65px;
          }
     }

     &.leave-component {
          .confirm-save {
               .btn.btn-light {
                    width: 100%;
                    margin-top: 15px;
               }

               .btn.btn-primary {
                    border-radius: 22.5px !important;
                    background: #4dbd74;
                    height: 45px;
                    color: #ffffff;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    width: 100%;
                    text-align: center;
                    text-transform: uppercase;

                    &:hover {
                         background: #369758;
                    }
               }
          }
     }

     .confirm-save {
          .btn.btn-primary {
               border-radius: 22.5px !important;
               background: #4dbd74;
               width: 156.5px;
               height: 45px;
               color: #ffffff;
               font-family: Roboto;
               font-size: 13px;
               font-weight: 700;
               line-height: 16px;
               text-align: center;
               text-transform: uppercase;
          }

          .btn-info {
               background-color: #20a8d8;
               color: #fff;

               &:hover {
                    background-color: #1b8eb7;
               }
          }

          h1 {
               font-family: 'Poppins';
               font-weight: 700;
               letter-spacing: 0.04em;
               background: linear-gradient(150deg, #29263e, #233b7d, #20a8d8);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               display: inline-block;
               letter-spacing: -0.5px;
               color: #3d475e;
               font-family: Poppins;
               font-size: 20px;
               line-height: 30px;
               text-align: center;
               min-width: 348px;
          }
     }
     .confirm-publish {
          .btn.btn-primary {
               background: #20a8d8;
               &:hover {
                    background-color: #1b8eb7;
               }
          }
     }
}

.choose-how-to-pay .modal-dialog.modal-dialog-centered form .input-card-number .logo-card img.card-active {
     filter: none;
     opacity: 1;
     top: 5px;
}

.modal-content {
     border-radius: 10px;
}

.confirm-save {
     &:before {
          // background: linear-gradient(128deg, #ffc107, #20a8d8, #d8d8d8);
          // content: "";
          // position: absolute;
          // top: 0;
          // left: 0;
          // width: 3px;
          // height: 100%;
          // background-color: transparent;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 8px;
          background-image: url(/assets/images/line-rainbow.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
     }
}

//  .confirm-save-popup .confirm-save btn{
//       border: 1px solid !important ;
//  }

// button.text-uppercase.btn.btn-primary {
//      border-radius: 20px;
// }

button.btn.cancel.btn {
     border: 1px solid #c8ced3 !important;
     color: #73818f;
     font-family: Roboto;
     font-weight: 700;
     text-align: center;
     text-transform: uppercase;
     width: 156.5px;
     height: 45px;
     font-size: 13px;
}

#custom-variable-form,
#domains-form {
     .variable-input-group {
          input {
               border-radius: 0;
               border-right: 0;
               border-top-left-radius: 12px;
               border-bottom-left-radius: 12px;
               padding-right: 0;
               margin-top: 3px;

               &.is-invalid {
                    border-color: #f86c6b !important;
                    padding-right: calc(1.5em + 0.75rem);

                    & + .btn-edit {
                         border-color: #f86c6b !important;
                    }

                    &:focus {
                         box-shadow: none;
                    }
               }

               &.new-variable {
                    border: 1px solid #e2e6e9;
                    border-radius: 12px;
                    margin-right: -2px;
                    margin-top: 0;
               }

               &[disabled] {
                    // color: #9da7b1;
                    background-color: transparent;
                    color: #73818f;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
               }
          }

          .btn-edit {
               background-color: transparent;
               font-size: 80%;
               color: #f96e6d;
               border: 1px solid #e2e6e9;
               border-left: 0;
               border-radius: 0;
               border-top-right-radius: 12px;
               border-bottom-right-radius: 12px;
               margin-top: 3px;
               margin-right: -2px;
          }
     }
}

#domains-form {
     .variable-input-group {
          .btn-edit {
               &.btn.disabled {
                    opacity: 1;
               }
          }
     }
}

.session-length {
     &.user-weather-box {
          max-width: 445px !important;
          color: #2f353a !important;
          margin-bottom: 30px;

          .headline {
               color: #000;
               font-size: 16px;
               font-weight: 400;
               line-height: 1.5em;
               margin-bottom: 5px;
               padding: 0 0 10px 0;
          }

          .divider {
               display: block;
               background-color: #c8ced3;
               width: 100%;
               height: 1px;
               margin: 1em 0;
          }

          .button-temperature {
               min-width: 115px;
          }

          input.has-error {
               border-color: red;
          }

          &:after {
               content: '';
               display: block;
               height: 30px;
               width: 100%;
               position: absolute;
               z-index: 5;
               bottom: -30px;
          }
     }

     .user-weather {
          margin-top: 20px;

          .condition-lb {
               font-size: 14px;

               &.error {
                    color: red;
               }
          }

          label {
               margin-bottom: 0;
          }

          .form-group {
               margin-bottom: 0.3rem;
          }

          .using-default {
               color: #2f353a !important;
               transition: 0.5s all;

               label {
                    color: #20a8d8 !important;

                    &:hover {
                         cursor: pointer;
                    }
               }
          }
     }

     .precise-location {
          margin-top: 20px;

          label {
               &:before,
               &:after {
                    cursor: pointer;
               }
          }

          #secondsAfterSessionBegins {
               margin-bottom: 10px;
          }
     }
}
