select {
     &.form-control, 
     &.form-select {
          -webkit-appearance: none;
          -moz-appearance: none;
          background: transparent;
          background-image: url("/assets/icons/icon-select.svg");
          background-repeat: no-repeat;
          background-position-x: calc(100% - 12px);
          background-position-y: center;
          background-size: 6px;
          padding-right: 23px;
          border: 1px solid #e2e6e9;
          box-shadow: none !important;
          border-radius: 12px;

          &.is-invalid {
               padding-right: 23px !important;
          }

          &:focus {
               box-shadow: none !important;
          } 
     }
}

input {
     &.form-control {
          border-radius: 12px;
     }
}

textarea {
     &.form-control {
          border: 1px solid #e2e6e9;
          box-shadow: none !important;
          border-radius: 12px;

          &:focus {
               box-shadow: none !important;
          }  
     }
}

.search-input-icon {
     position: relative;
     svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          opacity: 0.4;
          width: 17px;
     }
     > input {
          padding-left: 45px;
          // color: #758391;
          // font-weight: 500;
          border-radius: 20px;
          border: 1px solid #e4e7ea;
          background: #f0f3f5;

          color: #73818f;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          height: 40px;
          &::placeholder {
               font-family: Roboto;
               font-size: 14px;
               font-weight: 400;
          }
     }
}

.form-control {
     &.disabled {
          background-color: #e4e7ea !important;
          pointer-events: none;
          cursor: default;
     }

     &.is-invalid {
          border-color: #f86c6b !important;
     }

     &:focus {
          color: #5c6873;
     }
}

.form-control[type='number'].is-invalid {
     background: none;
}

label,
select {
     color: $body-color !important;
}
.form-group {
     label {
          color: $body-color !important;
     }
     input,
     textarea {
          &.form-control {
               color: $body-color;
               filter: none;
               &::placeholder {
                    color: $gray-600;
               }
          }
     }

     &.switch-group {
          display: flex;
          align-items: center;
          gap: 10px;

          label {
               margin-bottom: 0;
          }
     }

     &.using-type-wrapper {
          button:not(.active) {
               &:disabled,
               &.disabled {
                    &:hover {
                         background-color: #828282 !important;
                    }
               }
          }
     }
}

.select-icon-layer {
     position: relative;
     svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          opacity: 0.4;
          z-index: 2;
          width: 13px;
          height: 12px;
     }
     > select {
          padding-left: 45px;
          // color: #758391;
          // font-weight: 500;
          position: relative;
          border-radius: 20px;
          // z-index: 9;
          // background-color: transparent !important;

          border: 1px solid #e4e7ea;
          background-color: #f0f3f5;
          color: #73818f !important;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          height: 40px;
     }
}

.custom-checkbox {
     .custom-control-input,
     .custom-control-label {
          cursor: pointer;
     }
}

.form-control:disabled,
.form-control[readonly] {
     background-color: #e4e7ea;
}

.form-check-input {
     appearance: auto;
     &:checked[type="checkbox"] {
          appearance: none;
     }
}